import MasterLayout from '@/layout/master-layout';
import Head from 'next/head';
import { ButtonLink } from '@/components/button';
import { useRouter } from 'next/router';
import styles from './styles.module.scss';

const ErrorFallBackComponent = () => {
  const router = useRouter();

  return (
    <>
      <Head>
        <title>Certificate Hero | Error</title>
      </Head>
      <div id={styles.mainContainer} className={styles.mainContainer}>
        <div>
          <div className={styles.questionMarkContainer}>
            <div className={styles.questionMark}>
              <div>?</div>
            </div>
          </div>
          <h3 className={styles.errorMessage}>Oops, something went wrong</h3>
          <div className={styles.refreshMessage}>Refresh page to try again</div>
          <div className={styles.refereshButtonContainer}>
            <ButtonLink
              className={styles.refereshButton}
              onClick={() => router.reload()}
            >
              Refresh
            </ButtonLink>
          </div>
        </div>
      </div>
    </>
  );
};
ErrorFallBackComponent.MasterLayout = MasterLayout;

export default ErrorFallBackComponent;
