export const bulkJobActions = Object.freeze(
  /** @type {const} */ ({
    ADDED_TO_BULK_REVISION: 'added_to_bulk_revision',
    ADDED_TO_BULK_RENEWAL: 'added_to_bulk_renewal',
    DELETED_FROM_BULK_REVISION: 'deleted_from_bulk_revision',
    DELETED_FROM_BULK_RENEWAL: 'deleted_from_bulk_renewal',
    ISSUED_FROM_BULK_REVISION: 'issued_from_bulk_revision',
    ISSUED_FROM_BULK_RENEWAL: 'issued_from_bulk_renewal',
    SENT_FAX_FROM_BULK_JOB: 'sent_fax_from_bulk_job',
    SENT_EMAIL_FROM_BULK_JOB: 'sent_email_from_bulk_job',
  })
);

export const bulkActionsDisplayText = Object.freeze(
  /** @type {const} */ ({
    added_to_bulk_revision: 'Added to bulk revision',
    added_to_bulk_renewal: 'Added to bulk renewal',
    deleted_from_bulk_revision: 'Deleted from bulk revision',
    deleted_from_bulk_renewal: 'Deleted from bulk renewal',
    issued_from_bulk_revision: 'Issued from bulk revision',
    issued_from_bulk_renewal: 'Issued from bulk renewal',
    sent_fax_from_bulk_job: 'Sent fax from bulk job',
    sent_email_from_bulk_job: 'Sent email from bulk job',
  })
);
