import { OutboundCertificateEntity } from './outbound-certificate-entity';

export class BulkCertificateEntity extends OutboundCertificateEntity {
  /**
   * @param {Partial<BulkCertificateEntity>} cert
   */
  // eslint-disable-next-line no-useless-constructor
  constructor(cert) {
    super(cert);
  }
}
