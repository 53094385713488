import { AGENCY_CONTACT_STATUS } from '@/constants';

export class AgencyContactEntity {
  constructor({
    id,
    brokerId = '',
    name = '',
    phone = '',
    fax = '',
    email = '',
    status = AGENCY_CONTACT_STATUS.active,
    isDefault = false,
    deactivatedDate = null,
    createdAt = new Date(),
    updatedAt = new Date(),
    deleted = false,
    showNameOnCert = false,
  }) {
    this.id = id;
    this.brokerId = brokerId;
    this.name = typeof name === 'string' ? name : '';
    this.phone = typeof phone === 'string' ? phone : '';
    this.fax = typeof fax === 'string' ? fax : '';
    this.email = typeof email === 'string' ? email : '';
    this.status = status;
    this.isDefault = isDefault;
    this.deactivatedDate = deactivatedDate;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.deleted = deleted;
    this.showNameOnCert = showNameOnCert;
  }
}
