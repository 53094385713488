const config = require('./client_secrets.json');

const clientSecrets = {
  PDFTRON_WEBVIEWER_LICENSE_KEY: config.PDFTRON_WEBVIEWER_LICENSE_KEY,
  ANALYTICS_URL: config.ANALYTICS_URL,
  GTM_ID: config.GTM_ID || '',
  SWR_ERROR_TOAST_ENABLED: config.SWR_ERROR_TOAST_ENABLED === 'true',
  BROKER: config.BROKER,
  IDLE_MODAL_TIMEOUT_MINUTES: Number(config.IDLE_MODAL_TIMEOUT_MINUTES || 30), // 30 minutes,
  IDLE_MODAL_LOGOUT_MINUTES: Number(config.IDLE_MODAL_LOGOUT_MINUTES || 2), // 2 minutes,
  ENVIRONMENT_NAME: config.ENVIRONMENT_NAME,
  PUSHER_KEY: config.PUSHER_KEY,
  PUSHER_CLUSTER: config.PUSHER_CLUSTER,
  FREE_FORM_FEATURE_FLAG: config.FREE_FORM_FEATURE_FLAG === 'true',
  PDF_IMPORT_FEATURE_FLAG: config.PDF_IMPORT_FEATURE_FLAG === 'true',
  TOP_BRANDING_URL: config.TOP_BRANDING_URL,
};

function verifyConfig(secrets) {
  // Check if all our expected key names are there
  Object.keys(secrets).forEach(key => {
    const value = secrets[key];
    if (value === null || value === undefined) {
      throw Error(`Client ${key} value is missing, exiting. 
      Try running "npm run load-secrets`);
    }
  });
}

verifyConfig(clientSecrets);

export const CLIENT_SECRETS = clientSecrets;
