// TODO: Break this file up into smaller files if possible.
/* eslint-disable max-lines */

import {
  STATUS,
  IMPORT_STATUS,
  ACTIVE_STATUS,
  NAMED_INSURED_STATUS,
  LANGUAGE_LIBRARY_STATUS,
  ENDORSEMENT_STATUS,
  HOLDER_STATUS,
  AGENCY_CONTACT_STATUS,
  SIGNATURE_STATUS,
  LEAD_STATUS,
  LEAD_PRIORITY,
  USER_STATUS,
  INBOUND_CERTIFICATE_STATUS,
  USER_TYPE,
} from '@/constants';
import { AddressEntity } from '@/entities/address-entity';
import {
  BULK_JOB_STATUS,
  CERT_REQUEST_STATUS,
  FORM_TEMPLATES,
  bulkJobFlows,
} from '@certificate_hero/enums';
import { acordFormFilterOptions, parseDate } from '@/utils/helpers';
import AcordNumberRenderer from '@/components/grid/renderers/AcordNumberRenderer';
import isBefore from 'date-fns/isBefore';
import capitalize from 'lodash/capitalize';
import { format } from 'date-fns';

// ag-grid sort orders
// const ASCENDING = 'asc';
const DESCENDING = 'desc';

const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
  let cellDate = new Date(cellValue);

  // if cellDate is an invalid date, then return 0
  // this handles null, dashes, and other non-date values
  if (!cellValue || Number.isNaN(cellDate.getTime())) {
    return 0;
  }

  // compare the two dates
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

export const COLUMN_TYPES = {
  defaultColumn: {
    cellRenderer: 'defaultCell',
    minWidth: 100,
  },
  pillColumn: {
    cellRenderer: 'pill',
  },
  numberColumn: {
    filter: 'agNumberColumnFilter',
    type: ['numericColumn'],
  },
  yearColumn: {
    filter: 'agNumberColumnFilter',
    type: ['numericColumn'],
  },
  booleanColumn: {
    filter: 'agBooleanColumnFilter',
    customFilter: 'agBooleanColumnFilter',
    customFilterParams: {
      options: [
        { id: 'Yes', text: 'Yes' },
        { id: 'No', text: 'No' },
      ],
    },
    type: ['booleanColumn'],
    filterParams: {
      filterOptions: [
        'equals',
        {
          displayKey: 'booleanEquals',
          displayName: 'booleanEquals',
          test: (filterValue, cellValue) => {
            // Since we are filtering for "Yes" or "No", any value that is not "Yes" is "No",
            // So any boolean value that is not true is false.
            const isValueYes = String(cellValue) === 'true';
            const filterForYes = String(filterValue) === 'true';

            return filterForYes ? isValueYes : !isValueYes;
          },
        },
      ],
    },
  },
  emptyColumn: {
    valueFormatter: params => params.value,
  },
  dateColumn: {
    filter: 'agDateColumnFilter',
    filterParams: {
      inRangeInclusive: true,
      comparator: dateComparator,
    },
    valueFormatter: params => {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return params.value
        ? new Date(params.value).toLocaleString('en-US', options)
        : '—';
    },
    minWidth: 125,
  },
  countColumn: {
    filter: 'agNumberColumnFilter',
    valueFormatter: params => {
      return params?.value?.length ? params.value.length : 0;
    },
    sortable: false,
    minWidth: 125,
  },
  utcDateColumn: {
    filter: 'agDateColumnFilter',
    filterParams: {
      inRangeInclusive: true,
      comparator: dateComparator,
    },
    valueFormatter: params => {
      return params.value ? parseDate(params.value, 'MMM d, yyyy') : '—';
    },
    minWidth: 125,
  },
  multiSelectColumn: {
    customFilter: 'chMultiDropdownFilter',
    filterParams: {
      filterOptions: [
        'contains',
        {
          displayKey: 'withinArray',
          displayName: 'withinArray',
          test: (filterValue, cellValue) => {
            return filterValue
              .map(filter => filter.toLowerCase())
              .includes(cellValue.toLowerCase());
          },
        },
      ],
    },
  },
  expirationDateColumn: {
    customFilter: 'expirationDateColumnFilter',
    minWidth: 125,
    valueFormatter: params => {
      return params.value ? parseDate(params.value, 'MMM d, yyyy') : '—';
    },
    cellStyle: params => {
      const isExpired = isBefore(new Date(params.value), new Date());

      if (isExpired) {
        return { color: 'red' };
      }

      return null;
    },
  },

  expirationDateColumnRowData: {
    customFilter: 'expirationDateColumnFilter',
    filter: 'agDateColumnFilter',
    filterParams: {
      inRangeInclusive: true,
      comparator: dateComparator,
    },
    minWidth: 125,
    valueFormatter: params => {
      return params.value ? parseDate(params.value, 'MMM d, yyyy') : '—';
    },
    cellStyle: params => {
      const isExpired = isBefore(new Date(params.value), new Date());

      if (isExpired) {
        return { color: 'red' };
      }

      return null;
    },
  },

  monthColumn: {
    filter: 'agDateColumnFilter',
    filterParams: {
      inRangeInclusive: true,
      comparator: dateComparator,
    },
    valueFormatter: params => {
      const date = new Date(params.value);
      date.setDate(date.getDate() + 1);
      const options = {
        year: 'numeric',
        month: 'short',
      };
      return params.value ? date.toLocaleString('en-US', options) : '—';
    },
    minWidth: 125,
  },
  checkboxColumn: {
    headerName: '',
    field: 'action',
    checkboxSelection: true,
    pinned: 'left',
    width: 46,
    minWidth: 46,
    maxWidth: 46,
    lockPinned: true,
    suppressMovable: true,
    suppressAutoSize: true,
    resizable: false,
    sortable: false,
    type: ['emptyColumn'],
    headerCheckboxSelection: params => {
      return !!(
        params.api.gridOptionsService?.gridOptions.rowSelection === 'multiple'
      );
    },
    cellStyle: _params => {
      return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '3px',
      };
    },
  },
  actionMenuColumn: {
    headerName: 'Actions',
    field: 'actionMenu',
    minWidth: 124,
    maxWidth: 124,
    pinned: 'right',
    sortable: false,
    resizable: false,
    lockPinned: true,
    suppressMovable: true,
    suppressAutoSize: true,
    cellRenderer: 'actionMenu',
  },
  bulkJobIssuesColumn: {
    headerName: 'Issues',
    field: 'issues',
    tooltipField: 'issues',
    cellRenderer: 'bulkJobIssues',
    tooltipComponent: 'bulkJobIssuesTooltip',
    filter: 'agBooleanColumnFilter',
    customFilter: 'agBooleanColumnFilter',
    customFilterParams: {
      options: [
        { id: 'Yes', text: 'Yes' },
        { id: 'No', text: 'No' },
      ],
    },
    type: ['booleanColumn'],
    filterParams: {
      filterOptions: [
        'equals',
        {
          displayKey: 'booleanEquals',
          displayName: 'booleanEquals',
          test: (filterValue, cellValue) => {
            // Since we are filtering for "Yes" or "No", any value that is not "Yes" is "No",
            // So any boolean value that is not true is false.
            const isValueYes = String(cellValue.hasIssues) === 'true';
            const filterForYes = String(filterValue) === 'true';

            return filterForYes ? isValueYes : !isValueYes;
          },
        },
      ],
    },
  },
  status: {
    headerName: 'Status',
    field: 'status',
    cellRenderer: 'status',
    sortable: true,
    minWidth: 100,
  },
  fileCabinetStatus: {
    headerName: 'Status',
    field: 'status',
    cellRenderer: 'fileCabinetStatus',
    sortable: true,
    minWidth: 125,
  },
};

export const DEFAULTS = {
  ...COLUMN_TYPES.defaultColumn,
  flex: 1,
  resizable: true,
  sortable: true,
  filter: false,
  pinned: null,
  lockPinned: true,
  suppressMenu: true,
  floatingFilter: false,
  suppressMovable: false,
  floatingFilterComponentParams: {
    suppressFilterButton: true,
  },
  cellClass: _params => 'grid-center-cell',
  valueFormatter: params => {
    return params.value === undefined ||
      params.value === null ||
      params.value === ''
      ? '—'
      : params.value;
  },
};

//Grids that don't use remote data endpoint need to allow filtering.
export const DEFAULTS_WITH_ROW_DATA = {
  ...DEFAULTS,
  filter: true,
};

export const EDITOR_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    headerName: 'Name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    headerName: 'Content',
    field: 'content',
  },
];

export const FILE_CABINET_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    headerName: 'Cert #',
    field: 'certificateNumber',
    minWidth: 125,
    comparator: (valueA, valueB) => {
      if (valueA === valueB) {
        return 0;
      }

      return Number(valueA) > Number(valueB) ? 1 : -1;
    },
    filterField: 'certificateNumber',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.utcDateColumn,
    headerName: 'Issued',
    field: 'issued',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.utcDateColumn,
    headerName: 'Deactivated',
    field: 'deactivated',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.fileCabinetStatus,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(STATUS)
        .filter(
          val =>
            [
              STATUS.active,
              STATUS.inactive,
              STATUS.overridden,
              STATUS.inProgress,
            ].indexOf(val) >= 0
        )
        .map(value => ({ text: value, id: value })),
    },
  },
  {
    ...DEFAULTS,
    headerName: 'Named Insured',
    field: 'namedInsuredName',
    minWidth: 150,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.multiSelectColumn,
    headerName: 'Assigned to',
    field: 'assignedToUserName',
    filterField: 'assignedUserId',
    minWidth: 200,
  },
  {
    ...DEFAULTS,
    headerName: 'Holder',
    field: 'holderName',
    minWidth: 150,
  },
  {
    ...DEFAULTS,
    headerName: 'Description Of Operations',
    field: 'descriptionOfOperations',
    tooltipField: 'descriptionOfOperations',
    minWidth: 225,
    maxWidth: 675,
  },
  {
    ...DEFAULTS,
    headerName: 'Summary',
    field: 'summary',
    minWidth: 150,
  },

  {
    ...DEFAULTS,
    ...COLUMN_TYPES.multiSelectColumn,
    headerName: 'Acord Form',
    field: 'baseFormTemplateId',
    customFilterParams: {
      options: acordFormFilterOptions(),
    },
    valueFormatter: params =>
      AcordNumberRenderer({
        value: params.value,
      }).toUpperCase(),
  },
  {
    ...COLUMN_TYPES.numberColumn,
    headerName: 'Endorsements',
    field: 'endorsementCount',
    tooltipField: 'endorsementNames',
    suppressFilter: true,
  },
];

export const OUTBOUND_CERTIFICATE_REQUESTS_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.fileCabinetStatus,
    ...COLUMN_TYPES.multiSelectColumn,
    headerName: 'Request Status',
    field: 'requestInfo.requestStatus',
    customFilterParams: {
      options: Object.values(CERT_REQUEST_STATUS)
        .filter(
          val =>
            [
              CERT_REQUEST_STATUS.inProgress,
              CERT_REQUEST_STATUS.rejected,
              CERT_REQUEST_STATUS.completed,
            ].indexOf(val) >= 0
        )
        .map(value => ({ text: value, id: value })),
    },
  },
  {
    ...DEFAULTS,
    headerName: 'Insured',
    field: 'insuredName',
  },
  {
    ...DEFAULTS,
    headerName: 'Request Original Email',
    field: 'requestInfo.originalSenderEmail',
  },
  {
    ...DEFAULTS,
    headerName: 'Holder',
    field: 'holderName',
  },
];

export const INBOUND_FILE_CABINET_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
    hide: true,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.utcDateColumn,
    headerName: 'Date Expires',
    field: 'earliestExpDate',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(INBOUND_CERTIFICATE_STATUS).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
  {
    ...DEFAULTS,
    headerName: 'Named Insured',
    field: 'namedInsuredData',
    minWidth: 150,
  },
  {
    ...DEFAULTS,
    headerName: 'Holder',
    field: 'holderData',
    minWidth: 150,
  },
  {
    ...DEFAULTS,
    headerName: 'Description Of Operations',
    field: 'descriptionOfOperations',
    tooltipField: 'descriptionOfOperations',
    minWidth: 225,
    maxWidth: 675,
  },
];

export const ENDORSEMENTS_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
  },
  {
    ...DEFAULTS,
    headerName: 'Name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    headerName: 'Description',
    field: 'description',
    tooltipField: 'description',
  },
  {
    ...DEFAULTS,
    headerName: 'Policy',
    field: 'policyNumber',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.expirationDateColumn,
    headerName: 'Expiration Date',
    field: 'policy.expirationDate',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.monthColumn,
    headerName: 'Edition Date',
    field: 'endorsementForm.editionDate',
  },
  {
    ...DEFAULTS,
    headerName: 'Form',
    field: 'endorsementForm.name',
    flex: 1,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(ENDORSEMENT_STATUS).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
];

export const ENDORSEMENT_FORMS_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
  },
  {
    ...DEFAULTS,
    headerName: 'Name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    headerName: 'Form Number',
    field: 'formNumber',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.monthColumn,
    headerName: 'Edition Date',
    field: 'editionDate',
  },
  {
    ...DEFAULTS,
    headerName: 'LOB Type Name',
    field: 'lobTypeName',
    tooltipField: 'Line Of Business Type',
  },
];

export const ENDORSEMENT_FORMS_WITH_POLICY_COLUMNS = [
  ...ENDORSEMENT_FORMS_COLUMNS,
  {
    ...DEFAULTS,
    headerName: 'Policies Available',
    cellRenderer: 'boolean',
    field: 'policiesAvailable',
  },
];

export const CERTIFICATE_TEMPLATE_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
  },
  {
    ...DEFAULTS,
    headerName: 'Template Name',
    field: 'templateName',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(ACTIVE_STATUS).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
  {
    ...DEFAULTS,
    headerName: 'Named Insured',
    field: 'namedInsuredName',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.multiSelectColumn,
    headerName: 'Acord Form',
    field: 'baseFormTemplateId',
    customFilterParams: {
      options: acordFormFilterOptions(),
    },
    valueFormatter: params =>
      AcordNumberRenderer({
        value: params.value,
      }).toUpperCase(),
  },
  {
    ...DEFAULTS,
    headerName: 'Description Of Operations',
    field: 'descriptionOfOperations',
    tooltipField: 'descriptionOfOperations',
  },
  {
    ...DEFAULTS,
    headerName: 'Summary',
    field: 'summary',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.expirationDateColumn,
    headerName: 'Expiration Date',
    field: 'earliestExpDate',
  },
];

export const CERTIFICATE_TEMPLATE_RENEWAL_COLUMNS = [
  ...CERTIFICATE_TEMPLATE_COLUMNS.filter(column => column.field !== 'status'),
  {
    ...DEFAULTS,
    headerName: 'Notes',
    field: 'renewalNotes',
    tooltipField: 'renewalNotes',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.booleanColumn,
    headerName: 'Processed',
    field: 'issues',
    tooltipField: 'issues',
    cellRenderer: 'bulkJobIssues',
    tooltipComponent: 'bulkJobIssuesTooltip',
    suppressFilter: true, // We can't enable filtering on this column, because it's a "/grid" endpoint grid, but the column is a computed column
  },
];
export const CERTIFICATE_TEMPLATE_QUICK_ISSUE_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.booleanColumn,
    headerName: 'Quick Issue Default',
    field: 'isQuickIssueDefaultTemplate',
    cellRenderer: 'boolean',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
    hide: true,
  },
  {
    ...DEFAULTS,
    headerName: 'Template Name',
    field: 'templateName',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(ACTIVE_STATUS).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.multiSelectColumn,
    headerName: 'Acord Form',
    field: 'baseFormTemplateId',
    customFilterParams: {
      options: acordFormFilterOptions(),
    },
    valueFormatter: params =>
      AcordNumberRenderer({
        value: params.value,
      }).toUpperCase(),
  },
  {
    ...DEFAULTS,
    headerName: 'Description Of Operations',
    field: 'descriptionOfOperations',
    tooltipField: 'descriptionOfOperations',
    hide: true,
  },
  {
    ...DEFAULTS,
    headerName: 'Summary',
    field: 'summary',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.expirationDateColumn,
    headerName: 'Expiration Date',
    field: 'earliestExpDate',
  },
];

export const LANGUAGE_LIBRARY_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },

  {
    ...DEFAULTS,
    headerName: 'Name',
    field: 'name',
    maxWidth: 300,
  },
  {
    ...DEFAULTS,
    headerName: 'Content',
    field: 'content',
    tooltipField: 'content',
    cellRenderer: 'largeContent',
    flex: 4,
    maxWidth: 400,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(LANGUAGE_LIBRARY_STATUS).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
];

export const LANGUAGE_LIBRARY_COLUMNS_WITH_ROW_DATA = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },

  {
    ...DEFAULTS_WITH_ROW_DATA,
    headerName: 'Name',
    field: 'name',
    maxWidth: 300,
  },
  {
    ...DEFAULTS_WITH_ROW_DATA,
    headerName: 'Content',
    field: 'content',
    tooltipField: 'content',
    cellRenderer: 'largeContent',
    flex: 4,
    maxWidth: 400,
  },
  {
    ...DEFAULTS_WITH_ROW_DATA,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
  },
];

export const CERTIFICATE_IMPORT_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
  },
  {
    ...DEFAULTS,
    headerName: 'Import Name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    headerName: '# of Certs',
    field: 'certTotal',
    disableFilter: true,
  },
  {
    ...DEFAULTS,
    headerName: 'Description',
    field: 'description',
  },
  {
    ...DEFAULTS,
    headerName: 'Notes',
    field: 'notes',
    tooltipField: 'notes',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(IMPORT_STATUS).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
];

export const BULK_JOBS_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
  },
  {
    ...DEFAULTS,
    headerName: 'Job #',
    field: 'bulkJobNumber',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.multiSelectColumn,
    headerName: 'Type',
    field: 'operation',
    valueFormatter: params => {
      return params.value === bulkJobFlows.REVISE
        ? 'Revision'
        : params.value === bulkJobFlows.RENEW
        ? 'Renewal'
        : 'Reconciliation';
    },
    customFilterParams: {
      options: Object.values(bulkJobFlows).map(value => ({
        text:
          value === bulkJobFlows.REVISE
            ? 'Revision'
            : value === bulkJobFlows.RENEW
            ? 'Renewal'
            : 'Reconciliation',
        id: value,
      })),
    },
  },
  {
    ...DEFAULTS,
    headerName: 'Name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    headerName: 'Created By',
    field: 'createdByUserName',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(BULK_JOB_STATUS).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
  {
    ...DEFAULTS,
    headerName: 'Remaining Certificates',
    field: 'certsInBulkJobCount',
  },
  {
    ...DEFAULTS,
    headerName: 'Issued Certificates',
    field: 'certsIssuedFromBulkJob',
  },
];

export const HOLDER_MANAGER_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
    hide: true,
  },
  {
    ...DEFAULTS,
    headerName: 'Name',
    tooltipField: 'name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    headerName: 'Primary Address',
    field: 'addresses',
    filterField: 'addresses.fullAddress',
    tooltipValueGetter: ({ value }) =>
      value && value.length
        ? new AddressEntity(value[0]).fullAddress
        : 'No Address',
    valueFormatter: ({ value }) => {
      if (value && value.length) {
        const sortedAddresses = value.sort(function (a, b) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        return new AddressEntity(sortedAddresses[0]).fullAddress;
      } else {
        return 'No Address';
      }
    },
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.numberColumn,
    headerName: '# Of Addresses',
    field: 'addressCount',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(HOLDER_STATUS).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
];

export const NAMED_INSURED_MANAGER_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
    hide: true,
  },
  {
    ...DEFAULTS,
    headerName: 'Name',
    tooltipField: 'name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    headerName: 'Address',
    field: 'mailingAddress1',
  },
  {
    ...DEFAULTS,
    headerName: 'City',
    field: 'city',
  },
  {
    ...DEFAULTS,
    headerName: 'State (Format: NY)',
    field: 'state',
    filterInputMaxLimit: 2,
  },
  {
    ...DEFAULTS,
    headerName: 'Zip Code',
    field: 'postalCode',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.booleanColumn,
    headerName: 'Default',
    field: 'isDefault',
    cellRenderer: 'boolean',
  },

  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(NAMED_INSURED_STATUS).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
];

export const AGENCY_CONTACT_MANAGER_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
    hide: true,
  },
  {
    ...DEFAULTS,
    headerName: 'Name',
    field: 'name',
  },

  {
    ...DEFAULTS,
    headerName: 'Phone',
    field: 'phone',
  },
  {
    ...DEFAULTS,
    headerName: 'Fax',
    field: 'fax',
  },
  {
    ...DEFAULTS,
    headerName: 'Email',
    field: 'email',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.booleanColumn,
    headerName: 'Default',
    field: 'isDefault',
    cellRenderer: 'boolean',
    sortable: true,
    editable: false,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(AGENCY_CONTACT_STATUS).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
];

export const MANAGE_USERS_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Last Login',
    field: 'lastLogin',
  },

  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Updated At',
    field: 'updatedAt',
  },
  {
    ...DEFAULTS,
    headerName: 'Email',
    field: 'email',
    tooltipField: 'email',
  },
  {
    ...DEFAULTS,
    headerName: 'First Name',
    field: 'firstName',
  },
  {
    ...DEFAULTS,
    headerName: 'Last Name',
    field: 'lastName',
  },
  {
    ...DEFAULTS,
    headerName: 'User Type',
    field: 'userType',
    sortable: true,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(USER_TYPE).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
  {
    ...DEFAULTS,
    headerName: 'Group',
    field: 'group.name',
    hide: true,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    cellRenderer: 'userStatus',
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(USER_STATUS).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
];

export const MANAGE_GROUPS_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Last Updated',
    field: 'updatedAt',
  },
  {
    ...DEFAULTS,
    headerName: 'Name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    headerName: 'Type',
    field: 'type',
    valueFormatter: ({ value }) => capitalize(value),
  },
  {
    ...DEFAULTS,
    headerName: 'Users',
    field: 'numberOfUsers',
    suppressFilter: true,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(ACTIVE_STATUS).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
];

export const MANAGE_INSUREDS_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
  },

  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Updated At',
    field: 'updatedAt',
  },
  {
    ...DEFAULTS,
    headerName: 'Name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    headerName: 'City',
    field: 'city',
  },
  {
    ...DEFAULTS,
    headerName: 'State',
    field: 'state',
  },
  {
    ...DEFAULTS,
    headerName: 'Postal Code',
    field: 'postalCode',
  },
  {
    ...DEFAULTS,
    headerName: 'Quick Issue Email Domain',
    field: 'quickIssueEmailDomain',
  },
];

export const BULK_JOB_EDITOR_COLUMNS = () => [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.bulkJobIssuesColumn,
  },
  {
    ...DEFAULTS,
    headerName: 'Cert #',
    sort: DESCENDING,
    field: 'certificateNumber',
    minWidth: 125,
    filterField: 'certificateNumber',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Issued',
    field: 'issued',
  },
  {
    ...DEFAULTS,
    headerName: 'Named Insured',
    field: 'namedInsuredName',
  },
  {
    ...DEFAULTS,
    headerName: 'Holder',
    field: 'holder.name',
  },
  {
    ...DEFAULTS,
    headerName: 'Holder Address1',
    field: 'address.mailingAddress1',
    hide: true,
  },
  {
    ...DEFAULTS,
    headerName: 'Holder Address2',
    field: 'address.mailingAddress2',
    hide: true,
  },
  {
    ...DEFAULTS,
    headerName: 'Holder City',
    field: 'address.city',
    hide: true,
  },
  {
    ...DEFAULTS,
    headerName: 'Holder State',
    field: 'address.state',
    hide: true,
  },
  {
    ...DEFAULTS,
    headerName: 'Holder Zip',
    field: 'address.postalCode',
    hide: true,
  },
  {
    ...DEFAULTS,
    headerName: 'Description Of Operations',
    field: 'descriptionOfOperations',
    tooltipField: 'descriptionOfOperations',
    cellEditor: 'agLargeTextCellEditor',
  },
  {
    ...DEFAULTS,
    headerName: 'Email',
    field: 'contacts.emailTo.0',
  },
  {
    ...DEFAULTS,
    headerName: 'Fax',
    field: 'contacts.faxNumber.0',
  },
  {
    ...DEFAULTS,
    headerName: 'Summary',
    field: 'summary',
  },
];

export const RENEWAL_CERT_EDITOR_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.bulkJobIssuesColumn,
  },
  {
    ...DEFAULTS,
    headerName: 'Cert #',
    field: 'certificateNumber',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
  },
  {
    ...DEFAULTS,
    headerName: 'Named Insured',
    field: 'namedInsuredName',
  },
  {
    ...DEFAULTS,
    headerName: 'Holder',
    field: 'holder.name',
  },
  {
    ...DEFAULTS,
    headerName: 'Description Of Operations',
    field: 'descriptionOfOperations',
    tooltipField: 'descriptionOfOperations',
    cellEditor: 'agLargeTextCellEditor',
  },
  {
    ...DEFAULTS,
    headerName: 'Email',
    field: 'contacts.emailTo.0',
  },
  {
    ...DEFAULTS,
    headerName: 'Fax',
    field: 'contacts.faxNumber.0',
  },
  {
    ...DEFAULTS,
    headerName: 'Summary',
    field: 'summary',
  },

  {
    ...DEFAULTS,
    ...COLUMN_TYPES.countColumn,
    headerName: 'Endorsements',
    field: 'endorsementIds',
    tooltipField: 'endorsementNames',
    suppressFilter: true,
  },
];

export const HOME_ASSIGNED_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.numberColumn,
    sortable: false,
    headerName: 'Cert #',
    field: 'certificateNumber',
  },
  {
    ...DEFAULTS,
    sortable: false,
    headerName: 'Insured',
    field: 'insuredName',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    sortable: false,
    headerName: 'Last Update',
    field: 'updatedAt',
  },
];

export const HOME_OUTBOUND_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.numberColumn,
    sortable: false,
    headerName: 'Cert #',
    field: 'certificateNumber',
  },
  {
    ...DEFAULTS,
    sortable: false,
    headerName: 'Insured',
    field: 'insuredName',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    sortable: false,
    headerName: 'Last Update',
    field: 'updatedAt',
  },
];

export const HOME_RECENT_COLUMNS = [
  {
    ...DEFAULTS,
    lockPosition: true,
    ...COLUMN_TYPES.dateColumn,
    sortable: false,
    headerName: 'Last Update',
    field: 'updatedAt',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.numberColumn,
    sortable: false,
    headerName: 'Cert #',
    field: 'certificateNumber',
  },
  {
    ...DEFAULTS,
    sortable: false,
    headerName: 'Last Action',
    field: 'lastAction',
  },
  {
    ...DEFAULTS,
    sortable: false,
    headerName: 'Insured',
    field: 'insuredName',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.fileCabinetStatus,
    sortable: false,
    customFilterParams: {
      options: [STATUS.active, STATUS.inProgress, STATUS.inactive].map(
        value => ({
          text: value,
          id: value,
        })
      ),
    },
  },
];

export const HOME_PROGRESS_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.numberColumn,
    sortable: false,
    headerName: 'Cert #',
    field: 'certificateNumber',
  },
  {
    ...DEFAULTS,
    sortable: false,
    headerName: 'Insured',
    field: 'insuredName',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    sortable: false,
    headerName: 'Last Update',
    field: 'updatedAt',
  },
  {
    ...DEFAULTS,
    headerName: 'Assigned To',
    field: 'assignedToUserName',
  },
];

export const DISPLAY_ISSUES_COLUMNS = [
  {
    ...DEFAULTS,
    sortable: false,
    headerName: 'Type of Issue',
    field: 'type',
    autoHeight: true,
    minWidth: '150',
  },
  {
    ...DEFAULTS,
    sortable: false,
    headerName: 'Description',
    field: 'description',
    wrapText: true,
    autoHeight: true,
    minWidth: '250',
  },
];

export const POLICY_MANAGER_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
    hide: true,
  },
  {
    ...DEFAULTS,
    headerName: 'Policy Number',
    field: 'policyNumber',
  },
  {
    ...DEFAULTS,
    headerName: 'Policy Name',
    field: 'policyName',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.utcDateColumn,
    headerName: 'Effective Date',
    field: 'effectiveDate',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.expirationDateColumn,
    headerName: 'Expiration Date',
    field: 'expirationDate',
  },
  {
    ...DEFAULTS,
    headerName: 'Insurer',
    field: 'insurerName',
  },
  {
    ...DEFAULTS,
    headerName: 'NAIC Number',
    field: 'naicNumber',
  },
  {
    ...DEFAULTS,
    headerName: 'Description',
    field: 'description',
  },
];

export const NEW_AMS_POLICY_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS_WITH_ROW_DATA,
    headerName: 'Policy Number',
    field: 'policyNumber',
    sort: DESCENDING,
  },
  {
    ...DEFAULTS_WITH_ROW_DATA,
    headerName: 'Policy Name',
    field: 'policyName',
  },
  {
    ...DEFAULTS_WITH_ROW_DATA,
    ...COLUMN_TYPES.utcDateColumn,
    headerName: 'Effective Date',
    field: 'effectiveDate',
  },
  {
    ...DEFAULTS_WITH_ROW_DATA,
    ...COLUMN_TYPES.expirationDateColumnRowData,
    headerName: 'Expiration Date',
    field: 'expirationDate',
  },
  {
    ...DEFAULTS_WITH_ROW_DATA,
    headerName: 'Insurer',
    field: 'insurerName',
  },
  {
    ...DEFAULTS_WITH_ROW_DATA,
    headerName: 'NAIC Number',
    field: 'naicNumber',
  },
];

export const LOB_TEMPLATES_RENEWAL_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.booleanColumn,
    headerName: 'Processed',
    field: 'isProcessed',
    cellRenderer: 'boolean',
    sortable: true,
    editable: false,
  },
  {
    ...DEFAULTS,
    headerName: 'Name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    headerName: 'Prior Policy',
    field: 'policy.policyName',
  },
  {
    ...DEFAULTS,
    headerName: 'Renewal Policy',
    field: 'renewalPolicy',
  },
  {
    ...DEFAULTS,
    headerName: 'LOB Symbol',
    field: 'lobSymbol',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.multiSelectColumn,
    headerName: 'Acord Form',
    field: 'formId',
    customFilterParams: {
      options: acordFormFilterOptions(),
    },
    valueFormatter: params =>
      AcordNumberRenderer({
        value: FORM_TEMPLATES.find(
          formTemplate => formTemplate.id === params.value
        )?.shortName,
      }).toUpperCase(),
  },
  {
    ...DEFAULTS,
    headerName: 'LOB Type Name',
    field: 'lobTypeName',
  },
  {
    ...DEFAULTS,
    headerName: 'certs',
    field: 'count',
    tooltipField: 'countTooltip',
  },
];

export const LOB_TEMPLATES_RECONCILIATION_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.booleanColumn,
    headerName: 'Processed',
    field: 'isProcessed',
    cellRenderer: 'boolean',
    sortable: true,
    editable: false,
  },
  {
    ...DEFAULTS,
    headerName: 'Name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    headerName: 'Prior Policy',
    field: 'oldPolicy.policyNumber',
  },
  {
    ...DEFAULTS,
    headerName: 'New Policy',
    field: 'policy.policyNumber',
  },
  {
    ...DEFAULTS,
    headerName: 'LOB Symbol',
    field: 'lobSymbol',
    valueFormatter: params => {
      if (
        !params?.data?.parentLob?.lobSymbol ||
        params.value === params.data.parentLob.lobSymbol
      ) {
        return params.value;
      }

      return `${params.data.parentLob.lobSymbol} → ${params.value}`;
    },
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.multiSelectColumn,
    headerName: 'Acord Form',
    field: 'formId',
    customFilterParams: {
      options: acordFormFilterOptions(),
    },
    valueFormatter: params =>
      AcordNumberRenderer({
        value: FORM_TEMPLATES.find(
          formTemplate => formTemplate.id === params.value
        )?.shortName,
      }).toUpperCase(),
  },
  {
    ...DEFAULTS,
    headerName: 'LOB Type Name',
    field: 'lobTypeName',
  },
];

export const LOB_TEMPLATES_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.booleanColumn,
    headerName: 'Issues',
    field: 'isInvalid',
    tooltipField: 'issues',
    cellRenderer: 'lobIssues',
    tooltipComponent: 'lobTemplateIssuesToolTip',
  },

  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
    hide: true,
  },
  {
    ...DEFAULTS,
    headerName: 'Name',
    tooltipField: 'name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    headerName: 'Policy Name',
    tooltipField: 'policy.policyName',
    field: 'policy.policyName',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.expirationDateColumn,
    headerName: 'Expiration Date',
    field: 'policy.expirationDate',
  },
  {
    ...DEFAULTS,
    headerName: 'LOB Symbol',
    field: 'lobSymbol',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.multiSelectColumn,
    headerName: 'Acord Form',
    field: 'formId',
    customFilterParams: {
      options: acordFormFilterOptions(),
    },
    valueFormatter: params =>
      AcordNumberRenderer({
        value: FORM_TEMPLATES.find(
          formTemplate => formTemplate.id === params.value
        )?.shortName,
      }).toUpperCase(),
  },
  {
    ...DEFAULTS,
    headerName: 'LOB Type Name',
    field: 'lobTypeName',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.booleanColumn,
    headerName: 'Default',
    field: 'isDefault',
    cellRenderer: 'boolean',
    sortable: true,
    editable: false,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(ACTIVE_STATUS).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
];

export const SIGNATURE_MANAGER_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
    hide: true,
  },
  {
    ...DEFAULTS,
    headerName: 'Name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.booleanColumn,
    headerName: 'Default',
    field: 'isDefault',
    cellRenderer: 'boolean',
    sortable: true,
    editable: false,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(SIGNATURE_STATUS).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
];

export const CID_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    headerName: 'Created',
    field: 'createdAt',
    sort: DESCENDING,
    hide: true,
  },
  {
    ...DEFAULTS,
    headerName: 'Name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    headerName: 'Term Start',
    field: 'termStart',
    ...COLUMN_TYPES.utcDateColumn,
    sortable: true,
    editable: false,
  },
  {
    ...DEFAULTS,
    headerName: 'Term End',
    field: 'termEnd',
    ...COLUMN_TYPES.utcDateColumn,
    sortable: true,
    editable: false,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.booleanColumn,
    headerName: 'Default',
    field: 'isDefault',
    cellRenderer: 'boolean',
    sortable: true,
    editable: false,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(ACTIVE_STATUS).map(value => ({
        text: value,
        id: value,
      })),
    },
  },
];

export const RENEW_POLICIES_COLUMNS = [
  {
    ...DEFAULTS,
    headerName: 'Name',
    field: 'policyName',
  },
  {
    ...DEFAULTS,
    headerName: 'Line of Business',
    field: 'lobTemplate.lobTypeName',
  },
  {
    ...DEFAULTS,
    headerName: 'Policy #',
    field: 'policyNumber',
  },

  {
    ...DEFAULTS,
    headerName: 'certs',
    field: 'count',
    tooltipField: 'countTooltip',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.utcDateColumn,
    headerName: 'Effective Date',
    field: 'effectiveDate',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.utcDateColumn,
    headerName: 'Expiration Date',
    field: 'expirationDate',
  },
];

export const RECONCILIATION_POLICIES_COLUMNS = [
  {
    ...DEFAULTS,
    headerName: 'Name',
    field: 'policyName',
  },
  {
    ...DEFAULTS,
    headerName: 'Line of Business',
    field: 'lobTypeName',
  },
  {
    ...DEFAULTS,
    headerName: 'Policy #',
    field: 'policyNumber',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.utcDateColumn,
    headerName: 'Effective Date',
    field: 'effectiveDate',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.utcDateColumn,
    headerName: 'Expiration Date',
    field: 'expirationDate',
  },
];

export const ENDORSEMENT_MAPPER_COLUMNS = [
  {
    ...DEFAULTS,
    headerName: 'Name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    headerName: 'Prior Policy',
    field: 'policy.policyName',
  },
  {
    ...DEFAULTS,
    headerName: 'Certs',
    field: 'count',
    tooltipField: 'countTooltip',
  },
  {
    ...DEFAULTS,
    headerName: 'Renewal Policy',
    field: 'renewalPolicy',
  },
];

export const NAIC_LOOKUP_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS_WITH_ROW_DATA,
    headerName: 'NAIC #',
    field: 'coCode',
    suppressMovable: true,
    lockPosition: true,
    maxWidth: 300,
    minWidth: 300,
  },
  {
    ...DEFAULTS_WITH_ROW_DATA,
    headerName: 'Insurer',
    field: 'fullCompanyName',
  },
];

export const BULK_JOB_LOB_COLUMNS = [
  {
    ...DEFAULTS,
    headerName: 'LOB Type',
    field: 'lobTypeName',
    tooltipField: 'LOB Type',
  },
  {
    ...DEFAULTS,
    headerName: 'LOB Name',
    field: 'name',
  },
  {
    ...DEFAULTS,
    headerName: 'Form',
    field: 'formId',
  },
  {
    ...DEFAULTS,
    headerName: 'Policy Name',
    field: 'policy.policyName',
  },
  {
    ...DEFAULTS,
    headerName: 'Policy #',
    field: 'policy.policyNumber',
  },

  {
    ...DEFAULTS,
    ...COLUMN_TYPES.expirationDateColumn,
    headerName: 'Expiration Date',
    field: 'policy.expirationDate',
    sort: DESCENDING,
  },
];

export const LEADS_COLUMNS = [
  { ...DEFAULTS, ...COLUMN_TYPES.checkboxColumn },
  {
    ...DEFAULTS,
    headerName: 'Insured',
    field: 'insuredData',
  },
  {
    ...DEFAULTS,
    headerName: 'Coverage',
    field: 'coverage',
  },
  {
    ...DEFAULTS,
    headerName: 'Carriers',
    field: 'carriers',
    hide: true,
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.utcDateColumn,
    headerName: 'Effective Date',
    field: 'effectiveDate',
  },
  {
    ...DEFAULTS,
    headerName: 'Holder',
    field: 'holderData',
    minWidth: 150,
  },
  {
    ...DEFAULTS,
    headerName: 'Assigned To',
    field: 'assignedTo',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.status,
    cellRenderer: 'leadStatus',
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(LEAD_STATUS)
        .filter(
          status =>
            ![LEAD_STATUS.duplicate, LEAD_STATUS.appointment].includes(status)
        )
        .map(value => ({
          text: value,
          id: value,
        })),
    },
  },
  {
    ...DEFAULTS,
    headerName: 'Priority',
    field: 'priority',
    ...COLUMN_TYPES.multiSelectColumn,
    customFilterParams: {
      options: Object.values(LEAD_PRIORITY)
        .reverse()
        .map(value => ({
          text: value,
          id: value,
        })),
    },
  },
];

export const IMPORT_ISSUES_COLUMNS = [
  {
    ...DEFAULTS,
    headerName: 'Row',
    field: 'line',
    maxWidth: 150,
    cellStyle: {
      'font-size': '18px',
    },
  },
  {
    ...DEFAULTS,
    headerName: 'Issue',
    field: 'issue',
    maxWidth: 350,
    cellStyle: {
      'justify-content': 'flex-start',
    },
  },
  {
    ...DEFAULTS,
    headerName: 'Description',
    field: 'description',
    minWidth: 250,
    cellStyle: {
      'white-space': 'pre-line',
      'justify-content': 'flex-start',
      'text-align': 'left',
    },
  },
];

export const INGESTION_COLUMNS = [
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.checkboxColumn,
  },
  {
    ...DEFAULTS,
    headerName: 'Insured',
    sortable: false,
    field: 'insuredName',
  },
  {
    ...DEFAULTS,
    ...COLUMN_TYPES.dateColumn,
    valueFormatter: params => {
      if (!params.value) {
        return 'Not started yet';
      }
      return format(new Date(params.value), 'MM/dd/yy hh:mm a');
    },
    headerName: 'Ingestion Started At',
    sortable: false,
    field: 'ingestedStartedAt',
  },
  {
    ...DEFAULTS,
    headerName: 'Total Certs To Ingest',
    valueFormatter: params =>
      params.value >= 0 ? params.value : 'Not started yet',
    sortable: false,
    field: 'totalCertsToIngest',
  },
  {
    ...DEFAULTS,
    valueFormatter: params => {
      if (!params.value) {
        return 'Not completed yet';
      }
      return format(new Date(params.value), 'MM/dd/yy hh:mm a');
    },
    sortable: false,
    headerName: 'Ingestion Completed At',
    field: 'ingestedAt',
  },
  {
    ...DEFAULTS,
    headerName: 'Started by',
    sortable: false,
    field: 'userName',
  },
];
