import { useContext, useEffect, useMemo, useState } from 'react';
import cx from 'clsx';
import { exceedsAmsValue, formatMappingValue } from '@/utils/helpers';
import { coverageLineStyles } from '@/enums/coverage-line-styles';
import { covDataTypes } from '@/enums/lob-data-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { coverageFlowStyles } from '@/enums/coverage-flow-styles';
import Label from '../label';
import TextInput from '../text-input';
import styles from './styles.module.scss';
import { ErrorContext } from '../lob-templates/context';

const { LIMIT } = covDataTypes;

/**
 * @param {object} params
 * @param {string} params.mode
 * @param {LobTemplateFieldMapping} params.mapping
 * @param {(params: {updatedMapping: LobTemplateFieldMapping, position: LobFieldMapping['formPosition']}) => void} params.updateFieldMapping
 * @param {boolean} params.isReadonly
 * @param {LobFieldMapping['formPosition']} params.position
 * @param {number} params.contentLimit
 * @param {boolean} params.hasAmsCoverageData
 *  @param {boolean} params.isAmsPolicyValidating
 */
const StructuredField = ({
  mode,
  mapping,
  updateFieldMapping,
  isReadonly,
  position,
  contentLimit,
  hasAmsCoverageData,
  isAmsPolicyValidating,
}) => {
  const [fieldMapping, setFieldMapping] = useState({});
  const [exceedsLimit, setExceedsLimit] = useState(false);
  const [{ coverage: coverageError }, setError] = useContext(ErrorContext);

  useEffect(() => {
    if (!mapping) {
      return;
    }

    setFieldMapping({
      ...mapping,
      value: mapping.value?.toString().replace(/,/g, ''),
    });
  }, [mapping]);

  const shouldShowLabel =
    fieldMapping.lineStyle !== coverageLineStyles.INDICATORS_ONLY;
  const placeholder = shouldShowLabel ? 'Value' : fieldMapping.label;

  const amsValue = useMemo(() => {
    if (mapping.amsValue === '') {
      return 'Not Found';
    }

    return formatMappingValue(mapping.amsValue).replace(/,/g, '');
  }, [mapping.amsValue]);

  const disableCertOnlyField =
    mapping.flowStyle === coverageFlowStyles.CERT_ONLY && isAmsPolicyValidating;

  const subText = useMemo(() => {
    if (mapping.flowStyle === coverageFlowStyles.CERT_ONLY) {
      return 'Certificate Only Field';
    }

    if (isAmsPolicyValidating || (mode && hasAmsCoverageData)) {
      return `AMS Value: ${
        isAmsPolicyValidating ? 'Loading' : formatMappingValue(amsValue)
      }`;
    }

    return '';
  }, [
    amsValue,
    hasAmsCoverageData,
    mapping.flowStyle,
    mode,
    isAmsPolicyValidating,
  ]);

  useEffect(() => {
    if (!mapping) {
      return;
    }

    if (!isAmsPolicyValidating && exceedsAmsValue(mapping)) {
      setExceedsLimit(true);
      setError(prev => ({
        ...prev,
        type: 'editor',
        coverage: 'Entered coverage value exceeds AMS value',
      }));
      return;
    }

    setExceedsLimit(false);
  }, [isAmsPolicyValidating, mapping, setError]);

  const onChange = event => {
    let isValueValid = true;

    if (fieldMapping.type === LIMIT) {
      const strippedValue = event.target.value.replace(/,/g, '');
      const strippedAMSValue = amsValue.replace(/,/g, '');

      if (/^-?\d+$/.test(strippedAMSValue) && /^-?\d+$/.test(strippedValue)) {
        isValueValid =
          parseFloat(strippedAMSValue) >= parseFloat(strippedValue);
      }
    }

    const value = isValueValid
      ? event.target.value.replace(/,/g, '')
      : amsValue;

    setFieldMapping(prevState => ({
      ...prevState,
      value,
    }));

    if (coverageError) {
      setError(prev => ({ ...prev, coverage: null }));
    }
  };

  const onBlur = () => {
    updateFieldMapping({ updatedMapping: fieldMapping, position });
  };

  return (
    <div className={cx(styles.individualMapping)}>
      {shouldShowLabel && (
        <Label className={cx(styles.nameField, 'my-auto')}>
          {fieldMapping.label}
        </Label>
      )}

      {exceedsLimit ? (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 200 }}
          overlay={<Tooltip>{`Cannot exceed AMS amount ${amsValue}`}</Tooltip>}
        >
          <div className={styles.errorIcon}>
            <FontAwesomeIcon icon={faExclamation} />
          </div>
        </OverlayTrigger>
      ) : null}

      <TextInput
        id={fieldMapping.id}
        data-cy={`${mapping.name}_input`}
        value={
          fieldMapping?.skipValueFormatting
            ? fieldMapping.value
            : formatMappingValue(fieldMapping.value)
        }
        subText={subText}
        placeholder={placeholder}
        containerClassName={styles.valueField}
        disabled={
          (isReadonly && !fieldMapping.editable) || disableCertOnlyField
        }
        onChange={onChange}
        onBlur={onBlur}
        maxLimit={contentLimit}
      />
    </div>
  );
};

export default StructuredField;
