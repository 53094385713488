import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import WarningModal from '@/components/warning-modal';
import { useModal } from './use-modal';
import { useStore } from './use-store';

export const useHandleRouteChange = () => {
  const [
    { unsavedChangesExist, unsavedNotesExist, bulkJobEditCertIds },
    setStore,
  ] = useStore();
  const router = useRouter();
  const modal = useModal();

  // react state variables are updated async or batched
  // this is a ref to guarantee access to the current value
  const ignoreRouteChangeEvent = useRef(false);

  useEffect(() => {
    // We can write conditions based on routerChangeFrom & routerChangeTo

    const handleRouteChangeStart = routerChangeTo => {
      const routerChangeFrom = router.asPath;

      /**
       * This is the conditions on /bulk-job/edit from clearing the store values for bulkJobEditCertIds
       */
      if (routerChangeFrom.includes('/bulk-job/edit')) {
        if (
          bulkJobEditCertIds.length &&
          !routerChangeTo.includes('/bulk-job/edit')
        ) {
          setStore({ bulkJobEditCertIds: [] });
        }
      }
    };
    const handleRouteChangeComplete = () => {
      if (ignoreRouteChangeEvent.current) {
        return;
      }
      setStore({
        canChangeInsured: true,
        unsavedChangesExist: {},
        unsavedNotesExist: false,
      });
      modal.clear();
    };

    /**
     * @param {object} err Error object returned by next router event
     * @param {boolean} err.cancelled Boolean representing whether the route change was cancelled
     * @param {string} url The url targeted in the route change
     */
    const handleRouteChangeError = (err, url) => {
      if (err.cancelled) {
        // eslint-disable-next-line no-console
        console.log(`Route to ${url} was cancelled`);
      }
    };

    /**
     * This registers a callback function that is executed when the window popstate event is triggered
     * The popstate event is triggered when pressing the back button or calling `window.history.back()`
     *
     * Next will not perform the route change if this function returns `false`
     *
     * @see https://nextjs.org/docs/api-reference/next/router#routerbeforepopstate
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event
     */
    router.beforePopState(() => {
      if (!(Object.keys(unsavedChangesExist).length || unsavedNotesExist)) {
        return true;
      }

      ignoreRouteChangeEvent.current = true;

      try {
        window.history.pushState('/', '');
        router.push(router.asPath);
      } catch (err) {
        ignoreRouteChangeEvent.current = false;
        // something bad happened, return `true` and let Next handle the event
        return true;
      }

      modal.show(WarningModal, {
        handleContinue: () => {
          ignoreRouteChangeEvent.current = false;
          // call the routeChangeComplete handler to reset store variables
          handleRouteChangeComplete();
          // press the browser back button: `window.history.back()`
          router.back();
        },
      });

      // returning false tells Next that we are handling the event
      return false;
    });
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeError);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeError);
      router.beforePopState(() => true);
    };
  }, [
    bulkJobEditCertIds,
    modal,
    router,
    setStore,
    unsavedChangesExist,
    unsavedNotesExist,
  ]);
};
