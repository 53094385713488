import Dropdown from '@/components/dropdown';

import globalStyles from '@/styles/globals.module.scss';
import { useContext, useEffect, useState } from 'react';
import { GridFilterDropdownContext } from './context';
import styles from './styles.module.scss';

/**
 * @typedef {{ [key: string]: { id: string, text: string } }} DropdownSelections
 */

/**
 * @param {object} params
 * @param {ColDef} params.colDef
 * @param {(field: string) => (value: DropdownOption[]) => void} params.onFilter
 * @param {string} params.defaultValue
 */
const RenderDropdown = ({ colDef, onFilter, defaultValue }) => {
  const { field, headerName, customFilterParams } = colDef;
  const [dropdownSelection, setDropdownSelection] = useState(
    defaultValue || ''
  );

  const [, setResetHandlers] = useContext(GridFilterDropdownContext);

  useEffect(() => {
    setResetHandlers(prev => [...prev, () => setDropdownSelection('')]);
  }, [setResetHandlers]);

  useEffect(() => {
    onFilter(field)(dropdownSelection);
  }, [dropdownSelection, field, onFilter]);

  if (!customFilterParams) {
    return null;
  }

  const handleChange = selection => {
    setDropdownSelection(selection?.id || '');
  };

  return (
    <div className={globalStyles.col}>
      <Dropdown
        name={`filter-select-${field}`}
        options={customFilterParams?.options || []}
        label={`Select ${headerName}`}
        placeholder={`Filter by "${headerName}"`}
        value={dropdownSelection}
        onChange={handleChange}
        clearButton={!!dropdownSelection}
        onClear={() => setDropdownSelection('')}
        containerClassName={styles.statusDropdown}
      />
    </div>
  );
};

export default RenderDropdown;
