import { STATUS } from '@/constants';
import { v4 as uuidv4 } from 'uuid';
import { InboundLobEntity } from './inbound-lob-entity';

export class InboundCertificateEntity {
  constructor({
    id = uuidv4(),
    parentCertId = null,
    childCertId = null,
    origin = null,
    createdAt = new Date(),
    updatedAt = new Date(),
    certificateNumber = null,
    issued = null,
    deactivated = null,
    insuredId = null,
    insuredName = null,
    descriptionOfOperations = null,
    namedInsuredId = null,
    namedInsuredName = null,
    holderId = null,
    holderAddressId = null,
    lobs = [],
    status = STATUS.active,
    createdByUserId = null,
    issuedByUserId = null,
    summary = null,
    originalFileName = null,
    deleted = false,
    holderData = null,
    namedInsuredData = null,
    earliestExpDate = null,
    policies = [],
    insurers = [],
    agency = null,
    filePath = null,
  }) {
    this.id = id;
    this.parentCertId = parentCertId;
    this.childCertId = childCertId;
    this.origin = origin;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.certificateNumber = certificateNumber;
    this.issued = issued;
    this.deactivated = deactivated;
    this.insuredId = insuredId;
    this.insuredName = insuredName;
    this.descriptionOfOperations = descriptionOfOperations;
    this.namedInsuredId = namedInsuredId;
    this.namedInsuredName = namedInsuredName;
    this.holderId = holderId;
    this.holderAddressId = holderAddressId;
    this.lobs = lobs.map(lobTemplate => new InboundLobEntity(lobTemplate));
    this.status = status;
    this.createdByUserId = createdByUserId;
    this.issuedByUserId = issuedByUserId;
    this.summary = summary;
    this.originalFileName = originalFileName;
    this.deleted = deleted;
    this.holderData = holderData;
    this.namedInsuredData = namedInsuredData;
    this.earliestExpDate = earliestExpDate;
    this.policies = policies;
    this.insurers = insurers;
    this.agency = agency;
    this.filePath = filePath;
  }
}
