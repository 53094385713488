import { BulkIssuesEntity } from '@/entities/bulk-issues-entity';
import { forwardRef, useImperativeHandle } from 'react';
import styles from './tooltips.module.scss';

const BulkJobIssuesTooltip = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return ['custom-tooltip'];
      },
    };
  });

  const issueList = new BulkIssuesEntity(props.value || {});

  const issueHeading = 'Issues with cert:';
  const endorsementIssuesSummary = issueList.formatEndorsementIssues();
  const policyIssuesSummary = issueList.formatPolicyIssues();
  const contractIssuesSummary = issueList.formatContractIssues();
  const certIssueSummary = issueList.formatCertIssues();

  const issueSummary = `${issueHeading}${endorsementIssuesSummary}${policyIssuesSummary}${contractIssuesSummary}${certIssueSummary}`;

  return (
    <div
      className="custom-tooltip"
      style={issueList.hasIssues ? {} : { display: 'none' }}
    >
      <pre className={styles.tooltipText}>{issueSummary}</pre>
    </div>
  );
});

export default BulkJobIssuesTooltip;
