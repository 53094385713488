import { useCallback } from 'react';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import NotificationService from '@/components/notification-service';
import { useStore } from '@/hooks/use-store';

export function useBroker() {
  const [{ broker }, setStore] = useStore();

  /**
   * Only updating the broker branding is supported
   */
  const update = useCallback(
    async entity => {
      try {
        const payload = {
          branding: {
            logoURL: entity.logoURL,
            logoAltText: entity.logoAltText,
            headerColor: entity.headerColor,
          },
        };
        const response = await FrontendHttpClient.put(
          '/api/v2/broker',
          payload
        );
        setStore({ broker: response.data });
        NotificationService.success('Branding updated successfully');
      } catch {
        NotificationService.error('Error updating branding');
      }
    },
    [setStore]
  );

  return {
    broker,
    update,
  };
}
