import { forwardRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import cx from 'clsx';

import Label from '@/components/label';
import globalStyles from '@/styles/globals.module.scss';
import dynamic from 'next/dynamic';
import { InputGroup } from 'react-bootstrap';
import styles from './styles.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const PhoneInput = dynamic(() => import('react-phone-number-input/input'));

/**
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.name
 * @param {'color'|'date'|'month'|'number'|'password'|'range'|'tel'|'text'|'time'|'url'|'week'} props.type
 * @param {string} props.value
 * @param {string} props.label
 * @param {number} props.maxLength
 * @param {boolean} props.isInvalid
 * @param {string} props.error
 * @param {() => void} props.onChange
 * @param {string} props.containerClassName
 * @param {[className: string]: string} props.containerStyle
 * @param {string} props.borderClassName
 * @param {string} props.labelClassName
 * @param {string} props.className
 * @param {[className: string]: string} props.style
 * @param {FontAwesomeIconProps.icon} props.iconLeft
 * @param {FontAwesomeIconProps.icon} props.iconRight
 * @param {string|JSX.Element} props.componentLeft
 * @param {string|JSX.Element} props.componentRight
 * @param {number} props.maxLimit
 * @param {string} props.labelInfo - Adds an info icon to label with message
 */
const TextInput = forwardRef((props, ref) => {
  const {
    id,
    name,
    type,
    value,
    label,
    labelInfo,
    subText,
    maxLength,
    isInvalid,
    error,
    onChange,
    containerClassName,
    containerStyle,
    borderClassName,
    labelClassName,
    subTextClassName,
    className,
    style,
    iconLeft,
    iconRight,
    componentLeft,
    componentRight,
    maxLimit,
    textCase,
    setFieldValue,
    defaultCountry = 'US',
    onBlur,
    prefix,
    includeCommaSeparators,
    ...rest
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = e => {
    setIsFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  const [internalError, setInternalError] = useState('');
  const internalOnChange = e => {
    if (textCase === 'upper') {
      e.target.value = e.target.value.toUpperCase();
    }
    if (textCase === 'lower') {
      e.target.value = e.target.value.toLowerCase();
    }

    if (e.target.value.length > maxLimit) {
      e.target.value = e.target.value.slice(0, maxLimit);
      onChange(e);
      setInternalError(`Must be ${maxLimit} characters or less`);
    } else {
      if (internalOnChange) {
        setInternalError('');
      }
      onChange(e);
    }
  };

  /**
   * @param {string} str
   */
  const addCommaSeparators = str => {
    if (!str || typeof str !== 'string') {
      return '';
    }

    const valueWithoutCommas = str.replace(/,/g, '');

    if (Number.isNaN(Number(valueWithoutCommas))) {
      return str;
    }

    return Number(valueWithoutCommas).toLocaleString();
  };

  // Added this to ensure that even if value was previously set beyond the max-Limit,
  // opening the modal truncates it.
  useEffect(() => {
    if (value && value.length > maxLimit && setFieldValue) {
      setFieldValue(name, value.slice(0, maxLimit));
    }
  }, [value, maxLimit, setFieldValue, name]);

  return (
    <div
      className={cx(styles.container, containerClassName)}
      style={containerStyle}
      data-testid="text-input"
    >
      {label ? (
        <Label htmlFor={name} className={cx(styles.label, labelClassName)}>
          {label}
          {labelInfo && (
            <OverlayTrigger
              placement="right"
              delay={{ show: 50, hide: 200 }}
              overlay={<Tooltip>{labelInfo}</Tooltip>}
            >
              <div className={styles.labelInfo}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="15"
                  height="15"
                  viewBox="0 0 32 32"
                >
                  <path d="M 16 3 C 8.832031 3 3 8.832031 3 16 C 3 23.167969 8.832031 29 16 29 C 23.167969 29 29 23.167969 29 16 C 29 8.832031 23.167969 3 16 3 Z M 16 5 C 22.085938 5 27 9.914063 27 16 C 27 22.085938 22.085938 27 16 27 C 9.914063 27 5 22.085938 5 16 C 5 9.914063 9.914063 5 16 5 Z M 15 10 L 15 12 L 17 12 L 17 10 Z M 15 14 L 15 22 L 17 22 L 17 14 Z" />
                </svg>
              </div>
            </OverlayTrigger>
          )}
        </Label>
      ) : null}
      {subText ? (
        <Form.Text
          htmlFor={name}
          className={cx(styles.subText, subTextClassName)}
          muted
        >
          {subText}
        </Form.Text>
      ) : null}
      <div
        className={cx(
          globalStyles.row,
          styles.border,
          isFocused && styles.focused,
          props.disabled && globalStyles.disabled,
          props.disabled && styles.disabled,
          props.isInvalid && styles.invalid,
          borderClassName
        )}
      >
        {iconLeft ? (
          <div className={cx(styles.iconContainer, styles.left)}>
            <FontAwesomeIcon icon={iconLeft} />
          </div>
        ) : null}
        {componentLeft || null}
        {type === 'tel' ? (
          <PhoneInput
            id={id}
            defaultCountry={defaultCountry}
            value={value || ''}
            onChange={onChange}
            style={style}
            className={cx(
              styles.input,
              styles.phoneInput,
              isFocused && styles.focused,
              className
            )}
            {...rest}
          />
        ) : (
          <InputGroup>
            {prefix && (
              <InputGroup.Text className={styles.prefix}>
                {prefix}
              </InputGroup.Text>
            )}
            <Form.Control
              ref={ref}
              id={id}
              name={name || id}
              type={type || 'text'}
              value={includeCommaSeparators ? addCommaSeparators(value) : value}
              data-testid="text-input-text-field"
              className={cx(
                styles.input,
                isFocused && styles.focused,
                className
              )}
              style={style}
              onChange={internalOnChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              maxLength={maxLength || 1000}
              isInvalid={isInvalid}
              {...rest}
            />
          </InputGroup>
        )}
        {iconRight ? (
          <div className={cx(styles.iconContainer, styles.right)}>
            <FontAwesomeIcon icon={iconRight} />
          </div>
        ) : null}
        {componentRight || null}
      </div>
      {isInvalid && error ? (
        <Label className={styles.error}>{error}</Label>
      ) : null}
      {!(isInvalid && error) && internalError && (
        <Label className={styles.error}>{internalError}</Label>
      )}
    </div>
  );
});

export default TextInput;
