import mergeWith from 'lodash/mergeWith';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Function used to recursively combine keys
 * @param {object} accessSet1
 * @param {object} accessSet2
 */

const combineFunction = (accessSet1, accessSet2) => {
  if (!accessSet1) {
    return accessSet2;
  }
  const newAccessSet = cloneDeep(accessSet1);
  Object.entries(accessSet2).forEach(([key, value]) => {
    if (newAccessSet[key]) {
      return;
    }

    newAccessSet[key] = value;
  });

  return newAccessSet;
};

/**
 * Take a set of access keys tied to a group, and OR them together
 * @param {KeyTemplate} accessKeys1
 * @param {KeyTemplate} accessKeys2
 * @returns {KeyTemplate} The combined keys
 */

export const combineKeys = (accessKeys1, accessKeys2) => {
  // Don't want to mutate param passed in
  const clonedKeys = cloneDeep(accessKeys1);

  /**
   * mergeWith will return the merged result,
   * but it also mutates the object
   * @see https://docs-lodash.com/v4/merge-with/
   */
  return mergeWith(clonedKeys, accessKeys2, combineFunction);
};
