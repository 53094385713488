import { bulkEditActions } from '@/enums/bulk-edit-actions';
import { BulkEditLobEntity } from './bulk-edit-lob-entity';

/**
 * @typedef {object} BulkEditEndorsement
 * @property {string} id
 * @property {string} policyId
 */

export class BulkEditEntity {
  constructor({
    holder = {
      holderId: '',
      addressId: '',
    },
    namedInsured = {
      firstNamedInsured: '',
      firstNamedInsuredId: '',
      /** @type {string[]} */
      additionalNamedInsuredIds: [],
      additionalNamedInsureds: [],
    },
    descOfOps = {
      action: bulkEditActions.APPEND,
      text: '',
    },
    lobs = [],
    endorsements = {
      action: bulkEditActions.APPEND,

      /** @type {BulkEditEndorsement[]} */
      newEndorsements: [],
    },
    signature = {
      id: '',
    },
    contact = {
      id: '',
    },
  }) {
    this.holder = holder;
    this.namedInsured = namedInsured;
    this.descOfOps = descOfOps;
    this.lobs = lobs.map(lob => new BulkEditLobEntity(lob));
    this.endorsements = endorsements;
    this.signature = signature;
    this.contact = contact;
  }
}
