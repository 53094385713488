import useSWR from 'swr';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { route } from 'nextjs-routes';

export function useHolderAddress(addressId) {
  const { data, isValidating } = useSWR(
    addressId
      ? [route({ pathname: '/api/v2/holder_addresses' }), addressId]
      : null,
    async ([url, id]) => {
      const res = await FrontendHttpClient.get(url, {
        id,
      });

      return res.data[0];
    }
  );

  return {
    data,
    isValidating,
  };
}
