import { useEffect, useMemo, useState } from 'react';
import cx from 'clsx';
import { coverageFlowStyles } from '@/enums/coverage-flow-styles';
import TextInput from '../text-input';
import styles from './styles.module.scss';
import Label from '../label';
import { Form } from 'react-bootstrap';

/**
 * @param {object} params
 * @param {LobTemplateFieldMapping} params.mapping
 * @param {(params: {updatedMapping: LobTemplateFieldMapping, position: LobFieldMapping['formPosition']}) => void} params.updateFieldMapping
 * @param {boolean} params.isReadonly
 * @param {LobFieldMapping['formPosition']} params.position
 * @param {boolean} params.isAmsPolicyValidating
 */
const CompoundField = ({
  mapping,
  updateFieldMapping,
  isReadonly,
  position,
  isAmsPolicyValidating,
}) => {
  const [fieldMapping, setFieldMapping] = useState(mapping || {});
  const [values, setValues] = useState(
    mapping?.fieldSet?.map(line => line.map(() => ''))
  );

  useEffect(() => {
    if (!mapping) {
      return;
    }

    setFieldMapping({
      ...mapping,
    });

    if (mapping.value) {
      const splitLines = mapping.value.split('_NEWLINE_');
      const splitValues = splitLines.map(line => line.split('_SPACE_'));

      setValues(splitValues);
    } else if (!values) {
      setValues(mapping.fieldSet.map(line => line.map(() => '')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapping]);

  const disableCertOnlyField =
    mapping.flowStyle === coverageFlowStyles.CERT_ONLY && isAmsPolicyValidating;

  const subText = useMemo(() => {
    if (mapping.flowStyle === coverageFlowStyles.CERT_ONLY) {
      return 'Certificate Only Field';
    }

    return '';
  }, [mapping.flowStyle]);

  const onChange = (event, line, position) => {
    const updatedValues = values.map(line => [...line]);

    updatedValues[line][position] = event.target.value;

    setValues(updatedValues);
  };

  const onBlur = () => {
    const joinedLines = values.map(line => line.join('_SPACE_'));
    const joinedSets = joinedLines.join('_NEWLINE_');

    const updatedMapping = {
      ...fieldMapping,
      value: joinedSets,
    };

    updateFieldMapping({ updatedMapping, position });
  };

  if (!fieldMapping?.fieldSet) {
    return null;
  }

  const formFields = fieldMapping.fieldSet.map((line, lineIndex) => {
    const lineFields = line.map((name, position) => {
      return (
        <div
          key={`${fieldMapping.name}_${name}_input`}
          className={styles.individualMapping}
        >
          <Label className={cx(styles.nameField, 'my-auto')}>{name}</Label>
          <TextInput
            id={`${fieldMapping.name}_${name}_input`}
            data-cy={`${fieldMapping.name}_${name}_input`}
            value={values[lineIndex][position]}
            placeholder={'Value'}
            labelClassName={styles.label}
            containerClassName={styles.valueField}
            disabled={isReadonly || disableCertOnlyField}
            onChange={event => onChange(event, lineIndex, position)}
            onBlur={onBlur}
            maxLimit={fieldMapping.fieldLimits?.[lineIndex]?.[position]}
          />
        </div>
      );
    });

    return (
      <div
        key={`${fieldMapping.name}_line_${lineIndex + 1}`}
        className={styles.multiFieldContainer}
      >
        {lineFields}
      </div>
    );
  });

  return (
    <div id={fieldMapping.id} className={cx(styles.individualMapping)}>
      <Form.Text className={styles.freeFormAmsLabel} muted>
        {subText}
      </Form.Text>
      <Label className={styles.compoundFieldHeading}>
        {fieldMapping.label}
      </Label>
      {formFields}
    </div>
  );
};

export default CompoundField;
