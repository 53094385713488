import { useState, useRef, useEffect, useCallback, useMemo } from 'react';

import DefaultCellRenderer from '@/components/grid/renderers/DefaultCellRenderer';
import ActionMenuRenderer from '@/components/grid/renderers/ActionMenuRenderer';
import AcordNumberRenderer from '@/components/grid/renderers/AcordNumberRenderer';
import StatusRenderer from '@/components/grid/renderers/StatusRenderer';
import BulkJobIssuesRenderer from '@/components/grid/renderers/BulkJobIssuesRenderer';
import LobTemplateIssuesRenderer from '@/components/grid/renderers/LobTemplateIssuesRenderer';
import DateWithRelativeRenderer from '@/components/grid/renderers/DateWithRelativeRenderer';
import PillRenderer from '@/components/grid/renderers/PillRenderer';
import BooleanRenderer from '@/components/grid/renderers/BooleanRenderer';
import YesNoRenderer from '@/components/grid/renderers/YesNoRenderer';
import LargeContentRenderer from '@/components/grid/renderers/LargeContentRenderer';
import BulkJobIssuesTooltip from '@/components/grid/tooltips/BulkJobIssuesTooltip';
import LobTemplateIssuesTooltip from '@/components/grid/tooltips/LobTemplateIssuesTooltip';
import NotificationService from '@/components/notification-service';
import EmailCellRenderer from '@/components/grid/renderers/EmailCellRenderer';
import FileCabinetStatusRenderer from '@/components/grid/renderers/FileCabinetStatusRenderer';
import PoliciesMapperLinkRenderer from '@/components/grid/renderers/PoliciesMapperLinkRenderer';
import LobMapperLinkRenderer from '@/components/grid/renderers/LobMapperLinkRenderer';
import EndorsementMapperLinkRenderer from '@/components/grid/renderers/EndorsementMapperLinkRenderer';
import BulkJobLobActionsRenderer from '@/components/grid/renderers/BulkJobLobActionsRenderer';
import { COLUMN_TYPES } from '@/constants/column-defs';
import { useSWRConfig } from 'swr';
import LeadStatusRenderer from '@/components/grid/renderers/LeadStatusRenderer';
import UserStatusRenderer from '@/components/grid/renderers/UserStatusRenderer';
import { useStore } from './use-store';
import { useGridFilter } from './use-grid-filter';

export const handleCellKeyPress = disableSelection => params => {
  const { event } = params;
  // Intercept keyboard events on dropdown cells
  if (
    event?.key === 'Enter' &&
    params.colDef?.cellClass?.indexOf?.('dropdown') > -1
  ) {
    // Open the dropdown
    const btn = event?.target?.querySelector?.('button');
    btn?.click();
    // Focus the first item in dropdown menu
    const link = event?.target?.querySelector?.('a:not([aria-disabled])');
    link?.focus();
  } else if (!disableSelection) {
    // Default behavior, toggle row selection
    params.node.setSelected(!params.node.isSelected());
  }
};

/**
 * @param {object} param
 * @param {string} [param.name]
 * @param {string} [param.searchName]
 * @param {boolean} [param.disableSelection]
 * @param {() => void} [param.onSelectionChanged]
 * @param {boolean} [param.allowImageGrid]
 * @param {number} [param.recordCount]
 * @param {boolean} [param.refreshOnInsuredChange]
 * @param {AllApiRoutes} [param.remoteDataEndpoint]
 * @param {string[]} param.defaultStatusFilter Which statuses to filter by default
 */
export const useGrid = ({
  name,
  disableSelection,
  onSelectionChanged,
  allowImageGrid,
  searchName,
  recordCount: cnt,
  refreshOnInsuredChange = true,
  remoteDataEndpoint,
  defaultStatusFilter,
} = {}) => {
  const gridApiRef = useRef({});
  const [{ insuredId }] = useStore();

  /** @type {useStateReturn<GridApi>} */
  const [gridApi, setGridApi] = useState(null);
  const [recordCount, setRecordCount] = useState(cnt || null);
  const [columnApi, setColumnApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [imageGridEnabled, setImageGridEnabled] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedNodesAndPage, setSelectedNodesAndPage] = useState([]);
  const [isFirstSelectionEvent, setIsFirstSelectionEvent] = useState(false);
  const [openMenuRowNode, setOpenMenuRowNode] = useState(null);

  const imageGridApi = useRef(
    allowImageGrid
      ? {
          imageGridEnabled: false,
          setImageGridEnabled,
          filterModel: null,
        }
      : null
  );

  const { filterProps, searchInput, filterModel, resetFilter } = useGridFilter({
    gridApi,
    columnApi,
    searchName,
    recordCount,
    defaultStatusFilter,
    remoteDataEndpoint,
  });

  const { cache, mutate: globalMutate } = useSWRConfig();

  const mutateGridData = useCallback(() => {
    for (const key of cache.keys()) {
      if (key.includes(remoteDataEndpoint)) {
        globalMutate(key);
      }
    }
  }, [cache, globalMutate, remoteDataEndpoint]);

  const refreshGrid = useCallback(() => {
    mutateGridData();
    setSelectedRows([]);
    setSelectedNodesAndPage([]);
  }, [mutateGridData]);

  // if insured changes, update grid
  useEffect(() => {
    if (refreshOnInsuredChange) {
      refreshGrid();
    }
  }, [insuredId, refreshGrid, refreshOnInsuredChange]);

  gridApiRef.current = gridApi;

  if (allowImageGrid) {
    imageGridApi.current.imageGridEnabled = imageGridEnabled;
  }

  /**
   *
   * @returns {RowNode[]}
   */
  const getSelectedNodes = useCallback(() => {
    // selectedRows contains all the selected rows across all pages, but it is not always populated
    // when this is the case, we need to get the selected rows from the gridApi; default to an empty array
    return selectedRows?.length
      ? selectedRows
      : gridApi?.getSelectedNodes() || [];
  }, [gridApi, selectedRows]);

  const getSingleSelectedNode = useCallback(() => {
    const selectedNodes = getSelectedNodes();

    if (selectedNodes.length === 0) {
      NotificationService.error(`Please select a ${name || 'row'}`);

      return null;
    }

    return selectedNodes[0];
  }, [getSelectedNodes, name]);

  /** @type {(event: SelectionChangedEvent) => void} */
  const handleSelectionChanged = useCallback(
    event => {
      const nodes = event.api.getSelectedNodes();
      const multiSelectEnabled =
        event.api.gridOptionsService?.gridOptions.rowSelection === 'multiple';

      setSelectedNodesAndPage(values => {
        const nodesAndPageIndex = values.findIndex(
          value => value.page === pageIndex
        );

        if (nodesAndPageIndex === -1) {
          return [...values, { nodes, page: pageIndex }];
        }

        const newArr = [...values];

        if (isFirstSelectionEvent && nodes.length === 0) {
          setIsFirstSelectionEvent(false);
          return newArr;
        }

        if (multiSelectEnabled) {
          newArr[nodesAndPageIndex] = { ...newArr[nodesAndPageIndex], nodes };
          return newArr;
        }

        return newArr.reduce((prev, curr, idx) => {
          const clonedArr = [...prev];

          if (idx === nodesAndPageIndex) {
            clonedArr[idx] = { ...curr, nodes };
            return clonedArr;
          }

          if (curr.nodes.length && nodes.length) {
            clonedArr[idx] = { ...curr, nodes: [] };
          }

          return clonedArr;
        }, newArr);
      });

      if (onSelectionChanged && event.type === 'selectionChanged') {
        onSelectionChanged(event);
      }
    },
    [isFirstSelectionEvent, onSelectionChanged, pageIndex]
  );

  /**
   * @typedef {object} Action
   * @property {string} text Name of the action button
   * @property {() => void} onClick Function to call when the action button is clicked
   * @property {boolean} [isEnabled] Whether the action button is enabled
   * @property {'link'} [type] Type of action button
   * @property {React.ReactNode} [children] Children of the action button
   * @property {string} [tooltipText] Tooltip text for the action button
   * @typedef {object} Options
   * @property {boolean} isEnabled Whether all action buttons are enabled
   */

  /**
   *
   * @type {(actions: Action[], options?: Options) => Action[]}
   * @param {Action[]} actions Array of action buttons
   * @param {Options} [options] Options for the list of action buttons
   * @example
   * const actions = getGridActions([
   *    {
   *      text: 'Action 1',
   *      isEnabled: true,
   *      onClick: () => console.log('Action 1 clicked')
   *    },
   *    { isEnabled: isSomethingClicked }
   * ])
   */
  const getGridActions = useCallback((actions, options) => {
    if (options && !options?.isEnabled) {
      return [];
    }

    const actionsWithDefaults = actions.map(action => ({
      ...action,
      isEnabled: !Object.keys(action).includes('isEnabled')
        ? true
        : !!action.isEnabled,
    }));

    return actionsWithDefaults.filter(action => action.isEnabled);
  }, []);

  /**
   * @typedef {object} MenuOption
   * @property {string} text Name of the menu option
   * @property {() => void} onClick Function to call when the menu option is clicked
   * @property {boolean} [isEnabled] Whether the menu option is enabled
   * @typedef {object} Options
   * @property {boolean} isEnabled Whether all menu options are enabled
   * @property
   */

  /**
   * @type {(callback: (node: RowNode) => MenuOption[], options: Options) => MenuOption[]}
   * @param {Function} callback Function that returns an array of menu options
   * @param {Options} options Options for the list of menu options
   * @example
   * const menuOptions = getMenuOptions(
   *  node => [
   *    {
   *      text: 'Action 1',
   *      isEnabled: true,
   *      onClick: () => console.log('Action 1 clicked')
   *    },
   *  ],
   *  { isEnabled: isSomethingClicked }
   * )
   */
  const getMenuOptions = useCallback(
    (callback, options) => {
      if ((options && !options.isEnabled) || !openMenuRowNode) {
        return [];
      }

      const menuOptions = callback(openMenuRowNode);

      const menuOptionsWithDefaults = menuOptions.map(option => ({
        ...option,
        isEnabled: !Object.keys(option).includes('isEnabled')
          ? true
          : !!option.isEnabled,
      }));

      return menuOptionsWithDefaults.filter(option => option.isEnabled);
    },
    [openMenuRowNode]
  );

  const onPageChange = () => {
    setIsFirstSelectionEvent(true);
  };

  useEffect(() => {
    // If any of these change, deselect all rows
    if (searchInput || filterModel || pageSize) {
      setSelectedRows([]);
      setSelectedNodesAndPage([]);
    }
  }, [filterModel, pageSize, searchInput]);

  useEffect(() => {
    const selectedNodesOnPage =
      selectedNodesAndPage.find(value => value.page === pageIndex)?.nodes || [];

    if (selectedNodesOnPage.length && gridApi) {
      gridApi.forEachNodeAfterFilterAndSort(node => {
        selectedNodesOnPage.forEach(selectedNode => {
          if (selectedNode.rowIndex === node.rowIndex) {
            node.setSelected(true);
          }
        });
      });
    }

    const rows = selectedNodesAndPage.reduce((prev, curr) => {
      if (curr.nodes) {
        return [...prev, ...curr.nodes];
      }
      return prev;
    }, []);

    setSelectedRows(rows);
  }, [gridApi, pageIndex, selectedNodesAndPage]);

  useEffect(() => {
    let timeout;
    // Refresh actionMenu cells when datas change
    if (gridApi) {
      timeout = setTimeout(() => {
        gridApi.refreshCells({ columns: ['actionMenu'], force: true });
      });
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // selectedRows must be in dependency array to properly update on row change
  }, [gridApi, selectedRows]);

  // Append columnDef and rendererParams
  const updateColumnDefs = columnDefs => [
    ...columnDefs,
    {
      ...COLUMN_TYPES.actionMenuColumn,
      cellClass: 'dropdown',
    },
  ];

  const gridProps = useMemo(
    () => ({
      setGridApi,
      setColumnApi,
      setRecordCount,
      setPageIndex,
      setPageSize,
      setOpenMenuRowNode,
      onPageChange,
      pageSize,
      pageIndex,
      remoteDataEndpoint,
      searchInput,
      filterModel,
      refreshGrid,
      selectedRows,
      suppressRowTransform: true,
      suppressCellSelection: false,
      suppressRowClickSelection: false,
      suppressPropertyNamesCheck: true,
      suppressNoRowsOverlay: true,
      rowHeight: 55,
      headerHeight: 42,
      groupHeaderHeight: 20,
      tooltipShowDelay: 0,
      tooltipMouseTrack: true,
      scrollbarWidth: 10,
      enableBrowserTooltips: false,
      // We don't want to use 'autoheight' since that can cause issues with performance with large amount of rows
      //  and will make the horizontal scrollbar impossible to see
      // https://www.ag-grid.com/javascript-data-grid/grid-size/#grid-auto-height
      domLayout: 'normal',
      onSelectionChanged: handleSelectionChanged,
      frameworkComponents: {
        defaultCell: DefaultCellRenderer,
        actionMenu: ActionMenuRenderer,
        acordNumber: AcordNumberRenderer,
        bulkJobIssues: BulkJobIssuesRenderer,
        lobIssues: LobTemplateIssuesRenderer,
        bulkJobIssuesTooltip: BulkJobIssuesTooltip,
        lobTemplateIssuesToolTip: LobTemplateIssuesTooltip,
        bulkJobLobActionsDropdown: BulkJobLobActionsRenderer,
        emailCellRenderer: EmailCellRenderer,
        status: StatusRenderer,
        leadStatus: LeadStatusRenderer,
        userStatus: UserStatusRenderer,
        largeContent: LargeContentRenderer,
        fileCabinetStatus: FileCabinetStatusRenderer,
        policiesMapperLink: PoliciesMapperLinkRenderer,
        lobMapperLink: LobMapperLinkRenderer,
        endorsementMapperLink: EndorsementMapperLinkRenderer,
        boolean: BooleanRenderer,
        yesNo: YesNoRenderer,
      },
      components: {
        dateWithRelative: DateWithRelativeRenderer,
        pill: PillRenderer,
      },
      onCellKeyPress: handleCellKeyPress(disableSelection),
      columnTypes: COLUMN_TYPES,
      ...filterProps,
    }),
    [filterProps] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    getSingleSelectedNode,
    getSelectedNodes,
    getGridActions,
    getMenuOptions,
    gridApi,
    recordCount,
    columnApi,
    setColumnApi,
    gridApiRef,
    selectedRows,
    gridProps,
    imageGridApi,
    refreshGrid,
    resetFilter,
    updateColumnDefs,
  };
};
