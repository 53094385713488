import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useOutsideClick } from '@/hooks/use-outside-click';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import { format } from 'date-fns';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import globalStyles from '@/styles/globals.module.scss';
import { useIntersection } from '@/hooks/use-intersection';
import dynamic from 'next/dynamic';
import { GridFilterDropdownContext } from '../grid-filter-dropdown/context';
import TextInput from '../text-input';
import styles from './styles.module.scss';

const DateRange = dynamic(() =>
  import('react-date-range').then(mod => mod.DateRange)
);

const initialDateInfo = [
  {
    startDate: null,
    endDate: null,
    key: 'selection',
  },
];

/**
 * @param {object} params
 * @param {string} params.placement
 * @param {string} params.placeholder
 * @param {string} params.field
 * @param {() => void} params.onChange
 * @param {() => void} params.onFilter
 */
export const DateRangeInput = params => {
  const {
    placement = 'bottom-start',
    placeholder,
    field,
    onChange,
    onFilter,
    enableIntersection = true,
  } = params;

  const [, setResetHandlers] = useContext(GridFilterDropdownContext);

  const [show, setShow] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [dateInfo, setDateInfo] = useState(initialDateInfo);
  const [overlayPlacement, setOverlayPlacement] = useState(placement);

  const containerRef = useRef();
  useOutsideClick(containerRef, () => setShow(false));

  const intersection = useIntersection(containerRef, {
    rootMargin: '-350px',
  });

  const formattedDateRange = useMemo(() => {
    if (!dateInfo[0]) {
      return '';
    }
    const dates = {};
    const { startDate, endDate } = dateInfo[0];

    if (startDate) {
      dates.startDate = format(startDate, 'MMM do, y');
    } else {
      dates.startDate = '';
    }

    if (endDate) {
      dates.endDate = format(endDate, 'MMM do, y');
    } else {
      dates.endDate = '';
    }

    if (!startDate && !endDate) {
      return '';
    }

    if (!startDate) {
      return `${dates.endDate} and earlier`;
    }

    if (!endDate) {
      return `${dates.startDate} and after`;
    }

    if (dates.startDate === dates.endDate) {
      return dates.startDate;
    }

    return `${dates.startDate} - ${dates.endDate}`;
  }, [dateInfo]);

  /** @type {(item: RangeKeyDict) => void} */
  const handleDateRangeChange = useCallback(
    item => {
      if (onChange) {
        const dates = {};
        const { startDate, endDate } = item.selection;
        if (startDate) {
          dates.startDate = format(startDate, 'yyyy-MM-dd');
        }

        if (endDate) {
          dates.endDate = format(endDate, 'yyyy-MM-dd');
        }
        onChange(field)({
          target: {
            value: `${dates.startDate || ''}_${dates.endDate || ''}`,
          },
        });
      }
    },
    [field, onChange]
  );

  useEffect(() => {
    if (isTouched) {
      const resetDateFilters = () => {
        setIsTouched(false);
        setDateInfo(initialDateInfo);
      };

      setResetHandlers(prev => [...prev, resetDateFilters]);
    }
  }, [isTouched, setResetHandlers]);

  useEffect(() => {
    if (enableIntersection && !intersection?.isIntersecting) {
      setOverlayPlacement('top-start');
    } else {
      setOverlayPlacement(placement);
    }
  }, [intersection, placement, enableIntersection]);

  return (
    <div ref={containerRef}>
      <OverlayTrigger
        show={show}
        key="date-range-input-overlay"
        placement={overlayPlacement}
        container={containerRef}
        overlay={
          <Popover id="date-range-popover" className={styles.popover}>
            <DateRange
              editableDateInputs
              retainEndDateOnFirstSelection
              className={styles.dateRange}
              ranges={dateInfo}
              startDatePlaceholder="Start Date"
              endDatePlaceholder="End Date"
              onChange={item => {
                setIsTouched(true);
                setDateInfo([item.selection]);
                handleDateRangeChange(item);
              }}
            />
          </Popover>
        }
      >
        <TextInput
          type="button"
          value={isTouched ? formattedDateRange : placeholder}
          onClick={() => setShow(true)}
          className={styles.dateButton}
          componentRight={
            isTouched && (
              <button
                type="button"
                className={cx(globalStyles.buttonReset, styles.clearButton)}
                onClick={() => {
                  setDateInfo(initialDateInfo);
                  setShow(false);
                  setIsTouched(false);
                  onFilter(field)('');
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )
          }
        />
      </OverlayTrigger>
    </div>
  );
};
