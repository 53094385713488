import { useState, useEffect } from 'react';
import { loadCDN } from '@/utils/loadScript';

/**
 * A function to convert pdf into images
 * @param {object} params
 * @param {number} [params.quality]
 * @param {string|File} params.url
 * @param {(pageIndex: number, url: string) => void} params.onPageComplete
 * @param {() => void} [params.beforePageLoad]
 */
const getDocumentPages = async ({
  quality = 1,
  url,
  onPageComplete,
  beforePageLoad,
}) => {
  await loadCDN(
    'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.0.279/pdf.min.js'
  );
  const PDFJS = window['pdfjs-dist/build/pdf'];
  if (!PDFJS) {
    return;
  }
  PDFJS.GlobalWorkerOptions.workerSrc =
    'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.0.279/pdf.worker.min.js';

  let buffer = null;
  if (url instanceof File) {
    buffer = await url.arrayBuffer();
  }

  // First, we need to load the document using the getDocument utility
  const loadingTask = PDFJS.getDocument(buffer || url);
  const pdf = await loadingTask.promise;

  const { numPages } = pdf;

  const canvasPromises = [];

  beforePageLoad?.();
  // Now for every page in the document, we're going to add a page to the array
  for (let i = 0; i < numPages; i++) {
    const pageIndex = i;
    // eslint-disable-next-line no-await-in-loop
    const page = await pdf.getPage(i + 1);
    const canvas = document.createElement('canvas');
    const viewport = page.getViewport({ scale: quality });

    const devicePixelRatio = window.devicePixelRatio * 2 || 1;

    canvas.style.width = viewport.width;
    canvas.style.height = viewport.height;

    canvas.width = viewport.width * devicePixelRatio;
    canvas.height = viewport.height * devicePixelRatio;
    // [sx, 0, 0, sy, 0, 0]
    const transform = [devicePixelRatio, 0, 0, devicePixelRatio, 0, 0];

    canvasPromises.push(
      page
        .render({
          canvasContext: canvas.getContext('2d'),
          viewport,
          transform,
        })
        .promise.then(() => {
          onPageComplete(pageIndex, canvas.toDataURL());
        })
    );
  }
};

/**
 *
 * A hook to convert pdf into image array
 *
 * @param {string} param.url - Url for the pdf to generate
 * @param {number} param.quality - quality of the pdf to generate, depends on the pixel density, higher the quality higher the memory.
 * @returns
 */

export const useDocument = ({ url, quality, pfjsLoaded }) => {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (url) {
      getDocumentPages({
        url,
        quality,
        beforePageLoad: () => setPages([]),
        onPageComplete: (index, pdfUrl) => {
          setPages(old => {
            const clone = [...old];
            clone[index] = pdfUrl;
            return clone;
          });
          setLoading(false);
        },
      });
    }
  }, [pfjsLoaded, quality, url]);

  return {
    pages,
    loading,
  };
};
