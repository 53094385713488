import { SIGNATURE_STATUS } from '@/constants';

export class SignatureEntity {
  constructor({
    id,
    name = '',
    fileName = '',
    status = SIGNATURE_STATUS.active,
    isDefault = false,
    deactivatedDate = null,
    createdAt = null,
    updatedAt = null,
  }) {
    this.id = id;
    this.name = name;
    this.fileName = fileName;
    this.status = status;
    this.isDefault = isDefault;
    this.deactivatedDate = deactivatedDate;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
