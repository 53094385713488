/* eslint-disable camelcase */
// Helper functions
import escapeRegExp from 'lodash/escapeRegExp';
import { STATUS } from '@/constants';
import { format, utcToZonedTime, toDate } from 'date-fns-tz';
import addDays from 'date-fns/addDays';
import AcordNumberRenderer from '@/components/grid/renderers/AcordNumberRenderer';
import { pdfFileType } from '@/enums/supported-file-types';
import FrontendHttpClient from '../http-handler/frontend-http-client';
import { FORM_TEMPLATES } from '../form-templates';
export * from './strings';
export * from './user';

// Certificate Logical Status Machine
export function getCertificateStatus(certificate) {
  // https://certificate-hero.atlassian.net/wiki/spaces/CH/pages/163847/Status+Rules

  if (!certificate) {
    throw new Error('Error: no certificate passed to getCertificateStatus');
  }

  if (certificate.status === STATUS.deleted) {
    return STATUS.deleted;
  }

  const issueDate = certificate.issued;
  const deactivateDate = certificate.deactivated;
  const hasChild = !!certificate.childCertId;

  // const issueDate_dt = issueDate ? new Date(issueDate) : null;
  const deactivateDate_dt = deactivateDate ? new Date(deactivateDate) : null;
  if (deactivateDate_dt) {
    deactivateDate_dt.setHours(0, 0, 0, 0);
  }
  const now = new Date();
  now.setHours(0, 0, 0, 0); // set to midnight this morning

  let status = null;
  if (!issueDate) {
    status = STATUS.inProgress;
  } else if (hasChild) {
    status = STATUS.overridden;
  } else if (!deactivateDate || deactivateDate_dt > now) {
    status = STATUS.active;
  } else {
    status = STATUS.inactive;
  }
  return status;
}

export function parseGridFilter(column, filter) {
  let query = {};
  if (filter.operator) {
    const conditions = [{}, {}];
    conditions[0][column] = parseGridFilter(column, filter.condition1);
    conditions[1][column] = parseGridFilter(column, filter.condition2);
    // some functions create $and/$or compositions and some don't...
    if (conditions[0][column][column]) {
      conditions[0][column] = conditions[0][column][column];
    }
    if (conditions[1][column][column]) {
      conditions[1][column] = conditions[1][column][column];
    }

    if (filter.operator === 'AND') {
      query = { $and: conditions };
    } else if (filter.operator === 'OR') {
      query = { $or: conditions };
    }
  } else if (filter.filterType === 'date') {
    const dateFrom = filter.dateFrom ? filter.dateFrom.slice(0, 10) : null;
    const dateTo = filter.dateTo ? filter.dateTo.slice(0, 10) : null;
    const dateFrom_dt = dateFrom
      ? toDate(filter.dateFrom, { timeZone: 'UTC' })
      : null; // midnight AM selected day
    const nextDateFrom_dt = dateFrom ? addDays(dateFrom_dt, 1) : null; // midnight AM next day
    const dateTo_dt = dateTo
      ? toDate(filter.dateTo, { timeZone: 'UTC' })
      : null;
    const nextDateTo_dt = dateTo ? addDays(dateTo_dt, 1) : null; // midnight AM next day

    if (filter.type === 'greaterThan') {
      query[column] = { $gt: dateFrom_dt };
    } else if (filter.type === 'lessThan') {
      query[column] = { $lt: dateFrom_dt };
    } else if (filter.type === 'equals') {
      const conditions = [{}, {}];
      conditions[0][column] = { $gte: dateFrom_dt };
      conditions[1][column] = { $lt: nextDateFrom_dt };
      query = { $and: conditions };
    } else if (filter.type === 'notEqual') {
      const conditions = [{}, {}];
      conditions[0][column] = { $gte: nextDateFrom_dt };
      conditions[1][column] = { $lt: dateFrom_dt };
      query = { $or: conditions };
    } else if (filter.type === 'inRange') {
      const conditions = [{}, {}];
      // If we have a dateFrom 6/1/2019 and a dateTo 6/3/2019, we want to find
      // all dates between from 12:00:00am on 6/1/2019 until 11:59:59pm on 6/3/2019
      conditions[0][column] = { $gte: dateFrom_dt };
      conditions[1][column] = { $lt: nextDateTo_dt }; // Before midnight AM next day
      query = { $and: conditions };
    }
  } else if (filter.filterType === 'number') {
    if (filter.type === 'equals') {
      query[column] = Number(filter.filter);
    } else if (filter.type === 'notEqual') {
      query[column] = { $ne: Number(filter.filter) };
    } else if (filter.type === 'greaterThan') {
      query[column] = { $gt: Number(filter.filter) };
    } else if (filter.type === 'greaterThanOrEqual') {
      query[column] = { $gte: Number(filter.filter) };
    } else if (filter.type === 'lessThan') {
      query[column] = { $lt: Number(filter.filter) };
    } else if (filter.type === 'lessThanOrEqual') {
      query[column] = { $lte: Number(filter.filter) };
    } else if (filter.type === 'inRange') {
      const conditions = [{}, {}];
      conditions[0][column] = {
        $gte: Math.min(filter.filter, filter.filterTo),
      };
      conditions[1][column] = {
        $lte: Math.max(filter.filter, filter.filterTo),
      };
      query = { $and: conditions };
    }
  } else if (filter.filterType === 'text' && Array.isArray(filter.filter)) {
    query[column] = { $in: filter.filter };
  } else if (filter.filterType === 'text') {
    const filterPattern = escapeRegExp(`${filter.filter}`);
    if (filter.type === 'contains' || filter.type === 'withinArray') {
      query[column] = { $regex: filterPattern, $options: 'i' };
    } else if (filter.type === 'notContains') {
      const conditions = [{}, {}, {}];
      conditions[0][column] = {
        $regex: `^((?!${filterPattern}).)*$`,
        $options: 'i',
      };
      conditions[1][column] = { $regex: `^$`, $options: 'i' }; // not contains should match empty
      conditions[2][column] = null; // not contains should match empty
      query = { $or: conditions };
    } else if (filter.type === 'equals') {
      query[column] = filter.filter;
    } else if (filter.type === 'notEqual') {
      query[column] = { $ne: filter.filter };
    } else if (filter.type === 'startsWith') {
      query[column] = { $regex: `^${filterPattern}`, $options: 'i' };
    } else if (filter.type === 'endsWith') {
      query[column] = { $regex: `${filterPattern}$`, $options: 'i' };
    } else if (filter.type === 'notIn') {
      query[column] = { $nin: filter.filter };
    }
  } else if (filter.filterType === 'boolean') {
    query[column] = filter.filter === 'true' ? true : { $ne: true };
  }
  return query;
}

export function parseGridFilters(filterModel) {
  const params = [];
  for (const [column, filter] of Object.entries(filterModel)) {
    params.push(parseGridFilter(column, filter));
  }
  return params;
}

export function parseSort(sortModel) {
  const sort = [];
  for (const sm of sortModel) {
    const thisSort = {};
    thisSort[sm.colId] = sm.sort === 'asc' ? 1 : -1;
    sort.push(thisSort);
  }
  return sort;
}

export function parseQuery(query) {
  const result = {};
  if (query) {
    for (const [key, value] of Object.entries(query)) {
      result[key] = value === '' || value === 'null' ? null : value;
    }
  }
  return result;
}

/**
 * @param {string} filePath
 * @param {string} defaultValue
 */
export function getFileExtension(filePath, defaultValue) {
  // Format function
  const fileParts = filePath.split('.');
  return fileParts.length > 1 ? fileParts.pop() : defaultValue;
}

/**
 * @param {string} date
 * @param {string} [formatString]
 */
export const parseDate = (date, formatString = 'MM-dd-yyyy') => {
  if (!date) {
    return null;
  }
  const utcDate = utcToZonedTime(new Date(date).toISOString(), 'UTC');
  return format(utcDate, formatString, { timeZone: 'UTC' });
};

/**
 * A function to extract the UTC TimeZone date for a Date Object
 * @param {Date} date
 * @returns
 */
export const parseYear = date => {
  if (!date) {
    return null;
  }
  const utcDate = utcToZonedTime(new Date(date).toISOString(), 'UTC');
  return format(utcDate, 'yyyy', { timeZone: 'UTC' });
};

/**
 * returns the current full year (YYYY)
 * @returns {number}
 */
export const getCurrentYear = () => new Date().getFullYear();

/**
 * @param {string | boolean} value
 * @returns {string | number | boolean}
 */
export const formatMappingValue = value => {
  if (typeof value === 'string') {
    // ? Strip commas and test for a numeric value
    if (/^-?\d+$/.test(value.replace(/,/g, ''))) {
      return (
        value
          // ? Strip commas to get just the numeric string
          .replace(/,/g, '')
          // ? Strip any leading zeros but leave one if there are no other digits
          .replace(/^0+((?=[1-9]+)|(?=0+))/, '')
          // ? Add commas to resulting number before every group of 3 numbers
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      );
    }
  }
  return value;
};

/**
 * @param {LobTemplateFieldMapping} mapping
 * @returns {boolean}
 */
export const exceedsAmsValue = mapping => {
  const { amsValue, value } = mapping;
  if (typeof value !== 'string' || typeof amsValue !== 'string') {
    return false;
  }

  const strippedValue = value.replace(/,/g, '');
  const strippedAMSValue = amsValue.replace(/,/g, '');

  if (!/^-?\d+$/.test(strippedAMSValue) || !/^-?\d+$/.test(strippedValue)) {
    return false;
  }

  return parseInt(strippedAMSValue, 10) < parseInt(strippedValue, 10);
};

export const getFileLink = filename =>
  FrontendHttpClient.get('/api/v2/files', { filename });

/**
 * @param {number} time Time in milliseconds
 */
const padTime = time => {
  return String(time).length === 1 ? `0${time}` : `${time}`;
};

/**
 * @param {number} time Time in milliseconds
 */
export const formatSeconds = time => {
  // Convert seconds into minutes and take the whole part
  const minutes = Math.floor(time / 60);

  // Get the seconds left after converting minutes
  const seconds = time % 60;

  // Return combined values as string in format mm:ss
  return `${minutes}:${padTime(seconds)}`;
};

export const formatPolicyText = policy =>
  `(${parseDate(policy.effectiveDate, 'yyyy-MM-dd')}) ${policy.policyNumber} ${
    policy.policyName
  } `;

/**
 * Returns a percentage completed
 * @param {Array} data
 * @param {number} index
 */
export const percentComplete = (data, index) => {
  return `${Math.round(((index + 1) / data.length) * 100)}%`;
};

/**
 * Return duplicates in an array
 * @param {string[]} array
 */
export const duplicates = array => {
  return array.filter(
    (item, index) =>
      array.lastIndexOf(item) === index && array.indexOf(item) !== index
  );
};

export const acordFormFilterOptions = () => {
  const overflowForms = ['Acord 101', 'Acord 101-CH', 'Acord 101 Freeform'];

  const excludeOverflowFilter = FORM_TEMPLATES.filter(
    temp => !overflowForms.includes(temp.shortName)
  );

  return excludeOverflowFilter.map(template => ({
    text: AcordNumberRenderer({ value: template.shortName }).toUpperCase(),
    id: template.id,
  }));
};

/**
 * Returns an array of new unique names with the format "name (n)" where n is the next available number
 * @param {object} params
 * @param {object} params.model - the model to query
 * @param {string[]} params.names - an array of names to check for duplicates
 * @param {string} [params.insuredId] - (optional) if the names are unique to an insured, pass the insuredId
 * @param {string} [params.nameField] - (optional) the name field to query on, defaults to "name"
 */
export const getUniqueNames = async ({
  model,
  names,
  insuredId,
  nameField = 'name',
}) => {
  const uniqueNames = [];
  const nameRootCounts = {};

  for (const name of names) {
    // Check if the name has (n) suffix
    let nameRoot = name;

    const hasSuffix = name.match(/ \((\d+)\)$/);

    if (hasSuffix) {
      // Name root is basically the bare name with (n) removed from the end once detected
      nameRoot = name.slice(0, -3).trim();
    }

    //make sure to escape any special characters in the name
    const nameRootRegex = escapeRegExp(nameRoot);

    // this regex will match any name that ends with a space and a number in parentheses
    const query = {
      [nameField]: new RegExp(`^${nameRootRegex} \\(\\d+\\)$`),
      deleted: false,
    };

    if (insuredId) {
      query.insuredId = insuredId;
    }

    let count = nameRootCounts[nameRoot] || 0;

    if (count === 0) {
      // Find the highest count for this name root
      // For example, if there are already names "name (1)", "name (2)", and "name (3)", the highest name is "name (3)"
      // eslint-disable-next-line no-await-in-loop
      const existing = await model
        .find(query)
        .sort({ [nameField]: -1 })
        .limit(1)
        .lean();

      if (existing.length) {
        // This regex will match the number in parentheses at the end of the name
        // So for "name (3)", it will match "3"
        const matchResult = existing[0][nameField].match(/ \((\d+)\)$/);

        if (Array.isArray(matchResult)) {
          const [, existingCount] = matchResult;
          count = parseInt(existingCount, 10);
        }
      }
    }

    count += 1;
    nameRootCounts[nameRoot] = count;

    uniqueNames.push(`${nameRoot} (${count})`);
  }

  return uniqueNames;
};

/**
 * Helper function for validating PDF file type
 * @param {Array<*>} files
 */
export const isValidPdfFileType = files => {
  if (!files.length) {
    return pdfFileType.includes(files.type);
  }

  return Object.keys(files).every(key => pdfFileType.includes(files[key].type));
};

/**
 * Helper function for converting a buffer to an array buffer
 * @param {Buffer} buffer
 */
export const toArrayBuffer = buffer => {
  const ab = new ArrayBuffer(buffer.length);
  const view = new Uint8Array(ab);
  for (let i = 0; i < buffer.length; ++i) {
    view[i] = buffer[i];
  }
  return ab;
};

function levenshteinDistance(str1, str2) {
  const dp = Array(str2.length + 1)
    .fill(null)
    .map(() => Array(str1.length + 1).fill(null));

  for (let i = 0; i <= str1.length; i++) {
    dp[0][i] = i;
  }
  for (let j = 0; j <= str2.length; j++) {
    dp[j][0] = j;
  }

  for (let j = 1; j <= str2.length; j++) {
    for (let i = 1; i <= str1.length; i++) {
      const insertCost = dp[j][i - 1] + 1;
      const deleteCost = dp[j - 1][i] + 1;
      const substCost =
        str1[i - 1] === str2[j - 1] ? dp[j - 1][i - 1] : dp[j - 1][i - 1] + 1;

      dp[j][i] = Math.min(insertCost, deleteCost, substCost);
    }
  }

  return dp[str2.length][str1.length];
}

export function fuzzyMatch(text, pattern) {
  // const maxDistance = Math.ceil(0.1 * Math.max(text.length, pattern.length));
  const maxDistance = Math.ceil(Math.max(text.length, pattern.length) * 0.1);

  // Calculate Levenshtein distance (you'd likely use a library for this)
  const distance = levenshteinDistance(text, pattern);

  return distance <= maxDistance;
}

export function getCountries(lang = 'en') {
  const countryName = new Intl.DisplayNames([lang], {
    type: 'region',
  });
  const countriesSet = new Set(); // Use a Set to prevent duplicates
  for (let i = 65; i <= 90; i++) {
    for (let j = 65; j <= 90; j++) {
      const code = String.fromCharCode(i) + String.fromCharCode(j);
      let name = countryName.of(code);

      // If the name is valid (not the code itself), add it to countries
      if (name !== code) {
        // Replace 'United States' with 'USA'
        if (name === 'United States') {
          countriesSet.add('USA');
        } else {
          countriesSet.add(name);
        }
      }
    }
  }

  return Array.from(countriesSet);
}
