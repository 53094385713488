import { useState, useMemo } from 'react';
import {
  faAngleDown,
  faSearch,
  faSlidersH,
} from '@fortawesome/free-solid-svg-icons';
import cx from 'clsx';

import GridFilterDropdown from '@/components/grid-filter-dropdown';
import TextInput from '@/components/text-input';
import Button from '@/components/button';
import globalStyles from '@/styles/globals.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';

const GridFilter = props => {
  const {
    gridApi,
    columnApi,
    columns,
    inputs,
    onFilter,
    onReset,
    searchName,
    recordCount,
    searchInput,
    setSearchInput,
    usingDefaultInputs,
  } = props;

  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [toggleDisabled, setToggleDisabled] = useState(false);

  const resetToggleDisabled = () => setToggleDisabled(false);

  const handleToggleDropdown = _event => {
    if (toggleDisabled) {
      return;
    }

    setToggleDisabled(true);
    setTimeout(resetToggleDisabled, 200);
    setFilterMenuOpen(!filterMenuOpen);
  };
  const handleCloseDropdown = () => {
    setToggleDisabled(true);
    setTimeout(resetToggleDisabled, 200);
    setFilterMenuOpen(false);
  };
  const handleTextChange = field => event => {
    onFilter(field)(event.target.value);
  };

  const numInputs = useMemo(() => {
    let count = Object.keys(inputs).filter(k => !!inputs[k]).length;

    if (usingDefaultInputs) {
      count--;
    }

    return count;
  }, [inputs, usingDefaultInputs]);

  const label = numInputs > 0 ? `Filter (${numInputs})` : 'Filter';

  const handlePlaceholders = () => {
    if (recordCount && recordCount > 0) {
      if (recordCount === 1) {
        return 'Search';
      }
      return `Search ${recordCount} ${searchName}`;
    }

    return 'Search';
  };

  return (
    <div className={cx(globalStyles.row, styles.container)}>
      <TextInput
        value={searchInput}
        placeholder={handlePlaceholders()}
        componentLeft={<FontAwesomeIcon className="mx-2" icon={faSearch} />}
        onChange={e => setSearchInput(e.target.value)}
        containerStyle={{ flexGrow: 1 }}
        className={styles.input}
      />
      <Button
        id="grid-filter-dropdown"
        iconLeft={faSlidersH}
        onClick={handleToggleDropdown}
        containerStyle={{ marginLeft: -1 }}
        className={cx(styles.filterButton, filterMenuOpen && styles.filterOpen)}
        componentRight={
          <FontAwesomeIcon className={globalStyles.caret} icon={faAngleDown} />
        }
      >
        {label}
      </Button>

      <GridFilterDropdown
        columns={columns}
        inputs={inputs}
        hasInput={numInputs > 0}
        show={filterMenuOpen}
        gridApi={gridApi}
        columnApi={columnApi}
        onClose={handleCloseDropdown}
        onToggle={handleToggleDropdown}
        onFilter={onFilter}
        onReset={onReset}
      />
    </div>
  );
};

export default GridFilter;
