import { USER_STATUS } from '@/constants';
import * as vars from '@/styles/vars.module.scss';
import styles from './styles.module.scss';

/**
 * @param {object} props
 * @param {string|undefined} props.value
 * @param {string} props.valueFormatted
 */
const UserStatusRenderer = ({ value, valueFormatted }) => {
  if (value === undefined) {
    return <div>{valueFormatted}</div>;
  }

  const getColor = status => {
    switch (status) {
      case USER_STATUS.ACTIVE:
        return vars['color-status-active'];

      case USER_STATUS.INACTIVE:
        return vars['color-status-overridden'];

      case USER_STATUS.INVITED:
        return vars['color-status-progress'];

      default:
        return vars['color-status-fault'];
    }
  };

  return (
    <div
      data-cy="cell-renderer"
      className={styles.statusContainer}
      style={{ borderColor: getColor(valueFormatted) }}
    >
      {valueFormatted}
    </div>
  );
};

export default UserStatusRenderer;
