import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { exceedsAmsValue } from '@/utils/helpers';
import { ErrorContext } from '@/components/lob-templates/context';
import { useAmsPoliciesAndLobs } from './use-ams-policies-and-lobs';
import { useCertificate } from './use-certificate';

export const useValidateAmsLimit = () => {
  const [lobTemplates, setLobTemplates] = useState([]);
  const [policiesExceedingAms, setPoliciesExceedingAms] = useState([]);

  const { data, isValidating, mutate } = useAmsPoliciesAndLobs({
    includeCoverages: true,
    policyAmsIds:
      lobTemplates
        ?.map(lobTemplate => lobTemplate?.policy?.amsId)
        .filter(id => id) || [],
  });
  const certificate = useCertificate();
  const [error, setError] = useContext(ErrorContext);

  const [changedLobTemplates, setChangedLobTemplates] = useState(null);

  const validateSelectedLobTemplate = useCallback(selectedLobTemplates => {
    if (!selectedLobTemplates) {
      return;
    }
    setChangedLobTemplates(selectedLobTemplates);
  }, []);

  useEffect(() => {
    if (!certificate.editor) {
      return;
    }

    if (changedLobTemplates) {
      setLobTemplates(setChangedLobTemplates);
      return;
    }

    setLobTemplates(certificate.editor?.get('lobTemplates'));
  }, [certificate.editor, changedLobTemplates]);

  useEffect(() => {
    if (!lobTemplates || !data) {
      return;
    }

    const listOfExceedingAmsValuePolicies = lobTemplates.reduce(
      (listOfPolicies, lobTemplate) => {
        const amsLobByPolicy = data
          .find(d => d.amsId === lobTemplate.policy.amsId)
          ?.lobs.find(lob => lob.lobSymbol === lobTemplate.lobSymbol);

        lobTemplate.coverages.forEach(i => {
          const amsCoverage = amsLobByPolicy?.coverages.find(
            c => c.name === i.name
          );

          if (
            !Number.isNaN(amsCoverage?.value) &&
            exceedsAmsValue({
              amsValue: amsCoverage?.value,
              value: i.value,
            })
          ) {
            listOfPolicies.push(lobTemplate.policy.policyNumber);
          }
        });

        return listOfPolicies;
      },
      []
    );

    setPoliciesExceedingAms(listOfExceedingAmsValuePolicies);
  }, [data, lobTemplates]);

  useEffect(() => {
    if (!policiesExceedingAms?.length) {
      setError({});
      return;
    }
    const uniquePolicies = [...new Set(policiesExceedingAms)];
    setError(prev => ({
      ...prev,
      type: 'editor',
      coverage: `Saved limits exceed limits from AMS for: ${uniquePolicies.join(
        ', '
      )}`,
    }));
  }, [policiesExceedingAms, setError]);

  return {
    data,
    isValidating,
    policiesExceedingAms,
    error,
    validateSelectedLobTemplate,
    setError,
    mutate,
  };
};
