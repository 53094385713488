import { PolicyCoverageEntity } from './policy-coverage-entity';

export class CertificateLobTemplateEntity {
  constructor({ id, coverages = [] }) {
    this.id = id;
    this.coverages = coverages.map(
      coverage => new PolicyCoverageEntity(coverage)
    );
  }
}
