import { FORM_TEMPLATES, FORM_TYPES } from '@certificate_hero/enums';

// For legacy purposes
function getFormTemplatesArray() {
  return Object.values(FORM_TEMPLATES);
}

function findFormTemplateById(id) {
  return getFormTemplatesArray().find(formTemplate => formTemplate.id === id);
}

function findFormTemplateByCode(code) {
  return getFormTemplatesArray().find(
    formTemplate => formTemplate.shortName === code
  );
}

function getBaseFormTemplates() {
  return getFormTemplatesArray().filter(
    formTemplate => formTemplate.isBaseForm
  );
}

/**
 *
 * This verifies if a form is part of specialised forms.
 *
 * @param {string} formId
 * @returns
 */
const isFormSpecial = formId =>
  formId && FORM_TYPES.SPECIALIZED_FORMS.some(form => form.id === formId);

/**
 *
 * This verifies if a form is part of standard forms.
 *
 * @param {string} formId
 * @returns
 */
const isFormStandard = formId =>
  formId && FORM_TYPES.STANDARD_FORMS.some(form => form.id === formId);

export {
  getFormTemplatesArray,
  findFormTemplateById,
  findFormTemplateByCode,
  getBaseFormTemplates,
  FORM_TEMPLATES,
  isFormSpecial,
  isFormStandard,
};
