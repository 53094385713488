import useSWR from 'swr';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { useStore } from './use-store';
import { useRouter } from 'next/router';

export const useNotifications = () => {
  const [{ user }] = useStore();
  const router = useRouter();

  const { data, mutate } = useSWR(
    router.pathname !== '/' && '/api/v2/notifications/grid', // disabling the fetch on basePath the user won't be logged in.
    async url => {
      try {
        const res = await FrontendHttpClient.post(url, {
          startRow: 0,
          endRow: 10,
        });
        return res?.data;
      } catch (error) {
        return;
      }
    }
  );

  return {
    notifications: data,
    mutate,
  };
};
