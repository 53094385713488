/**
 * An extended error that sets the name of a custom error to the Class name.
 * Should be extended for all custom errors.
 * @example
 * // contrived example of how to use:
 * class InvalidCertificate extends CHError {
 *    constructor(id, invalidReason) {
 *       super(`${id} is invalid because: ${invalidReason}`)
 *    }
 * }
 * const err = new InvalidCertificate('fakeID', "this certificate is fake");
 * console.log(err.name); // prints `InvalidCertificate`
 * console.log(err); // prints:
 * // InvalidCertificate: fakeID is invalid because: this certificate is fake
 * // at <anonymous>:6:15
 */
export class CHError extends Error {
  /**
   *
   * @param {string} message
   */
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}
/**
 *
 * @param {Error} error
 * @param {object} info
 */

export const errorHandler = (error, info) => {
  console.log('error: ', error);
  console.log('info: ', info);
  // Do something with the error
  // E.g. log to an error logging client here
};
