import { bulkActionsDisplayText, bulkJobActions } from './bulk-job-actions';

export const changeHistoryActions = Object.freeze(
  /** @type {const} */ ({
    ...bulkJobActions,
    CLONE: 'clone',
    CREATE: 'create',
    SEND_EMAIL: 'send_email',
    SEND_FAX: 'send_fax',
    ISSUE: 'issue',
    ACTIVATE: 'activate',
    DEACTIVATE: 'deactivate',
    DELETE: 'delete',
    RENAME: 'rename',
    EDIT: 'edit',
    REMOVE_REVISION: 'remove_revision',
    SYNC_INSUREDS: 'sync_insureds',
  })
);

export const actionsDisplayText = Object.freeze(
  /** @type {const} */ ({
    ...bulkActionsDisplayText,
    create: 'Created',
    send_email: 'Sent Email',
    send_fax: 'Sent Fax',
    issue: 'Issued',
    activate: 'Activated',
    deactivate: 'Deactivated',
    activated: 'Activated',
    deactivated: 'Deactivated',
    requested: 'Requested',
    received: 'Received',
    rejected: 'Rejected',
    revisionRequested: 'Revision Requested',
    accepted: 'Accepted',
    cloned: 'Cloned',
    delete: 'Deleted',
    remove_revision: 'Removed Revision',
    sync_insureds: 'Synced Insureds',
  })
);

export const allowedChangeHistoryActions = Object.values(changeHistoryActions);
