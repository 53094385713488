import { useRouter } from 'next/router';
import { createContext, useEffect, useReducer } from 'react';
import useSWR from 'swr';
import { route } from 'nextjs-routes';
import defaultGlobalState from './default-global-state';
import reducerList from './reducer-list';
import reducers from './reducers';

export function Store({ children }) {
  const [state, dispatch] = useReducer(reducers, {
    ...defaultGlobalState,
    skipSettingLocalStorage: true, // don't set local storage on initial load
  });
  const router = useRouter();

  const { mutate } = useSWR(
    router.pathname !== '/' && state.user.id
      ? route({ pathname: '/api/v2/users/[id]', query: { id: state.user.id } })
      : null,
    {
      refreshInterval: 600000,
      refreshWhenHidden: true,
      onSuccess: data => {
        dispatch({ type: reducerList.SET_USER, payload: data });

        // If any login conditions are pending then sends the user back to fulfill those
        if (data.loginCode) {
          router.push('/');
        }
      },
    }
  );

  // Keep the broker data up to date
  const { mutate: mutateBroker } = useSWR(
    router.pathname !== '/' && state.user.id
      ? route({ pathname: '/api/v2/broker' })
      : null,
    {
      refreshInterval: 600000,
      refreshWhenHidden: true,
      onSuccess: data => {
        dispatch({ type: reducerList.SET_BROKER, payload: data });
      },
    }
  );

  useEffect(() => {
    mutate();
    mutateBroker();
  }, [mutate, mutateBroker, router.pathname]);

  useEffect(() => {
    const savedState = localStorage.getItem('currentState');

    if (savedState === null) {
      dispatch({ type: reducerList.SET_TO_DEFAULT_STATE });
    }

    const parsedState = JSON.parse(savedState) || defaultGlobalState;
    // reset these when we pull them back from local storage.
    // there is no reason to hang on to them in local storage.
    parsedState.unsavedNotesExist = false;
    parsedState.unsavedChangesExist = {};

    if (router.pathname !== '/' && !parsedState.user.id) {
      dispatch({ type: reducerList.SET_TO_DEFAULT_STATE });
      window.location.href = '/';
    } else {
      dispatch({
        type: reducerList.SET_INITIAL_STATE,
        payload: parsedState,
      });
    }
  }, [router]);

  useEffect(() => {
    if (state.skipSettingLocalStorage) {
      return;
    }

    localStorage.setItem('currentState', JSON.stringify(state));
  }, [state]);

  return (
    <AppContext.Provider value={[state, dispatch, mutate]}>
      {children}
    </AppContext.Provider>
  );
}

export const AppContext = createContext([
  defaultGlobalState,
  () => {},
  () => {},
]);
