import {
  Children,
  Fragment,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { usePreviousValue } from '@/hooks/use-previous-value';
import isEqual from 'lodash/isEqual';
import BDropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import isNil from 'lodash/isNil';
import cx from 'clsx';
import { Spinner, Form } from 'react-bootstrap';

import Label from '@/components/label';
import MultiSelect from '@/components/dropdown/multi-select/multi-select';
import { useOutsideClick } from '@/hooks/use-outside-click';
import globalStyles from '@/styles/globals.module.scss';
import { DROPDOWN_OPTION_TEXT_ELLIPSIS_CUTOFF_LIMIT } from '@/constants';
import { truncateString } from '@/utils/helpers/strings';

import { faAngleDown, faTimes } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import Button from '../button';

const POPPER_CONFIG = {
  modifiers: [
    {
      name: 'computeStyles',
      options: {
        adaptive: false,
      },
    },
  ],
};
/**
 * @typedef {object} DropdownOption
 * @property {string} [id] The unique identifier of the dropdown option
 * @property {string} [text] The text label that will be displayed on the dropdown option
 * @property {string} [value] The value of the dropdown option
 * @property {JSX.Element} [element]
 * @property {boolean} [disabled] Whether this option will be disabled (default: false)
 * @property {string} [tooltip] (optional) If we want to display a tooltip on the dropdown option
 * @property {import('react-bootstrap/DropdownItem').DropdownItemProps['onSelect']} onClick
 */

/**
 * @typedef {object} SelectedOption
 * @property {string} id The unique identifier of the selected dropdown item
 * @property {string} text The text label of the selected dropdown item
 */

/**
 * @typedef {object} CustomDropdownProps
 * @property {string} id
 * @property {Partial<import('react-bootstrap/DropdownToggle').DropdownToggleProps>} toggleProps
 * @property {string} containerClassName
 * @property {string} toggleClassName
 * @property {string} caretClassName
 * @property {string} labelClassName
 * @property {string} menuClassName
 * @property {string} className
 * @property {string} itemName
 * @property {string} label
 * @property {string} subLabel
 * @property {boolean} show
 * @property {boolean} hideToggle
 * @property {boolean} clearButton Whether to show the clear button with the "x" icon
 * @property {(event: React.MouseEvent<HTMLElement>) => void} onClear Callback to clear the dropdown
 * @property {boolean} multiSelect Whether this dropdown should be a multiselect
 * @property {SelectedOption[]} selectedItems (multiselect only) The array of selected options
 * @property {(options: SelectedOption[]) => void}  setSelectedItems (multiselect only) Callback to set the state of selected options
 * @property {React.ReactNode} children
 * @property {DropdownOption[]} options
 * @property {import('react-bootstrap/DropdownItem').DropdownItemProps['onSelect']} onClose
 * @property {React.MouseEventHandler<HTMLElement>} onToggle
 * @property {import('react-bootstrap/DropdownItem').DropdownItemProps['onSelect']} onClick
 * @property {string} value
 * @property {string} error
 * @property {(option: DropdownOption, event: React.SyntheticEvent<unknown>) => void} onChange
 * @property {boolean} isInvalid
 * @property {boolean} enableFilter Allows you to search and filter the dropdown options
 * @property {boolean} selectAllButton (multiselect only) Whether to show the "Select All" button
 * @property {string} placeholder
 * @property {boolean} disabled
 * @property {boolean} pending Whether the dropdown is in a loading data (disabled with spinner)
 * @property {boolean} [autoClose=true]
 * @property {boolean} [shouldCloseOnSelection=true]
 * @property {number} optionEllipsisCutoffLength
 * @property {string} noResultsMessage The message to display if the dropdown is empty
 * @property {boolean} [showExpired]
 * @property {boolean} [renderOnMount]
 * @property {() => void} [onShowExpired]
 * @property {string} [displayValueClass]
 */

/** @type {React.FC<CustomDropdownProps&Partial<import('react-bootstrap/Dropdown').DropdownProps>>} */
const Dropdown = forwardRef((props, ref) => {
  const {
    id,
    toggleProps,
    containerClassName,
    toggleClassName,
    caretClassName,
    selectAllButton = false,
    handleSelectAllSelection,
    isSelectAll,
    labelClassName,
    menuClassName,
    clearButton = false,
    onClear,
    className,
    label,
    subLabel,
    show,
    hideToggle,
    children,
    options = [],
    selectedItems,
    setSelectedItems,
    itemName = 'Items',
    onClose,
    onToggle,
    onClick,
    value,
    error,
    onChange,
    showExpired,
    onShowExpired,
    multiSelect = false,
    isInvalid,
    placeholder,
    disabled,
    pending = false,
    enableFilter = false,
    querySearch,
    autoClose = true,
    shouldCloseOnSelection = true,
    optionEllipsisCutoffLength = DROPDOWN_OPTION_TEXT_ELLIPSIS_CUTOFF_LIMIT,
    noResultsMessage = 'No results',
    renderOnMount,
    noReopenFilterFix = false,
    displayValueClass,
    ...dropdownProps
  } = props;
  const containerRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const dropdownDisabled = !!disabled || !!pending;

  if (multiSelect && (!selectedItems || !setSelectedItems)) {
    throw Error(
      'Multiselect dropdowns requires selectedItems and setSelectedItems'
    );
  }

  const handleClickDropdown = event => {
    if (event) {
      event.stopPropagation();
    }
    if (onClick) {
      onClick(event);
    }
  };

  const handleToggle = (eventOne, eventTwo) => {
    // Arguments differ between dropdown and toggle
    const event =
      typeof eventOne === 'object'
        ? eventOne
        : typeof eventTwo === 'object'
        ? eventTwo
        : null;

    if (event && event.key === 'Tab') {
      return;
    }
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    if (onToggle) {
      onToggle(eventOne);
    }
    setIsOpen(!isOpen);
  };

  const handleClickOutside = event => {
    if (isOpen) {
      if (onClose) {
        onClose(event);
      }
      if (autoClose) {
        setIsOpen(!isOpen);
      }
    }
  };

  const handleSelect = option => event => {
    if (multiSelect) {
      if (selectedItems.map(e => e.id).includes(option.id)) {
        setSelectedItems([...selectedItems.filter(e => e.id !== option.id)]);
      } else {
        setSelectedItems([
          ...selectedItems,
          { text: option.text, id: option.id },
        ]);
      }
    }

    if (option.onClick) {
      option.onClick(event);
    }
    if (onChange) {
      onChange(option, event);
    }
    if (onClose) {
      onClose(event);
    }
    if (!multiSelect && shouldCloseOnSelection) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const previousOptions = usePreviousValue(options);

  // when the options change (e.g. show/hide expired),
  // close the dropdown and reopen it to reset the position
  useEffect(() => {
    // check if options actually changed and do nothing if they didnt
    if (
      !noReopenFilterFix &&
      (!previousOptions || !isEqual(previousOptions, options))
    ) {
      // the setter function allows us to check the previous value without adding it to the dependency array
      setIsOpen(prev => {
        // if it isn't open... don't change anything
        if (!prev) {
          return prev;
        }
        // set the dropdown to reopen after the next render to reset the position
        setTimeout(() => setIsOpen(true));
        // close the dropdown
        return false;
      });
    }
  }, [options, previousOptions, noReopenFilterFix]);

  useOutsideClick(containerRef, handleClickOutside);

  let displayValue = value;

  if (multiSelect) {
    if (selectedItems.length > 1) {
      if (isSelectAll) {
        displayValue = `All Insureds selected`;
      } else {
        displayValue = `${selectedItems.length} ${itemName} selected`;
      }
    } else if (selectedItems.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      displayValue = selectedItems[0].text;
    } else {
      displayValue = null;
    }
  } else if (!isNil(value)) {
    const selectedOption = options.find(o => o && o.value === value);
    if (selectedOption) {
      displayValue = selectedOption.text;
    }
  }
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    querySearch?.(searchValue);
  }, [querySearch, searchValue]);

  return (
    <div
      ref={containerRef}
      className={cx(styles.container, containerClassName)}
    >
      {label ? (
        <Label
          htmlFor={props.name}
          className={cx(styles.label, labelClassName)}
        >
          {label}
        </Label>
      ) : null}
      {subLabel ? (
        <Label
          htmlFor={props.name}
          className={cx(styles.subLabel, labelClassName)}
        >
          {subLabel}
        </Label>
      ) : null}

      <BDropdown
        drop="down"
        {...dropdownProps}
        ref={ref}
        show={!!isOpen}
        onClick={handleClickDropdown}
        className={cx(
          styles.dropdown,
          isOpen && styles.dropdownOpen,
          className
        )}
      >
        {!hideToggle ? (
          <OverlayTrigger
            delay={{
              show:
                selectedItems?.length > 1 ||
                options.find(op => op?.text === value)?.fullText?.length >
                  value?.length
                  ? 50
                  : Number.MAX_SAFE_INTEGER,
              hide: 200,
            }}
            overlay={
              <Tooltip id="tooltip-dropdown">
                {selectedItems?.length ? (
                  selectedItems?.slice(0, 4).map((e, index) => {
                    return (
                      <span key={`drop-tooltip-${e.text}-${index}`}>
                        {e.text}
                        <br />
                      </span>
                    );
                  })
                ) : (
                  <span key="drop-tooltip-1">
                    {options.find(op => op?.text === value)?.fullText}
                    <br />
                  </span>
                )}
                {selectedItems?.length > 4 && <span>and more...{value}</span>}
              </Tooltip>
            }
          >
            <div>
              <BDropdown.Toggle
                id={id}
                {...toggleProps}
                disabled={dropdownDisabled}
                onClick={handleToggle}
                style={{ pointerEvents: dropdownDisabled ? 'none' : 'inherit' }}
                className={cx(
                  styles.toggle,
                  dropdownDisabled && globalStyles.disabled,
                  toggleClassName
                )}
              >
                <span className={cx(styles.childContainer, displayValueClass)}>
                  {displayValue || placeholder}
                </span>

                {pending ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <>
                    {clearButton && (
                      <FontAwesomeIcon
                        className={globalStyles.caret}
                        icon={faTimes}
                        onClick={event => {
                          event.stopPropagation();
                          setIsOpen(false);
                          onClear(event);
                        }}
                      />
                    )}

                    <FontAwesomeIcon
                      className={globalStyles.caret}
                      icon={faAngleDown}
                    />
                  </>
                )}
              </BDropdown.Toggle>
            </div>
          </OverlayTrigger>
        ) : null}

        {!children ? (
          <BDropdown.Menu
            align={props.align || 'end'}
            popperConfig={POPPER_CONFIG}
            {...(renderOnMount && { renderOnMount })}
            className={cx(
              styles.menu,
              menuClassName,
              !isOpen && styles.isClosed
            )}
          >
            {enableFilter && Array.isArray(options) && (
              <Form.Control
                autoFocus
                className="mx-3 my-2 w-75"
                placeholder="Type to filter..."
                onChange={e => setSearchValue(e.target.value)}
                id="searchFilter"
                value={searchValue}
              />
            )}
            {!options || !options.length ? (
              <Label className={styles.noResults}>{noResultsMessage}</Label>
            ) : null}
            {selectAllButton && (
              <BDropdown.Item
                className={cx(styles.menuItem, styles.selectAll)}
                onSelect={() => {
                  // deselect all if everything is selected
                  if (selectedItems.length === options.length) {
                    setSelectedItems([]);
                    handleSelectAllSelection?.(false);
                    return;
                  }
                  setSelectedItems(options);
                  handleSelectAllSelection?.(true);
                }}
              >
                Select All
              </BDropdown.Item>
            )}
            {options
              .filter(o => {
                if (!o) {
                  return false;
                }

                if (searchValue) {
                  return o.text
                    .toLowerCase()
                    .includes(searchValue.toLowerCase());
                }

                return true;
              })
              .map((option, index) => {
                const { text, element, id: elementId } = option;
                let displayText = text;
                if (text && text.length > optionEllipsisCutoffLength) {
                  displayText = truncateString(
                    text,
                    optionEllipsisCutoffLength
                  );
                }

                if (option.label) {
                  return (
                    <BDropdown.Item
                      key={`dropdown_item-${text}-${index}`}
                      className={cx(styles.menuItem, styles.label)}
                      disabled
                    >
                      {option.label}
                    </BDropdown.Item>
                  );
                }

                let isSelected = value && value === text;

                if (multiSelect) {
                  isSelected = !!selectedItems.find(e => e.id === elementId);
                }

                if (option.divider) {
                  return (
                    <Fragment key={`dropdown_menu_${text}-${index}`}>
                      <BDropdown.Divider key={`dropdown_divider-${index}`} />
                    </Fragment>
                  );
                }

                return (
                  <Fragment key={`dropdown_menu_${text}-${index}`}>
                    <BDropdown.Item
                      id={`dropdown_menu_${text.replace(/([ ()\n])/g, '')}`}
                      className={cx(
                        styles.menuItem,
                        isSelected && styles.selected,
                        option.disabled && styles.disabled
                      )}
                      onSelect={handleSelect(option)}
                      disabled={!!option.disabled}
                    >
                      <OverlayTrigger
                        placement="top"
                        delay={{
                          show:
                            option?.tooltip ||
                            text.length > optionEllipsisCutoffLength
                              ? 50
                              : Number.MAX_SAFE_INTEGER,
                          hide: 200,
                        }}
                        overlay={
                          <Tooltip id="tooltip-item">
                            {option.tooltip || text}
                          </Tooltip>
                        }
                      >
                        <div
                          className={styles.items}
                          data-cy={`dropdown-item-${text.replace(
                            /([ ()\n])/g,
                            ''
                          )}`}
                        >
                          {!option.disableMultiSelectCheckbox && multiSelect ? (
                            <MultiSelect
                              disabled={!!option.disabled}
                              selected={isSelectAll || isSelected}
                              option={option}
                              handleSelect={handleSelect}
                            />
                          ) : null}
                          {element || displayText}
                        </div>
                      </OverlayTrigger>
                    </BDropdown.Item>
                  </Fragment>
                );
              })}
            {onShowExpired && (
              <Button
                data-cy="showExpiredButton"
                className={styles.showExpiredButton}
                onClick={onShowExpired}
              >
                {showExpired ? 'Hide' : 'Show'} Expired
              </Button>
            )}
          </BDropdown.Menu>
        ) : (
          children
        )}
        {isInvalid && error ? (
          <Label className={styles.error}>{error}</Label>
        ) : null}
      </BDropdown>
    </div>
  );
});

export default Dropdown;
