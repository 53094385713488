import { useCallback } from 'react';
import { Modal } from '@/components/modal';
import Button, { ButtonPrimary } from '@/components/button';

/**
 * A modal that confirms a route change if user has unsaved changes
 * @param {object} props
 * @param {boolean} props.open
 * @param {() => void} props.hide
 * @param {() => void} props.setIsOpen
 * @param {() => void | Promise<void>} [props.handleContinue]
 * @param {string} [props.title]
 * @param {any} [props.body]
 * @param {() => void} [props.handleCancel]
 */
const WarningModal = props => {
  const {
    open,
    hide,
    setIsOpen = () => null,
    handleContinue,
    title,
    body,
    handleCancel,
  } = props;

  const defaultTitle = 'Are you sure you want to leave?';
  const defaultBody = 'Any unsaved changes will be discarded.';

  const onCancel = useCallback(() => {
    if (handleCancel) {
      handleCancel();
    }
    hide();
    setIsOpen(false);
  }, [handleCancel, hide, setIsOpen]);

  return (
    <Modal show={open} onHide={() => hide() && setIsOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title || defaultTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body || defaultBody}</Modal.Body>
      {handleContinue ? (
        <Modal.Footer>
          <Button id="cancel" variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <ButtonPrimary
            id="continue"
            variant="primary"
            onClick={() => {
              handleContinue();
              hide();
            }}
          >
            Continue
          </ButtonPrimary>
        </Modal.Footer>
      ) : (
        <Modal.Footer>
          <ButtonPrimary id="cancel" variant="primary" onClick={onCancel}>
            Close
          </ButtonPrimary>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default WarningModal;
