import EndorsementMapperDropdown from '@/components/renewals/endorsements-step/endorsement-mapper-dropdown';

/**
 * @param {object} props
 * @param {() => void} props.setShowModal
 * @param {EndorsementMapping[]} props.endorsementMappings
 * @param {(params: EndorsementMapperParams) => void} props.toggleEndorsementMappings
 * @param {() => void} props.setEndorsementToEdit
 * @param {() => void} props.setSelectedEndorsementId
 */
const EndorsementMapperLinkRenderer = props => {
  const { context, node } = props;

  const {
    setShowModal,
    endorsementMappings,
    toggleEndorsementMappings,
    setEndorsementToEdit,
    setSelectedEndorsementId,
  } = context;

  const { data: oldEndorsement = {} } = node || {};

  if (!oldEndorsement) {
    return '-';
  }

  return (
    <EndorsementMapperDropdown
      oldEndorsement={oldEndorsement}
      setShowModal={setShowModal}
      endorsementMappings={endorsementMappings}
      toggleEndorsementMappings={toggleEndorsementMappings}
      setEndorsementToEdit={setEndorsementToEdit}
      setSelectedEndorsementId={setSelectedEndorsementId}
    />
  );
};

export default EndorsementMapperLinkRenderer;
