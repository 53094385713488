import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import NotificationService from '@/components/notification-service';
import useSWR from 'swr';
import { route } from 'nextjs-routes';
import { useStore } from './use-store';
import { useCertId } from './use-cert-id';
import { usePageContext } from './use-page-context';

export const useNotes = () => {
  const [pageContext, setPageContext] = usePageContext();
  const [{ insuredId }, setStore] = useStore();
  const certId = useCertId();

  const [isUpdating, setIsUpdating] = useState(false);

  const {
    insuredNoteChanged,
    certNoteChanged,
    insuredNote,
    certNote,
    complianceNote,
  } = pageContext;
  const [initialNotes, setInitialNotes] = useState({
    insuredNote,
    certNote,
  });

  const { data } = useSWR(
    certId || insuredId
      ? [route({ pathname: '/api/v2/notes' }), certId, insuredId]
      : null,
    async ([url, id, insId]) => {
      const res = await FrontendHttpClient.get(url, {
        certId: id,
        insuredId: insId,
      });
      return res.data;
    }
  );

  /**
   * @param {string} note
   */
  const updateInsuredNote = note => {
    setPageContext({ insuredNote: note });

    if (note !== initialNotes.insuredNote) {
      setStore({ unsavedNotesExist: true });
      setPageContext({ insuredNoteChanged: true });
    } else {
      setPageContext({ insuredNoteChanged: false });
    }
  };

  /**
   * @param {string} note
   */
  const updateCertNote = note => {
    setPageContext({ certNote: note });

    if (note !== initialNotes.certNote) {
      setStore({ unsavedNotesExist: true });
      setPageContext({ certNoteChanged: true });
    } else {
      setPageContext({ certNoteChanged: false });
    }
  };

  useEffect(() => {
    if (!pageContext.certNoteChanged && !pageContext.insuredNoteChanged) {
      setStore({ unsavedNotesExist: false });
    }
  }, [pageContext.certNoteChanged, pageContext.insuredNoteChanged, setStore]);

  const save = useCallback(async () => {
    if (certNoteChanged) {
      setIsUpdating(true);

      try {
        await FrontendHttpClient.put('/api/v2/notes', {
          value: certNote,
          certId,
          type: 'cert',
          noteBatchId: certNote.noteBatchId || uuidv4(),
        });

        setPageContext({ certNoteChanged: false });
        setStore({ unsavedNotesExist: false });
      } catch (e) {
        NotificationService.error('Error saving notes');
      }
    }

    if (insuredNoteChanged) {
      setIsUpdating(true);
      try {
        await FrontendHttpClient.put('/api/v2/notes', {
          value: insuredNote,
          type: 'insured',
          insuredId,
        });

        setPageContext({ insuredNoteChanged: false });
        setStore({ unsavedNotesExist: false });
      } catch (e) {
        NotificationService.error('Error saving notes');
      }
    }
    NotificationService.success('Note saved');
    setIsUpdating(false);
  }, [
    certId,
    certNote,
    certNoteChanged,
    insuredId,
    insuredNote,
    insuredNoteChanged,
    setPageContext,
    setStore,
  ]);

  useEffect(() => {
    if (data) {
      setInitialNotes({
        certNote: data.record?.certNote?.value || certNote,
        complianceNote: data.record?.complianceNote?.value || complianceNote,
        insuredNote: data.record?.insuredNote?.value || insuredNote,
      });
    }
  }, [data, certNote, complianceNote, insuredNote, setInitialNotes]);

  useEffect(() => {
    if (data) {
      setPageContext({
        certNote: data.record?.certNote?.value || '',
        insuredNote: data.record?.insuredNote?.value || '',
        complianceNote: data.record?.complianceNote?.value || '',
      });
    }
  }, [data, setPageContext]);

  return {
    certNote,
    insuredNote,
    complianceNote,
    isUpdating,
    updateCertNote,
    updateInsuredNote,
    save,
  };
};
