import {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import useSWR from 'swr';
import { Spinner } from 'react-bootstrap';
import { format } from 'date-fns';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faBell } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'next/router';
import { route } from 'nextjs-routes';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import cx from 'clsx';

import reducerList from '@/utils/reducer-list';
import { AppContext } from '@/utils/app-context';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import Dropdown from '@/components/dropdown';
import Avatar from '@/components/avatar';
import { object, string } from 'yup';

import globalStyles from '@/styles/globals.module.scss';
import { userTypes } from '@/enums/user-types';
import { API_ENDPOINTS } from '@/enums/api-endpoints';
import { INSURED_NAME_TYPEAHEAD_LIMIT } from '@/constants';
import dynamic from 'next/dynamic';
import { useStore } from '@/hooks';
import styles from './styles.module.scss';

import editorStyles from '@/components/editor/styles.module.scss';
import NotificationService from '@/components/notification-service';
import NotificationBell from '@/components/notifications-bell';
import { CLIENT_SECRETS } from '@/config/client_secrets';

import NonProdMarker from '@/components/outside-prod-marker';

const { POLICIES, USERS } = API_ENDPOINTS;
const { BROKER, INSURED } = userTypes;

const Typeahead = dynamic(() => import('@/components/typeahead'));

let currentSearch;

const Header = ({ isSidebarCollapsed = false }) => {
  const router = useRouter();

  const [{ broker, user, canChangeInsured }] = useStore();
  const { isBranded = false } = broker?.branding || {};
  const [isFocused, setIsFocused] = useState();

  const [syncingInsureds, setSyncingInsureds] = useState(false);

  /**
   */

  const dispatch = useContext(AppContext)[1];

  const { data: mostRecentSync, mutate: mutateMostRecentSync } = useSWR(
    route({
      pathname: '/api/v2/change_history/most_recent',
      query: { entityType: 'insureds' },
    }),
    { revalidateOnFocus: false }
  );

  const [loadingInsureds, setLoadingInsureds] = useState(false);
  const [insuredIdSelection, setInsuredIdSelection] = useState([]);

  const lastInsuredSelected = useRef();

  const [insuredErrors, setInsuredErrors] = useState('');
  const [textErrors, setTextErrors] = useState('');

  const [insuredsList, setInsuredsList] = useState(user.recentInsureds);

  const parseClientCode = useCallback(clientCode => {
    const clientCodeAppend = clientCode ? ` - (${clientCode})` : '';

    return clientCodeAppend;
  }, []);

  useEffect(() => {
    if (user?.insuredId && user?.insuredName) {
      const clientCodeAppend = parseClientCode(user.clientCode);

      setInsuredIdSelection([
        {
          id: user.insuredId,
          label: `${user.insuredName}${clientCodeAppend}`,
        },
      ]);

      setInsuredErrors('');

      return;
    }

    setInsuredErrors('No Insured Currently Selected');
  }, [parseClientCode, user.clientCode, user.insuredId, user.insuredName]);

  // Client side sign out
  const signOut = async () => {
    try {
      await FrontendHttpClient.post('/api/logout');
      window.location.href = '/';
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error signing out: ', error);
    }
  };

  const handleAccountClick = () => {
    router.push('/profile');
  };

  const selectInsured = async insuredId => {
    FrontendHttpClient.post('/api/v2/policies/ams/pre-fetch', {
      insuredId,
    });

    // Update the user
    const response = await FrontendHttpClient.put('/api/v2/users/insured', {
      insuredId,
    });

    dispatch({
      type: reducerList.SET_USER,
      payload: response.data.record,
    });
  };

  const handleInsuredChange = selected => {
    if (Array.isArray(selected)) {
      setInsuredIdSelection([...selected]);
    }
    if (selected && selected[0] && selected[0].id) {
      selectInsured(selected[0].id);
      setInsuredErrors('');
      return;
    }

    setInsuredErrors('Please select a valid insured');
  };

  const getRecentViewedInsuredsList = () => {
    if ('recentInsureds' in user && user.recentInsureds.length > 0) {
      return [
        { label: 'Recently Viewed:', disabled: true },
        ...user.recentInsureds,
      ];
    } else {
      return [{ label: 'No Recently Viewed Insureds.', disabled: true }];
    }
  };

  useEffect(() => {
    if (insuredIdSelection && insuredIdSelection[0]) {
      lastInsuredSelected.current = insuredIdSelection;
    }
  }, [insuredIdSelection]);
  const handleInsuredSearch = async search => {
    // keep track of the most recently started search
    currentSearch = search;

    if (currentSearch === ' ' || !currentSearch) {
      setInsuredsList(getRecentViewedInsuredsList());
      return;
    }

    setLoadingInsureds(true);

    const response = await FrontendHttpClient.get('/api/v2/ams/insureds', {
      search,
    });

    // If a newer query has started, we can ignore old query result
    if (response.data.search !== currentSearch) {
      return;
    }

    setInsuredsList(
      response.data.insureds.map(insured => {
        return {
          label: `${insured.name}${parseClientCode(insured.clientCode)}`,
          id: insured.amsId,
        };
      })
    );
    setLoadingInsureds(false);
  };

  const searchSchema = object().shape({
    query: string().max(500, 'Must be less than 500 characters'),
  });

  const handleInsuredSearchCheck = async search => {
    try {
      await searchSchema.validate({ query: search });
    } catch (err) {
      setTextErrors(err.message);
      return;
    }

    setTextErrors('');
    await handleInsuredSearch(search);
  };

  const menuOptions = [
    { text: 'Account', onClick: handleAccountClick },
    { text: 'Logout', onClick: signOut },
  ];

  const isAllowedToChangeInsured = useMemo(() => {
    return (
      user.userType === BROKER ||
      (user.userType === INSURED && user.allowedInsuredIds.length > 1)
    );
  }, [user.allowedInsuredIds, user.userType]);

  return (
    <>
      <Navbar
        onMouseLeave={() => {
          if (!isFocused && (!insuredIdSelection || !insuredIdSelection[0])) {
            handleInsuredChange(lastInsuredSelected.current);
          }
        }}
        className={cx(
          styles.header,
          globalStyles.layoutPadding,
          isSidebarCollapsed && styles.collapseHeader
        )}
      >
        <div className={styles.headerLeft}>
          {isAllowedToChangeInsured && (
            <div className={styles.insuredContainer}>
              <span
                className={cx(
                  styles.insuredText,
                  !canChangeInsured && styles.insuredTextDisabled,
                  (!!textErrors || !!insuredErrors) && styles.insuredTextInvalid
                )}
              >
                Insured:
              </span>
              <Typeahead
                async
                clearButton
                clearOnInvalid
                multiple={false}
                allowNew={false}
                useCache={false}
                highlightOnlyResult
                data-cy="insured-typeahead"
                isLoading={loadingInsureds}
                placeholder="Search Company"
                id="search-typeahead-single"
                onChange={handleInsuredChange}
                onSearch={handleInsuredSearchCheck}
                options={insuredsList}
                filterBy={() => true}
                minLength={0}
                selected={insuredIdSelection}
                className={styles.typeahead}
                isInvalid={!!textErrors || !!insuredErrors}
                error={[textErrors, insuredErrors].filter(e => !!e).join(', ')}
                disabled={!canChangeInsured}
                maxLimit={500}
                menuItemLimit={INSURED_NAME_TYPEAHEAD_LIMIT}
                onFocus={() => {
                  setIsFocused(true);
                  setInsuredsList(getRecentViewedInsuredsList());
                }}
                onBlur={() => {
                  setIsFocused(false);
                  if (!insuredIdSelection || !insuredIdSelection[0]) {
                    handleInsuredChange(lastInsuredSelected.current);
                  }
                }}
              />

              <div
                className={editorStyles.syncButtonContainer}
                style={{ marginLeft: 20 }}
                onClick={async () => {
                  if (syncingInsureds) {
                    NotificationService.info('Insureds are already syncing');
                    return;
                  }

                  // if most recent sync is within 5 minutes, don't sync again
                  if (
                    mostRecentSync?.message?.createdAt &&
                    new Date(mostRecentSync.message.createdAt) >
                      new Date(Date.now() - 5 * 60 * 1000)
                  ) {
                    NotificationService.info(
                      'Synced less than 5 minutes ago, skipping sync'
                    );
                    return;
                  }

                  setSyncingInsureds(true);
                  await FrontendHttpClient.post('/api/v2/ams/sync', {});
                  NotificationService.success('Sync complete');
                  mutateMostRecentSync();
                  setSyncingInsureds(false);
                }}
              >
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 50, hide: 200 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      Insureds Last Sync: <br />
                      {mostRecentSync?.message?.createdAt
                        ? format(
                            new Date(mostRecentSync.message.createdAt),
                            'MM/dd/yyyy hh:mm a'
                          )
                        : ''}
                    </Tooltip>
                  }
                >
                  <div>
                    {syncingInsureds ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <FontAwesomeIcon icon={faSync} />
                    )}
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          )}

          {!isAllowedToChangeInsured && (
            <h4 className={styles.insuredName}>{user.insuredName}</h4>
          )}
        </div>
      </Navbar>

      <div
        className={cx(
          styles.dropdownContainer,
          isBranded && styles.insuredBranding,
          isBranded &&
            CLIENT_SECRETS.TOP_BRANDING_URL &&
            styles.expandedInsuredBranding
        )}
      >
        <NonProdMarker />
        <NotificationBell />
        <Dropdown
          align="end"
          drop="down"
          flip="false"
          options={menuOptions}
          placeholder={<Avatar user={user} />}
          className={cx(styles.dropdown, 'my-2')}
          menuClassName={styles.dropdownMenu}
          toggleClassName={styles.dropdownToggle}
          containerClassName={cx(isBranded ? styles.insuredBranding : '')}
        />
      </div>
    </>
  );
};

export default Header;
