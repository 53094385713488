import { useEffect, useRef, useState, useCallback } from 'react';

import { SocketContext } from '@/components/socket/context';
import NotificationService from '@/components/notification-service';
import { downloadFromURL } from '@/utils/client-side-link-download';
import Pusher from 'pusher-js';
import { useRouter } from 'next/router';
import { CLIENT_SECRETS } from '@/config/client_secrets';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { useStore, useNotifications } from '@/hooks';

export const SocketProvider = ({ children }) => {
  /** @type {MutableRefObject<import('pusher-js').default>} */
  const socket = useRef(null);
  const notifications = useRef({});

  const { mutate: mutateServerNotifications } = useNotifications();

  const [{ user }] = useStore();

  const emitter = useCallback(
    (eventName, data, { trackingId, notification = null }) => {
      if (trackingId && notification) {
        notifications.current[trackingId] = notification;
      }

      // API route that replaces socket calls, will eventually replace with real routes
      FrontendHttpClient.post('/api/v2/socket-replacement', {
        event: eventName,
        data,
        trackingId,
      });
    },
    []
  );

  const router = useRouter();
  const [isLoginPage, setIsLoginPage] = useState(router.pathname === '/');

  useEffect(() => {
    setIsLoginPage(router.pathname === '/');
  }, [router.pathname]);

  const notify = ({ trackingId, method, message }) => {
    /**
     * Create and start tracking notifications if they do not exist
     * This allows notifications to be recreated after a page refresh
     * Notifications will be displayed on of the all user's tabs & windows
     * @type {boolean}
     */
    const autoTrack = false;

    const notification =
      notifications.current[trackingId] ||
      (autoTrack &&
        (notifications.current[trackingId] = NotificationService.loading('')));

    if (!notification) {
      return null;
    }

    switch (method) {
      case 'update':
        return notification.update(message);
      case 'error':
      case 'info':
      case 'warning':
      case 'done':
        // stop tracking the notification after receiving a done/error/info/warning
        // duplicate notifications are possible and autoTrack will create a new one
        // wait a sec before removing it
        setTimeout(() => delete notifications.current[trackingId], 1000);
        return notification[method] ? notification[method](message) : null;
      default:
        return null;
    }
  };

  const getSocket = useCallback(userId => {
    // THIS SHOULD BE THE ONLY PLACE WE USE PUSHER CLIENT SIDE

    const pusher = new Pusher(CLIENT_SECRETS.PUSHER_KEY, {
      cluster: CLIENT_SECRETS.PUSHER_CLUSTER,
      authEndpoint: '/api/v2/pusher-auth',
      forceTLS: true,
    });

    const channel = pusher.subscribe(`private-${userId}`);

    // abstract the socket so client in case we want to change to some other socket provider
    return {
      bind: (event, listener) => {
        channel.bind(event, listener);
      },
      unbind: (event, listener) => {
        channel.unbind(event, listener);
      },
      disconnect: () => {
        pusher.disconnect();
      },
    };
  }, []);

  const addSocketListeners = useCallback(() => {
    socket.current.bind('error', async ({ message, trackingId }) => {
      notify({
        trackingId,
        method: 'error',
        message,
      });
    });
    socket.current.bind('info', async ({ message, trackingId }) => {
      notify({
        trackingId,
        method: 'info',
        message,
      });
    });
    socket.current.bind('warning', async ({ message, trackingId }) => {
      notify({
        trackingId,
        method: 'warning',
        message,
      });
    });
    socket.current.bind('success', async ({ message, trackingId }) => {
      notify({
        trackingId,
        method: 'done',
        message,
      });
    });

    socket.current.bind('progress', async ({ message, trackingId }) => {
      notify({
        trackingId,
        method: 'update',
        message: { newMessage: message },
      });
    });
    socket.current.bind('download', async ({ url, filename, trackingId }) => {
      await downloadFromURL(url, filename);
      notify({
        trackingId,
        method: 'done',
        message: 'Download Complete',
      });
    });
    socket.current.bind('notification_sync', async () => {
      mutateServerNotifications();
    });
  }, [mutateServerNotifications]);

  const shutdown = () => {
    socket.current?.disconnect();
    socket.current = null;
  };

  useEffect(() => {
    if (isLoginPage) {
      shutdown();
      return undefined;
    }

    if (!socket.current && user?.id) {
      socket.current = getSocket(user?.id);
      addSocketListeners();
    }

    return shutdown;
  }, [addSocketListeners, getSocket, isLoginPage, user?.id]);

  const reconnectSocket = () => {
    // Todo: Figure out if we still need this
    //
    // shutdown();
    // socket.current = getSocket(user?.id);
    // addSocketListeners();
  };

  return (
    <SocketContext.Provider value={[socket.current, emitter, reconnectSocket]}>
      {children}
    </SocketContext.Provider>
  );
};
