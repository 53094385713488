export const covDataTypes = Object.freeze(
  /** @type {const} */ ({
    LIMIT: 'LIMIT',
    ATTRIBUTE: 'ATTRIBUTE',
    INDICATOR: 'INDICATOR',
    DEDUCTIBLE: 'DEDUCTIBLE',
    DESCRIPTION: 'DESCRIPTION',
    SWITCH: 'SWITCH',
    FREE_TEXT: 'FREE_TEXT',
  })
);

export const subDataTypes = Object.freeze(
  /** @type {const} */ ({
    CURRENCY: 'CURRENCY',
    DATE: 'DATE',
  })
);
