/**
 * Attempts a promise and returns a tuple containing an error if the promise rejected and the resolved value.
 * Used to avoid nasty nested try-catches.
 * @template T
 * @template {Error} E
 * @param {PromiseLike<T> | Promise<T>} p
 * @returns {Promise<[E | undefined, T]>}
 */
export async function attempt(p) {
  try {
    const result = await p;

    return [undefined, result];
  } catch (e) {
    return [e, {}];
  }
}
