import styles from '../styles.module.scss';

/**
 * @typedef {object} option
 * @property {string} [text]
 * @property {JSX.Element} [element]
 * @property {boolean} [disabled]
 */

/**
 * @param {object} props
 * @param {(option: option) => (event: any) => void} props.handleSelect
 * @param {(option)} props.option
 * @param {boolean} props.selected
 * @param {boolean} props.disabled
 * @returns {JSX.Element}
 */
export default function MultiSelect({
  handleSelect,
  option,
  selected,
  disabled,
}) {
  const handleOnClick = e => {
    handleSelect(option);
    setTimeout(() => {
      e.target.checked = !selected;
    });
  };

  return (
    <input
      readOnly
      checked={selected}
      disabled={disabled}
      onClick={handleOnClick}
      className={styles.itemInput}
      type="checkbox"
    />
  );
}
