import useSWR from 'swr';
import { route } from 'nextjs-routes';
import { useBulkJobId } from './use-bulk-job-id';

export const useLobReconciliation = () => {
  const bulkJobId = useBulkJobId();
  const {
    data: lobAmsDifferences,
    isValidating,
    mutate,
  } = useSWR(
    bulkJobId
      ? route({
          pathname: '/api/v2/renewal-steps/get-lob-reconciliation',
          query: { bulkJobId },
        })
      : null
  );

  return {
    isValidating,
    lobAmsDifferences,
    mutate,
  };
};
