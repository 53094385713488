const v2 = '/api/v2';

export const API_ENDPOINTS = Object.freeze(
  /** @type {const} */ ({
    BLOB: `${v2}/pdfs/blob`,
    BROKERS: `${v2}/brokers`,
    BULK_JOB: `${v2}/bulk_job_certificate`,
    BULK_JOBS: `${v2}/bulk_job_certificates`,
    CERTIFICATES: `${v2}/outbound_certificates`,
    INBOUND_CERTIFICATES: `${v2}/inbound_certificates`,
    LEADS: `${v2}/leads`,
    CONTRACTS: `${v2}/contracts`,
    EMAIL_TEMPLATES: `${v2}/email_templates`,
    ENDORSEMENT_FORMS: `${v2}/endorsement_forms`,
    ENDORSEMENTS: `${v2}/endorsements`,
    FILES: `${v2}/files`,
    GROUPS: `${v2}/groups`,
    HOLDER_ADDRESSES: `${v2}/holder_addresses`,
    HOLDERS: `${v2}/holders`,
    IMPORTS: `${v2}/certificate_imports`,
    INSUREDS: `${v2}/ams/insureds`,
    INSURERS: `${v2}/ams/insurers`,
    LANGUAGE_LIBRARY: `${v2}/language_library`,
    EMAIL_TEMPLATE_LIBRARY: `${v2}/email_template_library`,
    NAMED_INSURED: `${v2}/named_insureds`,
    NOTES: `${v2}/notes`,
    POLICIES: `${v2}/policies`,
    SEND: `${v2}/send`,
    LOB_TEMPLATES: `${v2}/lob_templates`,
    CERTIFICATE_TEMPLATES: `${v2}/certificate_templates`,
    USERS: `${v2}/users`,
    SIGNATURES: `${v2}/signatures`,
    CI_DOCUMENTS: `${v2}/ci_documents`,
    AGENCIES: `${v2}/agency`,
    ASSIGNED_USERS: `${v2}/outbound_certificates/utils/assigned_user_options`,
    GET_PRESIGNED_LINK: `${v2}/upload/get_presigned_link`,
  })
);
