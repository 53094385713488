import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import useSWR from 'swr';
import { route } from 'nextjs-routes';

/** Grab all policies, lobs and coverages from the AMS for provided list of policyAmsIds
 */
export const useAmsPoliciesAndLobs = ({ includeCoverages, policyAmsIds }) => {
  const { data, isValidating, mutate } = useSWR(
    policyAmsIds.length !== 0
      ? [
          route({ pathname: '/api/v2/policies/ams/get-parsed-policies' }),
          policyAmsIds,
        ]
      : null,
    async ([url, ids]) => {
      const response = await FrontendHttpClient.post(url, {
        policyIds: ids,
        includeCoverages,
      });

      return response.data.policies;
    }
  );

  return {
    data,
    isValidating,
    mutate,
  };
};
