import { useCallback, useContext, useMemo, createContext } from 'react';
import { useRouter } from 'next/router';
import { EditorContext } from '@/components/editor/context';
import { ReviewContext } from '@/components/review/context';

/** @returns {EditorReducer} */
export const usePageContext = () => {
  const router = useRouter();

  const context = useMemo(() => {
    switch (router.pathname) {
      case '/editor/editor-layout':
      case '/renew/edit-cert-template':
      case '/renew/edit':
      case '/certificate-templates/edit':
        return EditorContext;
      case '/review/review-layout':
        return ReviewContext;
      default:
        return createContext({});
    }
  }, [router.pathname]);

  const contextValue = useContext(context);

  // Check if contextValue is actually an array before destructuring
  const [pageContext, dispatch] = Array.isArray(contextValue)
    ? contextValue
    : [contextValue, () => {}];

  const setPageContext = useCallback(
    update => dispatch({ type: 'UPDATE', payload: update }),
    [dispatch]
  );

  return [pageContext, setPageContext];
};
