import { toast } from 'react-toastify';
import cx from 'clsx';

import SuccessSvg from '@/assets/icons/toasts/success.svg';
import WarningSvg from '@/assets/icons/toasts/warning.svg';
import ErrorSvg from '@/assets/icons/toasts/error.svg';
import InfoSvg from '@/assets/icons/toasts/info.svg';
import { truncateString } from '@/utils/helpers';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { Spinner } from 'react-bootstrap';
import styles from './styles.module.scss';

const MAX_LENGTH = 52;
const WARNING_CLOSE = 3000;
const PROGRESS_CLOSE = 3000;
const INFO_CLOSE = 5000;
const SUCCESS_CLOSE = 5000;
const ERROR_CLOSE = 5000;

const renderIcon = ({ type }) => {
  if (type === 'success') {
    return <SuccessSvg />;
  }
  if (type === 'warning') {
    return <WarningSvg />;
  }
  if (type === 'error') {
    return <ErrorSvg />;
  }

  return <InfoSvg />;
};

function renderMessage(message) {
  return (
    <>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 50, hide: 200 }}
        overlay={
          <Tooltip style={{ zIndex: 9999 }} id="button-tooltip">
            {message}
          </Tooltip>
        }
      >
        <div>{truncateString(message, MAX_LENGTH)}</div>
      </OverlayTrigger>
    </>
  );
}

const DEFAULT_OPTIONS = {
  theme: 'colored',
  icon: renderIcon,
  position: 'top-right',
  className: ({ type }) => cx(styles.toast, styles[type]),
  bodyClassName: styles.body,
};

const NotificationService = {
  success: message => {
    const options = {
      ...DEFAULT_OPTIONS,
      autoClose: SUCCESS_CLOSE,
      pauseOnFocusLoss: false,
      type: toast.TYPE.SUCCESS,
      role: 'success',
    };
    return toast(renderMessage(message), options);
  },
  warning: message => {
    const options = {
      ...DEFAULT_OPTIONS,
      autoClose: WARNING_CLOSE,
      type: toast.TYPE.WARNING,
      role: 'warning',
    };
    return toast(renderMessage(message), options);
  },
  error: message => {
    const options = {
      ...DEFAULT_OPTIONS,
      autoClose: ERROR_CLOSE,
      type: toast.TYPE.ERROR,
      role: 'error',
    };
    return toast(renderMessage(message), options);
  },
  info: message => {
    const options = {
      ...DEFAULT_OPTIONS,
      autoClose: INFO_CLOSE,
      type: toast.TYPE.INFO,
      role: 'info',
    };
    return toast(renderMessage(message), options);
  },
  /**
   * @param {string} message
   */
  progress: message => {
    const options = {
      ...DEFAULT_OPTIONS,
      type: toast.TYPE.INFO,
      autoClose: false,
      progress: 0.0000001, // set to 0 and the first progress won't animate right
      role: 'progress',
    };
    const toastId = toast(renderMessage(message), options);
    return {
      progress: progress => {
        toast.update(toastId, { progress });
      },
      /**
       * @param {string} doneMessage
       * @param {boolean} [hasError]
       */
      done: (doneMessage, hasError) => {
        toast.update(toastId, {
          ...DEFAULT_OPTIONS,
          type: hasError ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
          render: doneMessage,
          progress: 0.9999999,
        }); // set to 1 and it will auto dismiss
        setTimeout(() => toast.done(toastId), PROGRESS_CLOSE);
      },
    };
  },
  /**
   * @param {string} message
   */
  loading: message => {
    const options = {
      ...DEFAULT_OPTIONS,
      type: toast.TYPE.INFO,
      autoClose: false,
      role: 'progress',
      icon: <Spinner className="mx-auto" animation="border" size="sm" />,
    };
    const toastId = toast(renderMessage(message), options);
    return {
      update: ({ newMessage }) => {
        toast.update(toastId, {
          render: renderMessage(newMessage),
        });
      },
      /**
       * @param {string} doneMessage
       */
      done: doneMessage => {
        toast.update(toastId, {
          ...DEFAULT_OPTIONS,
          type: toast.TYPE.SUCCESS,
          render: doneMessage,
          progress: 0.9999999,
        }); // set to 1 and it will auto dismiss
        setTimeout(() => toast.done(toastId), PROGRESS_CLOSE);
      },

      /**
       * @param {string} doneMessage
       */
      info: doneMessage => {
        toast.update(toastId, {
          ...DEFAULT_OPTIONS,
          type: toast.TYPE.INFO,
          render: doneMessage,
          progress: 0.9999999,
        }); // set to 1 and it will auto dismiss
        setTimeout(() => toast.done(toastId), PROGRESS_CLOSE);
      },

      /**
       * @param {string} doneMessage
       */
      error: doneMessage => {
        toast.update(toastId, {
          ...DEFAULT_OPTIONS,
          type: toast.TYPE.ERROR,
          render: doneMessage,
          progress: 0.9999999,
        }); // set to 1 and it will auto dismiss
        setTimeout(() => toast.done(toastId), PROGRESS_CLOSE);
      },

      /**
       * @param {string} warningMessage
       */
      warning: warningMessage => {
        toast.update(toastId, {
          ...DEFAULT_OPTIONS,
          autoClose: WARNING_CLOSE,
          type: toast.TYPE.WARNING,
          render: warningMessage,
          progress: 0.9999999,
          role: 'warning',
        });
        setTimeout(() => toast.done(toastId), PROGRESS_CLOSE);
      },
    };
  },
  clear: () => {
    toast.dismiss();
  },
  promise: (promiseFunc, { pending, success, error }) => {
    return toast.promise(promiseFunc, {
      pending: {
        render: () => renderMessage(pending),
        ...DEFAULT_OPTIONS,
        type: toast.TYPE.INFO,
        role: 'progress',
        icon: <Spinner className="mx-auto" animation="border" size="sm" />,
      },
      success: {
        render: () => renderMessage(success),
        ...DEFAULT_OPTIONS,
        type: toast.TYPE.SUCCESS,
        role: 'success',
      },
      error: {
        render: () => renderMessage(error),
        ...DEFAULT_OPTIONS,
        type: toast.TYPE.ERROR,
        role: 'error',
      },
    });
  },
};

export default NotificationService;
