/** @typedef {{ policyIssues: object[], missingPolicyNames: string[], missingEndorsementNames: string[] }} IssueList */
import { BULK_STATUS } from '@/constants';
import { BulkIssuesEntity } from '@/entities/bulk-issues-entity';
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';

import BooleanRenderer from './BooleanRenderer';

/**
 * @param {object} props
 * @param {IssueList} props.value
 */
const BulkJobIssuesRenderer = props => {
  const issueList = new BulkIssuesEntity(props.value || {});

  if (props?.value?.bulkStatus === BULK_STATUS.issuing) {
    return (
      <>
        <OverlayTrigger
          placement="top"
          delay={{ show: 50, hide: 200 }}
          overlay={<Tooltip>Certificate is being issued</Tooltip>}
        >
          <Spinner animation="border" size="sm" />
        </OverlayTrigger>
      </>
    );
  }

  return <BooleanRenderer value={!issueList.hasIssues} />;
};

export default BulkJobIssuesRenderer;
