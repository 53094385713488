export const FormName = Object.freeze({
  Group: 'group',
  User: 'user',
  AgencyContact: 'agency-contact',
  CertificateTemplate: 'certificate-template',
  CIDocument: 'ci-document',
  Endorsement: 'endorsement',
  Holder: 'holder',
  LanguageLibrary: 'language-library',
  NamedInsured: 'named-insured',
  Review: 'review',
  Signature: 'signature',
  Import: 'import',
  Certificate: 'certificate',
  Inbound: 'inbound',
  LOB: 'line-of-business',
  EmailTemplateAssign: 'email-template-assign',
  EmailTemplateOutbound: 'email-template-outbound',
  EmailTemplateBundle: 'email-template-bundle',
  EditPolicy: 'edit-policy',
});
