import cx from 'clsx';
import Button from 'react-bootstrap/Button';
import { useRouter } from 'next/router';
import { getCurrentYear } from '@/utils/helpers';

import { useStore } from '@/hooks';
import styles from './styles.module.scss';

export default function Footer() {
  const router = useRouter();
  const [{ broker }] = useStore();
  const { isBranded = false } = broker?.branding || {};

  const seeTerms = () => router.push('/about/terms-and-conditions');
  const seePrivacy = () => router.push('/about/privacy');

  return (
    <div className={cx(styles.footer, isBranded ? styles.insuredBranding : '')}>
      <div className={styles.footerLeft} />
      <div className={styles.footerMiddle}>
        <span
          className={cx(
            styles.copyLabel,
            isBranded ? styles.insuredBranding : ''
          )}
        >
          © {getCurrentYear()} Certificate Hero, Inc.
        </span>
      </div>
      <div
        className={cx(
          styles.footerRight,
          isBranded ? styles.insuredBranding : ''
        )}
      >
        <Button title="Terms" onClick={seeTerms}>
          Terms
        </Button>
        <Button title="Privacy" onClick={seePrivacy}>
          Privacy
        </Button>
      </div>
    </div>
  );
}
