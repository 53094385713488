import { useEffect, useMemo, useState } from 'react';
import Checkbox from '@/components/checkbox';
import { coverageLineStyles } from '@/enums/coverage-line-styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { coverageFlowStyles } from '@/enums/coverage-flow-styles';
import styles from './styles.module.scss';

/**
 * @param {object} params
 * @param {string} params.mode
 * @param {LobTemplateFieldMapping} params.mapping
 * @param {(params: {updatedMapping: LobTemplateFieldMapping, position: LobFieldMapping['formPosition']}) => void} params.updateFieldMapping
 * @param {boolean} params.isReadonly
 * @param {LobFieldMapping['formPosition']} params.position
 * @param {boolean} params.hasAmsCoverageData
 * @param {boolean} params.isAmsPolicyValidating
 */
const IndicatorField = ({
  mode,
  mapping,
  updateFieldMapping,
  isReadonly,
  position,
  hasAmsCoverageData,
  isAmsPolicyValidating,
}) => {
  const [fieldMapping, setFieldMapping] = useState(mapping);
  const [amsIndicatorMismatch, setAmsIndicatorMismatch] = useState(false);

  useEffect(() => {
    setFieldMapping(mapping);
  }, [mapping]);

  const shouldShowLabel =
    fieldMapping.lineStyle !== coverageLineStyles.NO_DESCRIPTORS;

  const amsValue = mapping.amsValue ? 'Checked' : 'Unchecked';

  const disableCertOnlyField =
    mapping.flowStyle === coverageFlowStyles.CERT_ONLY && isAmsPolicyValidating;

  const subText = useMemo(() => {
    if (mapping.flowStyle === coverageFlowStyles.CERT_ONLY) {
      return 'Certificate Only Field';
    }

    if (isAmsPolicyValidating || (mode && hasAmsCoverageData)) {
      return `AMS Value: ${isAmsPolicyValidating ? 'Loading' : amsValue}`;
    }

    return '';
  }, [
    amsValue,
    hasAmsCoverageData,
    mapping.flowStyle,
    mode,
    isAmsPolicyValidating,
  ]);

  /**
   * Indicator mapping value parser
   * @param {boolean | string} val
   */
  const isChecked = val => {
    return (typeof val === 'string' && val.toUpperCase() !== 'N') || !!val;
  };

  useEffect(() => {
    if (!mapping) {
      return;
    }

    const currentAmsValue = !!mapping.amsValue;

    const mappingValue = !!isChecked(mapping.value);

    if (
      hasAmsCoverageData &&
      currentAmsValue !== mappingValue &&
      mapping.flowStyle !== coverageFlowStyles.CERT_ONLY
    ) {
      setAmsIndicatorMismatch(true);
      return;
    }
    setAmsIndicatorMismatch(false);
  }, [hasAmsCoverageData, mapping]);

  return (
    <div
      className={styles.indicatorMapping}
      style={!shouldShowLabel ? { flex: 0 } : {}}
    >
      {amsIndicatorMismatch ? (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 200 }}
          overlay={<Tooltip>Different Value Found</Tooltip>}
        >
          <div className={styles.indicatorWarningIcon}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
        </OverlayTrigger>
      ) : null}
      <Checkbox
        data-cy={`${mapping.name}_checkbox`}
        label={shouldShowLabel ? fieldMapping.label : ''}
        subText={subText}
        disabled={
          (isReadonly && !fieldMapping.editable) || disableCertOnlyField
        }
        checked={isChecked(fieldMapping.value)}
        onChange={event => {
          const updatedMapping = {
            ...fieldMapping,
            value: event.target.checked,
          };
          setFieldMapping(updatedMapping);
          updateFieldMapping({ updatedMapping, position });
        }}
      />
    </div>
  );
};

export default IndicatorField;
