import BooleanRenderer from './BooleanRenderer';

/**
 * @param {object} props
 * @param {boolean} props.value
 */
const LobTemplateIssuesRenderer = props => {
  return <BooleanRenderer value={!props.value} />;
};

export default LobTemplateIssuesRenderer;
