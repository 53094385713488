export const certImportFileTypes = Object.freeze(
  /** @type {const} */ ([
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ])
);

export const contractFileTypes = Object.freeze(
  /** @type {const} */ ([
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
  ])
);

export const pdfFileType = Object.freeze(
  /** @type {const} */ (['application/pdf'])
);

export const commonFileTypes = Object.freeze(
  /** @type {const} */ ([
    ...contractFileTypes,
    ...certImportFileTypes,
    'text/plain',
    'image/jpeg',
    'image/png',
  ])
);

export const uploadTypes = Object.freeze(
  /** @type {const} */ ({
    sampleCert: 'sampleCert',
    contractClause: 'contractClause',
    endorsement: 'endorsement',
    importCSV: 'importCSV',
    importNamedInsured: 'importNamedInsured',
    signature: 'signature',
    ci_document: 'ci_document',
    inboundCertificate: 'inboundCertificate',
    naic: 'naic',
    branding_logo: 'branding_logo',
  })
);
