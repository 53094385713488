import { forwardRef } from 'react';
import cx from 'clsx';

import Button from '@/components/button/button';

import styles from '../styles.module.scss';

const ButtonCreate = forwardRef((props, ref) => {
  const { className, labelClassName, ...buttonProps } = props;

  return (
    <Button
      ref={ref}
      className={cx(styles.createButton, className)}
      labelClassName={cx(styles.createButtonLabel, labelClassName)}
      {...buttonProps}
      data-testid="create-button"
    />
  );
});

export default ButtonCreate;
