import globalStyles from '@/styles/globals.module.scss';

const DefaultCellRenderer = props => {
  const { valueFormatted, colDef } = props;

  if (colDef.field === 'action') {
    return null;
  }

  const value = typeof valueFormatted === 'object' ? '-' : valueFormatted;

  return <span className={globalStyles.truncate}>{value}</span>;
};

export default DefaultCellRenderer;
