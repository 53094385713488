import { useUnsavedChangesWarning } from '@/hooks';
import { useRouter } from 'next/router';
import Link from 'next/link';

/**
 * A link which shows warning modal if there's unsaved changes
 * @param {{ children: JSX.Element, href: string, className: string }} props
 * @returns JSX.Element
 */
export default function LinkWithUnsavedWarnings({ children, href, className }) {
  const router = useRouter();
  const {
    unsavedChangesExist = {},
    unsavedNotesExist,
    handleClick,
    renderWarningModal,
  } = useUnsavedChangesWarning({
    handleContinue() {
      router.push(href);
    },
  });

  return (
    <>
      {!href?.includes('#') &&
      (Object.keys(unsavedChangesExist).length || unsavedNotesExist) ? (
        <div
          role="button"
          tabIndex={0}
          className={className}
          onClick={handleClick}
        >
          {children}
        </div>
      ) : href?.includes('#') ? (
        <a href="#" className={className}>
          {children}
        </a>
      ) : (
        <Link href={href || '#'} className={className}>
          {children}
        </Link>
      )}
      {renderWarningModal()}
    </>
  );
}
