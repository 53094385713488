import PoliciesMapperDropdown from '@/components/renewals/policies-mapper-dropdown';

/**
 * @param {object} props
 * @param {PolicyEntity & {lobTemplates: [LobTemplateEntity]}} props.data
 * @param {() => void} props.setEditPolicyModalIsOpen
 * @param {LobTypeMapping[]} props.lobTypeMappings
 * @param {() => void} props.applyLobTypeMapping
 * @param {() => void} props.setAmsPolicy
 * @param {import('formik').FormikProps<PolicyEntity>} props.form
 */

const PoliciesMapperLinkRenderer = props => {
  const { data: policy, node, context } = props;

  const {
    setEditPolicyModalIsOpen,
    lobTypeMappings,
    applyLobTypeMapping,
    setAmsPolicy,
    form,
  } = context;

  if (!policy) {
    return '-';
  }

  const lobTemplate = policy.lobTemplate || {};

  return (
    <PoliciesMapperDropdown
      policy={policy}
      lobTemplate={lobTemplate}
      setEditPolicyModalIsOpen={setEditPolicyModalIsOpen}
      lobTypeMappings={lobTypeMappings}
      applyLobTypeMapping={applyLobTypeMapping}
      setAmsPolicy={setAmsPolicy}
      form={form}
      id={`policiesMapper-drop-${node.rowIndex}`}
    />
  );
};

export default PoliciesMapperLinkRenderer;
