import { useEffect } from 'react';

export const useFocusTrap = (firstInputRef, lastInputRef, visible) => {
  useEffect(() => {
    if (visible && firstInputRef?.current && firstInputRef.current.focus) {
      firstInputRef.current.focus();
    }

    const handleKeyDown = event => {
      if (event.key !== 'Tab' && event.keyCode !== 9) {
        return;
      }

      if (document.activeElement === lastInputRef.current) {
        event.preventDefault();
        if (firstInputRef?.current && firstInputRef.current.focus) {
          firstInputRef.current.focus();
        }
      }
    };

    if (typeof document === 'undefined') {
      return;
    }

    if (visible) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [visible, firstInputRef, lastInputRef]);
};
