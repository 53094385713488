import useSWR from 'swr';
import { useBulkJobId } from '@/hooks/use-bulk-job-id';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { route } from 'nextjs-routes';

const useRenewingLobs = () => {
  const bulkJobId = useBulkJobId();

  /**
   */
  const { data, mutate, isValidating } = useSWR(
    bulkJobId
      ? [route({ pathname: '/api/v2/lob_templates/renewing' }), bulkJobId]
      : null,
    async ([url, bulkId]) => {
      const response = await FrontendHttpClient.get(url, {
        bulkJobId: bulkId,
      });
      return response.data;
    }
  );

  return {
    data: data || [],
    mutate,
    isValidating,
  };
};

export default useRenewingLobs;
