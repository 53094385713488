import defaultGlobalState from './default-global-state';
import reducerList from './reducer-list';

// add the broker from the current state if it exists
const stateWithBroker = (current, next) =>
  current?.broker?.id && !next?.broker?.id
    ? {
        ...next,
        broker: { ...current.broker },
      }
    : next;

/**
 * @param {object} state
 * @param {object} savedState
 */
const setInitialState = (state, savedState) => {
  return stateWithBroker(state, savedState);
};

const setToDefaultState = state => {
  return stateWithBroker(state, defaultGlobalState);
};

/**
 * @param {object} state
 * @param {boolean} canChangeInsured
 */
const setCanChangeInsured = (state, canChangeInsured) => {
  return {
    ...state,
    canChangeInsured,
  };
};

const setBroker = (state, broker) => {
  // do not replace the broker with empty data
  return broker?.id
    ? {
        ...state,
        broker,
      }
    : state;
};

/**
 * @param {object} state
 * @param {object} user
 */
const setUser = (state, user) => {
  if (user.id !== state.user.id) {
    return {
      ...defaultGlobalState,
      insuredId: user.insuredId || '',
      insuredName: user.insuredName || '',
      canChangeInsured: !user.insuredId,
      user,
    };
  }

  return {
    ...state,
    user,
    insuredId: user.insuredId || '',
    insuredName: user.insuredName || '',
  };
};

/**
 * @param {state} state
 * @param {{[p: FormName[keyof FormName]]: boolean}} unsavedChangesExist - keyed by form name; if empty object, all forms will be cleared
 */
const setUnsavedChangesExist = (state, unsavedChangesExist) => {
  const newState = { ...state };

  const entries = Object.entries(unsavedChangesExist);
  if (entries.length === 0) {
    newState.unsavedChangesExist = {};
    return newState;
  }

  entries.forEach(([key, value]) => {
    // remove any old values
    delete newState.unsavedChangesExist[key];
    if (value) {
      newState.unsavedChangesExist[key] = value;
    }
  });

  return newState;
};

/**
 * @param {state} state
 * @param {boolean} unsavedNotesExist
 */
const setUnsavedNotesExist = (state, unsavedNotesExist) => {
  return {
    ...state,
    unsavedNotesExist,
  };
};

/**
 * @param {state} state
 * @param {string[]} bulkJobEditCertIds
 */
const setBulkJobEditCertIds = (state, bulkJobEditCertIds) => {
  return {
    ...state,
    bulkJobEditCertIds,
  };
};

const Reducers = (state, action) => {
  if (action.type === reducerList.SET_INITIAL_STATE) {
    return setInitialState(state, action.payload);
  }

  if (action.type === reducerList.SET_TO_DEFAULT_STATE) {
    return setToDefaultState(state);
  }

  if (action.type === reducerList.SET_CAN_CHANGE_INSURED) {
    return setCanChangeInsured(state, action.payload);
  }

  if (action.type === reducerList.SET_USER) {
    return setUser(state, action.payload);
  }

  if (action.type === reducerList.SET_BROKER) {
    return setBroker(state, action.payload);
  }

  if (action.type === reducerList.SET_UNSAVED_CHANGES_EXIST) {
    return setUnsavedChangesExist(state, action.payload);
  }

  if (action.type === reducerList.SET_UNSAVED_NOTES_EXIST) {
    return setUnsavedNotesExist(state, action.payload);
  }

  if (action.type === reducerList.SET_BULK_JOB_EDIT_CERT_IDS) {
    return setBulkJobEditCertIds(state, action.payload);
  }

  return state;
};

export default Reducers;
