import Dropdown from 'react-bootstrap/Dropdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faClose, faSync } from '@fortawesome/free-solid-svg-icons';
import { useNotifications } from '@/hooks';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import styles from './styles.module.scss';
import cx from 'clsx';

import { useRouter } from 'next/router';

const NotificationBell = () => {
  const { notifications, mutate } = useNotifications();

  const router = useRouter();

  const handleNotificationClick = async record => {
    if (!record) {
      return;
    }

    FrontendHttpClient.get(`/api/v2/notifications/${record.id}/read`).then(() =>
      mutate()
    );

    if (record.href) {
      router.push(record.href);
    }
  };

  const handleDeleteNoticationClick = async record => {
    if (!record) {
      return;
    }

    await FrontendHttpClient.delete(
      `/api/v2/notifications/${record.id}/delete`
    );

    mutate();
  };

  return (
    <>
      {notifications?.records?.length ? (
        <Dropdown>
          <Dropdown.Toggle
            as="div"
            // className="my-dropdown"
            className={styles.bellIcon}
            id="dropdown-basic"
          >
            <FontAwesomeIcon
              // className={styles.bellIcon}
              icon={faBell}
              color="gray"
              size="xl"
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {notifications?.records?.map(record => (
              <div
                className={cx(
                  styles.notificationItem,
                  (!record.read || record.isPendingAction) &&
                    styles.unreadNotification
                )}
                key={record.id}
              >
                <div>
                  <button
                    style={{ border: 'none', background: 'transparent' }}
                    onClick={() => handleNotificationClick(record)}
                  >
                    {record.message}
                  </button>
                </div>
                {!record?.isPendingAction && (
                  <FontAwesomeIcon
                    onClick={() => handleDeleteNoticationClick(record)}
                    icon={faClose}
                    color="gray"
                    size="lg"
                  />
                )}
              </div>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ) : null}
    </>
  );
};

export default NotificationBell;
