import { endorsementIssues } from '@/enums/endorsement-issues';

export class EndorsementIssueEntity {
  constructor({
    id = '',
    name = '',
    type = endorsementIssues.MISSING_POLICY,
    fieldId = '',
    fieldName = 'none',
  }) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.fieldId = fieldId;
    this.fieldName = fieldName;
    this.description = `${this.name} was removed - Missing ${this.fieldName}`;
  }

  toString() {
    return `${this.type}: ${this.description}`;
  }
}
