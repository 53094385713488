import useSWR from 'swr';

import { route } from 'nextjs-routes';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { useStore } from '@/hooks/use-store';

/** Grab all policies from the AMS
 */
export const useAmsPolicies = () => {
  const [{ insuredId }] = useStore();

  /**
   * @type {SWRResponse<PolicyEntity[]>}
   */
  const {
    data: amsPolicies,
    mutate,
    isValidating,
    isLoading,
  } = useSWR(
    [route({ pathname: '/api/v2/policies/ams' }), insuredId],
    async ([url, id]) => {
      const res = await FrontendHttpClient.get(url, { insuredId: id });
      return res.data.result;
    }
  );
  return { amsPolicies, mutate, isValidating, isLoading };
};
