import useSWR from 'swr';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { route } from 'nextjs-routes';

export function useOutBoundMessages(certId) {
  const { data } = useSWR(
    certId ? [route({ pathname: '/api/v2/outbound_message' }), certId] : null,
    async ([url, id]) => {
      const res = await FrontendHttpClient.get(url, {
        certId: id,
      });

      return res.data;
    }
  );
  return {
    outboundMessages: data || [],
    emails: data?.filter(x => x.method === 'email'),
    faxes: data?.filter(x => x.method === 'fax'),
  };
}
