export const validObjectNames = Object.freeze(
  /** @type {const} */ ({
    BROKERS: 'brokers',
    BULK_JOB_CERTIFICATES: 'bulk_job_certificates',
    BULK_JOBS: 'bulk_jobs',
    CERTIFICATE_IMPORTS: 'certificate_imports',
    CHANGE_HISTORY: 'change_history',
    CI_DOCUMENTS: 'ci_documents',
    COUNTERS: 'counters',
    CUSTOM_LOOKAHEAD_NAMES: 'custom_lookahead_names',
    EMAIL_TEMPLATES: 'email_templates',
    EMAIL_TEMPLATE_LIBRARY: 'email_template_library',
    ENDORSEMENT_FORMS: 'endorsement_forms',
    ENDORSEMENTS: 'endorsements',
    GROUPS: 'groups',
    HOLDER_ADDRESSES: 'holder_addresses',
    HOLDERS: 'holders',
    INSUREDS: 'insureds',
    INSURERS: 'insurers',
    LANGUAGE_LIBRARY: 'language_library',
    LOB_TEMPLATES: 'lob_templates',
    NAMED_INSUREDS: 'named_insureds',
    NOTES: 'notes',
    INBOUND_CERTIFICATES: 'inbound_certificates',
    OUTBOUND_CERTIFICATES: 'outbound_certificates',
    OUTBOUND_CERTIFICATE_REQUESTS: 'outbound_certificate_requests',
    IMPORTED_OUTBOUND_CERTIFICATES: 'imported_outbound_certificates',
    POLICIES: 'policies',
    RENEWAL: 'renewals',
    SAMPLE_CERTIFICATES: 'sample_certificates',
    SIGNATURES: 'signatures',
    CERTIFICATE_TEMPLATES: 'certificate_templates',
    USERS: 'users',
    AGENCY: 'agency',
    LEADS: 'leads',
    INGESTED_INSUREDS: 'ingestedinsureds',
    NOTIIFICATIONS: 'notifications',
  })
);
