import { lobTypeCodes } from '@certificate_hero/enums';

export const LOB_TYPES_ALLOWED_PROPERTY_DROPDOWN = [
  lobTypeCodes.PROPERTY_POLICY,
  lobTypeCodes.BUILDERS_RISK,
  lobTypeCodes.COMMERCIAL_CONDO,
  lobTypeCodes.COMMERCIAL_FIRE,
  lobTypeCodes.COMMERCIAL_FLOOD,
  lobTypeCodes.COMMERCIAL_WIND,
  lobTypeCodes.ELECTRONICS_DATA_PROCESSING,
  lobTypeCodes.EXCESS_FLOOD,
];

export const LOB_TYPES_ALLOWED_SCHEDULES = [
  lobTypeCodes.AUTO_LIABILITY,
  lobTypeCodes.EQUIPMENT_FLOATERS,
  lobTypeCodes.FINE_ARTS,
  lobTypeCodes.INLAND_MARINE,
  lobTypeCodes.INSTALLATION_FLOATERS,
  ...LOB_TYPES_ALLOWED_PROPERTY_DROPDOWN,
];
