import useSWR from 'swr';

/** Get all endorsement forms
 *
 */
export const useEndorsementForms = () => {
  /**
   * @type {SWRResponse<EndorsementFormEntity[]>}
   */
  const data = useSWR(`/api/v2/endorsement_forms`);

  return data;
};
