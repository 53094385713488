export class UserEntity {
  constructor({
    email = '',
    firstName = '',
    lastName = '',
    userType = '',
    brokerId = '',
    amsId = '',
    lastLogin = new Date(),
    insuredId = '',
    insuredName = '',
    clientCode = '',
    certificateFlow = '',
    allowedInsuredIds = [],
    groupId = '',
    divisionNumbers = [],
    status = '',
    isQuickIssueContact = false,
  }) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.userType = userType;
    this.brokerId = brokerId;
    this.amsId = amsId;
    this.lastLogin = lastLogin;
    this.insuredId = insuredId;
    this.insuredName = insuredName;
    this.clientCode = clientCode;
    this.certificateFlow = certificateFlow;
    this.allowedInsuredIds = allowedInsuredIds;
    this.groupId = groupId;
    this.divisionNumbers = divisionNumbers;
    this.status = status;
    this.isQuickIssueContact = isQuickIssueContact;
  }
}
