import { useCallback, useEffect, useState } from 'react';

/**
 * Creates a loading state for a modifiable period of time
 * @param {number} [loadTime=500]
 */
export const useFakeLoader = (loadTime = 500) => {
  const [loading, setLoading] = useState(false);

  const toggleLoader = useCallback(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    let timeout;
    if (loading) {
      timeout = setTimeout(() => setLoading(false), loadTime);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [loadTime, loading]);

  return {
    loading,
    toggleLoader,
  };
};
