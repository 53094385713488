import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import qs from 'qs';

// ! graceful way of refreshing token with axios interceptors
const refreshAccessToken = async () => {
  await axios.post('/api/refresh-access-token', {}, { skipAuthRefresh: true });
  return Promise.resolve();
};

createAuthRefreshInterceptor(axios, refreshAccessToken, {
  statusCodes: [419],
  retryInstance: axios,
});

const BaseHttpClient = {
  /**
   * @param {AllApiRoutes} url
   * @param {object} queryParams
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  get: async (url, queryParams) => {
    return axios.get(url, {
      params: queryParams,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },

  /**
   * @param {AllApiRoutes} url
   * @param {object} [body]
   * @param {import('axios').AxiosRequestConfig} [opts]
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  post: async (url, body, opts) => {
    return axios.post(url, body, opts);
  },
  /**
   * @param {AllApiRoutes} url
   * @param {object} [body]
   * @param {import('axios').AxiosRequestConfig} [opts]
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  put: async (url, body, opts) => {
    return axios.put(url, body, opts);
  },
  /**
   * @param {AllApiRoutes} url
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  delete: async url => {
    return axios.delete(url);
  },
};

export default BaseHttpClient;
