import { lobTypeCodes } from '@/enums/lob-type-codes';

export const STATUS = {
  draft: 'Draft',
  inProgress: 'In Progress',
  active: 'Active',
  inactive: 'Inactive',
  overridden: 'Overridden',
  deleted: 'Deleted',
  completed: 'Completed',
  expiring: 'Expiring',
};

export const USER_STATUS = {
  ACTIVE: 'Active', // user has access to the system
  INACTIVE: 'Inactive', // user has been declared inactive
  INVITED: 'Invited', // user has been invited but has not logged in yet
};

export const USER_TYPE = {
  INSURED: 'Insured',
  BROKER: 'Broker',
};
export const CERT_STATUS = {
  active: 'Active',
  inactive: 'Inactive',
};

export const ACTIVE_STATUS = {
  active: 'Active',
  inactive: 'Inactive',
};

export const BULK_STATUS = {
  issuing: 'Issuing',
  active: 'Active',
  completed: 'Completed',
};

export const BULK_JOB_STATUS = {
  inProgress: 'In Progress',
  deleted: 'Deleted',
  failed: 'Failed',
};

export const HOLDER_STATUS = {
  ...ACTIVE_STATUS,
};

export const LANGUAGE_LIBRARY_STATUS = {
  ...ACTIVE_STATUS,
};
export const EMAIL_TEMPLATE_LIBRARY_STATUS = {
  ...ACTIVE_STATUS,
};

export const IMPORT_STATUS = {
  processing: 'Processing',
  completed: 'Completed',
  importFailure: 'Failed',
};

export const CONTRACT_STATUS = {
  active: 'Active',
  deleted: 'Deleted',
};

export const NOTIFICATION_STATUS = {
  ...ACTIVE_STATUS,
};

export const NAMED_INSURED_STATUS = {
  ...ACTIVE_STATUS,
};
export const AGENCY_CONTACT_STATUS = {
  ...ACTIVE_STATUS,
};

export const ENDORSEMENT_STATUS = {
  ...ACTIVE_STATUS,
};

export const SIGNATURE_STATUS = {
  ...ACTIVE_STATUS,
};

export const CERTIFICATE_STATUS = {
  ...ACTIVE_STATUS,
};

export const INBOUND_CERTIFICATE_STATUS = {
  ...ACTIVE_STATUS,
  expiring: 'Expiring',
};

export const LEAD_STATUS = {
  lead: 'Lead',
  assigned: 'Assigned',
  inactive: 'Inactive',
  duplicate: 'Duplicate',
  appointment: 'Appointment',
  win: 'Win',
};

export const LEAD_PRIORITY = {
  low: 'Low',
  medium: 'Medium',
  high: 'High',
};

export const BULK_OPERATION = {
  revise: 'revise',
  renew: 'renew',
  issue: 'issue',
  clone: 'clone',
  activate: 'activate',
  deactivate: 'deactivate',
};

export const LOGIN_STATES = {
  INITIAL: 'INITIAL',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  LOGGED_IN: 'LOGGED_IN',
  ERROR: 'ERROR',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_ENTER_EMAIL: 'RESET_PASSWORD_ENTER_EMAIL',
  SIGNUP: 'SIGNUP',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  SMS_2FA: 'SMS_2FA',
  USER_NOT_ENABLED: 'USER_NOT_ENABLED',
  NO_USER_PERMISSIONS: 'NO_USER_PERMISSIONS',
  HAS_NOT_READ_TERMS: 'HAS_NOT_READ_TERMS',
  DOES_NOT_EXIST: 'DOES_NOT_EXIST',
  ENTER_NAME: 'ENTER_NAME',
};

export const TAG_STATES = {
  INITIAL: 'INITIAL',
  EDITING: 'EDITING',
  SAVED: 'SAVED',
};

export const TAG_IDENTIFIERS = {
  POLICY_NUM: 'policyNum',
  POLICY_EFFECTIVE_DATE: 'policyEffectiveDate',
  POLICY_EXPIRATION_DATE: 'policyExpirationDate',
  NAMED_INSURED_NAME: 'namedInsuredName',
};

export const INITIAL_TAG_STATES = Object.freeze({
  policyNum: {
    state: TAG_STATES.INITIAL,
    identifier: TAG_IDENTIFIERS.POLICY_NUM,
    name: 'Policy Number',
  },
  policyEffectiveDate: {
    state: TAG_STATES.INITIAL,
    identifier: TAG_IDENTIFIERS.POLICY_EFFECTIVE_DATE,
    name: 'Policy Effective Date',
  },
  policyExpirationDate: {
    state: TAG_STATES.INITIAL,
    identifier: TAG_IDENTIFIERS.POLICY_EXPIRATION_DATE,
    name: 'Policy Expiration Date',
  },
  namedInsuredName: {
    state: TAG_STATES.INITIAL,
    identifier: TAG_IDENTIFIERS.NAMED_INSURED_NAME,
    name: 'Named Insured',
  },
});

export const COOKIE_NAMES = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  CSRF_TOKEN: 'csrf_token',
};

export const CERTIFICATE_COUNTERS = {
  OUTBOUND: 'outboundCertificateNumber',
  BULK_JOB: 'bulkJobNumber',
};

export const BULK_JOB_COUNTER = 'bulkJobNumber';

export const CURRENT_EULA_PATH = '/Certificate Hero EULA 04-13-21.pdf';
export const CURRENT_PRIVACY_PATH =
  '/CertificateHero Privacy Policy Jan 1 2021 FINAL-2.pdf';

export const TOKEN_VERSION = 8;

export const NULL_QUERY_PARAM = 'NULL';

// PDF Utilities
export const OF_POLICY_FORM_CODE = 'Acord 101-CH';
export const OF_DESC_OPS_FORM_CODE = 'Acord 101';
export const FREE_FORM_CODE = 'Acord 101 Freeform';

// Contract Constants
export const OCCURRENCE_ACCUMULATOR = 1;
export const AGGREGATE_ACCUMULATOR = 2;
export const SOCKET_RETRIES = 5;
export const COLLECT_RESULTS_AFTER_EVERY = 15;

export const COVERAGE_NAME_DELIMITER = '__';

export const HOMEPAGE_RECORD_LIMIT = 50;

export const DYNAMIC_TAB_NAMES = {
  namedInsured: 'Named Insured',
  holder: 'Holder',
  descOps: 'Desc of Ops',
  signature: 'Signature',
  contact: 'Contact',
};

export const LOB_TAB_MODAL_NAMES = {
  /** Acord-25 */
  GL: 'General Liability',
  AUTO: 'Automotive',
  UMB: 'Umbrella',
  WC: 'Workers Comp',
  /** Acord-24 */
  PROP: 'Property',
  CRIME: 'Crime',
  BR: 'Builders Risk',
  BM: 'Boiler & Machinery / Equipment Breakdown',
  EB: 'Boiler & Machinery / Equipment Breakdown',
  FA: 'Fine Arts',
  EQ: 'Equipment Floaters',
  IF: 'Installation Floaters',
  AIR: 'Aircraft',
  CARGO: 'Cargo',
  TIPD: 'Trailer',
  FGL: 'Foreign General Liability',
  AC: 'Accounts Receivable',
  COMART: 'Commercial Articles',
  COMCONDO: 'Commercial Condo',
  COMFIRE: 'Commercial Fire',
  COMFLOOD: 'Commercial Flood',
  COMWIND: 'Commercial Wind',
  EDP: 'Electronic Data Processing',
  EXFLOOD: 'Excess Flood',
  FID: 'Fiduciary',
  GS: 'Glass and Sign',
  IM: 'Inland Marine',
  VPR: 'Valuable Papers and Records',
  HULL: 'Hull and Machinery',
  PI: 'Protection and Indemnity',
  POLL: 'Pollution',
  MEMP: 'Maritime Employers Liability',
  USLH: 'U.S. Longshore and Harbor Workers',
  ENGY: 'Energy',
};

export const LOB_TAB_NAV_FROM_BUTTON = {
  /** Acord-25 */
  Add_GL_Button: lobTypeCodes.GENERAL_LIABILITY,
  Add_AUTO_Button: lobTypeCodes.AUTO_LIABILITY,
  Add_UMB_Button: lobTypeCodes.UMBRELLA_AND_EXCESS_LIABILITY,
  Add_WC_Button: lobTypeCodes.WORKERS_COMP,
  /** Acord-24 */
  Add_PropertyPolicy_Button: lobTypeCodes.PROPERTY_POLICY,
  Add_InlandMarinePolicy_Button: lobTypeCodes.INLAND_MARINE,
  Add_CrimePolicy_Button: lobTypeCodes.CRIME,
  Add_BoilerAndMachineryEquipmentBreakdownPolicy_Button:
    lobTypeCodes.BOILER_MACHINERY,
  /** Acord-22 */
  Add_CARGO_Button: lobTypeCodes.CARGO,
  Add_TIPD_Button: lobTypeCodes.TRAILER_INTERCHANGE_PHYSICAL_DAMAGE,
  /** Acord-31 */
  Add_HULL_Button: lobTypeCodes.HULL_MACHINERY,
  Add_POLL_Button: lobTypeCodes.POLLUTION,
  Add_MEMP_Button: lobTypeCodes.MARITIME_EMPLOYERS,
  Add_USLH_Button: lobTypeCodes.US_LONGSHORE_HARBOR,
  Add_AIR_Button: lobTypeCodes.AIRCRAFT,
  Add_ENGY_Button: lobTypeCodes.ENERGY,
  /** Acord-23 */
  Add_Equipment_Button: lobTypeCodes.EQUIPMENT_FLOATERS,
  Add_Vehicle_Button: lobTypeCodes.AUTO_LIABILITY,
};

export const NOT_APPLICABLE = 'N/A';

export const SIGNATURE_IMAGE_SIZE = Object.freeze({
  width: 1600,
  height: 500,
});

export const VALID_SPREADSHEET_FILE_TYPES = Object.freeze({
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv',
});

export const VALID_CID_FILE_TYPES = Object.freeze({
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pdf: 'application/pdf',
});

export const LANGUAGE_LIBRARY_LIMITS = {
  NAME_LIMIT: 99,
  CONTENT_LIMIT: 99999,
};

export const CERTIFICATE_FILE_PATHS = {
  PREVIEW_IMAGE: 'preview',
  PDF: 'persisted-certs',
};

export const SIGNATURES_LIMITS = {
  NAME_LIMIT: 100,
};

export const NAMED_INSURED_NAME_LIMIT = {
  MINOR: 40,
  MAJOR: 200,
  MEDIUM: 60,
};

export const HOLDER_LIMITS = {
  NAME: 40,
};

export const HOLDER_COUNTRY = [
  'USA',
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua & Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Ascension Island',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia & Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Canary Islands',
  'Cape Verde',
  'Caribbean Netherlands',
  'Cayman Islands',
  'Central African Republic',
  'Ceuta & Melilla',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Clipperton Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo - Brazzaville',
  'Congo - Kinshasa',
  'Cook Islands',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  'Côte d’Ivoire',
  'Denmark',
  'Diego Garcia',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'European Union',
  'Eurozone',
  'Falkland Islands (Islas Malvinas)',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard & McDonald Islands',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar (Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'North Korea',
  'North Macedonia',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Outlying Oceania',
  'Pakistan',
  'Palau',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn Islands',
  'Poland',
  'Portugal',
  'Pseudo-Accents',
  'Pseudo-Bidi',
  'Puerto Rico',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Réunion',
  'Samoa',
  'San Marino',
  'Sark',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia & South Sandwich Islands',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'St. Barthélemy',
  'St. Helena',
  'St. Kitts & Nevis',
  'St. Lucia',
  'St. Martin',
  'St. Pierre & Miquelon',
  'St. Vincent & Grenadines',
  'Sudan',
  'Suriname',
  'Svalbard & Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syria',
  'São Tomé & Príncipe',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad & Tobago',
  'Tristan da Cunha',
  'Tunisia',
  'Turkmenistan',
  'Turks & Caicos Islands',
  'Tuvalu',
  'Türkiye',
  'U.S. Outlying Islands',
  'U.S. Virgin Islands',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United Nations',
  'Unknown Region',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Wallis & Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];
export const FILE_UPLOAD_LIMIT = 50 * 1024 * 1024;

export const PDF_IMPORT_LIMIT = 10; // MB

export const DESCRIPTION_OF_OPERATIONS_LIMIT = 99999;

export const AGENCY_CONTACT_LIMIT = {
  NAME_LIMIT: 40,
  PHONE_LIMIT: 15,
  FAX_LIMIT: 15,
  EMAIL_LIMIT: 320,
};

export const POLICY_CHAR_LIMITS = {
  POLICY_NUMBER_LIMIT: 50,
  POLICY_NAME_LIMIT: 100,
  EFFECTIVE_DATE_LIMIT: 10,
  EXPIRATION_DATE_LIMIT: 10,
  INSURER_NAME_LIMIT: 40,
  DESCRIPTION_LIMIT: 100,
  NAIC_NUMBER_LIMIT: 5,
};

export const EMAIL_CHAR_LIMITS = Object.freeze({
  REPLY_TO_LIMIT: 320,
  TO_LIMIT: 4000,
  CC_LIMIT: 4000,
  BCC_LIMIT: 4000,
  SUBJECT_LIMIT: 150,
  BODY_LIMIT: 50000,
});

export const FAX_CHAR_LIMITS = Object.freeze({
  NUMBER_LIMIT: 100,
  NAME_LIMIT: 150,
  BODY_LIMIT: EMAIL_CHAR_LIMITS.BODY_LIMIT,
  SUBJECT_LIMIT: EMAIL_CHAR_LIMITS.SUBJECT_LIMIT,
});

export const VALID_FILE_UPLOAD_EXTENSIONS = Object.freeze({
  CID_MANAGER: ['.docx', '.xlsx', '.pdf'],
  SIGNATURE: ['.png', '.jpg', '.jpeg'],
  BRANDING_LOGO: ['.png', '.jpg', '.jpeg'],
  EDIT_UPDATE_CONTRACT: ['.pdf', '.docx'],
  ENDORSEMENTS: ['.pdf'],
  SAMPLE_CERTIFICATE: ['.pdf'],
  IMPORT_DRAWER: ['.csv', '.xlsx'],
  INBOUND_IMPORT: ['.png'],
  INBOUND_CERTIFICATE_IMPORT: ['.pdf'],
  NAMED_INSURED_UPLOAD: ['.csv'],
  REVIEW_SCREEN_UPLOAD: ['.jpg', '.jpeg', '.txt', '.docx', '.csv', '.pdf'],
});

export const KRYSTAL_MAGIC_NUMBER = 50;
export const DROPDOWN_OPTION_TEXT_ELLIPSIS_CUTOFF_LIMIT = KRYSTAL_MAGIC_NUMBER;
export const ACCORD_FORM_DROPDOWN_OPTION_TEXT_ELLIPSIS_CUTOFF_LIMIT = 100;
export const INSURED_NAME_TYPEAHEAD_LIMIT = 60;
export const HOLDER_NAME_TYPEAHEAD_LIMIT = 100;

export const DYNAMIC_COVERAGE_FORM_LIMIT = 13;

export const EMAIL_RECEIVER_USER_ID = 'a23bae02-6b09-4385-8f24-86efa8e10faf';

export const PROXY_MAP = {
  sentry:
    'https://o779597.ingest.sentry.io/api/5798079/envelope/?sentry_key=e6acc55643cf4120a34b8639459dce57&sentry_version=7&sentry_client=sentry.javascript.nextjs%2F7.30.0',
  local_health: 'http://localhost:3000/api/health',
};
export const LANGUAGE_LIBRARY_TYPE = {
  email: 'Email Library',
  descOps: 'Description Of Operations',
};

export const JOBS = Object.freeze({
  DEACTIVATE_EXPIRED_CERTS: 'deactivate expired certs',
  CHECK_IMPORT_HEALTH: 'check import health',
  LOBS_AMS_AUDIT: 'audit lobs to see ams limits are honoured',
  AMS_SYNC_INSUREDS: 'sync ams insureds',
  AMS_SYNC_INSURERS: 'sync ams insurers',
  AMS_SYNC_POLICIES: 'sync ams policies',
});

// @todo put this in the shared constants
export const ACTIVITY_ACTION = Object.freeze({
  ISSUE: 'Issued',
  FAX: 'Faxed',
  EMAIL: 'Emailed',
  DOWNLOAD: 'Downloaded',
  ASSIGN: 'Assigned',
  SAVE: 'Saved in Progress',
  RENEW: 'Renewed',
  REVISE: 'Revised',
  IMPORT: 'Imported',
  UPDATE: 'Updated',
});

export const FORM_TEMPLATE_VALUE_DELIM = '___';
export const LOBS_AMS_AUDIT_BATCH_SIZE = 100;
export const HOLDER_SEARCH_RESULT_LIMIT = 100;

export const DB_CONSTRAINT_ERROR_CODE = 11000;

export const LOCATION_PREFIX_REGEX = /Loc# 0*(\d+)\/Bldg# 0*(\d+)/;
export const LOCATION_COVERAGE_REGEX = /\([^)]*\)/g;
