import { STATUS } from '@/constants';
import * as vars from '@/styles/vars.module.scss';
import styles from './styles.module.scss';

/**
 * @param {object} props
 * @param {string|undefined} props.value
 * @param {string} props.valueFormatted
 */
const StatusRenderer = ({ value, valueFormatted }) => {
  if (value === undefined) {
    return <div>{valueFormatted}</div>;
  }

  const getColor = status => {
    switch (status) {
      case STATUS.inProgress:
        return vars['color-status-progress'];
      case STATUS.overridden:
        return vars['color-status-overridden'];
      case STATUS.active:
        return vars['color-status-active'];
      case STATUS.expiring:
        return vars['color-status-expiring'];
      default:
        return vars['color-status-default'];
    }
  };

  return (
    <div
      data-cy="cell-renderer"
      className={styles.statusContainer}
      style={{ borderColor: getColor(valueFormatted) }}
    >
      {valueFormatted}
    </div>
  );
};

export default StatusRenderer;
