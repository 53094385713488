import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { useEffect, useState } from 'react';
import { useStore } from './use-store';

export const useAssignableUsers = () => {
  const [assignableUsers, setAssignableUsers] = useState([]);
  const [{ user }] = useStore();

  useEffect(() => {
    const getAssignableUsers = async () => {
      const res = await FrontendHttpClient.get('/api/v2/users/assignable');

      setAssignableUsers(res?.data?.records || []);
    };

    getAssignableUsers();
  }, [user.brokerId]);

  return assignableUsers;
};
