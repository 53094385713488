import { ACTIVE_STATUS } from '@/constants';
import accessKeyTemplate from '@/enums/access-key-template';
import { combineKeys } from '@/utils/groups';

export class GroupEntity {
  constructor({
    id,
    name = '',
    type = '',
    editable = true,
    accessKeys = {},
    createdAt = new Date(),
    updatedAt = new Date(),
    status = ACTIVE_STATUS.active,
    deleted = false,
  }) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.editable = editable;
    this.accessKeys = combineKeys(accessKeyTemplate, accessKeys);
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.status = status;
    this.deleted = deleted;
  }
}
