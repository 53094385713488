import React, { useEffect, useMemo, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {
  faFileDownload,
  faPencilRuler,
} from '@fortawesome/free-solid-svg-icons';
import cx from 'clsx';
import SidebarLink from '@/components/sidebar-link';
import HomeSvg from '@/assets/icons/sidebar/home.svg';
import CertificatesSvg from '@/assets/icons/sidebar/certificates.svg';
import TemplatesSvg from '@/assets/icons/sidebar/templates.svg';
import LibrarySvg from '@/assets/icons/sidebar/library.svg';
import PolicySvg from '@/assets/icons/policies.svg';
import AdminSvg from '@/assets/icons/admin.svg';

import globalStyles from '@/styles/globals.module.scss';
import { useBroker, useStore } from '@/hooks';
import { userTypes } from '@/enums/user-types';
import { isInternalUser } from '@/utils/helpers';
import { LogoImg } from '@/components/branding/logo-img';
import styles from './sidebar.module.scss';
import { CLIENT_SECRETS } from '@/config/client_secrets';
import LinkWithUnsavedWarnings from '../link-with-unsaved-warnings';

/**
 * @param {object} param
 * @param {boolean} [param.isSidebarCollapsed]
 * @param {React.Dispatch<React.SetStateAction<boolean>>} [param.setIsSidebarCollapsed]
 */
const Sidebar = ({ isSidebarCollapsed, setIsSidebarCollapsed }) => {
  const [{ user }] = useStore();
  const { broker } = useBroker();

  const sideTabsList = useMemo(() => {
    // So that the order of tabs remain intact
    const sideTabsList = [
      {
        href: '/home/recent',
        renderIcon: () => <HomeSvg className={styles.icon} />,
        title: 'Home',
        expanded: false,
        children: [],
      },
      {
        href: '#',
        renderIcon: () => <CertificatesSvg className={styles.icon} />,
        title: 'Certificates',
        accessKey: 'CERTIFICATES',
        expanded: false,
        subIcon: true,
        children: [
          {
            filtersName: 'Manager',
            href: '/certificates/manager',
            accessKey: 'CERTIFICATES.ACCESS',
          },
          {
            filtersName: 'Import',
            href: '/certificates/import',
            accessKey: 'CERTIFICATES.IMPORT_AND_EXPORT',
          },
          {
            filtersName: 'Export',
            href: '/certificates/export',
            accessKey: 'CERTIFICATES.IMPORT_AND_EXPORT',
          },
          {
            filtersName: 'Bulk Jobs',
            href: '/certificates/bulk-jobs',
            accessKey: 'BULK_JOBS.ACCESS',
          },
          {
            filtersName: 'Requests',
            href: '/certificates/requests',
            accessKey: 'AUTOMATED_QUICK_ISSUE.ACCESS',
          },
        ].filter(Boolean),
      },
      {
        href: '/certificate-templates',
        renderIcon: () => (
          <TemplatesSvg className={cx(styles.icon, styles.templates)} />
        ),
        title: 'COI Templates',
        accessKey: 'CERTIFICATE_TEMPLATES.ACCESS',
        expanded: false,
        children: [],
      },
      {
        href: '/policy-manager/policy-manager-layout',
        renderIcon: () => (
          <PolicySvg className={cx(styles.icon, styles.language)} />
        ),
        title: 'Policies',
        accessKey: 'POLICIES',
        expanded: false,
        children: [],
      },
      {
        href: '#',
        renderIcon: () => (
          <LibrarySvg className={cx(styles.icon, styles.language)} />
        ),
        title: 'Managers',
        expanded: false,
        subIcon: true,
        children: [
          {
            filtersName: 'LOB Profiles',
            href: '/lob-templates/lob-templates-layout',
            accessKey: 'LOB_TEMPLATES',
          },
          {
            filtersName: 'Named Insureds',
            href: '/named-insured/grid',
            accessKey: 'NAMED_INSURED_MANAGEMENT',
          },
          {
            filtersName: 'Endorsements',
            href: '/endorsements',
            accessKey: 'ENDORSEMENTS',
          },
          {
            filtersName: 'Holders',
            href: '/holder-manager/holder-manager-layout',
            accessKey: 'HOLDER_MANAGEMENT',
          },
          {
            filtersName: 'Desc of Ops',
            href: '/desc-of-ops/templates',
            accessKey: 'LANGUAGE_LIBRARY',
          },
          {
            filtersName: 'Documents',
            href: '/cid-manager',
            accessKey: 'CI_DOCUMENTS',
          },
          {
            filtersName: 'Agency Contacts',
            href: '/agency-contacts/grid',
            accessKey: 'AGENCY_CONTACTS',
          },
          {
            filtersName: 'Agency Signatures',
            href: '/signature-manager',
            accessKey: 'SIGNATURES',
          },
        ],
      },
      {
        href: '#',
        renderIcon: () => <AdminSvg className={styles.icon} />,
        title: 'Admin',
        expanded: false,
        subIcon: true,
        children: [
          {
            filtersName: 'Users',
            href: '/admin/users',
            accessKey: 'USER_MANAGEMENT',
          },
          {
            filtersName: 'Groups',
            href: '/admin/groups',
            accessKey: 'GROUP_MANAGEMENT',
          },
          {
            filtersName: 'Insureds',
            href: '/admin/insureds',
            accessKey: 'AUTOMATED_QUICK_ISSUE.CONFIGURE',
          },
          {
            filtersName: 'Analytics',
            href: '/admin/analytics',
            accessKey: 'ANALYTICS',
          },
          {
            filtersName: 'Email Config',
            href: '/email/config',
            accessKey: 'EMAIL_CONFIGURATION',
          },
          {
            filtersName: 'Leads',
            href: '/admin/leads',
            accessKey: 'LEADS.ACCESS',
          },
          {
            filtersName: 'Data',
            href: '/admin/data',
          },
        ],
      },
      {
        href: '/inbound/inbound-certificate-layout',
        renderIcon: () => faFileDownload,
        title: 'Inbound Certificates',
        accessKey: 'INBOUND_CERTIFICATES',
        expanded: false,
        children: [],
      },
      {
        href: '/branding',
        renderIcon: () => faPencilRuler,
        title: 'Branding',
        expanded: false,
        children: [],
      },
    ];

    return sideTabsList;
  }, []);

  /**
   * Per Kristin, the homepage will be the inbound cert page if the user does not have certificate access
   * @type {string}
   */
  const [homepage, setHomepage] = useState('/home/recent');

  const [linkData, setLinkData] = useState(sideTabsList);

  useEffect(() => {
    const link = user.accessKeys.CERTIFICATES.ACCESS
      ? '/home/recent'
      : '/inbound/inbound-certificate-layout';

    setHomepage(link);

    setLinkData(prev => {
      const [homeTab] = sideTabsList.filter(item => item.href === link);
      const restOfTabs = sideTabsList
        .filter(item => item.href !== link)
        .map(item => ({ ...item, children: [...item.children] }));
      let next = [homeTab, ...restOfTabs];

      if (!isInternalUser(user)) {
        next = next.filter(item => item.title !== 'Branding');

        next = next.map(item => {
          if (item.title === 'Admin') {
            return {
              ...item,
              children: item.children.filter(sub => sub.filtersName !== 'Data'),
            };
          }
          return item;
        });
      }

      // If you are an insured user, filter out the admin tab, and bulk jobs.
      if (user.userType === userTypes.INSURED) {
        next = next.filter(item => item.title !== 'Admin');

        const cert = next.find(item => item.title === 'Certificates');
        cert.children = cert.children.filter(
          sub => !['Bulk Jobs'].includes(sub.filtersName)
        );
      }

      prev
        .filter(item => item.expanded)
        .forEach(item => {
          const tab = next.find(i => i.title === item.title);
          if (tab) {
            tab.expanded = item.expanded;
          }
        });

      return next;
    });
  }, [user, sideTabsList]);

  const hasAccess = accessKey => {
    if (typeof accessKey === 'function') {
      return accessKey();
    }
    const [key, permission] = accessKey.split('.');
    return user.accessKeys[key]?.[permission || 'ACCESS'];
  };

  const darkLogo =
    CLIENT_SECRETS.TOP_BRANDING_URL && broker?.branding?.isBranded;

  return (
    <>
      <Navbar.Brand className={styles.logoContainer}>
        <LinkWithUnsavedWarnings href={homepage}>
          {isSidebarCollapsed ? (
            <LogoImg
              className={cx(styles.logoSM)}
              src={'/logo-sm.png'}
              srcSet={'/logo-sm.png'}
              alt="Certificate Hero"
            />
          ) : (
            <LogoImg
              className={cx(globalStyles.image, styles.logoMD)}
              src={darkLogo ? '/logo-dark-1x.png' : '/logo-1x.png'}
              srcSet={darkLogo ? '/logo-dark-2x.png 2x' : '/logo-2x.png 2x'}
              alt="Certificate Hero"
            />
          )}
        </LinkWithUnsavedWarnings>
      </Navbar.Brand>

      <div
        className={cx(
          styles.sidebarContainer,
          isSidebarCollapsed && styles.collapsedSidebarContainer
        )}
      >
        <ButtonGroup className={styles.buttonGroup} vertical>
          {linkData.map((link, index) => {
            if (link.href === '/home/recent' && link.href !== homepage) {
              return null;
            }
            if (link?.accessKey) {
              return (
                hasAccess(link.accessKey) && (
                  <SidebarLink
                    link={link}
                    key={`sidebar-${link.title}`}
                    linkData={linkData}
                    setLinkData={setLinkData}
                    index={index}
                    checkAccessKey={key => hasAccess(key)}
                    isSidebarCollapsed={isSidebarCollapsed}
                    setIsSidebarCollapsed={setIsSidebarCollapsed}
                  />
                )
              );
            }

            return (
              <SidebarLink
                link={link}
                key={`sidebar-${link.title}`}
                linkData={linkData}
                setLinkData={setLinkData}
                index={index}
                checkAccessKey={hasAccess}
                isSidebarCollapsed={isSidebarCollapsed}
                setIsSidebarCollapsed={setIsSidebarCollapsed}
              />
            );
          })}
        </ButtonGroup>
      </div>
    </>
  );
};

export default Sidebar;
