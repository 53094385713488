import styles from './styles.module.scss';

import { CLIENT_SECRETS } from '@/config/client_secrets';

const NonProdMarker = () => {
  return (
    <>
      {!['prod', 'demo'].includes(CLIENT_SECRETS.ENVIRONMENT_NAME) ? (
        <div className={styles.nonProdMarker}>Non Production Environment</div>
      ) : (
        <></>
      )}
    </>
  );
};

export default NonProdMarker;
