import styles from './styles.module.scss';

class DateWithRelativeRenderer {
  init(params) {
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `
      <span class="my-value ${styles.dateValue}"></span>
      <div class="my-diff ${styles.dateDiff}"></div>
    `;

    this.eValue = this.eGui.querySelector('.my-value');
    this.cellValue = this.getValueToDisplay(params);
    this.eDiff = this.eGui.querySelector('.my-diff');
    this.cellDiff = this.timeDifference(params.value);

    this.eValue.innerHTML = this.cellValue;
    this.eDiff.innerHTML = this.cellDiff;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    this.eValue = this.eGui.querySelector('.my-value');
    this.cellValue = this.getValueToDisplay(params);
    this.eDiff = this.eGui.querySelector('.my-diff');
    this.cellDiff = this.timeDifference(params.value);

    this.eValue.innerHTML = this.cellValue;
    this.eDiff.innerHTML = this.cellDiff;
    return true;
  }

  destroy() {}

  getValueToDisplay(params) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }

  timeDifference(date, locale) {
    if (!date) {
      return '';
    }
    const dt = new Date(date);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(dt.getTime())) {
      return '';
    }

    if (!locale) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-param-reassign
      locale = 'en';
    }

    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const current = Date.now();
    const elapsed = current - dt;

    const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });

    if (Math.abs(elapsed) < msPerMinute) {
      return rtf.format(-Math.floor(elapsed / 1000), 'seconds');
    }
    if (Math.abs(elapsed) < msPerHour) {
      return rtf.format(-Math.floor(elapsed / msPerMinute), 'minutes');
    }
    if (Math.abs(elapsed) < msPerDay) {
      return rtf.format(-Math.floor(elapsed / msPerHour), 'hours');
    }
    if (Math.abs(elapsed) < msPerMonth) {
      return rtf.format(-Math.floor(elapsed / msPerDay), 'days');
    }
    if (Math.abs(elapsed) < msPerYear) {
      return rtf.format(-Math.floor(elapsed / msPerMonth), 'months');
    }
    return rtf.format(-Math.floor(elapsed / msPerYear), 'years');
  }
}

export default DateWithRelativeRenderer;
