import { STATUS } from '@/constants';
import { v4 as uuidv4 } from 'uuid';
import { CertificateLobTemplateEntity } from './certificate-lob-template-entity';
import { CERTIFICATE_ORIGIN } from '@certificate_hero/enums';
export class OutboundCertificateEntity {
  constructor({
    id = uuidv4(),
    parentCertId = null,
    childCertId = null,
    createdAt = new Date(),
    updatedAt = new Date(),
    certificateNumber = null,
    issued = null,
    deactivated = null,
    insuredId = null,
    insuredName = null,
    descriptionOfOperations = null,
    baseFormTemplateId = null,
    ofPolicyFormTemplateId = null,
    ofDescOpsFormTemplateId = null,
    freeFormTemplateId = null,
    freeformContent = null,
    namedInsuredId = null,
    namedInsuredName = null,
    additionalNamedInsuredIds = [],
    holderId = null,
    holderAddressId = null,
    lobTemplates = [],
    summary = null,
    status = STATUS.draft,
    userTemplateId = null,
    formTemplateId = null,
    contacts = {
      faxNumber: [],
      faxName: '',
      faxSubject: '',
      faxBody: '',
      emailTo: [],
      emailCc: [],
      emailBcc: [],
      emailSubject: '',
      emailBody: '',
    },
    createdByUserId = null,
    issuedByUserId = null,
    assignedUserId = null,
    deleted = false,
    userId = null,
    bulkJobId = null,
    endorsementIds = [],
    agencyContactId = null,
    origin = CERTIFICATE_ORIGIN.CREATION,
    signatureId = null,
  }) {
    this.id = id;
    this.parentCertId = parentCertId;
    this.childCertId = childCertId;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.certificateNumber = certificateNumber;
    this.issued = issued;
    this.deactivated = deactivated;
    this.insuredId = insuredId;
    this.insuredName = insuredName;
    this.descriptionOfOperations = descriptionOfOperations;
    this.baseFormTemplateId = baseFormTemplateId;
    this.ofPolicyFormTemplateId = ofPolicyFormTemplateId;
    this.ofDescOpsFormTemplateId = ofDescOpsFormTemplateId;
    this.freeFormTemplateId = freeFormTemplateId;
    this.freeformContent = freeformContent;
    this.namedInsuredId = namedInsuredId;
    this.namedInsuredName = namedInsuredName;
    this.additionalNamedInsuredIds = additionalNamedInsuredIds;
    this.holderId = holderId;
    this.holderAddressId = holderAddressId;
    this.lobTemplates = lobTemplates.map(
      lobTemplate => new CertificateLobTemplateEntity(lobTemplate)
    );
    this.summary = summary;
    this.status = status;
    this.userTemplateId = userTemplateId;
    this.formTemplateId = formTemplateId;
    this.contacts = contacts;
    this.createdByUserId = createdByUserId;
    this.issuedByUserId = issuedByUserId;
    this.assignedUserId = assignedUserId;
    this.deleted = deleted;
    this.userId = userId;
    this.bulkJobId = bulkJobId;
    this.endorsementIds = endorsementIds;
    this.agencyContactId = agencyContactId;
    this.origin = origin;
    this.signatureId = signatureId;
  }
}
