import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import TextArea from '../text-area';

/**
 * @param {object} params
 * @param {LobTemplateFieldMapping} params.mapping
 * @param {(params: {updatedMapping: LobTemplateFieldMapping, position: LobFieldMapping['formPosition']}) => void} params.updateFieldMapping
 * @param {boolean} params.isReadonly
 * @param {LobFieldMapping['formPosition']} params.position
 * @param {{ nameField: string }} params.contentLimit
 */
const DescriptionField = ({
  mapping,
  updateFieldMapping,
  isReadonly,
  position,
  contentLimit,
}) => {
  const [fieldMapping, setFieldMapping] = useState(mapping);

  useEffect(() => {
    setFieldMapping(mapping);
  }, [mapping]);

  return (
    <div className={styles.individualMapping}>
      <TextArea
        containerClassName={styles.descField}
        label={fieldMapping.label}
        id={fieldMapping.id}
        value={fieldMapping.value}
        placeholder="Value"
        disabled={isReadonly && !fieldMapping.editable}
        onChange={event => {
          const updatedMapping = { ...fieldMapping, value: event.target.value };
          setFieldMapping(updatedMapping);
          updateFieldMapping({ updatedMapping, position });
        }}
        maxLimit={contentLimit?.nameField}
      />
    </div>
  );
};

export default DescriptionField;
