import { forwardRef, useImperativeHandle } from 'react';
import styles from './tooltips.module.scss';

const LobTemplateIssuesTooltip = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return ['custom-tooltip'];
      },
    };
  });

  const { lobIssues } = props.value;

  const issueSummary = lobIssues.join(',');

  return (
    <div
      className="custom-tooltip"
      style={lobIssues?.length ? {} : { display: 'none' }}
    >
      <pre className={styles.tooltipText}>{issueSummary}</pre>
    </div>
  );
});

export default LobTemplateIssuesTooltip;
