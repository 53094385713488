import Checkbox from '@/components/checkbox';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import Label from '../label';
import styles from './styles.module.scss';

/**
 * @param {object} params
 * @param {LobTemplateFieldMapping} params.mapping
 * @param {(params: {updatedMapping: LobTemplateFieldMapping, position: LobFieldMapping['formPosition']}) => void} params.updateFieldMapping
 * @param {boolean} params.isReadonly
 * @param {LobFieldMapping['formPosition']} params.position
 * @param {boolean} params.hasAmsCoverageData
 */
const SwitchField = ({
  mapping,
  updateFieldMapping,
  isReadonly,
  position,
  hasAmsCoverageData,
  isAmsPolicyValidating,
}) => {
  const [fieldMapping, setFieldMapping] = useState(mapping);

  useEffect(() => {
    setFieldMapping(mapping);
  }, [mapping]);

  const handleOnChange = useCallback(
    event => {
      let value = '';
      if (event.target.value === '0' && event.target.checked) {
        value = 'N';
      } else if (event.target.value === '1' && event.target.checked) {
        value = 'Y';
      }

      const updatedMapping = { ...fieldMapping, value };
      setFieldMapping(updatedMapping);
      updateFieldMapping({ updatedMapping, position });
    },
    [fieldMapping, position, updateFieldMapping]
  );

  const checkboxes = [
    {
      value: 1,
      key: 'yesCheckboxKey',
      label: 'Yes',
      checked: fieldMapping.value === true || fieldMapping.value === 'Y',
    },
    {
      value: 0,
      key: 'noCheckboxKey',
      label: 'No',
      checked: fieldMapping.value === false || fieldMapping.value === 'N',
    },
  ];

  const amsValue = useMemo(
    () =>
      mapping.amsValue !== ''
        ? mapping.amsValue === 'Y' || mapping.amsValue === true
          ? 'Yes'
          : 'No'
        : 'Not Found',
    [mapping.amsValue]
  );

  return (
    <div className={styles.switchContainer}>
      {isAmsPolicyValidating || (amsValue && hasAmsCoverageData) ? (
        <Form.Text
          htmlFor={fieldMapping.id}
          className={styles.switchAmsValue}
          muted
        >
          {`AMS Value: ${isAmsPolicyValidating ? 'Loading' : amsValue}`}
        </Form.Text>
      ) : null}
      <Label className={styles.switchLabel}>{fieldMapping.label}</Label>
      <div className={styles.switchCheckbox}>
        {checkboxes.map(item => (
          <Checkbox
            key={item.key}
            label={item.label}
            labelClassName={styles.checkboxLabel}
            value={item.value}
            disabled={isReadonly && !fieldMapping.editable}
            checked={item.checked}
            onChange={handleOnChange}
          />
        ))}
      </div>
    </div>
  );
};

export default SwitchField;
