import { createContext } from 'react';

export const EDITOR_CONTEXT = Object.freeze(
  /** @type {const} */ ({
    DEFAULT: 'DEFAULT',
    UPDATE: 'UPDATE',
  })
);

export const defaultEditorContext = {
  dynamicProps: {},
  tabProps: {},
  isEditorReady: false,
  // Contracts
  hasContract: false,
  contractUrl: '',
  contractLoading: true,
  contractOcrLoading: true,
  contract: {},
  // Notes
  certNoteChanged: false,
  insuredNoteChanged: false,
  // Added note states here so that it can persist when adding new columns and changing tabs
  insuredNote: '',
  certNote: '',
  complianceNote: '',
  // Templates
  template: {},
  templateMode: false,
  saveTemplateLoading: false,
  /** @type {CertContextEditor} */
  certContext: null,
  viewerAPI: null,
  hasAcordForm: false,
  allowedTabNames: [],
  sampleCert: null,
  columnSizes: [50, 50, 0, 0],
  currentTabSizes: [50, 50, 0, 0],
  columnViews: [],
  cidDocument: {
    options: [],
    selected: null,
    loaded: false,
    filelink: null,
  },
  fileID: null,
  threadID: null,
  chatMessages: null,
  highlighting: {
    quickFinds: '',
    highlightValues: [],
    jumpAreas: [],
    jumpAreaCount: 0,
  },
  chatFloatMode: false,
};

/**
 * @param {EditorContext} state
 * @param {{type: keyof EDITOR_CONTEXT, payload: Partial<EditorContext>}} action
 */
export const editorReducer = (state, { type, payload }) => {
  switch (type) {
    case EDITOR_CONTEXT.DEFAULT:
      return defaultEditorContext;
    case EDITOR_CONTEXT.UPDATE:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const EditorContext = createContext([defaultEditorContext, () => null]);
