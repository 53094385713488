import BulkJobLobActionsDropdown from '@/components/bulk-job/edit/lobs-form/actions-dropdown';

/**
 * @param {object} props
 * @param {LobTemplateEntity} props.data
 */

const BulkJobLobActionsRenderer = ({ data: lob, context, ...props }) => {
  if (!lob) {
    return '-';
  }

  const { formValuesLobs, onChange } = context;

  return (
    <BulkJobLobActionsDropdown
      lob={lob}
      formValuesLobs={formValuesLobs}
      onChange={onChange}
      {...props}
    />
  );
};

export default BulkJobLobActionsRenderer;
