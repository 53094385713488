import NotificationService from '@/components/notification-service';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { route } from 'nextjs-routes';
import { useCallback } from 'react';

export const useHolders = () => {
  const create = useCallback(async newData => {
    try {
      const res = await FrontendHttpClient.post(`/api/v2/holders`, newData);
      //
      NotificationService.success('Holder created');
      return res.data.result;
    } catch (err) {
      let message = 'Error creating holder';

      if (err.response?.status === 409) {
        message = err.response.data.message;
      }
      NotificationService.error(message);

      return null;
    }
  }, []);

  const update = useCallback(async (id, newData) => {
    try {
      const res = await FrontendHttpClient.put(
        route({
          pathname: '/api/v2/holders/[id]',
          query: { id },
        }),
        newData
      );

      NotificationService.success('Holder updated');
      return res.data.result;
    } catch (err) {
      let message = 'Error updating holder';

      if (err.response?.status === 409) {
        message = err.response.data.message;
      }

      NotificationService.error(message);

      return null;
    }
  }, []);

  return {
    create,
    update,
  };
};
