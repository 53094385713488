import useSWR from 'swr';

import { route } from 'nextjs-routes';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';

export const useAmsPolicy = ({ policyAmsId, includeCoverages }) => {
  const { data, error, isLoading } = useSWR(
    policyAmsId
      ? [
          route({
            pathname: `/api/v2/policies/ams/${policyAmsId}`,
          }),
          policyAmsId,
        ]
      : null,
    async ([url]) => {
      const res = await FrontendHttpClient.get(url, {
        includeCoverages,
      });
      return res.data?.result;
    }
  );

  const isValidating = isLoading;

  return {
    data,
    isValidating,
  };
};
