import { useMemo } from 'react';
import useSWR from 'swr';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { useStore } from '@/hooks/use-store';
import { ENDORSEMENT_STATUS } from '@/constants';
import { route } from 'nextjs-routes';

export const useEndorsements = () => {
  const [{ insuredId }] = useStore();

  /**
   */
  const { data, mutate, isValidating } = useSWR(
    insuredId ? [route({ pathname: '/api/v2/endorsements' }), insuredId] : null,
    async ([url, insId]) => {
      const res = await FrontendHttpClient.get(url, {
        insuredId: insId,
        status: ENDORSEMENT_STATUS.active,
      });
      return res.data.result;
    }
  );

  const endorsements = useMemo(() => {
    return data || [];
  }, [data]);

  return {
    endorsements,
    mutate,
    isValidating,
  };
};
