import styles from './styles.module.scss';

/**
 * @param {object} params
 * @param {JSX.Element} params.children
 * @param {string} params.id
 * @param {string} params.headerText
 */
const MultiFieldContainer = ({ children, id, headerText }) => {
  return (
    <div className={styles.multiFieldContainer} key={id}>
      {headerText.length !== 0 && (
        <b className={styles.headerText}>{headerText}</b>
      )}
      {children}
    </div>
  );
};

export default MultiFieldContainer;
