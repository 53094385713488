import LobMapperDropdown from '@/components/renewals/lob-mapper-dropdown';

/**
 * @param {object} props
 * @param {LobTemplateEntity} props.data
 * @param {RowNode} props.node
 * @param {() => void} props.onEdit
 */
const LobMapperLinkRenderer = props => {
  const { data: currentLob, node, context } = props;

  const { onRenewalEdit } = context;

  if (!currentLob) {
    return '-';
  }

  return (
    <LobMapperDropdown
      currentLob={currentLob}
      node={node}
      onRenewalEdit={onRenewalEdit}
    />
  );
};

export default LobMapperLinkRenderer;
