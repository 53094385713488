import cx from 'clsx';

import styles from './styles.module.scss';

const Label = props => {
  const { id, htmlFor, children, className, style } = props;

  return (
    <label
      id={id}
      htmlFor={htmlFor}
      className={cx(styles.label, className)}
      style={style}
    >
      {children}
    </label>
  );
};

export default Label;
