import { route } from 'nextjs-routes';
import useSWR from 'swr';
import {
  HOME_ASSIGNED_COLUMNS,
  HOME_OUTBOUND_COLUMNS,
  HOME_PROGRESS_COLUMNS,
  HOME_RECENT_COLUMNS,
} from '@/constants/column-defs';
import { useMemo } from 'react';

const errorData = {
  title: 'Error loading certs',
  rowData: [],
  noResultsMessage: 'Error',
  columnDefs: HOME_PROGRESS_COLUMNS,
};

export const useHomeCerts = gridId => {
  const { data, mutate } = useSWR(
    route({ pathname: '/api/v2/outbound_certificates/home' })
  );

  const gridData = useMemo(() => {
    if (!data || !gridId) {
      return errorData;
    }

    switch (gridId) {
      case 'assigned':
        return {
          title: 'Assigned Certificates',
          rowData: data?.assignedOutbounds,
          noResultsMessage: 'No certificates assigned to you.',
          columnDefs: HOME_ASSIGNED_COLUMNS,
        };
      case 'issued':
        return {
          title: 'Recently Issued Certificates',
          rowData: data?.recentOutbounds,
          noResultsMessage: 'No outbound certificates.',
          columnDefs: HOME_OUTBOUND_COLUMNS,
        };
      case 'progress':
        return {
          title: 'In Progress Certificates',
          rowData: data?.userInProgressCerts,
          noResultsMessage: 'No certificates in progress.',
          columnDefs: HOME_PROGRESS_COLUMNS,
        };
      case 'recent':
        return {
          title: 'Recently Worked On Certificates',
          rowData: data?.userLatestChanges,
          noResultsMessage: 'No recent certificates.',
          columnDefs: HOME_RECENT_COLUMNS,
        };
      default:
        return errorData;
    }
  }, [data, gridId]);

  return {
    data,
    mutate,
    gridData,
  };
};
