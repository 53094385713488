import { HolderEntity } from '@/entities/holder-entity';
import { InsuredEntity } from '@/entities/insured-entity';

import { AmsPolicyEntity } from '@/entities/ams-policy-entity';
import { ContactEntity } from '@certificate_hero/entities';
import { EndorsementEntity } from '@/entities/endorsement-entity';
import { AddressEntity } from '@/entities/address-entity';
import { AgencyContactEntity } from '@/entities/agency-contact-entity';
import { LobTemplateEntity } from './lob-template-entity';
import { NamedInsuredEntity } from './named-insured-entity';

export class CertContextEntity {
  constructor({
    objName,
    objId,
    // Core certificate properties
    certNumber,
    agencyContact,
    issued,
    broker,
    user,
    insured,
    namedInsured,
    additionalNamedInsureds = [],
    holder,
    holderAddress,
    contacts,
    certPolicies = [],
    lobTemplates = [],
    endorsements = [],
    descriptionOfOperations = '',
    baseFormTemplate,
    ofPolicyFormTemplate,
    ofDescOpsFormTemplate,
    freeformContent = '',
    // Context-specific properties for use in real-time editing
    amsPolicies = [],
    insuredEndorsements = [],
    signature,
    removedData = [],
    autoIngested = false,
  }) {
    this.objName = objName;
    this.objId = objId;
    // Core certificate properties
    this.certNumber = certNumber || null;
    this.agencyContact = agencyContact
      ? new AgencyContactEntity(agencyContact)
      : null;
    this.issued = issued || null;
    this.broker = broker;
    this.user = user;
    this.insured = new InsuredEntity(insured);
    this.namedInsured = new NamedInsuredEntity(namedInsured);
    this.additionalNamedInsureds = additionalNamedInsureds.filter(Boolean);
    this.holder = holder ? new HolderEntity(holder) : null;
    this.holderAddress = holderAddress
      ? new AddressEntity(holderAddress)
      : null;
    this.contacts = new ContactEntity(contacts);
    this.certPolicies = certPolicies.map(p => p);
    this.lobTemplates = lobTemplates.map(l => new LobTemplateEntity(l));
    this.endorsements = endorsements.map(e => new EndorsementEntity(e));
    this.descriptionOfOperations = descriptionOfOperations;
    this.baseFormTemplate = baseFormTemplate;
    this.ofPolicyFormTemplate = ofPolicyFormTemplate;
    this.ofDescOpsFormTemplate = ofDescOpsFormTemplate;
    this.freeformContent = freeformContent;
    // Context-specific properties for use in real-time editing
    this.amsPolicies = amsPolicies.map(p => new AmsPolicyEntity(p));
    this.insuredEndorsements = insuredEndorsements.map(
      e => new EndorsementEntity(e)
    );
    this.signature = signature;
    this.removedData = removedData;
    this.autoIngested = autoIngested;
  }
}
