import { useStore } from '@/hooks/use-store';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { useCallback } from 'react';

export const useUser = () => {
  const [{ user }, , mutate] = useStore();

  /**
   * Updates the current users information
   */
  const update = useCallback(async newData => {
    const res = await FrontendHttpClient.put('/api/v2/users/profile', newData);
    return res.data.record;
  }, []);

  const checkAccessKey = targetKey => {
    return user.accessKeys[targetKey].ACCESS;
  };

  const canAccess = accessKey => checkAccessKey(accessKey);

  return {
    ...user,
    update,
    mutate,
    canAccess,
  };
};
