module.exports = /** @type {const} */ Object.freeze({
  CERTIFICATES: {
    ACCESS: false,
    CREATE: false,
    C_FROM_COI: false,
    C_FROM_CONTRACT: false,
    C_FROM_INS_CLAUSE: false,
    IMPORT_AND_EXPORT: false,
    SEND_EMAIL: false,
    SEND_FAX: false,
    HIDE_IN_PROGRESS: false,
  },
  AUTOMATED_QUICK_ISSUE: {
    CONFIGURE: false,
  },
  DESCRIPTION_OF_OPERATIONS: {
    CREATE: false,
  },
  NOTES: {
    INSURED_NOTES_ACCESS: false,
    INSURED_NOTES_CREATE: false,
    CERTIFICATE_NOTES_ACCESS: false,
    CERTIFICATE_NOTES_CREATE: false,
  },
  ACORD_FORMS: {
    ACORD_24: false,
    ACORD_25: false,
    ACORD_27: false,
    ACORD_28: false,
    ACORD_855_NY: false,
    ACORD_21: false,
    ACORD_22: false,
    ACORD_23: false,
    ACORD_31: false,
  },
  ENDORSEMENTS: {
    ACCESS: false,
    CREATE: false,
    DELETE: false,
    FORM_ACCESS: false,
    FORM_CREATE: false,
    TAGS_ALLOWED: false,
    TAGS_EDITABLE: false,
  },
  CERTIFICATE_TEMPLATES: {
    ACCESS: false,
    CREATE: false,
    QUICK_ISSUE: false,
  },
  LOB_TEMPLATES: { ACCESS: false, CREATE: false, DELETE: false },
  LANGUAGE_LIBRARY: {
    ACCESS: false,
    CREATE: false,
    DELETE: false,
  },
  POLICIES: { ACCESS: false, CREATE: false },
  BULK_JOBS: {
    ACCESS: false,
    CREATE_REVISIONS: false,
    CREATE_RENEWALS: false,
  },
  EMAIL_CONFIGURATION: {
    ACCESS: false,
    CREATE: false,
  },
  HOLDER_MANAGEMENT: {
    ACCESS: false,
    CREATE: false,
    DELETE: false,
  },
  USER_MANAGEMENT: { ACCESS: false, CREATE: false },
  NAMED_INSURED_MANAGEMENT: { ACCESS: false, CREATE: false, DELETE: false },
  GROUP_MANAGEMENT: { ACCESS: false, CREATE: false },
  ANALYTICS: {
    ACCESS: false,
  },
  LEADS: {
    ACCESS: false,
    ASSIGN: false,
    UPDATE: false,
  },
  SIGNATURES: {
    ACCESS: false,
    CREATE: false,
    DELETE: false,
  },
  INBOUND_CERTIFICATES: {
    ACCESS: false,
    CREATE: false,
  },
  AGENCY_CONTACTS: {
    ACCESS: false,
    CREATE: false,
    DELETE: false,
  },
  CI_DOCUMENTS: {
    ACCESS: false,
    CREATE: false,
    DELETE: false,
  },
  INGESTION: {
    ACCESS: false,
  },
});
