import { LobTemplateEntity } from './lob-template-entity';
import { PolicyLobEntity } from './policy-lob-entity';

export class XfdfContextEntity {
  constructor({
    ofPolicyFormCount = 0, // todo: rename to overflow
    ofDescOpsFormCount = 0,
    allowAddingOfPolicyForm = false,
    continuedLOBs = [],
    formsToAddManually = [],
    ofFormLOBsByFormIdx = [],
  }) {
    this.ofPolicyFormCount = ofPolicyFormCount;
    this.ofDescOpsFormCount = ofDescOpsFormCount;
    this.allowAddingOfPolicyForm = allowAddingOfPolicyForm;
    this.continuedLOBs = continuedLOBs.map(lob => ({
      ...new PolicyLobEntity(lob),
      policyId: lob.policyId,
    }));

    this.formsToAddManually = formsToAddManually.map(form => ({
      formCode: form.formCode || null,
      qty: form.qty || 0,
      pageIdx: form.pageIdx || 0,
    }));

    this.ofFormLOBsByFormIdx = ofFormLOBsByFormIdx.map(lobSet => ({
      /** @type {number} */
      templateIdx: lobSet.templateIdx || 0,
      /** @type {LobTemplateEntity[]} */
      xfdfLOBs: lobSet.xfdfLOBs.map(lob => new LobTemplateEntity(lob)),
      /** @type {number} */
      numLobsSupported: lobSet.numLobsSupported || 0,
      /** @type {number} */
      numCoveragesSupported: lobSet.numCoveragesSupported || 0,
    }));
  }
}
