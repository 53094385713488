import React, { useCallback, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';

import ArrowDownSvg from '@/assets/icons/down-arrow-white.svg';
import ArrowUpSvg from '@/assets/icons/up-arrow-white.svg';
import LinkWithUnsavedWarnings from '@/components/link-with-unsaved-warnings';
import globalStyles from '@/styles/globals.module.scss';
import { useStore } from '@/hooks';
import styles from './sidebar-link.module.scss';

/**
 */

/**
 *
 * @param {object} param
 * @param {Link} param.link
 * @param {() => void} param.setLinkData
 * @param {number} param.index
 * @param {(key:string) => bool} param.checkAccessKey
 * @param {boolean} [param.isSidebarCollapsed]
 * @param {React.Dispatch<React.SetStateAction<boolean>>} [param.setIsSidebarCollapsed]
 */
const SidebarLink = ({
  link,
  setLinkData,
  index,
  checkAccessKey,
  isSidebarCollapsed = false,
  setIsSidebarCollapsed,
}) => {
  const { href, title, renderIcon, expanded, subIcon, children } = link;

  const [{ broker }] = useStore();
  const { isBranded = false } = broker?.branding || {};

  const router = useRouter();

  //collapse childNav when isSidebarCollapsed is true
  useEffect(() => {
    if (isSidebarCollapsed) {
      setLinkData(prevLinkData =>
        prevLinkData.map((tag, ind) =>
          ind === index
            ? {
                ...tag,
                expanded: false,
              }
            : tag
        )
      );
    }
  }, [isSidebarCollapsed, index, setLinkData]);

  /** @param {string[] | string} value Either a single pathname, or an array of pathnames */
  const pathIncludes = useCallback(
    value => {
      if (Array.isArray(value)) {
        return value.some(path => router.asPath.includes(path));
      }
      return router.pathname.includes(value) || router.asPath.includes(value);
    },
    [router.asPath, router.pathname]
  );

  const isActive = useCallback(() => {
    if (children.length) {
      let activeLinks = children.map(child => child.href);

      if (title === 'Certificates') {
        activeLinks = [
          ...activeLinks,
          ...[
            '/editor/editor-layout',
            '/review/review-layout',
            '/bulk-job/cert-grid',
            '/bulk-job/edit-certs',
            '/renew/edit',
          ],
        ];
      }

      return pathIncludes(activeLinks);
    }

    const isCertRoute = pathIncludes([
      href,
      '/editor/editor-layout',
      '/review/review-layout',
    ]);

    switch (title) {
      case 'Home':
        return pathIncludes('/home/[gridId]');
      case 'Certificates':
        return isCertRoute;
      case 'Endorsements':
        return pathIncludes([
          href,
          '/endorsements/edit',
          '/endorsement-forms/edit',
        ]);
      case 'Inbound Certificates':
        return pathIncludes([href, '/inbound/invound-view']);
      default:
        return pathIncludes(href);
    }
  }, [children, href, pathIncludes, title]);

  const childNavLinks = useMemo(() => {
    const linkChildren = link?.children || [];
    return linkChildren
      .filter(item => !item.accessKey || checkAccessKey(item.accessKey))
      .map(item => {
        // Refactor needed - this is a mess - this was written by copilot haha
        let isSelected = pathIncludes(item?.href);

        if (
          item?.href === '/certificates/manager' &&
          pathIncludes(['/editor/editor-layout', '/review/review-layout'])
        ) {
          isSelected = true;
        }

        if (
          item?.href === '/certificates/bulk-jobs' &&
          pathIncludes([
            '/bulk-job/cert-grid',
            '/bulk-job/edit-certs',
            '/renew/edit',
            '/renew/edit-certs',
          ])
        ) {
          isSelected = true;
        }

        return (
          <LinkWithUnsavedWarnings
            key={item?.href}
            href={item?.href}
            className={cx(
              styles.subLinks,
              isActive() && styles.activeSubLinks,
              isSelected && styles.selectedSubLink,
              isBranded ? styles.insuredBranding : ''
            )}
          >
            {item?.filtersName}
          </LinkWithUnsavedWarnings>
        );
      });
  }, [link.children, checkAccessKey, pathIncludes, isActive, isBranded]);

  // the link container is only visible under 2 conditions
  // 1. it never had any children
  // 2. the user has access to at least 1 child link
  if (!childNavLinks.length && !!link?.children.length) {
    return <></>;
  }

  return (
    <>
      <div
        className={cx(styles.container)}
        onClick={() => {
          setIsSidebarCollapsed(false);
          setLinkData(prevLinkData =>
            prevLinkData.map((tag, ind) =>
              ind === index
                ? {
                    ...tag,
                    expanded:
                      link?.children.length === 0 ? false : !tag.expanded,
                  }
                : tag
            )
          );
        }}
      >
        <LinkWithUnsavedWarnings
          href={href}
          className={cx(
            styles.link,
            isActive() && styles.active,
            isBranded ? styles.insuredBranding : ''
          )}
        >
          {renderIcon && renderIcon().prefix ? (
            <div
              className={cx(
                styles.iconContainer,
                isSidebarCollapsed && styles.collapsedIconContainer
              )}
            >
              <FontAwesomeIcon
                icon={renderIcon()}
                size="lg"
                alt={title}
                className={
                  (styles.icon, isBranded ? styles.insuredBranding : '')
                }
              />
            </div>
          ) : null}
          {renderIcon && !renderIcon().prefix ? renderIcon() : null}
          {!isSidebarCollapsed && (
            <div className={styles.labelContainer}>
              <span
                className={cx(
                  globalStyles.truncate,
                  styles.label,
                  isBranded ? styles.insuredBranding : ''
                )}
              >
                {title}{' '}
                {subIcon && !expanded && (
                  <ArrowDownSvg className={styles.svgComponent} />
                )}
                {subIcon && expanded && (
                  <ArrowUpSvg className={styles.svgComponent} />
                )}
              </span>
            </div>
          )}
        </LinkWithUnsavedWarnings>
      </div>
      {expanded && childNavLinks}
    </>
  );
};

export default SidebarLink;
