import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import useSWR from 'swr';
import { route } from 'nextjs-routes';
import { useBulkJobId } from './use-bulk-job-id';

/**
 * certPolicyCounts - The number of certs each policy/lob is on
 * certPolicyCountsAfterLobRemoval - The number of certs each policy/lob would be on after removing the requested lobs
 * longestDescOfOps - The length of the longest description of operations on any cert in this bulk edit
 */

/**
 * @param {object} params
 * @param {string[]} params.certIds
 * @param {string[]} params.lobsBeingRemovedIds
 */
export const useBulkPolicyCounts = ({ certIds, lobsBeingRemovedIds = [] }) => {
  const bulkJobId = useBulkJobId();

  /**
   */
  const { data, mutate, isValidating } = useSWR(
    bulkJobId
      ? [
          route({ pathname: '/api/v2/bulk-info/policy-counts' }),
          certIds,
          lobsBeingRemovedIds,
        ]
      : null,
    async ([url, _certIds, _lobsBeingRemovedIds]) => {
      const res = await FrontendHttpClient.post(url, {
        bulkJobId,
        certIds: _certIds,
        lobsBeingRemovedIds: _lobsBeingRemovedIds,
      });
      return res.data;
    }
  );

  return {
    ...data,
    bulkJobId,
    mutate,
    isValidating,
  };
};
