import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import useSWR from 'swr';
import { route } from 'nextjs-routes';

import { useStore } from '@/hooks';

export const useEligibleCertificatesForBulkJob = () => {
  const [{ insuredId }] = useStore();

  // By default we are grabbing all certificates we can revise/renew.
  // This can get filtered down by the form selections for revise.
  const {
    data = [],
    isValidating,
    mutate,
  } = useSWR(
    route({ pathname: '/api/v2/bulk_job_certificate/get_data' }),
    async url => {
      const res = await FrontendHttpClient.get(url, { insuredId });
      return res.data;
    }
  );

  return {
    data,
    mutate,
    isValidating,
  };
};
