import NotificationService from '@/components/notification-service';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { useContext, useState } from 'react';
import {
  ErrorContext,
  LobTemplateContext,
} from '@/components/lob-templates/context';
import { LobTemplateEntity } from '@/entities';
import { ACTIVE_STATUS } from '@/constants';
import { parseYear } from '@/utils/helpers';
import { route } from 'nextjs-routes';
import { useLobTemplates } from './use-lob-templates';

export const useLobTemplate = (hide = null) => {
  const [lobTemplate, setLobTemplate] = useContext(LobTemplateContext);
  const [errors, setErrors] = useContext(ErrorContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const { mutate: mutateLobTemplates } = useLobTemplates({
    enableAllForms: true,
  });

  /**
   * @param {object} params
   * @param {boolean} [params.notifications=false]
   * @param {PolicyCoverageEntity[]} params.updatedCoverages
   * @param {string} [params.updatedName]
   * @param {boolean} [params.isProcessed]
   * @param {string} [params.updatedLobTypeName]
   * @param {boolean} [params.isDefault]
   * @param {string} [params.templateId]
   * @param {boolean} [params.hideOnSuccess]
   * @param {boolean} [params.isQuickAdd]
   * @param {(lob) => void} [params.onSuccess]
   * @param {string} [params.renewalLobSymbol]
   * @param {boolean} [params.editMode=false]
   */
  const update = async ({
    notifications = true,
    updatedCoverages,
    updatedAddresses,
    updatedLobTypeName,
    updatedName,
    isProcessed,
    isDefault = lobTemplate.isDefault,
    hideOnSuccess = true,
    templateId,
    isQuickAdd = false,
    onSuccess,
    renewalLobSymbol,
    editMode = false,
  }) => {
    /**
     * This section validates the form input on lob modal
     */

    let hasErrors = false;
    if (!lobTemplate.name && editMode) {
      setErrors(prevState => ({ ...prevState, name: 'Must include name.' }));
      hasErrors = true;
    }
    if (
      (!updatedCoverages ||
        !Array.isArray(updatedCoverages) ||
        !updatedCoverages.length) &&
      (!updatedAddresses ||
        !Array.isArray(updatedAddresses) ||
        !updatedAddresses.length)
    ) {
      setErrors(prevState => ({
        ...prevState,
        coverage: 'Must contain at least 1 coverage',
      }));
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    setLobTemplate(prev => ({ ...prev, isUpdating: true }));

    setIsUpdating(true);
    const id = templateId || lobTemplate.id;

    let parsedYear;

    if (lobTemplate.policy.effectiveDate) {
      parsedYear = parseYear(new Date(lobTemplate.policy.effectiveDate));
    }

    // Update template
    if (id) {
      try {
        const lobSymbol = lobTemplate.lobSymbol || renewalLobSymbol;

        // Once created, we only want to edit coverages and lobTypeName.
        const res = await FrontendHttpClient.put(
          route({
            pathname: '/api/v2/lob_templates/[id]',
            query: { id },
          }),
          {
            isDefault,
            lobSymbol,
            coverages: updatedCoverages,
            addresses: updatedAddresses,
            lobTypeName: updatedLobTypeName,
            ...(updatedName && { name: `${parsedYear} ${updatedName}` }),
            isProcessed: isProcessed && isProcessed,
          }
        );
        if (res.status === 200) {
          if (onSuccess) {
            onSuccess(res.data.result);
          }
          if (notifications) {
            NotificationService.success('Template updated');
          }
          if (hideOnSuccess && hide) {
            hide();
          }
        }
      } catch (error) {
        if (error.response?.status === 409) {
          setErrors(prevState => ({
            ...prevState,
            name: 'Profile name already exists.',
          }));
        } else {
          NotificationService.error(`An error has occurred ${error}`);
        }
      }
    } else {
      try {
        const body = {
          ...lobTemplate,
          name: `${parsedYear} ${lobTemplate.name}`,
          lobTypeName: updatedLobTypeName,
          coverages: updatedCoverages,
          addresses: updatedAddresses,
          status: ACTIVE_STATUS.active,
          isInvalid: false,
        };
        delete body.id;
        delete body.policy;

        const res = await FrontendHttpClient.post(
          '/api/v2/lob_templates',
          body
        );
        if (res.status === 200) {
          NotificationService.success('LOB Profile created');

          mutateLobTemplates();

          if (isQuickAdd) {
            setLobTemplate(new LobTemplateEntity(res.data.result));
          }
          if (onSuccess) {
            onSuccess(res.data.result);
          }
          if (hideOnSuccess && hide) {
            hide();
          }
        }
      } catch (error) {
        if (error.response?.status === 409) {
          setErrors(prevState => ({
            ...prevState,
            name: 'Profile name already exists.',
          }));
        } else {
          NotificationService.error('Error creating template');
        }
      }
    }

    setLobTemplate(prev => {
      const { isUpdating: _removed, ...template } = prev;
      return template;
    });
    setIsUpdating(false);
  };

  // This creates a fresh instance of lobTeplate object to reset form
  const reset = () => {
    setLobTemplate(new LobTemplateEntity({}));
  };

  return {
    update,
    data: lobTemplate,
    setLobTemplate,
    errors,
    setErrors,
    isUpdating,
    reset,
  };
};
