export const RENEWAL_STEPS = Object.freeze(
  /** @type {const} */ ({
    POLICIES: 'policies',
    ENDORSEMENTS: 'endorsements',
    LOB_TEMPLATES: 'lob-profiles',
    CIDS: 'cids',
    CERT_TEMPLATES: 'cert-templates',
    EDIT_CERTIFICATES: 'edit-certificates',
  })
);
