import globalStyles from '@/styles/globals.module.scss';
import { useStore } from '@/hooks';
import Image from 'next/image';
import { CLIENT_SECRETS } from '@/config/client_secrets';
import cx from 'clsx';
import styles from './logo.module.scss';

export const LogoImg = props => {
  const [{ broker }] = useStore();
  const {
    isBranded = false,
    logoURL = '',
    logoAltText = '',
  } = broker?.branding || {};

  return (
    broker?.id &&
    (isBranded && logoURL && !CLIENT_SECRETS.TOP_BRANDING_URL ? (
      <Image
        className={cx(globalStyles.image, styles.logoImage)}
        unoptimized
        src={logoURL}
        alt={logoAltText}
        layout="fill"
      />
    ) : (
      <Image alt="" {...props} layout="fill" unoptimized />
    ))
  );
};
