// TODO: Better UX for displaying errors in small space.
// TODO: Error is displaying under the clearButton.

import * as yup from 'yup';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import dynamic from 'next/dynamic';
import InputGroup from 'react-bootstrap/InputGroup';

const Typeahead = dynamic(() => import('@/components/typeahead'));

export default function EmailCellRenderer(props) {
  const schema = yup.object().shape({
    emails: yup
      .array()
      .of(yup.string().email(({ value }) => `${value} is not a valid email`)),
  });

  const updateContext = values => {
    props.context.updateEmailState(values, props.data.id, props.colDef.colId);
  };

  return (
    <span className="d-flex">
      <Formik
        validationSchema={schema}
        initialValues={{
          emails: props.value,
        }}
      >
        {({ values, setFieldValue, errors }) => (
          <Form
            id={props.data.id + props.colDef.colId}
            style={{ width: '100%' }}
          >
            <InputGroup className="pb-2">
              <Typeahead
                isInvalid={!!errors.emails}
                selected={values.emails}
                setFieldValue={setFieldValue}
                field="emails"
                updateContext={updateContext}
              />
              <div>{errors.emails}</div>
            </InputGroup>
          </Form>
        )}
      </Formik>
    </span>
  );
}
