import { forwardRef } from 'react';
import cx from 'clsx';

import Button from '@/components/button/button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styles from '../styles.module.scss';

const ButtonPreview = forwardRef((props, ref) => {
  const { className, labelClassName, shown, ...buttonProps } = props;

  return (
    <Button
      ref={ref}
      className={cx(className)}
      labelClassName={cx(labelClassName)}
      {...buttonProps}
      data-testid="preview-button"
    >
      <FontAwesomeIcon
        icon={faSearch}
        className={cx(styles.previewButtonIcon)}
      />
      <span className={cx(styles.previewButtonLabel)}>
        {shown ? 'Hide' : 'Show'} Preview
      </span>
    </Button>
  );
});

export default ButtonPreview;
