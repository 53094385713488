import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as vars from '@/styles/vars.module.scss';
import styles from './styles.module.scss';

/**
 * @param {object} params
 * @param {boolean} params.value
 */

const BooleanRenderer = ({ value }) => {
  const icon = value ? faCheck : faTimes;
  const color = value ? vars['color-success'] : vars['color-danger'];

  return (
    <div
      data-cy={`boolean-${Boolean(value)}`}
      className={styles.booleanIconContainer}
      style={{ color }}
    >
      <FontAwesomeIcon icon={icon} />
    </div>
  );
};

export default BooleanRenderer;
