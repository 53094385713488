import { useRef, useState, useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Transition } from 'react-transition-group';
import cx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import Dropdown from '@/components/dropdown';
import TextInput from '@/components/text-input';
import { ButtonPrimary } from '@/components/button';
import RenderDropdown from '@/components/grid-filter-dropdown/render-dropdown';

import { useFocusTrap } from '@/hooks/use-focus-trap';
import { useOutsideClick } from '@/hooks/use-outside-click';

import globalStyles from '@/styles/globals.module.scss';
import Label from '@/components/label';
import styles from './styles.module.scss';
import { GridFilterDropdownContext } from './context';
import RenderMultiDropdown from './render-multi-dropdown';
import { DateRangeInput } from '../date-range-input';

const GridFilterDropdown = props => {
  const {
    columns,
    inputs,
    hasInput,
    show,
    onClose,
    onToggle,
    onReset,
    onFilter,
  } = props;

  const menuRef = useRef();
  const buttonRef = useRef();

  const firstInputRef = useRef();
  const lastInputRef = useRef();
  useFocusTrap(firstInputRef, lastInputRef, true);

  const [resetHandlers, setResetHandlers] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const handleClickOutside = event => {
    if (show) {
      event.stopPropagation();
      onClose();
    }
  };

  useOutsideClick(menuRef, handleClickOutside);

  const handleReset = () => {
    resetHandlers.forEach(handler => handler());
    setIsChecked(false);

    if (onReset) {
      onReset();
    }
  };

  const handleTextChange = field => event => {
    const { value } = event.target;

    if (onFilter) {
      onFilter(field)(value);
    }
  };

  const handleClearData = colDef => () => {
    const { field } = colDef;

    if (onFilter) {
      onFilter(field)('');
    }
  };

  const renderClearButton = colDef => {
    const { field } = colDef;

    return inputs[field] ? (
      <button
        type="button"
        tabIndex={show ? 0 : -1}
        className={cx(globalStyles.buttonReset, styles.clearButton)}
        onClick={handleClearData(colDef)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    ) : null;
  };

  const expirationFilter = useMemo(() => {
    return columns.map(({ colDef }, idx) => {
      if (colDef.customFilter === 'expirationDateColumnFilter') {
        return (
          <div key={idx} className={styles.expirationFilterContainer}>
            <span className={styles.expirationFilterText}>Hide Expired?</span>
            <input
              type="checkbox"
              className="mx-2"
              checked={isChecked}
              onChange={e => {
                if (e.target.checked) {
                  setIsChecked(true);
                  onFilter(colDef.field)(e.target.checked);
                } else {
                  setIsChecked(false);
                  onFilter(colDef.field)('');
                }
              }}
            />
          </div>
        );
      }
      return null;
    });
  }, [columns, isChecked, onFilter]);

  return (
    <GridFilterDropdownContext.Provider
      value={[resetHandlers, setResetHandlers]}
    >
      <Transition enter exit in={show} timeout={300}>
        {state => (
          <Dropdown
            ref={buttonRef}
            show
            hideToggle
            align="left"
            drop="down"
            onToggle={onToggle}
            className={cx(styles.dropdown, styles[state])}
          >
            <div
              ref={menuRef}
              className={cx(
                styles.menu,
                globalStyles.openAnimation,
                globalStyles[state]
              )}
            >
              <Container className={styles.inner}>
                <Row className={globalStyles.gutterSmall}>
                  {columns
                    .filter(x => x.colDef.filter !== 'agDateColumnFilter')
                    .map((column, index) => {
                      const { colDef } = column;

                      if (!colDef) {
                        return null;
                      }

                      const {
                        headerName,
                        field,
                        filter,
                        customFilter,
                        filterInputMaxLimit,
                        disableFilter,
                      } = colDef;

                      if (disableFilter) {
                        return null;
                      }

                      let type = 'text';

                      if (filter === 'agNumberColumnFilter') {
                        type = 'number';
                      }

                      if (filter === 'agDateColumnFilter') {
                        type = 'date';
                      }

                      if (customFilter !== 'expirationDateColumnFilter') {
                        return (
                          <Col
                            xs={12}
                            md={6}
                            key={`gridFilter-${field}`}
                            className={styles.col}
                          >
                            {customFilter === 'chMultiDropdownFilter' ? (
                              <RenderMultiDropdown
                                colDef={colDef}
                                onFilter={onFilter}
                                defaultValue={inputs[field]}
                                disabled={!show}
                              />
                            ) : null}

                            {customFilter === 'chDropdownFilter' ||
                            customFilter === 'agBooleanColumnFilter' ? (
                              <RenderDropdown
                                colDef={colDef}
                                onFilter={onFilter}
                                defaultValue={inputs[field]}
                                disabled={!show}
                              />
                            ) : null}

                            {!customFilter ? (
                              <TextInput
                                type={type}
                                name={field}
                                ref={index === 0 ? firstInputRef : undefined}
                                tabIndex={show ? 0 : -1}
                                label={headerName}
                                value={inputs[field] || ''}
                                placeholder={`Filter by "${headerName}"`}
                                aria-label={`${headerName} Filter Input`}
                                onChange={handleTextChange(field)}
                                className={cx(
                                  !!inputs[field] && styles.inputWithClear
                                )}
                                data-lpignore="true"
                                componentRight={renderClearButton(colDef)}
                                maxLimit={filterInputMaxLimit || 1000}
                                disabled={!show}
                              />
                            ) : null}
                          </Col>
                        );
                      }
                      return null;
                    })}
                </Row>
                <Row className={globalStyles.gutterSmall}>
                  {columns
                    .filter(
                      x =>
                        x.colDef.filter === 'agDateColumnFilter' &&
                        x.colDef.customFilter !== 'expirationDateColumnFilter'
                    )
                    .map(({ colDef }) => {
                      if (!colDef) {
                        return null;
                      }

                      const { headerName, field } = colDef;

                      return (
                        <Col
                          xs={6}
                          md={6}
                          className={styles.col}
                          key={headerName}
                        >
                          <Label>{headerName}</Label>
                          <DateRangeInput
                            placeholder={`Filter by "${headerName}"`}
                            field={field}
                            onChange={handleTextChange}
                            onFilter={onFilter}
                            disabled={!show}
                          />
                        </Col>
                      );
                    })}
                </Row>
                <div className={styles.actions}>
                  {expirationFilter}
                  <div className={styles.buttonsContainer}>
                    {hasInput ? (
                      <button
                        className={cx(
                          globalStyles.buttonReset,
                          globalStyles.link,
                          styles.resetButton
                        )}
                        type="button"
                        tabIndex={show ? 0 : -1}
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                    ) : null}
                    <ButtonPrimary
                      id="grid-filter-submit"
                      onClick={onClose}
                      tabIndex={show ? 0 : -1}
                      containerClassName={globalStyles.buttonMargin}
                    >
                      Close
                    </ButtonPrimary>
                  </div>
                </div>
              </Container>
            </div>
          </Dropdown>
        )}
      </Transition>
    </GridFilterDropdownContext.Provider>
  );
};

export default GridFilterDropdown;
