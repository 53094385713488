import useSWR from 'swr';

/**
 */

export const useActiveBulkJob = () => {
  /**
   */
  const { data, mutate, isValidating } = useSWR('/api/v2/bulk_jobs/active');

  return {
    hasActiveBulkJob: data?.hasActiveBulkJob,
    mutate,
    isValidating,
  };
};
