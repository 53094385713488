import { ModalContext } from '@/components/modal-provider/context';
import { useContext, useEffect } from 'react';

export const useModal = (options = { disableAutoDestroy: false }) => {
  const { disableAutoDestroy } = options;

  const context = useContext(ModalContext);
  const { clear } = context;

  useEffect(() => {
    return () => {
      if (!disableAutoDestroy) {
        clear();
      }
    };
  }, [disableAutoDestroy, clear]);

  return context;
};
