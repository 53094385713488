import { useCallback, useContext, useEffect, useState } from 'react';
import { SocketContext } from '@/components/socket/context';
import { v4 } from 'uuid';

/**
 * @typedef {object} SocketEventMessage
 * @property {string} type - the type of message
 * @property {string} message - the message from the server
 */

/**
 * @typedef {object} SocketEventListeners
 * @property {(data:SocketEventMessage)=>{}} [error] - called when an error message is received from server
 * @property {(data:SocketEventMessage)=>{}} [info] - called when an info message is received from server
 * @property {(data:SocketEventMessage)=>{}} [success] - called when a success message is received from server
 * @property {(data:SocketEventMessage)=>{}} [warning] - called when a warning message is received from server
 * @property {(data:SocketEventMessage)=>{}} [progress] - called when a progress message is received from server
 * @property {(data:SocketEventMessage)=>{}} [download] - called when a download message is received from server
 */

/**
 * @param {SocketEventListeners} [eventListeners={}]
 */
export const useSocket = (eventListeners = {}, initialTrackingId = null) => {
  const [socket, emitter, reconnectSocket] = useContext(SocketContext);

  const [trackingId, setTrackingId] = useState(initialTrackingId || v4());

  // wrap the emitter to create the trackingId
  const emit = useCallback(
    (
      eventName,
      data,
      { notification = null, trackingId: _trackingId = null } = {}
    ) => {
      // a trackingId may be passed in when multiple events are tied together like uploads or bulk jobs
      const tId = _trackingId || trackingId;
      setTrackingId(tId);
      emitter(eventName, data, { notification, trackingId: tId });
    },
    [emitter, trackingId]
  );

  useEffect(() => {
    if (!trackingId || !socket) {
      return undefined;
    }

    // wrapping the listeners with a function that checks the trackingId
    // the handler will only be called if the trackingId of the event matches the current trackingId
    // this is to prevent the handler from being called for events emitted from other components
    const listeners = Object.entries(eventListeners).reduce(
      (accumulator, [event, handler]) => {
        const wrappedListeners = { ...accumulator };
        wrappedListeners[event] = data => {
          if (data.trackingId === trackingId) {
            handler(data);
          }
        };
        return wrappedListeners;
      },
      {}
    );

    Object.entries(listeners).forEach(([event, listener]) => {
      socket.bind(event, listener);
    });

    return () => {
      Object.entries(listeners).forEach(([event, listener]) => {
        socket.unbind(event, listener);
      });
    };
  }, [emit, eventListeners, socket, trackingId]);

  return { emit, reconnectSocket, trackingId };
};
