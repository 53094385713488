import useSWR from 'swr';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { route } from 'nextjs-routes';

/**
 * @param {FilterData} filterData
 */
export const useCertificateCount = filterData => {
  /**
   * @type {SWRResponse<{totalWithoutBulkJobId: number, totalWithBulkJobId: number}>}
   */
  const { data, mutate, isValidating } = useSWR(
    route({ pathname: '/api/v2/outbound_certificates/count' }),
    async url => {
      const res = await FrontendHttpClient.get(url, filterData);
      return res.data;
    }
  );

  return {
    data,
    mutate,
    isValidating,
  };
};
