import styles from './styles.module.scss';

/**
 * @param {object} props
 * @param {string} props.value
 */
const LargeContentRenderer = ({ value }) => {
  return <div className={styles.largeContent}>{value}</div>;
};

export default LargeContentRenderer;
