import { v4 } from 'uuid';
import { formPositions } from '@/enums/form-positions';
import cloneDeep from 'lodash/cloneDeep';
import { findLobTypeBySymbol } from '@certificate_hero/utils';
import { covDataTypes } from '@/enums/lob-data-types';
import { useCallback } from 'react';
import { coverageFlowStyles } from '@/enums/coverage-flow-styles';
import { lobFormModes } from '@/enums/lob-form-modes';
import { coverageLineStyles } from '@/enums/coverage-line-styles';
import uniqBy from 'lodash/uniqBy';

const { TOP, LEFT, CENTER, RIGHT, ADDITIONAL, BOTTOM, ADDITIONAL_SCHEDULES } =
  formPositions;
const { FREE_TEXT, INDICATOR } = covDataTypes;

/**
 * @param {LobTemplateFieldAreas} lobFields
 * @param {LobFieldMapping} field
 * @param {LobTypeEntity} basicLobType
 */
const addLobTemplateFieldMapping = (lobFields, field, basicLobType) => {
  if (!field.formPosition || field.lobFieldName === 'lobTypeName') {
    return lobFields;
  }

  const defaultProps = {
    id: v4(),
    amsId: '',
    value: '',
    amsValue: '',
    editable: false,
    flowStyle: field.flowStyle,
    lineStyle: field.lineStyle,
    isEndOfLine: !!field.isEndOfLine,
    contentLimit: field.contentLimit,
    headerText: field.headerText,
    skipValueFormatting: field.skipValueFormatting,
    fieldSet: field.fieldSet,
    fieldLimits: field.fieldLimits,
  };

  if (!field.lobFieldName && !field.coverageFamily) {
    lobFields[field.formPosition].push({
      ...defaultProps,
      name: '',
      label: '',
      type: 'FREE_TEXT',
    });

    return lobFields;
  }

  const matchedCov = basicLobType.coverages.find(
    cov =>
      cov.name === field.lobFieldName ||
      (cov.family &&
        field.coverageFamily &&
        cov.family === field.coverageFamily)
  );

  if (!matchedCov) {
    lobFields[field.formPosition].push({
      ...defaultProps,
      name: '',
      label: '',
      type: 'FREE_TEXT',
    });

    return lobFields;
  }

  lobFields[field.formPosition].push({
    ...defaultProps,
    name: matchedCov.name,
    label: matchedCov.label,
    type: matchedCov.type,
    value: matchedCov.type === INDICATOR ? false : '',
    family: matchedCov.family,
  });

  return lobFields;
};

/**
 * @param {LobTemplateFieldMapping[]} fieldMappings
 * @param {PolicyCoverageEntity[]} coverages
 * @param {PolicyCoverageEntity[]} amsCoverages
 * @param {string} [lobTypeName]
 */
const mapStructuredFieldsToValue = (fieldMappings, coverages, amsCoverages) => {
  const unassignedCoverages = cloneDeep(coverages);
  const coverageNames = coverages.map(cov => cov.name);

  const structuredMappings = fieldMappings.map(mapping => {
    const amsCoverage = amsCoverages.find(cov => cov.name === mapping.name);
    const amsValue = amsCoverage?.value || '';

    if (!coverageNames.includes(mapping.name)) {
      return {
        ...mapping,
        amsValue,
      };
    }

    const index = unassignedCoverages.findIndex(
      cov => cov.name === mapping.name
    );

    const coverage = unassignedCoverages[index];
    unassignedCoverages.splice(index, 1);

    return {
      ...mapping,
      value: coverage.value,
      amsId: coverage?.amsId,
      locationId: coverage?.locationId, // used by Property 101
      amsValue,
    };
  });

  return { structuredMappings, unassignedCoverages };
};

/**
 * @param {LobTemplateFieldMapping[]} fieldMappings
 * @param {PolicyCoverageEntity[]} coverages
 * @param {PolicyCoverageEntity[]} amsCoverages
 * @param {LobTypeEntity} lobType
 */
const mapFreeformValues = (fieldMappings, coverages, amsCoverages, lobType) => {
  const unassignedCoverages = cloneDeep(coverages);

  const allMappings = fieldMappings.map(mapping => {
    if (mapping.type !== FREE_TEXT) {
      return mapping;
    }

    if (unassignedCoverages.length === 0) {
      return mapping;
    }

    const coverage = unassignedCoverages[0];
    unassignedCoverages.splice(0, 1);

    const covType = lobType.coverages.find(cov => cov.name === coverage.name);

    const amsCoverage = amsCoverages.find(cov => cov.name === coverage.name);
    const amsValue = amsCoverage?.value || '';

    return {
      ...mapping,
      name: coverage.name,
      amsId: coverage?.amsId,
      label: covType?.label || coverage.name,
      value: coverage.value,
      amsValue,
      editable: coverage.editable,
    };
  });

  return {
    allMappings,
    unassignedCoverages: uniqBy(
      unassignedCoverages,
      coverage =>
        `${coverage.name}-${coverage.value}-${coverage.amsId}-${coverage.locationId}`
    ),
  };
};

/**
 * @param {FieldMappingInfo} fieldMappingInfo
 * @param {string} mode
 */
export const useFieldMappings = (fieldMappingInfo, mode) => {
  const lobMappings = fieldMappingInfo.lobs;

  /**
   * @param {string} lobSymbol
   */
  const getFieldsForLob = useCallback(
    lobSymbol => {
      const lobMap = lobMappings?.find(mapping =>
        mapping.lobSymbols.includes(lobSymbol)
      );

      const basicLobType = findLobTypeBySymbol(lobSymbol);

      if (!lobMap || !basicLobType) {
        return {
          top: [],
          left: [],
          center: [],
          right: [],
          schedule: [],
          additional: [],
          bottom: [],
          internal: [],
        };
      }

      return lobMap.fields.reduce(
        (lobFields, field) => {
          if (
            field.flowStyle === coverageFlowStyles.CERT_ONLY &&
            mode === lobFormModes.MANAGER
          ) {
            return lobFields;
          }

          return addLobTemplateFieldMapping(lobFields, field, basicLobType);
        },
        {
          /** @type {LobTemplateFieldMapping[]} */
          top: [],
          /** @type {LobTemplateFieldMapping[]} */
          left: [],
          /** @type {LobTemplateFieldMapping[]} */
          center: [],
          /** @type {LobTemplateFieldMapping[]} */
          right: [],
          /** @type {LobTemplateFieldMapping[]} */
          schedule: [],
          /** @type {LobTemplateFieldMapping[]} */
          additional: [],
          /** @type {LobTemplateFieldMapping[]} */
          bottom: [],
          /** @type {LobTemplateFieldMapping[]} */
          internal: [],
        }
      );
    },
    [lobMappings, mode]
  );

  const addAdditionalIndicators = useCallback(
    /**
     * @param {LobTemplateFieldMapping[]} mappedValues
     * @param {PolicyCoverageEntity[]} unassignedCoverages
     * @param {string} lobSymbol
     * @param {boolean} isOverflow
     */
    (mappedValues, unassignedCoverages, lobSymbol, isOverflow) => {
      const additionalIndicators = [];

      const addlInsd = mappedValues.find(value => value.family === 'ADDL_INSD');
      /**
       * Added the additional condition due to
       * https://certificate-hero.atlassian.net/browse/CHST-1622
       */

      if (!addlInsd && !(lobSymbol === 'WC' && isOverflow)) {
        const value = unassignedCoverages.find(
          uc => uc.name === 'addlInsd'
        )?.value;

        additionalIndicators.push({
          id: v4(),
          type: INDICATOR,
          name: 'addlInsd',
          label: 'Additional Insured',
          value,
          editable: true,
          lineStyle: coverageLineStyles.MULTI_LABEL,
          flowStyle: coverageFlowStyles.CERT_ONLY,
        });
      }

      const waiverOfSubBro = mappedValues.find(
        val => val.family === 'WVR_OF_SUBRO'
      );

      if (!waiverOfSubBro) {
        const value = unassignedCoverages.find(
          uc => uc.name === 'waiverOfSubroBlkt'
        )?.value;

        additionalIndicators.push({
          id: v4(),
          type: INDICATOR,
          name: 'waiverOfSubroBlkt',
          label: 'Waiver of Subrogation',
          value,
          editable: true,
          lineStyle: coverageLineStyles.MULTI_LABEL,
          flowStyle: coverageFlowStyles.CERT_ONLY,
          isEndOfLine: true,
        });
      }

      return additionalIndicators;
    },
    []
  );

  const BUILDING_INDICATOR = 'buildingCoverageInd';
  const BPP_INDICATOR = 'personalBuildingCoverageInd';
  const LIMIT_FIELD = 'commercialPropertyLimitA';
  const DEDUCTIBLE_FIELD = 'commercialPropertyDeductibleA';

  const combineMappingsAndValues = useCallback(
    /**
     * @param {LobTemplateFieldAreas} fieldMappings
     * @param {PolicyCoverageEntity[]} coverages
     * @param {PolicyCoverageEntity[]} amsCoverages
     * @param {string} lobSymbol
     * @param {boolean} isOverflow
     * @param {string} formId
     */
    (fieldMappings, coverages, amsCoverages, lobSymbol, isOverflow, formId) => {
      let unassignedCoverages = coverages;
      const formAreas = [
        TOP,
        LEFT,
        RIGHT,
        CENTER,
        ADDITIONAL_SCHEDULES,
        ADDITIONAL,
        BOTTOM,
        'internal',
      ];
      const mappedStructuredValues = formAreas.map(fieldPosition => {
        const results = mapStructuredFieldsToValue(
          fieldMappings[fieldPosition],
          unassignedCoverages,
          amsCoverages
        );
        unassignedCoverages = results.unassignedCoverages;
        return results.structuredMappings;
      });

      const lobType = findLobTypeBySymbol(lobSymbol);

      const mappedValues = formAreas.map((_, index) => {
        const mappedStructuredValueSet = mappedStructuredValues[index];

        const results = mapFreeformValues(
          mappedStructuredValueSet,
          unassignedCoverages,
          amsCoverages,
          lobType
        );

        unassignedCoverages = results.unassignedCoverages;
        return results.allMappings;
      });

      const fullyMappedValues = {
        top: mappedValues[0],
        left: mappedValues[1],
        right: mappedValues[2],
        center: mappedValues[3],
        schedule: mappedValues[4],
        additional: mappedValues[5],
        bottom: mappedValues[6],
        internal: mappedValues[7],
      };

      // acord 28 has some additional fields that live outside the form... in the internal section
      // @todo - set the position of these fields to "internal" so we don't need to check the form ID
      //         and push this logic somewhere else
      if (formId === 'Acord 28') {
        // get any ams values for building and personal building coverage
        const internalAmsValues =
          amsCoverages.find(c => c.name === 'ch-internal')?.value || {};

        const buildingIndCoverage = coverages.find(
          c => c.name === BUILDING_INDICATOR
        );

        const bppIndCoverage = coverages.find(c => c.name === BPP_INDICATOR);

        let limit = '';
        let deductible = '';

        if (buildingIndCoverage) {
          limit = internalAmsValues.building?.buildingCoverageAmount ?? '';
          deductible =
            internalAmsValues.building?.buildingCoverageDeductible ?? '';
        } else if (bppIndCoverage) {
          limit = internalAmsValues.bpp?.personalBuildingCoverageAmount ?? '';
          deductible =
            internalAmsValues.bpp?.personalBuildingCoverageDeductible ?? '';
        }

        const buildingCoverageInd = {
          id: 'internal-buildingCoverageInd',
          name: BUILDING_INDICATOR,
          label: 'Building',
          section: 'internal',
          value: buildingIndCoverage?.value,
          amsValue: internalAmsValues.building || {},
          flowStyle: coverageFlowStyles.MANAGER_ONLY,
        };

        const personalBuildingCoverageInd = {
          id: 'internal-personalBuildingCoverageInd',
          name: BPP_INDICATOR,
          label: 'Business Personal Property',
          section: 'internal',
          value: bppIndCoverage?.value,
          amsValue: internalAmsValues.bpp || {},
          flowStyle: coverageFlowStyles.MANAGER_ONLY,
        };

        fullyMappedValues.internal.push(
          buildingCoverageInd,
          personalBuildingCoverageInd
        );

        mappedValues.flat().forEach(mapping => {
          if (mapping.name === LIMIT_FIELD) {
            mapping.amsValue = String(limit || '');
            mapping.value = String(limit || '');
          }
          if (mapping.name === DEDUCTIBLE_FIELD) {
            mapping.amsValue = String(deductible || '');
            mapping.value = String(deductible || '');
          }
        });
      }

      const remainingAdditionalCoverages = unassignedCoverages.filter(
        uc =>
          ![
            'addlInsd',
            'waiverOfSubroBlkt',
            BUILDING_INDICATOR,
            BPP_INDICATOR,
          ].includes(uc.name)
      );

      if (remainingAdditionalCoverages.length !== 0) {
        if (mode === lobFormModes.CERTIFICATE) {
          const flatMappedValues = mappedValues.flat();

          const additionalIndicators = addAdditionalIndicators(
            flatMappedValues,
            unassignedCoverages,
            lobSymbol,
            isOverflow
          );

          if (additionalIndicators.length) {
            additionalIndicators.forEach(indicator =>
              fullyMappedValues.additional.push(indicator)
            );
          }
        }

        remainingAdditionalCoverages.forEach(coverage => {
          const covType = lobType.coverages.find(
            cov => cov.name === coverage.name
          );

          const amsCoverage = amsCoverages.find(cov =>
            coverage.locationId // if a coverage contains locationId match it with the correct location-specific AMS coverage
              ? coverage.locationId === cov.locationId &&
                cov.name === coverage.name
              : cov.name === coverage.name
          );

          const amsValue = amsCoverage?.value || '';

          if (/^schedule(-\w)?$/.test(coverage.section)) {
            fullyMappedValues.schedule.push({
              ...coverage,
              amsId: coverage?.amsId,
              section: coverage.section,
              name: coverage.name,
              value: coverage.value,
            });
            return;
          }

          // Hack to avoid duplicating schedule 101 coverages
          if (
            coverage.section === 'coverage' &&
            fullyMappedValues.schedule.some(s => s.name === coverage.name)
          ) {
            // skip
            return;
          }

          fullyMappedValues.additional.push({
            id: v4(),
            type: FREE_TEXT,
            name: coverage.name,
            section: coverage.section,
            amsId: coverage?.amsId,
            locationId: coverage?.locationId,
            label: covType?.label || coverage.name,
            value: coverage.value,
            amsValue,
            editable: coverage.editable,
            lineStyle: coverageLineStyles.SINGLE,
          });
        });
      }

      return fullyMappedValues;
    },
    [addAdditionalIndicators, mode]
  );

  return { getFieldsForLob, combineMappingsAndValues };
};
