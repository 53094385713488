import { ContractCoverageEntity } from '@/entities/contract-coverage-entity';

export class ContractLobEntity {
  constructor({ lobSymbol, name, sectionName, sectionText, coverages }) {
    this.lobSymbol = lobSymbol;
    this.name = name;
    this.sectionName = sectionName;
    this.sectionText = sectionText;
    this.coverages = coverages.map(c => new ContractCoverageEntity(c));
  }
}
