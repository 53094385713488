export const MAPPING_ACTIONS = Object.freeze(
  /** @type {const} */ ({
    MAP: 'map',
    DROP: 'drop',
    CLONE: 'clone',
    CREATE: 'create',
    CLEAR: 'clear',
    ADD: 'add',
    REMOVE: 'remove',
    EDIT: 'edit',
  })
);

export const ENDORSEMENT_MAPPING_DESCRIPTIONS = Object.freeze(
  /** @type {const} */ ({
    CARRY_FORWARD: 'CARRY FORWARD',
    DROP_OFF: 'DROP OFF',
    DROP: 'DROP',
    MAP: 'MAP',
  })
);
