import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';

import useSWR from 'swr';
import { route } from 'nextjs-routes';
import { useRenewal } from './use-renewal';
import { useStore } from './use-store';

/**
 * @param {object} params
 * @param {string[]|null} [params.formIds=null] - To get Lob Templates filtered by formIds if it's special
 * @param {boolean} [params.enableAllForms=false] - To get non-standard forms
 */

export const useLobTemplates = ({
  formIds = [],
  enableAllForms = false,
} = {}) => {
  const [{ insuredId }] = useStore();
  const { onRenewalPage } = useRenewal();

  /**
   * @type {SWRResponse<LobTemplateEntity[]>}
   */
  const { data, isValidating, mutate } = useSWR(
    insuredId
      ? [
          route({ pathname: '/api/v2/lob_templates/with_policy' }),
          insuredId,
          formIds,
          onRenewalPage,
        ]
      : null,
    async ([url, insuredIdQuery, formIdQuery, isRenewing]) => {
      const res = await FrontendHttpClient.get(url, {
        insuredId: insuredIdQuery,
        isRenewal: isRenewing,
        enableAllForms,
        formIds:
          (Array.isArray(formIdQuery) && formIdQuery.filter(Boolean)) || [],
      });

      return res?.data;
    }
  );

  return { lobs: data, isValidating, mutate };
};
