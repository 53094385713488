import { forwardRef } from 'react';
import { Field } from 'formik';
import cx from 'clsx';

import Form from 'react-bootstrap/Form';
import Label from '@/components/label';
import styles from './styles.module.scss';

/**
 * @param {object} params
 * @param {string} params.id
 * @param {string} params.label
 * @param {boolean} params.disabled
 * @param {string} params.className
 * @param {string} params.containerClassName
 * @param {object} params.containerStyle
 * @returns {JSX.Element}
 */
const Checkbox = forwardRef((props, ref) => {
  const {
    id,
    subText,
    label,
    disabled,
    className,
    labelClassName,
    containerClassName,
    containerStyle,
    ...fieldProps
  } = props;

  return (
    <div
      className={cx(styles.checkbox, containerClassName)}
      style={containerStyle}
    >
      {subText ? (
        <Form.Text htmlFor={id} muted>
          {subText}
        </Form.Text>
      ) : null}
      <label className={cx(styles.inner, disabled && styles.disabled)}>
        <Field
          id={id}
          ref={ref}
          type="checkbox"
          disabled={disabled}
          className={cx(styles.input, className)}
          {...fieldProps}
        />

        {label ? (
          <Label htmlFor={id} className={cx(styles.label, labelClassName)}>
            {label}
          </Label>
        ) : null}
      </label>
    </div>
  );
});

export default Checkbox;
