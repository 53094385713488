import cx from 'clsx';

import styles from './styles.module.scss';

/**
 * @param {object} params
 * @param {object} params.user
 * @param {number} params.size
 * @param {string} params.className
 */
const Avatar = ({ user, size = 32, className }) => {
  const firstInitial = user?.firstName?.slice(0, 1) || 'P';
  const fontSize = Math.floor(size * (size > 50 ? 0.4 : 0.5)) - 2;
  const borderRadius = Math.ceil(size * 0.5);

  return (
    <div
      className={cx(styles.avatar, className)}
      style={{ fontSize, borderRadius, width: size, height: size }}
      id="user-info"
      data-id={user?.id}
      data-firstname={user?.firstName}
      data-lastname={user?.lastName}
      data-usertype={user?.userType}
      data-email={user?.email}
    >
      {firstInitial}
    </div>
  );
};

export default Avatar;
