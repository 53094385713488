import { createContext } from 'react';

/** @type {Context<useStateReturn<LobTemplateEntity>>} */
export const LobTemplateContext = createContext([{}, () => null]);

/** @type {Context<useStateReturn<{ name: string, coverage: string, type: string }>>} */
export const ErrorContext = createContext([{}, () => null]);

/** @type {Context<[{oldTemplateId: string, newTemplateId: string}[], (oldTemplateId: string, newTemplateId: string) => void]>} */
export const LobTemplateMappingContext = createContext([{}, () => null]);

export const LobTypeContext = createContext([null, () => null]);
