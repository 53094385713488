const download = (filename, data) => {
  const url = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

/**
 * @param {Res} httpResponse
 */
export const downloadFile = httpResponse => {
  const [, filename] =
    httpResponse.headers['content-disposition'].split('filename=');
  download(filename, httpResponse.data);
};

export const downloadFromURL = async (url, filename) => {
  const response = await fetch(url);

  const header = response.headers.get('content-disposition');

  const filenameRes = header?.split('filename=')[1];

  const blob = await response.blob();
  download(filename || filenameRes, blob);
};
