import { forwardRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import cx from 'clsx';

import Label from '@/components/label';
import globalStyles from '@/styles/globals.module.scss';
import styles from './styles.module.scss';

/**
 * @typedef {object} Props
 * @property {string} id
 * @property {string} name
 * @property {string} value
 * @property {string} label
 * @property {boolean} isInvalid
 * @property {string} error
 * @property {() => void} onChange
 * @property {string} containerClassName
 * @property {object} containerStyle
 * @property {string} className
 * @property {object} style
 * @property {number} maxLimit
 * @property {Function} onBlur
 */

/**
 * @type {React.ForwardRefExoticComponent<Props>}
 */
const TextArea = forwardRef((props, ref) => {
  const {
    id,
    name,
    value,
    label,
    isInvalid,
    error,
    onChange,
    containerClassName,
    containerStyle,
    className,
    style,
    maxLimit,
    onBlur,
    ...rest
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    setIsFocused(false);
    if (onBlur && typeof onBlur === 'function') {
      onBlur();
    }
  };

  const [internalError, setInternalError] = useState('');
  const internalOnChange = e => {
    if (e.target.value.length > maxLimit) {
      e.target.value = e.target.value.slice(0, maxLimit);
      onChange(e);
      setInternalError(`Must be ${maxLimit} characters or less`);
    } else {
      if (internalError) {
        setInternalError('');
      }
      onChange(e);
    }
  };

  return (
    <div
      className={cx(styles.container, containerClassName)}
      style={containerStyle}
    >
      {label ? (
        <Label htmlFor={name} className={styles.label}>
          {label}
        </Label>
      ) : null}
      <div
        className={cx(
          globalStyles.row,
          styles.border,
          isFocused && styles.focused,
          props.disabled && globalStyles.disabled,
          props.disabled && styles.disabled,
          props.isInvalid && styles.invalid,
          className
        )}
      >
        <Form.Control
          as="textarea"
          ref={ref}
          id={id}
          name={name || id}
          value={value}
          className={cx(styles.input, isFocused && styles.focused)}
          style={style}
          isInvalid={isInvalid}
          onChange={internalOnChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...rest}
        />
      </div>
      {isInvalid && error ? (
        <Label className={styles.error}>{error}</Label>
      ) : null}
      {!(isInvalid && error) && internalError && (
        <Label className={styles.error}>{internalError}</Label>
      )}
    </div>
  );
});

export default TextArea;
