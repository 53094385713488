import { PolicyCoverageEntity } from './policy-coverage-entity';

export class BulkEditLobEntity {
  constructor({
    action = '',
    policyNumber = '',
    policyId = '',
    policyDescription = '',
    id = '',
    name = '',
    code = '',
    coverages = [],
  }) {
    this.action = action;
    this.policyNumber = policyNumber;
    this.policyId = policyId;
    this.policyDescription = policyDescription;
    this.id = id;
    this.name = name;
    this.code = code;
    this.coverages = coverages.map(
      coverage => new PolicyCoverageEntity(coverage)
    );
  }
}
