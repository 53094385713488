import { useState } from 'react';
import cx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import Dropdown from '@/components/dropdown';
import MenuSvg from '@/assets/icons/menu.svg';

import globalStyles from '@/styles/globals.module.scss';
import styles from './styles.module.scss';

/**
 * @param {object} params
 * @param {RowNode} params.node
 * @param {any} params.context
 * @param {GridApi} params.api
 */
const ActionMenuRenderer = ({ node, context, api }) => {
  const {
    getMenuOptions,
    menuOptions: selectedRowOptions,
    setOpenMenuRowNode,
  } = context;

  const [openRowId, setOpenRowId] = useState(null);

  const onToggle = rowNode => event => {
    if (event) {
      event.stopPropagation();
    }

    api.applyTransaction({ updateRowData: { update: [node.data] } });
    setOpenRowId(rowNode.data?.id);
    setOpenMenuRowNode(rowNode);
  };

  const onClose = _node => event => {
    if (event) {
      event.stopPropagation();
    }

    setOpenRowId(null);
    setOpenMenuRowNode(null);
  };

  const { data } = node;

  if (!data) {
    return null;
  }

  const isDisabled = node.gridApi?.getSelectedRows().length > 1;
  const isOpen = !!(!isDisabled && openRowId && openRowId === node.data.id);
  // Menu options must be declared after "data" is confirmed to exist
  const menuOptions =
    selectedRowOptions || (getMenuOptions ? getMenuOptions(node) : []);

  return (
    <>
      {!isOpen ? (
        <button
          type="button"
          id={`actionMenu-btn-${node.rowIndex}`}
          onClick={onToggle(node)}
          disabled={isDisabled}
          className={cx(
            globalStyles.buttonReset,
            styles.actionButton,
            isDisabled && globalStyles.disabled
          )}
        >
          <MenuSvg className={styles.actionMenuSvg} />
        </button>
      ) : (
        <Dropdown
          show
          align="end"
          drop="down"
          options={menuOptions}
          onClose={onClose(node)}
          onToggle={onClose(node)}
          id={`actionMenu-drop-${node.rowIndex}`}
          placeholder={<FontAwesomeIcon icon={faTimes} />}
          menuClassName={styles.actionMenu}
          caretClassName={styles.actionMenuCaret}
          toggleClassName={styles.actionMenuToggle}
          onSelect={() => api.redrawRows()}
        />
      )}
    </>
  );
};

export default ActionMenuRenderer;
