import { EndorsementIssueEntity } from './endorsement-issue-entity';
import { IssueEntity } from './issue-entity';

export class BulkIssuesEntity {
  constructor({
    certificateId = '',
    endorsementIssues = [],
    policyIssues = [],
    certIssues = [],
    contractIssues = [],
  }) {
    this.certificateId = certificateId;
    this.endorsementIssues = endorsementIssues.map(
      issue => new EndorsementIssueEntity(issue)
    );

    this.certIssues = certIssues;

    this.policyIssues = policyIssues.map(
      issue =>
        new IssueEntity({
          id: issue.id,
          field: issue.field,
          type: issue.type,
          old: issue.old,
          newValue: issue.new,
        })
    );

    this.contractIssues = contractIssues.map(
      issue =>
        new IssueEntity({
          id: issue.id,
          field: issue.field,
          type: issue.type,
          old: issue.old,
          newValue: issue.new,
        })
    );

    this.hasIssues =
      this.endorsementIssues.length !== 0 ||
      this.policyIssues.length !== 0 ||
      this.certIssues.length !== 0 ||
      this.contractIssues.length !== 0;
  }

  /**
   * @param {'policyIssues' | 'contractIssues' | 'endorsementIssues'} type
   * @returns {string}
   */
  getFormattedIssues(type) {
    return this[type]?.map(issue => `    ${issue.toString()}`).join(',\n');
  }

  formatPolicyIssues() {
    const issueDescriptions = this.getFormattedIssues('policyIssues');
    return issueDescriptions.length !== 0
      ? `\n  Non-compliant with previous certificate:\n${issueDescriptions}`
      : '';
  }

  formatCertIssues() {
    const issueDescriptions = this.getFormattedIssues('certIssues');
    return issueDescriptions.length !== 0 ? `\n ${issueDescriptions}` : '';
  }

  formatContractIssues() {
    const issueDescriptions = this.getFormattedIssues('contractIssues');
    return issueDescriptions.length !== 0
      ? `\n  Non-compliant with contract:\n${issueDescriptions}`
      : '';
  }

  formatEndorsementIssues() {
    const issueDescriptions = this.getFormattedIssues('endorsementIssues');
    return issueDescriptions.length !== 0
      ? `\n  The following issues were found migrating endorsements:\n${issueDescriptions}`
      : '';
  }
}
