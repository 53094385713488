import cx from 'clsx';
import SideBar from '@/components/sidebar';
import Footer from '@/layout/footer';
import Header from '@/layout/header';
import { useHandleRouteChange, useStore } from '@/hooks';
import { useEffect, useRef, useState } from 'react';
import styles from './layout.module.scss';
import { CLIENT_SECRETS } from '@/config/client_secrets';
import CollapseNav from '@/assets/icons/collapse.svg';

export default function MasterLayout({ children }) {
  useHandleRouteChange();

  const [{ broker }] = useStore();
  const { isBranded, headerColor } = broker.branding;
  const [loaded, setLoaded] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const layOutRef = useRef(null);

  useEffect(() => {
    setLoaded(isBranded !== undefined);
  }, [isBranded]);

  useEffect(() => {
    const handleResize = () => {
      if (layOutRef.current) {
        const windowWidth = layOutRef.current.clientWidth;
        setIsSidebarCollapsed(() => windowWidth < 1024);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!loaded) {
    return null;
  }

  const showExpandedInsuredHeaderBar =
    isBranded && CLIENT_SECRETS.TOP_BRANDING_URL;

  return (
    <>
      <div
        className={cx(
          styles.insuredHeaderBar,
          isBranded ? '' : 'd-none',
          showExpandedInsuredHeaderBar && styles.expandedInsuredHeaderBar
        )}
        style={{
          backgroundColor: headerColor,
          position: 'fixed',
          width: '100%',
        }}
      >
        {showExpandedInsuredHeaderBar && (
          <div className={styles.insuredLogoContainer}>
            <img
              className={styles.insuredLogo}
              src={CLIENT_SECRETS.TOP_BRANDING_URL}
              alt="insured"
            />
          </div>
        )}
      </div>
      <div
        role="button"
        tabIndex={0}
        className={cx(
          styles.navButton,
          isSidebarCollapsed && styles.collapseNavButton,
          showExpandedInsuredHeaderBar && styles.expandedInsuredNavButton
        )}
        onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
      >
        <CollapseNav />
      </div>
      <div className={styles.outer} ref={layOutRef}>
        <div
          className={cx(
            styles.sidebarColumn,
            isBranded ? styles.insuredBranding : '',
            showExpandedInsuredHeaderBar && styles.expandedInsuredBranding,
            isSidebarCollapsed && styles.collapsedSidebar
          )}
        >
          <SideBar
            isSidebarCollapsed={isSidebarCollapsed}
            setIsSidebarCollapsed={setIsSidebarCollapsed}
          />
        </div>
        <div
          className={cx(
            styles.mainColumn,
            isBranded ? styles.insuredBranding : '',
            showExpandedInsuredHeaderBar && styles.expandedInsuredBranding,
            isSidebarCollapsed && styles.collapsedMainColumn
          )}
        >
          <Header isSidebarCollapsed={isSidebarCollapsed} />
          <div
            className={cx(
              styles.inner,
              isBranded ? styles.insuredBranding : '',
              showExpandedInsuredHeaderBar && styles.expandedInsuredBranding
            )}
          >
            {children}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
