import { useEffect } from 'react';

/**
 * A hook that allows different browser tabs to communicate with each other
 * @param {string} channelName The name of the channel to use
 * @param {(event: MessageEvent) => void} [onMessage] A callback to run when a message is received
 */
export const useBroadcastChannel = (channelName, onMessage) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const channel = new BroadcastChannel(channelName);

  useEffect(() => {
    if (onMessage) {
      channel.onmessage = onMessage;
    }

    return () => {
      channel.close();
    };
  }, [channel, onMessage]);

  return channel;
};
