import { ACTIVE_STATUS } from '@/constants';
import { PolicyCoverageEntity } from './policy-coverage-entity';
import { PolicyEntity } from './policy-entity';

export class LobTemplateEntity {
  constructor({
    id,
    policyId = '',
    lobSymbol = '',
    formId = '',
    lobTypeName = '',
    name = '',
    unmappedCoverages = [],
    unmappedLocation = '',
    displayName = '',
    displayDescription = '',
    coverages = [],
    addresses = [],
    policy = {},
    isDefault = false,
    status = ACTIVE_STATUS.active,
    parentLobId = '',
    differences = [],
    isRenewing = false,
    isProcessed = true,
    certCoverages,
  }) {
    this.id = id;
    this.policyId = policyId;
    this.lobSymbol = lobSymbol;
    this.formId = formId;
    this.lobTypeName = lobTypeName;
    this.name = name;
    this.policy = new PolicyEntity(policy);
    this.displayName = displayName;
    this.displayDescription = displayDescription;
    this.unmappedCoverages = unmappedCoverages;
    this.unmappedLocation = unmappedLocation;
    this.coverages = coverages.map(
      coverage => new PolicyCoverageEntity(coverage)
    );
    this.addresses = addresses;
    this.isDefault = isDefault;
    this.status = status;
    this.parentLobId = parentLobId;
    this.differences = differences;
    this.isRenewing = isRenewing;
    this.isProcessed = isProcessed;
    this.certCoverages = certCoverages;
  }
}
