import axios from 'axios';
import { attempt } from '@/utils/attempt';
import BaseHttpClient from '@/utils/http-handler/base-http-client';

/**
 * @param {import('axios').AxiosError|Error} error
 */
const handleError = async error => {
  if (!axios.isAxiosError(error) || !error.response) {
    throw error;
  }

  if (
    error.response.status === 401 &&
    error.response.data.name === 'InvalidUserError'
  ) {
    // Need to use location.href instead of router.push()
    // eslint-disable-next-line no-restricted-globals
    location.href = '/?isSessionExpired=true';
    await new Promise(resolve => setTimeout(resolve, 3000));
  }

  throw error;
};

const getExtensionFromContentType = contentType => {
  if (typeof contentType !== 'string') {
    return '.unknown';
  }

  // Remove any parameters from the content type
  const type = contentType.split(';')[0].trim();

  switch (type) {
    case 'image/jpeg':
      return 'jpg';
    case 'image/png':
      return 'png';
    case 'image/gif':
      return 'gif';
    case 'application/pdf':
      return 'pdf';
    case 'text/plain':
      return 'txt';
    case 'application/msword':
      return 'doc';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/vnd.ms-excel':
      return 'xls';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'application/vnd.ms-powerpoint':
      return 'ppt';
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'pptx';
    default:
      return 'unknown';
  }
};

/** @type {BaseHttpClient} */
const FrontendHttpClient = {
  get: async (url, queryParams) => {
    const [error, res] = await attempt(BaseHttpClient.get(url, queryParams));
    if (error) {
      await handleError(error);
    }

    return res;
  },
  post: async (url, body, opts) => {
    const [error, res] = await attempt(BaseHttpClient.post(url, body, opts));
    if (error) {
      await handleError(error);
    }

    return res;
  },
  put: async (url, body, opts) => {
    const [error, res] = await attempt(BaseHttpClient.put(url, body, opts));
    if (error) {
      await handleError(error);
    }

    return res;
  },
  delete: async url => {
    const [error, res] = await attempt(BaseHttpClient.delete(url));
    if (error) {
      await handleError(error);
    }

    return res;
  },
  /**
   * FrontendHttpClient file download
   * @param {string} url
   * @param {string} fileName
   */

  download: async (url, fileName) => {
    const [error, response] = await attempt(
      axios.get(url, {
        responseType: 'blob',
      })
    );

    if (error) {
      await handleError(error);
    }
    let contentType = response?.headers?.['content-type'];
    let extension = '';

    // Remove query parameters and hash fragments from the URL
    const urlWithoutParams = url.split(/[?#]/)[0];
    if (!fileName) {
      const urlPieces = urlWithoutParams.split('/');
      fileName = urlPieces[urlPieces.length - 1];
    }

    if (contentType === 'application/octet-stream') {
      const urlParts = urlWithoutParams.split('.');
      if (urlParts.length > 1) {
        extension = urlParts[urlParts.length - 1];
      }
    } else {
      extension = getExtensionFromContentType(contentType);
    }

    const updatedFileName = fileName.endsWith(`.${extension}`)
      ? fileName
      : `${fileName}.${extension}`;

    const blob = new Blob([response?.data]);

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = updatedFileName;
    link.click();

    return updatedFileName;
  },
};

export default FrontendHttpClient;
