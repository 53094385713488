import NotificationService from '@/components/notification-service';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { route } from 'nextjs-routes';
import { useCallback } from 'react';
import useSWR from 'swr';

export const useEmailTemplates = () => {
  const swr = useSWR(
    route({ pathname: '/api/v2/email_templates' }),
    async url => {
      const res = await FrontendHttpClient.get(url);
      return res.data.result;
    }
  );

  const { data: emailTemplates, mutate } = swr;

  const getDefaultTemplateByRole = useCallback(
    role => {
      if (!emailTemplates) {
        return null;
      }

      return emailTemplates.find(t => t.role === role);
    },
    [emailTemplates]
  );

  const update = useCallback(
    async (id, newData) => {
      try {
        await FrontendHttpClient.put(
          route({
            pathname: '/api/v2/email_templates/[id]',
            query: { id },
          }),
          { values: newData }
        );
        await mutate();
      } catch (err) {
        NotificationService.error('Error updating new template');
        return;
      }
      NotificationService.success('Template updated');
    },
    [mutate]
  );

  return {
    ...swr,
    getDefaultTemplateByRole,
    update,
  };
};
