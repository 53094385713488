import { createContext } from 'react';

export const REVIEW_CONTEXT = Object.freeze(
  /** @type {const} */ ({
    DEFAULT: 'DEFAULT',
    UPDATE: 'UPDATE',
  })
);

export const defaultReviewContext = {
  hasContract: false,
  contractUrl: '',
  contractLoading: false,
  contract: {},
  certNoteChanged: false,
  insuredNoteChanged: false,
  isCertificateCompliant: true,
  allowedTabNames: [],
  columnSizes: [100],
  currentTabSizes: [100],
  columnViews: [
    {
      columnKey: 'certificate',
      selectedColumn: 'certificate',
    },
  ],
  highlighting: {
    quickFinds: '',
    highlightValues: [],
    jumpAreas: [],
    jumpAreaCount: 0,
  },
};

export const reviewReducer = (state, { type, payload }) => {
  switch (type) {
    case REVIEW_CONTEXT.DEFAULT:
      return defaultReviewContext;
    case REVIEW_CONTEXT.UPDATE:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const ReviewContext = createContext([defaultReviewContext, () => null]);
