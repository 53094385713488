import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useStore } from './use-store';

/**
 * A hook which returns formik and set unsavedChangesExist when form is changed
 * @template T
 * @param {import('formik').FormikConfig<T>} params
 */
export function useFormikWithUnsavedChanges({ formName, onSubmit, ...params }) {
  const [{ unsavedChangesExist }, setStore] = useStore();
  const form = useFormik({
    ...params,
    onSubmit(values, formikBag) {
      setStore({ unsavedChangesExist: { [formName]: false } });
      onSubmit(values, formikBag);
    },
  });

  useEffect(() => {
    if (form.dirty && !unsavedChangesExist[formName]) {
      setStore({ unsavedChangesExist: { [formName]: true } });
    }

    if (!form.dirty && unsavedChangesExist[formName]) {
      setStore({ unsavedChangesExist: { [formName]: false } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.dirty]);
  // We only want this to trigger if this form updates
  // If we have unsavedChangesExist in the dependencies,
  // then if we have multiple forms A and B and we edit A:
  //  Form A is dirty --> Form A sets unsavedChangesExist to true
  // this triggers the form B useEffect:
  //  Form B is clean --> Form B sets unsavedChangesExist to false
  // this triggers the form A useEffect:
  //  Form A is dirty --> Form A sets unsavedChangesExist to true ...and so on

  return form;
}
