import { STATUS } from '@/constants';

export class CIDocumentEntity {
  constructor({
    id,
    brokerId = '',
    insuredId = '',
    name = '',
    termStart = '',
    termEnd = '',
    fileName = '',
    status = STATUS.active,
    isDefault = false,
    deactivatedDate = null,
    createdAt = null,
    updatedAt = null,
  }) {
    this.id = id;
    this.brokerId = brokerId;
    this.insuredId = insuredId;
    this.name = name;
    this.termStart = termStart;
    this.termEnd = termEnd;
    this.fileName = fileName;
    this.status = status;
    this.isDefault = isDefault;
    this.deactivatedDate = deactivatedDate;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
