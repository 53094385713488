import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { useCallback, useMemo, useState } from 'react';
import useSWR from 'swr';
import { useStore } from './use-store';

export const EMPTY = {
  totalCount: 0,
  pageCount: 0,
  records: [],
};

export const usePagination = ({
  remoteDataEndpoint,
  remoteDataParams,
  remoteDataCallback,
  pageIndex,
  filterInsured,
  pageSize,
  setRowData,
  setPending,
  searchInput,
  columnApi,
  filterModel,
}) => {
  const [{ insuredId }] = useStore();

  const [sortModel, setSortModel] = useState();

  // AG Grid sets the sortModel to an empty array when the user clears the sort
  // we need to hang onto the original sortModel to restore it when the user clears the sort
  const initialSort = useMemo(() => {
    const sort = columnApi
      ?.getAllColumns()
      .filter(col => col.getColDef().sort)
      .map(col => ({ colId: col.getColId(), sort: col.getColDef().sort }));
    // set the sort model if it does not already exist
    setSortModel(prev => prev || sort);
    return sort;
  }, [columnApi]);

  const onSortChanged = useCallback(() => {
    if (!columnApi) {
      return;
    }
    const model = columnApi
      .getColumnState()
      .filter(col => col.sort)
      .map(col => ({
        colId: col.colId,
        sort: col.sort,
      }));

    // if the sort model is empty... then we need to restore the initial sort
    setSortModel(model.length ? model : initialSort);
  }, [columnApi, initialSort]);

  const columnNames = useMemo(() => {
    if (columnApi) {
      return columnApi.getColumnState().map(col => col.colId);
    }
    return null;
  }, [columnApi]);

  async function fetchPageData(
    url,
    page,
    size,
    sort,
    filter,
    input,
    colNames,
    id,
    dataParams
  ) {
    const response = await FrontendHttpClient.post(url, {
      startRow: page * size - size,
      endRow: page * size,
      sortModel: sort,
      filterModel: filter,
      searchInput: input,
      columnNames: colNames,
      dataParams: {
        ...(filterInsured && { insuredId: id }),
        ...(dataParams && { ...dataParams }),
      },
    });

    if (remoteDataCallback && response.data?.records) {
      remoteDataCallback(response.data.records);
    }

    return response.data;
  }

  const { data = EMPTY, mutate } = useSWR(
    insuredId && sortModel && filterModel && columnNames && remoteDataEndpoint
      ? [
          remoteDataEndpoint,
          insuredId,
          pageIndex,
          sortModel,
          filterModel,
          pageSize,
          searchInput,
          columnNames,
          remoteDataParams,
        ]
      : null,
    async ([
      url,
      id,
      page,
      sort,
      filter,
      size,
      input,
      colNames,
      dataParams,
    ]) => {
      setPending(true);

      return fetchPageData(
        url,
        page,
        size,
        sort,
        filter,
        input,
        colNames,
        id,
        dataParams
      );
    },
    {
      onSuccess: swrData => {
        setRowData(swrData);
        setPending(false);
      },
    }
  );

  // This SWR call is to prefetch and cache the next page
  useSWR(
    insuredId &&
      sortModel &&
      filterModel &&
      columnNames &&
      remoteDataEndpoint &&
      // if there are more pages
      data.pageCount > pageIndex
      ? [
          remoteDataEndpoint,
          insuredId,
          pageIndex + 1,
          sortModel,
          filterModel,
          pageSize,
          searchInput,
          columnNames,
          remoteDataParams,
        ]
      : null,
    async ([
      url,
      id,
      page,
      sort,
      filter,
      size,
      input,
      colNames,
      dataParams,
    ]) => {
      return fetchPageData(
        url,
        page,
        size,
        sort,
        filter,
        input,
        colNames,
        id,
        dataParams
      );
    }
  );

  return {
    data,
    mutate,
    onSortChanged,
  };
};
