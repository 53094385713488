import { CONTRACT_STATUS } from '@/constants';
import { v4 as uuidv4 } from 'uuid';
import { ContractLobEntity } from '@/entities/contract-lob-entity';
import { ContractCommonCoverageEntity } from '@certificate_hero/entities';

export class ContractEntity {
  constructor({
    id = uuidv4(),
    certId,
    highlightFileKeyPath,
    contractKeyPath,
    searchablePdfKeyPath,
    aiFileId,
    ocrCheck,
    parsingComplete,
    insuranceSectionText,
    lobs = [],
    commonCoverages = [],
    status = CONTRACT_STATUS.active,
    createdAt = new Date(),
    updatedAt = new Date(),
  }) {
    this.id = id;
    this.certId = certId;
    this.highlightFileKeyPath = highlightFileKeyPath;
    this.contractKeyPath = contractKeyPath;
    this.searchablePdfKeyPath = searchablePdfKeyPath;
    this.aiFileId = aiFileId;
    this.ocrCheck = ocrCheck;
    this.parsingComplete = parsingComplete;
    this.insuranceSectionText = insuranceSectionText;
    this.lobs = lobs.map(lob => new ContractLobEntity(lob));
    this.commonCoverages = commonCoverages.map(
      commonCoverage => new ContractCommonCoverageEntity(commonCoverage)
    );
    this.status = status;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
