import crypto from 'crypto';
/**
 * @param {string} string
 * @param {number} maxLength
 */
export const truncateString = (string = '', maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}...` : string;

/**
 * @param {string} string
 */
export const uppercaseFirstChar = (string = '') =>
  string[0].toUpperCase() + string.slice(1);

/**
 * @param {string} keyName
 */
export const prettifyKeyName = (keyName = '') => {
  return keyName
    .split('_')
    .map(string => string[0] + string.substring(1).toLowerCase())
    .join(' ');
};

/**
 * convert new lines to spaces
 * @param {string} str
 */
export const newLinesToSpaces = str => str?.replace(/(\r\n|\n|\r)/gm, ' ');

export const formatLobDropdownOption = option =>
  option?.name +
  (option?.isDefault ? ' (Default)' : '') +
  (option?.policy?.policyName ? ` - ${option?.policy?.policyName}` : '');

/**
 * Generate a random password string, without confusing letters that look like numbers
 * @param {object} [params]
 * @param {number} [params.length = 12]
 * @returns {string}
 */
export function makeHumanReadablePassCode({ length = 12 } = { length: 12 }) {
  const chars = '23456789abcdefghjkmnopqrstuvwxyz!';
  let password = '';

  for (let i = 0; i < length; i++) {
    const randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }

  return password;
}

export const cleanAndTrim = str => (str ? str.replace(/\n/g, ' ').trim() : str);

/**
 * Function that checks for boolean and converts it to string if its a boolean, else it returns the value provided
 * @param {boolean | string} val
 */
export function booleanToString(val) {
  const covertToString = typeof val === 'boolean' ? val.toString() : val;

  return covertToString.toLowerCase();
}

/**
 * Function to interpolate a string with data
 * @example interpolate('Hello {{name}}', { name: 'John' }) // Hello John
 * @param {string} template
 * @param {object} data
 */
export const interpolate = (template, data) =>
  template.replace(/{{\s?([a-zA-Z0-9_]+)\s?}}/g, (_, key) => {
    if (data[key] === undefined) {
      throw Error(`Missing ${key} for interpolation`);
    }
    return data[key];
  });

/**
 * Splits texts that are comma or space delimited into an array.
 * @param {string} text
 * @returns { string[] }
 */
export const splitText = text => {
  return text?.length ? text.split(/[, ]+/).map(x => x.trim()) : [];
};

/**
 * Trims all strings in an object, array or string
 * @param {string | object | Array} data
 */
export const trimAllStrings = data => {
  const jsonString = JSON.stringify(data);
  const trimmedJsonString = jsonString.replace(/"\s+|\s+"/g, '"');
  return JSON.parse(trimmedJsonString);
};

/**
 * @param {number} number
 * @return {string}
 */
export const formatButtonNumber = number => {
  if (!number) {
    return '00';
  }

  if (number === 0 || number < 10) {
    return `0${number}`;
  }

  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(number);
};

/**
 *  Generates a random base62 String based on the input length
 * @param {number} length
 * @returns {string}
 */

export function generateRandomBase62String(length) {
  const base62Chars =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const randomBytes = crypto.randomBytes(length);
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomValue = randomBytes.readUInt8(i);
    result += base62Chars[randomValue % 62];
  }

  return result;
}

export const isEmail = email => {
  return Boolean(
    String(email)
      .trim()
      .match(
        /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
      )
  );
};

export const isNumericString = str => {
  return typeof str === 'string' && /^-?\d+$/.test(str.replace(/,/g, ''));
};

export function normalizeString(str) {
  return str
    .toLowerCase() // Normalize case
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remove accents/diacritics
    .trim() // Remove leading and trailing spaces
    .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
    .replace(/[^\w\s]/g, '') // Remove special characters (except word characters and spaces)
    .replace(/\b(inc|llc|pa|co|trust|md|plc)\b/g, '') // Remove business entities
    .replace(/\bd\/b\/a\b/g, '') // Remove "d/b/a"
    .replace(/\s+and\s+/g, ' ') // Replace "and" with a space to normalize combinations
    .replace(/\s+/g, ' ') // Ensure single space between words after replacements
    .trim();
}

export function normalizeAddressString(str) {
  return str
    .replace(/[^a-zA-Z0-9\s]/g, '') // Remove special characters
    .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
    .trim() // Trim leading/trailing spaces
    .toLowerCase(); // Convert to lowercase
}
