import { InsurerEntity } from './insurer-entity';
import { NamedInsuredEntity } from './named-insured-entity';
import { PolicyLobEntity } from './policy-lob-entity';

export class AmsPolicyEntity {
  constructor({
    id = '',
    policyId = '',
    amsId = '',
    policyNumber = '',
    name = '',
    description = '',
    effectiveDate = new Date(),
    expirationDate = new Date(),
    cancelNonRenewDate = new Date(),
    cancelNonRenewIndicator = '',
    namedInsureds = [],
    insuredId = '',
    insuredName = '',
    insurerId = '',
    insurer = null,
    lobs = [],
    coverageCode = '',
    nextPolicyId = '',
    previousPolicyId = '',
  }) {
    this.id = id;
    /**
     * @deprecated - Use AmsPolicyEntity.id instead
     */
    this.policyId = policyId;
    this.amsId = amsId;
    this.policyNumber = policyNumber;
    this.name = name;
    this.description = description;
    this.effectiveDate = effectiveDate;
    this.expirationDate = expirationDate;
    this.cancelNonRenewDate = cancelNonRenewDate;
    this.cancelNonRenewIndicator = cancelNonRenewIndicator;
    this.namedInsureds = namedInsureds.map(n => new NamedInsuredEntity(n));
    this.insuredId = insuredId;
    this.insuredName = insuredName;
    this.insurerId = insurerId;
    this.insurer = insurer ? new InsurerEntity(insurer) : null;
    this.lobs = lobs.map(lob => new PolicyLobEntity({ ...lob }));
    this.nextPolicyId = nextPolicyId;
    this.previousPolicyId = previousPolicyId;
    this.coverageCode = coverageCode;
  }
}
