import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useRouter } from 'next/router';

import Tooltip from 'react-bootstrap/Tooltip';
import { useStore } from '@/hooks/use-store';
import StatusRenderer from './StatusRenderer';
import styles from './styles.module.scss';

import NotificationService from '@/components/notification-service';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';

/**
 * @param {object} props
 * @param {string|undefined} props.value
 * @param {string} props.valueFormatted
 * @param {OutboundCertificateEntity|undefined} props.data
 */
const FileCabinetStatusRenderer = ({ value, valueFormatted, data }) => {
  const router = useRouter();
  const [{ user }] = useStore();
  const {
    CREATE_REVISIONS: revisionPermission,
    CREATE_RENEWALS: renewalPermission,
  } = user.accessKeys.BULK_JOBS;

  const tooltip = (
    <Tooltip className={styles.inBulkTooltip}>
      In {data?.renewalId ? 'Renewal' : 'Bulk Job'}
    </Tooltip>
  );

  return (
    <>
      <div className={styles.fileCabinetStatusContainer}>
        <StatusRenderer value={value} valueFormatted={valueFormatted} />
      </div>
      <OverlayTrigger
        key={data?.certificateNumber}
        placement="left"
        overlay={tooltip}
        delay={data?.bulkJobId ? 10 : 1000000}
      >
        <div
          onClick={async e => {
            if (data?.renewalId && renewalPermission) {
              NotificationService.info(`Grabbing your renewal data...`);
              await FrontendHttpClient.put('/api/v2/users/insured', {
                insuredId: data.insuredId,
              });

              router.push({
                pathname: `/renew/edit`,
                query: {
                  bulkJobId: data?.bulkJobId,
                },
              });
            } else if (
              !data?.renewalId &&
              data?.bulkJobId &&
              revisionPermission
            ) {
              NotificationService.info(`Grabbing your bulk job data...`);

              await FrontendHttpClient.put('/api/v2/users/insured', {
                insuredId: data.insuredId,
              });
              router.push({
                pathname: `/bulk-job/cert-grid`,
                query: {
                  bulkJobId: data?.bulkJobId,
                },
              });
            }

            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <FontAwesomeIcon
            className={styles.inBulkIcon}
            style={{ visibility: data?.bulkJobId ? 'visible' : 'hidden' }}
            aria-label="In Bulk Job"
            icon={faCalendarAlt}
          />
        </div>
      </OverlayTrigger>
    </>
  );
};

export default FileCabinetStatusRenderer;
