import Dropdown from '@/components/dropdown';

import { MAPPING_ACTIONS } from '@/enums/renewal-mapping-actions';

import { useEndorsements } from '@/hooks/use-endorsements';
import { useMemo, useState } from 'react';
import { DROPDOWN_OPTION_TEXT_ELLIPSIS_CUTOFF_LIMIT } from '@/constants';
import { truncateString } from '@/utils/helpers';

import { useBulkStats } from '@/hooks/use-bulk-stats';
import { RENEWAL_STEPS } from '@/enums/renewal-steps';
import styles from './styles.module.scss';

/**
 *
 * @param {object} params
 * @param {EndorsementEntity} params.oldEndorsement - The endorsement that is being mapped
 * @param {EndorsementMapping[]} params.endorsementMappings
 * @param {(params: EndorsementMapperParams) => void} params.toggleEndorsementMappings
 * @param {()=>{}} params.setSelectedEndorsementId
 * @param {()=>{}} params.setEndorsementToEdit
 * @param {()=>{}} params.setShowModal
 */
export default function EndorsementMapperDropdown({
  oldEndorsement,
  endorsementMappings,
  toggleEndorsementMappings,
  setSelectedEndorsementId,
  setEndorsementToEdit,
  setShowModal,
}) {
  const { endorsementCounts } = useBulkStats({
    step: RENEWAL_STEPS.POLICIES,
  });

  const [isLoading, setIsLoading] = useState(false);

  const certEndorsements = useMemo(
    () =>
      new Set(endorsementCounts.map(endorsementCount => endorsementCount.id)),
    [endorsementCounts]
  );

  const { endorsements: allEndorsements } = useEndorsements();

  // List of endorsements that can be mapped to the current endorsement row
  const targetEndorsementOptions = useMemo(() => {
    if (!oldEndorsement) {
      return [];
    }

    const { policyId: newPolicyId } = oldEndorsement.cloneEndorsement;

    return allEndorsements
      .filter(endorsementOption => {
        return (
          // Filter out endorsements listed on the grid, can't map to itself
          !certEndorsements.has(endorsementOption.id) &&
          // The endorsement must be of the same lob type as the endorsement row
          endorsementOption.endorsementForm.lobTypeId ===
            oldEndorsement.endorsementForm.lobTypeId &&
          // Endorsement must use the new policy we are mapping to
          endorsementOption.policyId === newPolicyId
        );
      })
      .map(endorsement => {
        return {
          id: endorsement.id,
          text: endorsement.name,
          action: MAPPING_ACTIONS.MAP,
          parentId: endorsement.parentEndorsementId,
        };
      });
  }, [allEndorsements, certEndorsements, oldEndorsement]);

  const options = useMemo(
    () => [
      {
        id: MAPPING_ACTIONS.CREATE,
        text: 'Create New Endorsement',
        action: MAPPING_ACTIONS.CREATE,
        element: (
          <div className={styles.dropdownElement}>
            <div className={styles.newTag}>New!</div>
            {`Create New Endorsement`.length >
            DROPDOWN_OPTION_TEXT_ELLIPSIS_CUTOFF_LIMIT - 32
              ? truncateString(
                  `Create New Endorsement`,
                  DROPDOWN_OPTION_TEXT_ELLIPSIS_CUTOFF_LIMIT - 32
                )
              : `Create New Endorsement`}
          </div>
        ),
      },
      ...targetEndorsementOptions,
      {
        divider: true,
      },
      {
        id: MAPPING_ACTIONS.DROP,
        text: 'Drop Endorsement',
        action: MAPPING_ACTIONS.DROP,
        disableMultiSelectCheckbox: true,
      },
    ],
    [targetEndorsementOptions]
  );

  /**
   * @param {object} selection
   * @param {string} selection.id
   * @param {string} selection.action
   * @param {string} selection.text
   */
  const onChange = selection => {
    const { id, action } = selection;
    setIsLoading(true);

    const newEndorsement = oldEndorsement.cloneEndorsement;

    if (action === MAPPING_ACTIONS.CREATE) {
      // set the policy id, so it is populated in the create form
      setEndorsementToEdit({
        policyId: newEndorsement?.policyId || oldEndorsement.policyId,
      });
      setSelectedEndorsementId(oldEndorsement.id);
      setShowModal(true);
    } else if (
      action === MAPPING_ACTIONS.MAP ||
      action === MAPPING_ACTIONS.DROP
    ) {
      toggleEndorsementMappings({
        oldEndorsementId: oldEndorsement.id,
        newEndorsementIds: [id],
      });
    }

    setIsLoading(false);
  };

  const selectedItems = useMemo(() => {
    if (!endorsementMappings || !oldEndorsement) {
      return [];
    }

    const endorsementMapping = endorsementMappings.find(
      mapping => mapping.oldEndorsementId === oldEndorsement.id
    );

    if (!endorsementMapping) {
      return [];
    }

    return endorsementMapping.newEndorsementIds
      .map(newEndorsementId => {
        const foundOption = options.find(
          option => option.id === newEndorsementId
        );
        if (!foundOption) {
          return null;
        }

        return { id: foundOption?.id, text: foundOption?.text };
      })
      .filter(Boolean);
  }, [endorsementMappings, oldEndorsement, options]);

  const setSelectedItems = () => {};

  if (!oldEndorsement) {
    return null;
  }

  return (
    <Dropdown
      id={oldEndorsement.id}
      data-cy="endorsementMapper"
      drop="down"
      align="middle"
      name="endorsementMappingDropdown"
      placeholder="Select Endorsements"
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      multiSelect
      pending={isLoading || !endorsementMappings}
      options={options}
      onChange={onChange}
      containerClassName={styles.dropdown}
    />
  );
}
