import DescriptionField from '@/components/lob-template-forms/description-field';
import FreeformField from '@/components/lob-template-forms/free-form-field';
import IndicatorField from '@/components/lob-template-forms/indicator-field';
import StructuredField from '@/components/lob-template-forms/structured-field';
import SwitchField from '@/components/lob-template-forms/switch-field';
import { coverageFlowStyles } from '@/enums/coverage-flow-styles';
import { lobFormModes } from '@/enums/lob-form-modes';

import { covDataTypes } from '@certificate_hero/enums';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { coverageLineStyles } from '@/enums/coverage-line-styles';
import MultiFieldContainer from '@/components/lob-template-forms/multi-field-container';
import { findLobTypeBySymbol } from '@certificate_hero/utils';
import { useStore } from '@/hooks/use-store';
import { FormName } from '@/enums/form-names';
import isEqual from 'lodash/isEqual';
import { ErrorContext } from '@/components/lob-templates/context';
import CompoundField from '@/components/lob-template-forms/compound-field';

const { INDICATOR, DESCRIPTION, FREE_TEXT, SWITCH, MULTI_FIELD, LIMIT } =
  covDataTypes;

/**
 * Fallback constants in case the content limit is not enforced in the enums
 *
 */
const FORM_INPUT_TEXT_LIMIT = {
  nameField: 60,
  valueField: 15,
  description: 100000,
};

let count = 0;

/**
 * @param {object} params
 * @param {string} params.mode
 * @param {(fieldAreas: LobTemplateFieldAreas) => void} params.onSave
 * @param {boolean} params.hasAmsCoverageData
 * @param {PolicyCoverageEntity[]} params.amsCoverages
 * @param {string} params.lobSymbol
 * @param {string} params.isAmsPolicyValidating
 */
export const useDynamicLobForm = ({
  mode,
  onSave,
  amsCoverages,
  additionalAmsCoverages,
  hasAmsCoverageData,
  lobSymbol,
  componentInjector,
  isAmsPolicyValidating,
  additionalLocationAddresses,
  formTemplate,
}) => {
  /** @type {{ current?: LobTemplateFieldAreas }} */
  const fieldMappings = useRef(null);
  const V0FieldMappings = useRef(null);
  const [shouldRegen, setShouldRegen] = useState(true);
  const [inputChanged, setInputChanged] = useState(false);

  const [{ user }, updateStore] = useStore();

  const [errors, setErrors] = useContext(ErrorContext);

  const mappingState = JSON.stringify(fieldMappings.current);

  const freeformAmsCoverages = useMemo(() => {
    if (!lobSymbol || !hasAmsCoverageData || !fieldMappings.current) {
      return [];
    }

    const lobType = findLobTypeBySymbol(lobSymbol);
    const coverageNames = Object.values(fieldMappings.current)
      .flat(1)
      .map(mapping => mapping.name);

    /** Start of PROP additional coverages on Acord 27 and Acord 28**/
    if (
      lobType.code === 'PROP' &&
      ['Acord 27', 'Acord 28'].includes(formTemplate.id)
    ) {
      // Group coverages by location ID
      const coveragesByLocation = [
        ...amsCoverages,
        ...additionalAmsCoverages,
      ].reduce((acc, coverage) => {
        const { locationId } = coverage;
        if (!locationId) {
          return acc;
        }

        acc[locationId] = acc[locationId] || [];
        acc[locationId].push(coverage);
        return acc;
      }, {});

      // Process all coverages in one pass
      const mappedCoverages = Object.entries(coveragesByLocation).flatMap(
        ([locationId, locationCoverages]) =>
          locationCoverages
            .map(amsCoverage => {
              const coverageType = lobType.coverages.find(
                coverage => coverage.name === amsCoverage.name
              );
              const location = additionalLocationAddresses.find(
                loc => loc.locationId === locationId
              );

              const labelWithLocation = location
                ? `${coverageType?.label || amsCoverage.name} (${
                    location.address
                  })`
                : coverageType?.label || amsCoverage.name;

              return {
                ...amsCoverage,
                locationId,
                label: labelWithLocation,
                type: coverageType?.type || LIMIT,
                ...(coverageType && {
                  acordSpecific: coverageType.acordSpecific,
                }),
              };
            })
            .filter(Boolean)
      );

      // Filter coverages
      const filteredCoverages = mappedCoverages.filter(amsCoverage => {
        if (amsCoverage.name === 'ch-internal') {
          return false;
        }

        // Show overflow coverages with the same name as long as they're tied to a different location
        if (coverageNames.includes(amsCoverage.name)) {
          const existingMappingWithSameLocationExists = Object.values(
            fieldMappings.current
          )
            .flat(1)
            .some(
              mapping =>
                mapping.name === amsCoverage.name &&
                mapping.locationId === amsCoverage.locationId
            );

          return !existingMappingWithSameLocationExists;
        }
        return true;
      });

      return { allCoverages: mappedCoverages, filteredCoverages };
    } /** End of PROP Additional Coverages**/

    // Non-PROP LOB type handling
    const mappedCoverages = amsCoverages
      .map(amsCoverage => {
        const coverageType = lobType.coverages.find(
          coverage => coverage.name === amsCoverage.name
        );

        return coverageType
          ? {
              ...amsCoverage,
              label: coverageType.label,
              type: coverageType.type,
              acordSpecific: coverageType.acordSpecific,
            }
          : {
              ...amsCoverage,
              label: amsCoverage.name,
              type: LIMIT,
            };
      })
      .filter(Boolean);

    // Remove duplicates based on the label
    const uniqueCoverages = Object.values(
      mappedCoverages.reduce((acc, coverage) => {
        if (!acc[coverage.label]) {
          acc[coverage.label] = coverage;
        }
        return acc;
      }, {})
    );

    // Filter coverages
    const filteredCoverages = uniqueCoverages.filter(
      amsCoverage =>
        !coverageNames.includes(amsCoverage.name) &&
        !amsCoverage.acordSpecific &&
        amsCoverage.name !== 'ch-internal'
    );

    return {
      allCoverages: uniqueCoverages,
      filteredCoverages,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amsCoverages, hasAmsCoverageData, lobSymbol, mappingState]);

  /**
   * @type {(params: {updatedMapping: LobTemplateFieldMapping, position: LobFieldMapping['formPosition']}) => void}
   */
  const updateFieldMapping = useCallback(
    ({ updatedMapping, position }) => {
      count += 1;
      const updatedFieldMappings = cloneDeep(fieldMappings.current);
      if (count === 1) {
        V0FieldMappings.current = cloneDeep(fieldMappings.current);
      }

      const index = updatedFieldMappings[position].findIndex(
        c => c.id === updatedMapping.id
      );
      updatedFieldMappings[position][index] = updatedMapping;
      setErrors({});
      if (!isEqual(V0FieldMappings.current, updatedFieldMappings)) {
        updateStore({ unsavedChangesExist: { [FormName.LOB]: true } });
      } else {
        updateStore({ unsavedChangesExist: { [FormName.LOB]: false } });
      }

      fieldMappings.current = updatedFieldMappings;

      if (mode === lobFormModes.CERTIFICATE) {
        onSave({ fieldMappings: updatedFieldMappings });
      }
    },
    [mode, onSave, setErrors, updateStore]
  );

  /**
   * @type {(id: string, position: LobFieldMapping['formPosition']) => void}
   */
  const removeFieldMapping = useCallback(
    (id, position) => {
      const updatedFieldMappings = cloneDeep(fieldMappings.current);
      const index = updatedFieldMappings[position].findIndex(c => c.id === id);
      updatedFieldMappings[position].splice(index, 1);
      fieldMappings.current = updatedFieldMappings;
      setShouldRegen(true);

      if (errors.coverage) {
        setErrors(prev => ({ ...prev, coverage: null }));
      }
    },
    [errors, setErrors]
  );

  /**
   * @param {LobTemplateFieldMapping} mapping
   */
  const checkIfReadOnly = useCallback(
    mapping => {
      if (!mode || mode === lobFormModes.VIEW) {
        return true;
      }

      switch (mapping.flowStyle) {
        case coverageFlowStyles.CERT_ONLY:
          return (
            mode !== lobFormModes.CERTIFICATE ||
            !user?.accessKeys.CERTIFICATES.CREATE
          );
        case coverageFlowStyles.MANAGER_ONLY:
          return mode !== lobFormModes.MANAGER;
        case coverageFlowStyles.CERT_AND_MANAGER:
          return (
            mode !== lobFormModes.CERTIFICATE && mode !== lobFormModes.MANAGER
          );
        // If a flow style has not been defined, default to true in cert, false in manager
        default:
          return mode === lobFormModes.CERTIFICATE;
      }
    },
    [mode, user?.accessKeys]
  );

  /**
   * @type {(mapping: LobTemplateFieldMapping, position: LobFieldMapping['formPosition'], JSX.Element) => JSX.Element[]}
   */
  const generateIndividualFields = useCallback(
    (mapping, position, addressDropdown) => {
      if (mapping.name === 'physicalAddress') {
        if (!addressDropdown) {
          return <></>;
        }
        return addressDropdown();
      }

      if (mapping.type === INDICATOR) {
        return (
          <IndicatorField
            mode={mode}
            mapping={mapping}
            isReadonly={checkIfReadOnly(mapping)}
            updateFieldMapping={updateFieldMapping}
            position={position}
            key={`${mapping.name}_${mapping.id}`}
            hasAmsCoverageData={hasAmsCoverageData}
            isAmsPolicyValidating={isAmsPolicyValidating}
          />
        );
      }

      if (mapping.type === FREE_TEXT) {
        return (
          <FreeformField
            mapping={mapping}
            amsCoverages={freeformAmsCoverages}
            isReadonly={checkIfReadOnly(mapping)}
            updateFieldMapping={updateFieldMapping}
            removeFieldMapping={removeFieldMapping}
            inputChanged={inputChanged}
            setInputChanged={setInputChanged}
            position={position}
            contentLimit={mapping.contentLimit || FORM_INPUT_TEXT_LIMIT}
            key={`${mapping.name}_${mapping.id}`}
            isAmsPolicyValidating={isAmsPolicyValidating}
            additionalLocationAddresses={additionalLocationAddresses}
          />
        );
      }

      if (mapping.type === SWITCH) {
        return (
          <SwitchField
            mapping={mapping}
            isReadonly={checkIfReadOnly(mapping)}
            updateFieldMapping={updateFieldMapping}
            position={position}
            key={`${mapping.name}_${mapping.id}`}
            hasAmsCoverageData={hasAmsCoverageData}
            isAmsPolicyValidating={isAmsPolicyValidating}
          />
        );
      }

      if (mapping.type === DESCRIPTION) {
        return (
          <DescriptionField
            mapping={mapping}
            isReadonly={checkIfReadOnly(mapping)}
            updateFieldMapping={updateFieldMapping}
            position={position}
            contentLimit={
              mapping.contentLimit || FORM_INPUT_TEXT_LIMIT.description
            }
            key={`${mapping.name}_${mapping.id}`}
          />
        );
      }

      if (mapping.type === MULTI_FIELD) {
        return (
          <CompoundField
            mapping={mapping}
            isReadonly={checkIfReadOnly(mapping)}
            updateFieldMapping={updateFieldMapping}
            position={position}
            isAmsPolicyValidating={isAmsPolicyValidating}
          />
        );
      }

      return (
        <StructuredField
          mode={mode}
          mapping={mapping}
          isReadonly={checkIfReadOnly(mapping)}
          updateFieldMapping={updateFieldMapping}
          position={position}
          contentLimit={
            mapping.contentLimit || FORM_INPUT_TEXT_LIMIT.valueField
          }
          key={`${mapping.name}_${mapping.id}`}
          hasAmsCoverageData={hasAmsCoverageData}
          isAmsPolicyValidating={isAmsPolicyValidating}
        />
      );
    },
    [
      mode,
      checkIfReadOnly,
      updateFieldMapping,
      hasAmsCoverageData,
      isAmsPolicyValidating,
      freeformAmsCoverages,
      removeFieldMapping,
      inputChanged,
      additionalLocationAddresses,
    ]
  );

  /**
   * @type {(mappings: LobTemplateFieldMapping[], position: LobFieldMapping['formPosition'], JSX.Element) => JSX.Element[]}
   */
  const generateFormArea = useCallback(
    (mappings, position, addressApi) => {
      if (!mappings) {
        return '';
      }

      const { formArea } = mappings.reduce(
        (data, mapping, index) => {
          const injected = componentInjector({
            previousField: mappings[index - 1]?.name,
            fieldMappings,
            checkIfReadOnly,
            updateFieldMapping,
          });
          if (injected) {
            data.formArea.push(injected);
            return data;
          }

          if (mapping.lineStyle === coverageLineStyles.SINGLE) {
            data.formArea.push(
              generateIndividualFields(mapping, position, addressApi)
            );
            return data;
          }

          const newElement = generateIndividualFields(
            mapping,
            position,
            addressApi
          );
          data.currentLine.push(newElement);

          if (!mapping.isEndOfLine) {
            return data;
          }

          const headerText = mapping.headerText ? mapping.headerText : '';

          data.formArea.push(
            <MultiFieldContainer id={index} headerText={headerText}>
              {data.currentLine}
            </MultiFieldContainer>
          );

          return {
            currentLine: [],
            formArea: data.formArea,
          };
        },
        {
          /** @type {JSX.Element[]} */
          currentLine: [],
          /** @type {JSX.Element[]} */
          formArea: [],
        }
      );

      return formArea;
    },
    [
      checkIfReadOnly,
      componentInjector,
      generateIndividualFields,
      updateFieldMapping,
    ]
  );

  return {
    fieldMappings,
    generateFormArea,
    shouldRegen,
    setShouldRegen,
  };
};
