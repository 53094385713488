import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';

import useSWR from 'swr';
import { route } from 'nextjs-routes';
import { useBulkJobId } from './use-bulk-job-id';
import { useStore } from './use-store';

export const useRenewal = () => {
  const [{ insuredId }] = useStore();

  const bulkJobId = useBulkJobId();

  /**
   * @type {SWRResponse<Renewal>}
   */
  const { data, isValidating, mutate, isLoading } = useSWR(
    [route({ pathname: '/api/v2/renewals/get_active_renewal' }), insuredId],
    async ([url]) => {
      const res = await FrontendHttpClient.get(url);
      return res?.data;
    }
  );

  /** If we are currently on page related to renewals */
  const onRenewalPage = !!bulkJobId && data?.bulkJobId === bulkJobId;

  return {
    renewal: data,
    isValidating,
    isLoading,
    mutate,
    onRenewalPage,
  };
};
