import styles from '@/components/lob-template-forms/styles.module.scss';
import Label from '@/components/label';
import { Form } from 'react-bootstrap';
import TextInput from '@/components/text-input';
import cx from 'clsx';
import { formatMappingValue } from '@/utils/helpers';
import Button from '@/components/button';
import { lobFormModes } from '@certificate_hero/enums';

/**
 * ScheduleTable is a component responsible for displaying and managing a list of schedules.
 * It can handle interactions such as adding new rows, removing existing ones,
 * and ensuring values don't exceed specified limits.
 *
 * @param {Object} props - The component properties.
 * @param {Array} props.allSchedulesOnForm - Array of schedules currently on the form.
 * @param {Function} props.setAllSchedulesOnForm - Function to update the array of schedules on the form.
 * @param {Function} props.getAmsValue - Function to retrieve AMS values based on schedule ID and key.
 * @param {Array} props.amsSchedules - Array of AMS schedules to compare and validate against form data.
 * @param {string} props.currentMode - Current mode of the Line of Business (LOB) form, influencing component behavior.
 * @param {number} props.numberOfSchedules - The number of base schedules on the form.
 * @param {Function} props.shouldAddNewRow - Function to determine whether a new row should be added to the form.
 * @param {Function} props.setSelectedScheduleOptions - Function to update selected schedule options from dropdown.
 *
 * @returns {JSX.Element} A table UI element rendering the list of schedules with ability to add, modify, or remove rows.
 */
const ScheduleTable = ({
  allSchedulesOnForm,
  setAllSchedulesOnForm,
  getAmsValue,
  amsSchedules,
  currentMode,
  numberOfSchedules,
  shouldAddNewRow,
  setSelectedScheduleOptions,
}) => {
  const baseFormSchedules = allSchedulesOnForm.slice(0, numberOfSchedules);
  const overflowFormSchedules = allSchedulesOnForm.slice(numberOfSchedules);
  const onFreeFormChange = (event, index, amsId, key) => {
    let value = event.target.value.replace(/,/g, '');
    // If we are changing a field that is mapped from the dropdown
    // We need to make sure that limits do not exceed the AMS value.
    if (amsId) {
      // Find the amsSchedule for this row.
      const amsSchedule = amsSchedules.find(
        schedule => schedule.amsId === amsId
      );

      // If there is no AMS value for this key, don't compare.
      if (amsSchedule && amsSchedule[key]) {
        const strippedAMSValue = `${amsSchedule[key]}`?.replace(/,/g, '');

        if (/^-?\d+$/.test(strippedAMSValue) && /^-?\d+$/.test(value)) {
          if (!(parseFloat(strippedAMSValue) >= parseFloat(value))) {
            value = strippedAMSValue;
          }
        }
      }
    }

    setAllSchedulesOnForm(current => {
      const updatedSchedules = [...current];
      updatedSchedules[index] = {
        ...updatedSchedules[index],
        [key]: value,
      };
      // if manager, and the last row is filled out, add a new row
      if (shouldAddNewRow(updatedSchedules, currentMode)) {
        updatedSchedules.push({
          amsId: '',
          name: '',
          value: '',
          deductible: '',
          locationId: '',
        });
      }
      return updatedSchedules;
    });
  };

  const onRemove = rowStartIndex => {
    const amsId = allSchedulesOnForm[rowStartIndex]?.amsId;

    // Remove the schedule from the form
    setAllSchedulesOnForm(current => {
      const next = current.filter((_, idx) => idx !== rowStartIndex);
      if (next.length < numberOfSchedules) {
        const hasEmptyRow = next.some(
          o => !o.amsId && !o.name && !o.value && !o.deductible
        );
        if (!hasEmptyRow) {
          next.push({
            amsId: '',
            name: '',
            value: '',
            deductible: '',
            locationId: '',
          });
        }
      }
      return next;
    });

    // unselect the schedule from the dropdown options list if it exists
    if (amsId) {
      setSelectedScheduleOptions(option => {
        return option.filter(option => option.id !== amsId);
      });
    }
  };

  const renderAdditionalScheduleRows = () => {
    return overflowFormSchedules.map((schedule, i) => {
      let amountName = schedule.overflowAmountName;
      let deductName = schedule.overflowDeductName;
      // this is overflow coverage... offset the index by the number of fields to correctly map the form
      const formIdx = i + numberOfSchedules;
      return (
        <tr key={formIdx}>
          <td className={styles.schedulesCell}>
            <div>
              <Form.Text className={styles.freeFormAmsLabel} muted>
                {`AMS Value: ${getAmsValue(schedule.amsId, 'name')} - Amount`}
              </Form.Text>
              <div className={styles.schedulesEndCell}>
                <div className="w-100">
                  <TextInput
                    className={cx(styles.valueField, styles.schedulesField)}
                    id={`${amountName}_101_${i}_AMOUNT_NAME`}
                    value={amountName}
                    placeholder="Name"
                    disabled={currentMode !== lobFormModes.MANAGER}
                    onChange={event =>
                      onFreeFormChange(
                        event,
                        formIdx,
                        schedule.amsId,
                        'overflowAmountName'
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div>
              <Form.Text className={styles.freeFormAmsLabel} muted>
                {`AMS Value: ${getAmsValue(
                  schedule.amsId,
                  'name'
                )} - Deductible`}
              </Form.Text>
              <div className={styles.schedulesEndCell}>
                <div className="w-100">
                  <TextInput
                    className={cx(styles.valueField, styles.schedulesField)}
                    id={`${deductName}_101_${i}_DEDUCTIBLE_NAME`}
                    value={deductName}
                    placeholder="Name"
                    disabled={currentMode !== lobFormModes.MANAGER}
                    onChange={event =>
                      onFreeFormChange(
                        event,
                        formIdx,
                        schedule.amsId,
                        'overflowDeductName'
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </td>
          <td className={cx(styles.schedulesCell, 'px-3')}>
            <div>
              <Form.Text className={styles.freeFormAmsLabel} muted>
                {`AMS Value: ${getAmsValue(schedule.amsId, 'value')}`}
              </Form.Text>
              <div className={styles.schedulesEndCell}>
                <div className="w-100">
                  <div>
                    <TextInput
                      className={styles.valueField}
                      id={`${schedule.name}_101_${i}_AMOUNT_VALUE`}
                      value={formatMappingValue(schedule.value)}
                      placeholder="Value"
                      disabled={currentMode !== lobFormModes.MANAGER}
                      onChange={event =>
                        onFreeFormChange(
                          event,
                          formIdx,
                          schedule.amsId,
                          'value'
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Form.Text className={styles.freeFormAmsLabel} muted>
                {`AMS Value: ${getAmsValue(schedule.amsId, 'deductible')}`}
              </Form.Text>
              <div className={styles.schedulesEndCell}>
                <div className="w-100">
                  <TextInput
                    className={styles.valueField}
                    id={`${schedule.name}_101_${i}_VALUE`}
                    value={formatMappingValue(schedule.deductible)}
                    placeholder="Value"
                    disabled={currentMode !== lobFormModes.MANAGER}
                    onChange={event =>
                      onFreeFormChange(
                        event,
                        formIdx,
                        schedule.amsId,
                        'deductible'
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </td>
          {currentMode === lobFormModes.MANAGER && (
            <td>
              <Button onClick={() => onRemove(formIdx)}>Remove</Button>
            </td>
          )}
        </tr>
      );
    });
  };

  return (
    <div>
      <table className={styles.schedulesTable}>
        <thead>
          <tr>
            <th>
              <Label>Name</Label>
            </th>
            <th className="px-3">
              <Label>Amount</Label>
            </th>
            <th>
              <Label>Deductible</Label>
            </th>
          </tr>
        </thead>
        <tbody>
          {baseFormSchedules.map((schedule, i) => {
            const amsId = schedule.amsId;
            return (
              <tr key={i}>
                <td className={styles.schedulesCell}>
                  <Form.Text className={styles.freeFormAmsLabel} muted>
                    {`AMS Value: ${getAmsValue(amsId, 'name')}`}
                  </Form.Text>

                  <TextInput
                    className={cx(styles.valueField, styles.schedulesField)}
                    id={`${schedule.name}_${i}_NAME`}
                    value={schedule.name}
                    placeholder="Name"
                    onChange={event =>
                      onFreeFormChange(event, i, amsId, 'name')
                    }
                    disabled={currentMode !== lobFormModes.MANAGER}
                  />
                </td>
                <td className={cx(styles.schedulesCell, 'px-3')}>
                  <Form.Text className={styles.freeFormAmsLabel} muted>
                    {`AMS Value: ${getAmsValue(amsId, 'value')}`}
                  </Form.Text>
                  <TextInput
                    className={styles.valueField}
                    id={`${schedule.name}_${i}_AMOUNT`}
                    value={formatMappingValue(schedule.value)}
                    placeholder="Amount"
                    onChange={event =>
                      onFreeFormChange(event, i, amsId, 'value')
                    }
                    disabled={currentMode !== lobFormModes.MANAGER}
                  />
                </td>
                <td className={styles.schedulesCell}>
                  <Form.Text className={styles.freeFormAmsLabel} muted>
                    {`AMS Value: ${getAmsValue(amsId, 'deductible')}`}
                  </Form.Text>
                  <div className={styles.schedulesEndCell}>
                    <div className="w-100">
                      <TextInput
                        className={styles.valueField}
                        id={`${schedule.name}_${i}_DEDUCTIBLE`}
                        value={formatMappingValue(schedule.deductible)}
                        placeholder="Deductible"
                        onChange={event =>
                          onFreeFormChange(event, i, amsId, 'deductible')
                        }
                        disabled={currentMode !== lobFormModes.MANAGER}
                      />
                    </div>
                    {currentMode === lobFormModes.MANAGER && (
                      <Button
                        className="ml-2"
                        disabled={
                          !amsId &&
                          !schedule.name &&
                          !schedule.value &&
                          !schedule.deductible &&
                          i === allSchedulesOnForm.length - 1
                        }
                        onClick={() => {
                          onRemove(i);
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {allSchedulesOnForm?.length > numberOfSchedules && (
        <>
          <h5>ACORD 101 Additional Schedules</h5>
          <table className={styles.schedulesTable}>
            <tbody>{renderAdditionalScheduleRows()}</tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default ScheduleTable;
