import Dropdown from '@/components/dropdown';
import useRenewingPolicies from '@/components/renewals/hooks/use-renewing-policies';
import { MAPPING_ACTIONS } from '@/enums/renewal-mapping-actions';
import { parseDate, truncateString } from '@/utils/helpers';
import { DROPDOWN_OPTION_TEXT_ELLIPSIS_CUTOFF_LIMIT } from '@/constants';

import { useMemo } from 'react';
import styles from './styles.module.scss';

/**
 * @param {object} props
 * @param {string} props.id
 * @param {PolicyEntity & {lobTemplates: [LobTemplateEntity]}} props.policy
 * @param {LobTemplateEntity} props.lobTemplate
 * @param {() => void} props.setEditPolicyModalIsOpen
 * @param {LobTypeMapping[]} props.lobTypeMappings
 * @param {import('formik').FormikProps<values>} props.form
 * @param {(oldPolicyId: string, lobTemplate: LobTemplateEntity, selectionId: string, action: string) => void} props.applyLobTypeMapping
 * @param {() => void} props.setAmsPolicy
 */
export default function PoliciesMapperDropdown({
  id,
  policy,
  lobTemplate,
  setEditPolicyModalIsOpen,
  lobTypeMappings,
  form,
  applyLobTypeMapping,
  setAmsPolicy,
}) {
  const { renewalTargetPolicies, isValidating } = useRenewingPolicies();

  /**
   * @param {object} selection
   * @param {string} selection.id
   * @param {string} selection.text
   * @param {RenewingPolicy} selection.policy
   */
  const onChange = selection => {
    // On new selection, display import modal, mapping logic on submit.
    if (selection.policy?.isNew) {
      const selectedPolicy = JSON.parse(JSON.stringify(selection.policy));

      const { isNew, lobs, ...rest } = selectedPolicy;

      setAmsPolicy(rest);
      form.setValues({
        ...rest,
        oldPolicyId: policy.id,
        lobTemplate,
        effectiveDate: parseDate(rest.effectiveDate, 'yyyy-MM-dd'),
        expirationDate: parseDate(rest.expirationDate, 'yyyy-MM-dd'),
      });
      setEditPolicyModalIsOpen(true);
    }
    // Else, standard mapping logic.
    else {
      applyLobTypeMapping(
        policy.id,
        lobTemplate,
        selection.id,
        selection.action
      );
    }
  };

  const getLobTypeMapping = () => {
    return lobTypeMappings?.find(
      mapping =>
        mapping.oldPolicyId === policy.id &&
        mapping.lobType === lobTemplate.lobSymbol
    );
  };

  const getDropdownValue = () => {
    if (isValidating && !renewalTargetPolicies) {
      return 'Loading...';
    }
    // Check the mapping
    const lobTypeMapping = getLobTypeMapping();
    // If it exists, find the policy data.
    if (lobTypeMapping) {
      if (lobTypeMapping.action === MAPPING_ACTIONS.DROP) {
        return 'Drop';
      }
      const selectedPolicy = renewalTargetPolicies.find(
        selectablePolicy => selectablePolicy.id === lobTypeMapping.newPolicyId
      );
      return `${selectedPolicy?.policyName} (${selectedPolicy?.policyNumber})`;
    }
    return '';
  };

  const filteredPolicyOptions = useMemo(() => {
    return (
      renewalTargetPolicies
        // Filter by lobSymbol and is a newer policy.
        ?.filter(renewingPolicy => {
          const hasMatchingLobSymbol = renewingPolicy.lobs.some(
            lob => lob.lobSymbol === lobTemplate.lobSymbol
          );
          const renewingPolicyHasValidDate =
            renewingPolicy.effectiveDate >= policy.effectiveDate;

          return hasMatchingLobSymbol && renewingPolicyHasValidDate;
        })
        .map(renewingPolicy => {
          // Default id as internal id, however for new AMS policies, set a temporary id as amsId.
          return {
            id: renewingPolicy?.id ? renewingPolicy.id : renewingPolicy.amsId,
            text: `${renewingPolicy.policyName} (${renewingPolicy.policyNumber})`,
            action: MAPPING_ACTIONS.MAP,
            policy: renewingPolicy,
            sortingKey: `(${parseDate(
              renewingPolicy.effectiveDate,
              'yyyy-MM-dd'
            )}) ${renewingPolicy.policyNumber} `,
            element: (
              <div className={styles.dropdownElement}>
                {renewingPolicy.isNew && (
                  <div className={styles.newTag}>New!</div>
                )}
                {`${renewingPolicy.policyNumber} (${parseDate(
                  renewingPolicy.effectiveDate,
                  'yyyy-MM-dd'
                )})`.length > DROPDOWN_OPTION_TEXT_ELLIPSIS_CUTOFF_LIMIT
                  ? truncateString(
                      `(${parseDate(
                        renewingPolicy.effectiveDate,
                        'yyyy-MM-dd'
                      )}) ${renewingPolicy.policyNumber} `,
                      DROPDOWN_OPTION_TEXT_ELLIPSIS_CUTOFF_LIMIT
                    )
                  : `(${parseDate(
                      renewingPolicy.effectiveDate,
                      'yyyy-MM-dd'
                    )}) ${renewingPolicy.policyNumber} `}
              </div>
            ),
          };
        })
        .sort((a, b) => (a.sortingKey > b.sortingKey ? -1 : 1)) || []
    );
  }, [lobTemplate, policy, renewalTargetPolicies]);

  const options = [
    getLobTypeMapping()
      ? {
          id: MAPPING_ACTIONS.CLEAR,
          text: `Clear`,
          action: MAPPING_ACTIONS.CLEAR,
        }
      : null,
    ...(filteredPolicyOptions?.length > 0
      ? filteredPolicyOptions
      : [
          {
            text: `No New ${lobTemplate?.lobSymbol || ''} Policies`,
            disabled: true,
            id: 'no_policies',
            tooltip: `No new ${
              lobTemplate?.lobSymbol || ''
            } policies found in the AMS to map to`,
          },
        ]),
    {
      divider: true,
    },
    {
      id: MAPPING_ACTIONS.DROP,
      text: 'Drop Policy',
      action: MAPPING_ACTIONS.DROP,
    },
  ];

  return (
    <Dropdown
      id={id}
      drop="down"
      align="middle"
      name="mappingDropdown"
      pending={!lobTypeMappings || !renewalTargetPolicies}
      disabled={!renewalTargetPolicies}
      placeholder={`Select ${lobTemplate?.lobSymbol || ''} Policy`}
      value={getDropdownValue()}
      options={options}
      onChange={onChange}
      containerClassName={styles.mappingDropdown}
    />
  );
}
