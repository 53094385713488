import { v4 as uuidv4 } from 'uuid';

export class PolicyEntity {
  constructor({
    ams = '',
    amsId = '',
    createdAt = new Date(),
    coverageCode = '',
    deleted = false,
    description = '',
    effectiveDate = '',
    expirationDate = '',
    insurerName = '',
    naicNumber = '',
    policyName = '',
    policyNumber = '',
    id = uuidv4(),
    insuredId = '',
    namedInsuredIds = [],
    updatedAt = new Date(),
    autoIngested = false,
  }) {
    this.ams = ams;
    this.amsId = amsId;
    this.createdAt = createdAt;
    this.coverageCode = coverageCode;
    this.deleted = deleted;
    this.description = description;
    this.effectiveDate = effectiveDate;
    this.expirationDate = expirationDate;
    this.insurerName = insurerName;
    this.naicNumber = naicNumber;
    this.policyName = policyName;
    this.policyNumber = policyNumber;
    this.id = id;
    this.insuredId = insuredId;
    this.namedInsuredIds = namedInsuredIds;
    this.updatedAt = updatedAt;
    this.autoIngested = autoIngested;
  }
}
