export class PolicyCoverageEntity {
  constructor({
    name = '',
    section = 'coverage',
    amsId = '',
    value,
    editable = false,
    flowStyle,
    locationId = '',
  }) {
    this.name = name;
    this.section = section || 'coverage';
    this.amsId = amsId;
    this.value = value;
    this.editable = editable;
    if (flowStyle) {
      this.flowStyle = flowStyle;
    }
    if (locationId) {
      this.locationId = locationId;
    }
  }
}
