import { formPositions } from '@/enums/form-positions';
import { covDataTypes } from '@/enums/lob-data-types';
import { exceedsAmsValue, formatMappingValue } from '@/utils/helpers';
import {
  faExclamation,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as yup from 'yup';
import dynamic from 'next/dynamic';
import Button from '../button';
import { ErrorContext } from '../lob-templates/context';
import TextInput from '../text-input';
import styles from './styles.module.scss';
import { LOCATION_COVERAGE_REGEX } from '@/constants';

const { ADDITIONAL } = formPositions;
const { LIMIT, INDICATOR } = covDataTypes;

const Typeahead = dynamic(() => import('@/components/typeahead'));

/**
 * @param {object} params
 * @param {LobTemplateFieldMapping} params.mapping
 * @param {{ allCoverages: PartialPolicyCoverageEntity[], filteredCoverages: PartialPolicyCoverageEntity[]}} params.amsCoverages
 * @param {(params: {updatedMapping: LobTemplateFieldMapping, position: LobFieldMapping['formPosition']}) => void} params.updateFieldMapping
 * @param {(id: string, position: LobFieldMapping['formPosition']) => void} params.removeFieldMapping
 * @param {boolean} params.isReadonly
 * @param {LobFieldMapping['formPosition']} params.position
 * @param {object} params.contentLimit
 * @param {string} params.index
 * @param {boolean} params.inputChanged
 * @param {() => void} params.setInputChanged
 */
const FreeformField = ({
  mapping,
  amsCoverages,
  updateFieldMapping,
  removeFieldMapping,
  isReadonly,
  position,
  contentLimit,
  index,
  inputChanged,
  setInputChanged = () => null,
  isAmsPolicyValidating,
  additionalLocationAddresses,
}) => {
  const [fieldMapping, setFieldMapping] = useState({ ...mapping });
  const [exceedsLimit, setExceedsLimit] = useState(false);
  const [isAmsValueMisMatched, setIsAmsValueMisMatched] = useState(false);
  const [maxCharLimitError, setMaxCharLimitError] = useState('');
  const [, setError] = useContext(ErrorContext);

  useEffect(() => {
    const newFieldMapping = { ...mapping };

    if (amsCoverages.allCoverages) {
      const amsCov = amsCoverages.allCoverages.find(
        cov => cov.name === mapping.name
      );
      if (amsCov?.type === INDICATOR) {
        newFieldMapping.amsValue = !!newFieldMapping.amsValue;
      }
    }
    setFieldMapping(newFieldMapping);
  }, [mapping, amsCoverages]);

  useEffect(() => {
    if (!amsCoverages.allCoverages) {
      return;
    }

    if (!fieldMapping) {
      return;
    }

    if (
      fieldMapping.amsValue &&
      Number.isNaN(parseFloat(fieldMapping.amsValue)) &&
      String(fieldMapping.amsValue) !== String(fieldMapping.value)
    ) {
      setIsAmsValueMisMatched(true);
      return;
    }

    const foundCoverage = amsCoverages.allCoverages.find(
      cov => cov.name === fieldMapping.name
    );

    if (
      !isAmsPolicyValidating &&
      foundCoverage?.type === LIMIT &&
      exceedsAmsValue(fieldMapping)
    ) {
      setExceedsLimit(true);
      setError(prev => ({
        ...prev,
        type: 'editor',
        coverage: 'Entered coverage value exceeds AMS value',
      }));
      return;
    }

    setExceedsLimit(false);
    setIsAmsValueMisMatched(false);
  }, [amsCoverages, fieldMapping, setError, isAmsPolicyValidating]);

  const onNameChange = selection => {
    // Hack to do nothing when this gets called with an empty array
    // which is something a component futher down the chain constantly does
    if (selection && !selection[0]) {
      return;
    }

    if (!selection) {
      const updatedMapping = {
        ...fieldMapping,
        name: '',
        label: '',
        value: '',
        amsValue: '',
      };

      setFieldMapping(updatedMapping);
      updateFieldMapping({ updatedMapping, position });
      return;
    }

    // if the label is the same as the current label, don't do anything
    // the user didn't change anything
    if (selection[0].label === fieldMapping.label) {
      return;
    }

    const existingCoverage = amsCoverages.allCoverages?.find(
      cov => cov.label === selection[0].label
    );

    if (existingCoverage) {
      const { name, label, value, type, amsId, locationId } = existingCoverage;

      const cleanLabel = label
        .replace(LOCATION_COVERAGE_REGEX, '') //Remove address within parenthesis
        .trim();

      const updatedMapping = {
        ...fieldMapping,
        value: type === INDICATOR ? !!value : value,
        amsValue: value,
        locationId,
        name,
        label: cleanLabel,
        amsId,
      };

      setFieldMapping(updatedMapping);
      updateFieldMapping({ updatedMapping, position });
      return;
    }

    const updatedMapping = {
      ...fieldMapping,
      amsValue: '',
      name: selection[0].label,
      label: selection[0].label,
    };

    setFieldMapping(updatedMapping);
    updateFieldMapping({ updatedMapping, position });
  };

  const onValueChange = event => {
    const foundCoverage = amsCoverages.allCoverages?.find(
      cov => cov.name === fieldMapping.name
    );

    let isValueValid = true;
    let { value } = event.target;
    if (foundCoverage?.type === LIMIT) {
      const strippedValue = event.target.value.replace(/,/g, '');
      const amsValue = fieldMapping.amsValue.replace(/,/g, '');

      if (/^-?\d+$/.test(amsValue) && /^-?\d+$/.test(strippedValue)) {
        isValueValid = parseFloat(amsValue) >= parseFloat(strippedValue);
      }

      value = isValueValid ? strippedValue : amsValue;
    }

    const updatedMapping = {
      ...fieldMapping,
      value: value.replace(/,/g, ''),
    };
    setFieldMapping(updatedMapping);
    updateFieldMapping({ updatedMapping, position });
  };

  const searchSchema = yup.object().shape({
    query: yup
      .string()
      .max(
        contentLimit?.nameField,
        `Must be less than ${contentLimit?.nameField} characters`
      ),
  });

  const handleInputChange = async input => {
    try {
      await searchSchema.validate({ query: input });
    } catch (error) {
      setMaxCharLimitError(error.message);
      return;
    }

    setInputChanged(true);
    setMaxCharLimitError('');

    if (input === '') {
      onNameChange();
      return;
    }

    onNameChange([{ label: input }]);
  };

  const locationDisplay = useMemo(() => {
    const locationInfo = additionalLocationAddresses?.find(
      loc => loc.locationId === fieldMapping.locationId
    );

    if (!locationInfo?.address) {
      return null;
    }

    return <> {`Location: ${locationInfo.address.slice(0, 50)}...`}</>;
  }, [additionalLocationAddresses, fieldMapping.locationId]);

  return (
    <div className={styles.individualMapping}>
      {(fieldMapping.amsValue || isAmsPolicyValidating) && (
        <Form.Text className={styles.freeFormAmsLabel} muted>
          {`AMS Value: ${
            isAmsPolicyValidating
              ? 'Loading'
              : formatMappingValue(fieldMapping.amsValue)
          }`}
          {locationDisplay}
        </Form.Text>
      )}
      <Typeahead
        allowNew
        dropup
        flip
        changeOnBlur
        hideMenuAfterSelect
        multiple={false}
        containerClassName={styles.nameField}
        id={`${fieldMapping.name}_${index}_NAME`}
        selected={[{ label: fieldMapping.label || '' }]}
        options={amsCoverages?.filteredCoverages?.filter(c => !!c.label) || []}
        placeholder="Coverage Name"
        disabled={isReadonly && !fieldMapping.editable}
        onChange={onNameChange}
        isInvalid={maxCharLimitError}
        error={maxCharLimitError}
        onInputChange={handleInputChange}
        maxLimit={60}
        autoFocus={inputChanged && fieldMapping.label}
      />
      {exceedsLimit ? (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 200 }}
          overlay={
            <Tooltip>{`Cannot exceed amount ${fieldMapping.amsValue}`}</Tooltip>
          }
        >
          <div className={styles.freeformErrorIcon}>
            <FontAwesomeIcon icon={faExclamation} />
          </div>
        </OverlayTrigger>
      ) : null}
      {isAmsValueMisMatched ? (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 200 }}
          overlay={
            <Tooltip>{`Entered value doesn't match the AMS Value: ${fieldMapping.amsValue}`}</Tooltip>
          }
        >
          <div className={styles.freeformWarningIcon}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
        </OverlayTrigger>
      ) : null}
      <TextInput
        className={styles.valueField}
        id={`${fieldMapping.name}_${index}_VALUE`}
        value={formatMappingValue(fieldMapping.value)}
        placeholder="Value"
        disabled={isReadonly && !fieldMapping.editable}
        onChange={onValueChange}
        maxLimit={contentLimit?.valueField}
      />
      {position === ADDITIONAL && !isReadonly && (
        <Button
          containerClassName={styles.removeButton}
          onClick={() => removeFieldMapping(fieldMapping.id, position)}
        >
          Remove
        </Button>
      )}
    </div>
  );
};

export default FreeformField;
