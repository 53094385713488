import { useContext, useCallback } from 'react';
import { AppContext } from '@/utils/app-context';
import reducerList from '@/utils/reducer-list';

const types = {
  canChangeInsured: reducerList.SET_CAN_CHANGE_INSURED,
  user: reducerList.SET_USER,
  broker: reducerList.SET_BROKER,
  unsavedChangesExist: reducerList.SET_UNSAVED_CHANGES_EXIST,
  unsavedNotesExist: reducerList.SET_UNSAVED_NOTES_EXIST,
  bulkJobEditCertIds: reducerList.SET_BULK_JOB_EDIT_CERT_IDS,
};

/**
 * Custom hook that can access and update the global store
 * anywhere in the application
 * @returns {[useAppContext['0'], () => unknown, () => unknown]}
 */
export const useStore = () => {
  /** @type {useAppContext} */
  const [state, dispatch, mutate] = useContext(AppContext);

  /** @type {(newState: Partial<Omit<State, 'insuredId' | 'insuredName'>>) => void} */
  const updateStore = useCallback(
    newState => {
      if (newState) {
        Object.entries(newState).forEach(([key, value]) => {
          const stateType = types[key];
          if (stateType && state[key] !== value) {
            dispatch({ type: stateType, payload: value });
          }
        });
      }
    },
    [state, dispatch]
  );

  return [state, updateStore, mutate];
};
