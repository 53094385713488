import { NOT_APPLICABLE } from '@/constants';
import * as vars from '@/styles/vars.module.scss';
import styles from './styles.module.scss';

/**
 * @param {object} params
 * @param {boolean} params.value
 */

const YesNoRenderer = ({ value }) => {
  const lcValue = value.trim().toLowerCase();
  const text =
    lcValue === 'yes' ? 'YES' : lcValue === 'no' ? 'NO' : NOT_APPLICABLE;
  const color =
    lcValue === 'no' ? vars['color-danger'] : vars['color-readable-dark'];
  return (
    <div className={styles.textContainer} style={{ color }}>
      {text}
    </div>
  );
};

export default YesNoRenderer;
