/**
 * Removes the "Acord" before the number
 * @param {object} props
 * @param {string} props.value
 */
const AcordNumberRenderer = ({ value }) => {
  if (!value) {
    return '-';
  }

  const text = String(value).toLowerCase();

  return text.indexOf('acord ') === 0 ? text.substring(6) : value;
};

export default AcordNumberRenderer;
