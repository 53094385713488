import { forwardRef } from 'react';
import cx from 'clsx';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import globalStyles from '@/styles/globals.module.scss';
import styles from './styles.module.scss';

/**
 * @param {object} params
 * @param {string} params.description
 * @param {object[]} params.data
 * @param {string} params.containerClassName
 * @param {object} params.containerStyle
 * @param {string} params.className
 * @param {string} params.labelClassName
 * @param {string|object} params.children
 * @param {object} params.iconLeft
 * @param {object} params.iconRight
 * @param {object} params.componentLeft
 * @param {object} params.componentRight
 * @param {number} params.minWidth
 * @param {Function} params.onClick
 * @param {boolean} params.disabled
 * @param {boolean} params.pending
 * @param {string} params.pendingText
 */
const Button = forwardRef((props, ref) => {
  const {
    containerClassName,
    containerStyle,
    className,
    labelClassName,
    children,
    iconLeft,
    iconRight,
    componentLeft,
    componentRight,
    minWidth: _minWidth,
    onClick,
    disabled,
    pending,
    pendingText,
    ...buttonProps
  } = props;

  const content = pending ? (
    <>
      {pendingText ? (
        <span style={{ marginRight: 10 }}>{pendingText}</span>
      ) : null}
      <Spinner animation="border" size="sm" />
    </>
  ) : (
    children
  );
  const isDisabled = disabled || pending;
  const minWidth =
    _minWidth ||
    (pending && typeof children === 'string'
      ? children.length * 7.5
      : undefined);

  return (
    <div
      className={cx(styles.container, containerClassName)}
      style={containerStyle}
    >
      <button
        type="button"
        data-testid="default-button"
        tabIndex={0}
        {...buttonProps}
        ref={ref}
        onClick={onClick}
        disabled={isDisabled}
        className={cx(
          globalStyles.buttonReset,
          styles.button,
          isDisabled && globalStyles.disabled,
          className
        )}
      >
        {iconLeft ? (
          <div className={styles.iconLeftContainer}>
            <FontAwesomeIcon icon={iconLeft} />
          </div>
        ) : null}
        {componentLeft || null}
        <div
          className={cx(
            globalStyles.truncate,
            styles.labelContainer,
            labelClassName
          )}
          style={{ minWidth }}
        >
          {content}
          {iconRight ? (
            <div className={styles.iconRightContainer}>
              <FontAwesomeIcon icon={iconRight} />
            </div>
          ) : null}
          {componentRight || null}
        </div>
      </button>
    </div>
  );
});

export default Button;
