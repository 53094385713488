import { useCallback, useEffect, useMemo, useState } from 'react';
import { lobFormModes } from '@certificate_hero/enums';
import { LOB_TYPES_ALLOWED_PROPERTY_DROPDOWN } from '@/enums/lob-types-allowed';
import Dropdown from '@/components/dropdown';
import { validate as isUuid } from 'uuid';
import Acord27Schedules from '@/components/lob-template-forms/acord-27-schedules';
import { formPositions } from '@/enums/form-positions';
import { normalizeAddressString } from '@/utils/helpers';
import { LOCATION_PREFIX_REGEX } from '@/constants';

export const usePropertyFormManager = ({
  amsPolicy,
  lobSymbol,
  currentMode,
  lobTemplate,
  fieldMappings,
  formTemplateId,
}) => {
  const [selectedLocationAmsId, setSelectedLocationAmsId] = useState('');
  const [selectedAdditionalAddresses, setSelectedAdditionalAddresses] =
    useState([]);
  const [amsLocations, setAmsLocations] = useState([]);
  const [amsSchedules, setAmsSchedules] = useState([]);
  const [clearSchedulesForm, setClearSchedulesForm] = useState(false);

  const getPhysicalAddressCoverage = useCallback(() => {
    return lobTemplate?.data?.coverages?.find(
      coverage => coverage.name === 'physicalAddress'
    );
  }, [lobTemplate.data.coverages]);

  const hasAddress = !!getPhysicalAddressCoverage();

  // Initial render - set selectedLocationAmsId
  useEffect(() => {
    // Skip if no address is needed
    if (!hasAddress) {
      return;
    }

    setSelectedLocationAmsId(current => {
      // Case 1: We have a value but it's not a UUID - update to amsId if possible
      if (current && !isUuid(current)) {
        const matchingLocation = amsLocations?.find(l => l.address === current);
        return matchingLocation?.amsId || current;
      }

      // Case 2: No current value - try to get it from address coverage
      if (!current) {
        const addressCoverage = getPhysicalAddressCoverage();
        if (addressCoverage) {
          // Use amsId if it exists in amsLocations
          let matchingLocation = amsLocations?.find(
            l => l.amsId === addressCoverage.amsId
          );

          if (!matchingLocation) {
            // Match on location address if we don't have amsId
            matchingLocation = amsLocations?.find(
              l =>
                normalizeAddressString(l.address) ===
                normalizeAddressString(
                  addressCoverage.amsId || addressCoverage.value
                )
            );
          }
          return matchingLocation?.amsId;
        }
      }

      // Default: Keep current value
      return current;
    });
  }, [
    amsLocations,
    getPhysicalAddressCoverage,
    hasAddress,
    lobTemplate.data.coverages,
  ]);

  // Set property addresses and
  // their schedules from an AMS policy/lob
  useEffect(() => {
    if (!amsPolicy) {
      return;
    }

    const amsLob = amsPolicy.lobs.find(lob => lob.lobSymbol === lobSymbol);

    if (!amsLob || !amsLob.schedules || !amsLob.locations) {
      return;
    }

    /**--Auto Schedules--**/
    if (!LOB_TYPES_ALLOWED_PROPERTY_DROPDOWN.includes(lobSymbol)) {
      setAmsSchedules(amsLob.schedules || []);
      return;
    }

    /**--Property Schedules--**/
    setAmsLocations(amsLob.locations);
    const locations = amsLob.locations.filter(
      ({ amsId }) => selectedLocationAmsId === amsId
    );

    const additionalLocations =
      selectedAdditionalAddresses.length && amsLob.locations?.length
        ? amsLob.locations.filter(loc =>
            selectedAdditionalAddresses.some(addr => addr.id === loc.amsId)
          )
        : [];

    //combine primary and additional addresses
    locations.push(...additionalLocations);

    const propertySchedules = locations.reduce((acc, address) => {
      const idTypes = ['blanketId', 'buildingId', 'locationId'];
      const location = address.address;
      for (const idType of idTypes) {
        if (address[idType]) {
          const matchingSchedules = amsLob.schedules.filter(
            schedule => schedule[idType] === address[idType]
          );

          acc.push(
            ...matchingSchedules.map(schedule => ({
              ...schedule,
              locationId: address.amsId, // locationId isn't unique enough
              name: location
                ? `${schedule.name} (${location.replace(
                    LOCATION_PREFIX_REGEX,
                    'Loc# $1/Bldg# $2'
                  )})`
                : schedule.name,
            }))
          );
          break;
        }
      }

      return acc;
    }, []);

    setAmsSchedules(propertySchedules);
  }, [
    lobSymbol,
    amsPolicy,
    lobTemplate.data.policy,
    selectedAdditionalAddresses,
    selectedLocationAmsId,
  ]);

  const hasAdditionalAddress = useMemo(() => {
    // Don't calculate while data is still loading
    if (lobTemplate.isValidating || !lobTemplate?.data) {
      return false;
    }

    return !!lobTemplate.data.addresses?.length;
  }, [lobTemplate.data, lobTemplate.isValidating]);

  // Initial render - set selectedAdditionalAddresses
  useEffect(() => {
    if (!hasAdditionalAddress) {
      return;
    }

    // If we have a value but it's not a UUID - update to amsId if possible
    setSelectedAdditionalAddresses(current => {
      if (current.length) {
        return current.map(item => {
          if (isUuid(item.value)) {
            return item;
          }

          const location = amsLocations?.find(loc => loc.address === item.text);

          return location
            ? {
                id: location.amsId,
                text: location.address,
                locationId: location.amsId,
              }
            : item;
        });
      }

      return lobTemplate.data.addresses.map(coverage => {
        const location = amsLocations?.find(
          loc => loc.amsId === coverage.amsId
        );

        return location
          ? {
              id: location.amsId,
              text: location.address,
              locationId: location.amsId,
            }
          : {
              id: coverage.amsId,
              text: coverage.value,
              locationId: coverage.amsId,
            };
      });
    });
  }, [amsLocations, hasAdditionalAddress, lobTemplate.data.addresses]);

  const propertyOptions = useMemo(() => {
    if (!amsLocations?.length) {
      return [];
    }

    const addresses =
      amsLocations.map(({ amsId, address }) => {
        return {
          text: address,
          value: amsId,
        };
      }) || [];

    addresses.push({ text: 'None', value: '' });

    return addresses;
  }, [amsLocations]);

  //primary address on change
  const onPrimaryAddressSelect = useCallback(option => {
    let hasChanged = true;

    setSelectedLocationAmsId(current => {
      if (current === option.value) {
        hasChanged = false;
      }
      return option.value;
    });

    setSelectedAdditionalAddresses([]);

    if (!hasChanged) {
      return;
    }

    setSelectedAdditionalAddresses(prevAddresses =>
      prevAddresses.filter(addr => addr.value !== option.value)
    );
    setClearSchedulesForm(true);
  }, []);

  // additional addresses on change
  const onAdditionalAddressSelect = useCallback(
    options => {
      const primaryLocationId = amsLocations.find(
        loc => loc.amsId === selectedLocationAmsId
      )?.amsId;

      const filteredOptions = primaryLocationId
        ? options.filter(opt => opt.id !== primaryLocationId)
        : options;

      const newSelectedIds = filteredOptions.map(loc => loc.id);

      const currentSelections = amsLocations
        .filter(loc => newSelectedIds.includes(loc.amsId))
        .map(({ amsId, address }) => ({
          id: amsId,
          text: address.replace(LOCATION_PREFIX_REGEX, 'Loc# $1/Bldg# $2'),
          locationId: amsId,
        }));

      setSelectedAdditionalAddresses(currentSelections);
    },
    [amsLocations, selectedLocationAmsId]
  );

  const getFilteredPropertyOptions = useCallback(() => {
    if (!selectedLocationAmsId) {
      return [];
    }

    const primaryLocationId = amsLocations.find(
      loc => loc.amsId === selectedLocationAmsId
    )?.amsId;
    const additionalPropertyOptions = primaryLocationId
      ? propertyOptions.filter(opt => opt.value !== primaryLocationId)
      : propertyOptions;

    return additionalPropertyOptions
      .map(({ value, text }) => ({
        id: value,
        text,
      }))
      .filter(({ text }) => text !== 'None'); // exclude 'None' option
  }, [amsLocations, propertyOptions, selectedLocationAmsId]);

  const addressDropdownComponent = useCallback(() => {
    if (!LOB_TYPES_ALLOWED_PROPERTY_DROPDOWN.includes(lobSymbol)) {
      return null;
    }
    return (
      <>
        <br />
        <Dropdown
          id="addressDropdown"
          data-testid="addressDropdown"
          label="Property"
          options={propertyOptions}
          value={selectedLocationAmsId}
          disabled={currentMode !== lobFormModes.MANAGER}
          optionEllipsisCutoffLength={60}
          onChange={onPrimaryAddressSelect}
          placeholder={
            currentMode === lobFormModes.CERTIFICATE
              ? 'No Property Selected'
              : 'Select Property'
          }
        />
        {['Acord 27', 'Acord 28'].includes(formTemplateId) && (
          <>
            <br />
            <Dropdown
              id="additionalAddressDropdown"
              data-testid="additionalAddressDropdown"
              multiSelect
              setSelectedItems={onAdditionalAddressSelect}
              selectedItems={selectedAdditionalAddresses}
              label="Additional Properties"
              options={getFilteredPropertyOptions()}
              disabled={currentMode !== lobFormModes.MANAGER}
              optionEllipsisCutoffLength={60}
              placeholder={
                currentMode === lobFormModes.CERTIFICATE
                  ? 'No Additional Properties Selected'
                  : !selectedLocationAmsId
                  ? 'Select Primary Property'
                  : 'Select Additional Properties'
              }
            />
          </>
        )}
      </>
    );
  }, [
    lobSymbol,
    propertyOptions,
    selectedLocationAmsId,
    currentMode,
    onPrimaryAddressSelect,
    formTemplateId,
    onAdditionalAddressSelect,
    selectedAdditionalAddresses,
    getFilteredPropertyOptions,
  ]);

  const acord27SchedulesComponent = useCallback(() => {
    return (
      <Acord27Schedules
        fieldMappings={fieldMappings}
        lobTemplate={lobTemplate}
        amsSchedules={amsSchedules}
        currentMode={currentMode}
        clearSchedulesForm={clearSchedulesForm}
        setClearSchedulesForm={setClearSchedulesForm}
        formPosition={formPositions.CENTER}
      />
    );
  }, [
    amsSchedules,
    clearSchedulesForm,
    currentMode,
    fieldMappings,
    lobTemplate,
  ]);

  return {
    additionalAddresses:
      selectedAdditionalAddresses
        .filter(a => a.value !== '')
        .map(({ id, text, locationId }) => ({
          amsId: id,
          value: text,
          locationId,
        })) || [],
    primaryLocationId: selectedLocationAmsId,
    setSelectedLocationAmsId,
    addressDropdownComponent,
    acord27SchedulesComponent,
    setSelectedAddresses: setSelectedAdditionalAddresses,
    amsLocations,
    setClearSchedulesForm,
  };
};
