import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { formatMappingValue, isNumericString } from '@/utils/helpers';
import cloneDeep from 'lodash/cloneDeep';
import { produce } from 'immer';
import Dropdown from '@/components/dropdown';
import { lobFormModes } from '@certificate_hero/enums';
import uniqBy from 'lodash/uniqBy';
import { ErrorContext } from '@/components/lob-templates/context';
import { formPositions } from '@/enums/form-positions';
import ScheduleTable from '@/components/lob-template-forms/schedules/schedule-table';
import { LOCATION_COVERAGE_REGEX } from '@/constants';

const { ADDITIONAL_SCHEDULES } = formPositions;

// the 27 has 10 fields...
// @todo - make this dynamic
const NUM_OF_SCHEDULE_FIELDS = 10;

const isScheduleEmpty = schedule =>
  !schedule.amsId && !schedule.name && !schedule.value && !schedule.deductible;

const shouldAddNewRow = (schedulesOnForm, currentMode) => {
  return (
    currentMode === lobFormModes.MANAGER && // in manager mode
    schedulesOnForm.length < NUM_OF_SCHEDULE_FIELDS && // still room on the form
    !schedulesOnForm.some(isScheduleEmpty) // no empty rows
  );
};

const Acord27Schedules = ({
  fieldMappings,
  formPosition,
  amsSchedules,
  currentMode,
  clearSchedulesForm,
  setClearSchedulesForm,
}) => {
  const [, setErrors] = useContext(ErrorContext);
  // all schedules on the form
  // default to one empty row... just in case we get a render before the data is loaded
  // we don't want the form to look empty
  const [allSchedulesOnForm, setAllSchedulesOnForm] = useState([
    {
      amsId: '',
      name: '',
      value: '',
      deductible: '',
      overflowAmountName: undefined,
      overflowDeductName: undefined,
      locationId: '',
    },
  ]);

  // this is used to keep track of which schedules are selected in the dropdown
  // because the options are loaded asynchronously, any initial selections will not have a text value
  // we will update the text value when the options are loaded
  const [selectedScheduleOptions, setSelectedScheduleOptions] = useState(() =>
    uniqBy(
      (fieldMappings?.current[formPosition] || [])
        .concat(fieldMappings?.current[ADDITIONAL_SCHEDULES] || [])
        .map(({ amsId }) => ({ id: amsId })) // only keep the amsId
        .filter(({ id }) => !!id), // remove any empty amsIds
      'id'
    )
  );

  /**
   * This boolean avoids a race condition between data hydration and editing schedule use effects
   */
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);

  //Helper function to grab ams values for schedules
  const getAmsValue = useCallback(
    (amsId, key) => {
      if (!amsId) {
        return 'Not Found';
      }

      const amsSchedule = amsSchedules.find(
        schedule => schedule.amsId === amsId
      );

      if (!amsSchedule || !amsSchedule[key]) {
        return 'Not Found';
      }
      return formatMappingValue(amsSchedule[key]);
    },
    [amsSchedules]
  );

  // options can be delayed in loading... so we need to update the selected options when they are loaded
  const scheduleOptions = useMemo(
    () =>
      amsSchedules.map(schedule => ({
        id: schedule.amsId,
        text: schedule.name,
      })) || [],
    [amsSchedules]
  );

  // simple boolean to trigger the useEffect below when the field mappings are loaded
  const hasFieldMappings = !!fieldMappings?.current?.[formPosition];

  useEffect(() => {
    // we need all these things to be true before we can move on
    // if they are not... get out... we will be back
    if (!(hasFieldMappings && formPosition && currentMode)) {
      return;
    }
    // this is the data that will be used to populate the form
    const schedulesMap = new Map(); // Use Map to deal with duplicating bug

    let schedule;
    // loop through the mapped fields and create a new schedule for each amsId
    // the mappedFields will have 3 rows per amsId; name, amount, and deductible (in that order)
    const mappedFields = fieldMappings.current[formPosition] || [];

    for (let i = 0; i < mappedFields.length; i += 3) {
      const name = mappedFields[i]?.value || '';
      const value = mappedFields[i + 1]?.value || '';
      const deductible = mappedFields[i + 2]?.value || '';

      // Try to find an amsId for a schedule from AMS via matching with current name
      const amsSchedule = amsSchedules.find(
        schedule =>
          schedule.name.replace(/\s+/g, ' ') === name.replace(/\s+/g, ' ')
      );

      const amsId = mappedFields[i]?.amsId || amsSchedule?.amsId || '';
      const locationId =
        mappedFields[i]?.locationId || amsSchedule?.locationId || '';

      schedule = {
        amsId,
        locationId,
        name,
        value: String(value || '').replace(/\$/g, ''),
        deductible: String(deductible || '').replace(/\$/g, ''),
      };

      // pre-existing forms with empty rows will be shifted up
      if (!isScheduleEmpty(schedule)) {
        const key = amsId || `${name}-${value}-${deductible}`;
        schedulesMap.set(key, schedule);
      }
    }

    // const additionalSchedules = [];
    const additionalFields = fieldMappings.current[ADDITIONAL_SCHEDULES] || [];

    // loop through the additional fields and create a new schedule for each amsId
    // the additionalFields will have 1 or 2 rows per amsId; amount and/or deductible
    for (let i = 0; i < additionalFields.length; i++) {
      // all additional fields should™ have an amsId... user entered data is restricted to mapped fields
      const amsId = additionalFields[i]?.amsId;
      const locationId = additionalFields[i]?.locationId;
      if (!amsId) {
        continue;
      }
      const existingSchedule = schedulesMap.get(amsId) || {
        amsId,
        locationId,
        name: '',
        value: '',
        deductible: '',
      };

      if (additionalFields[i]?.section === 'schedule-A') {
        existingSchedule.overflowAmountName = additionalFields[i].name;
        existingSchedule.value = additionalFields[i].value;
      } else if (additionalFields[i]?.section === 'schedule-D') {
        existingSchedule.overflowDeductName = additionalFields[i].name;
        existingSchedule.deductible = additionalFields[i].value;
      }

      schedulesMap.set(amsId, existingSchedule);
    }

    const mappedSchedules = Array.from(schedulesMap.values());

    if (shouldAddNewRow(mappedSchedules, currentMode)) {
      mappedSchedules.push({
        amsId: '',
        name: '',
        value: '',
        deductible: '',
        overflowAmountName: undefined,
        overflowDeductName: undefined,
        locationId: '',
      });
    }

    setAllSchedulesOnForm(mappedSchedules);

    // This indicates that everything is hydrated and is ready to be seen and edited by the user
    setIsInitialDataLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentMode,
    fieldMappings,
    formPosition,
    hasFieldMappings,
    setAllSchedulesOnForm,
  ]);

  // handle changes in the schedule selection dropdown
  // this is the only way a schedule can end up on the 101
  useEffect(() => {
    // if there are no options to select from or nothing is selected, do nothing
    if (!selectedScheduleOptions.length === 0) {
      setAllSchedulesOnForm([
        { amsId: '', name: '', value: '', deductible: '', locationId: '' },
      ]);
      return;
    }

    // update the list of all schedules
    setAllSchedulesOnForm(current => {
      const idsOnForm = current.length
        ? current.map(schedule => schedule.amsId)
        : [];

      const selectedOptionIds = selectedScheduleOptions.map(
        option => option.id
      );

      // Remove any schedules that are no longer selected
      const updatedSchedules = current.filter(
        schedule =>
          !schedule.amsId || selectedOptionIds.includes(schedule.amsId)
      );

      // Add any schedules that are selected but not on the form
      selectedScheduleOptions.forEach(option => {
        if (!idsOnForm.includes(option.id)) {
          const schedule = amsSchedules.find(
            schedule => schedule.amsId === option.id
          );

          // fill in the blank empty space
          const blankSpace = updatedSchedules.find(isScheduleEmpty);
          const updatedSchedule = blankSpace || {};

          updatedSchedule.amsId = schedule?.amsId;
          updatedSchedule.name = schedule?.name
            .replace(LOCATION_COVERAGE_REGEX, '') //Remove address within parenthesis
            .trim();
          updatedSchedule.value = schedule?.value;
          updatedSchedule.deductible = schedule?.deductible;
          updatedSchedule.locationId = schedule?.locationId;

          if (blankSpace) {
            return;
          }

          // otherwise add a new schedule
          updatedSchedules.push(updatedSchedule);
        }
      });

      if (shouldAddNewRow(updatedSchedules, currentMode)) {
        updatedSchedules.push({
          amsId: '',
          name: '',
          value: '',
          deductible: '',
          locationId: '',
        });
      }
      return updatedSchedules.map((schedule, idx) => {
        // we only need to deal with ams schedules
        if (!schedule.amsId) {
          return schedule;
        }
        const amsSchedule = amsSchedules?.find(s => s.amsId === schedule.amsId);

        // if we shifted an overflow schedule to a regular schedule, update the name
        // set the name to the ams schedule name for lack of a better option
        // remove the overflow names from the schedule; it is no longer an overflow schedule
        // it will never be an overflow schedule again
        if (
          idx < NUM_OF_SCHEDULE_FIELDS &&
          !schedule.name &&
          (schedule.overflowDeductName || schedule.overflowAmountName)
        ) {
          schedule.name = amsSchedule?.name;
          delete schedule.overflowDeductName;
          delete schedule.overflowAmountName;
        }

        // if we added a new schedule, and it overflows, set the overflow names
        if (
          idx >= NUM_OF_SCHEDULE_FIELDS &&
          amsSchedule?.name &&
          !schedule.overflowDeductName &&
          !schedule.overflowAmountName
        ) {
          schedule.overflowDeductName = `${amsSchedule?.name
            .replace(LOCATION_COVERAGE_REGEX, '') //Remove address within parenthesis
            .trim()} - Deductible`;
          schedule.overflowAmountName = `${amsSchedule?.name
            .replace(LOCATION_COVERAGE_REGEX, '') //Remove address within parenthesis
            .trim()} - Amount`;
        }

        return schedule;
      });
    });
  }, [
    amsSchedules,
    selectedScheduleOptions,
    scheduleOptions,
    currentMode,
    setAllSchedulesOnForm,
  ]);

  //  clear out schedules
  useEffect(() => {
    if (!clearSchedulesForm) {
      return;
    }

    setAllSchedulesOnForm([{ amsId: '', name: '', value: '', deductible: '' }]);
    setSelectedScheduleOptions([]);

    // Use Immer's produce to handle immutable updates
    fieldMappings.current = produce(fieldMappings.current, draft => {
      // Update the field mappings at formPosition
      draft[formPosition] = draft[formPosition].map(mapping => ({
        ...mapping,
        value: '',
        amsId: '',
      }));

      // Clear the additional schedules
      draft[formPositions.ADDITIONAL] = [];
    });

    setClearSchedulesForm(false);
  }, [clearSchedulesForm, fieldMappings, setClearSchedulesForm, formPosition]);

  // handle updating field mappings when the schedules on the form change
  useEffect(() => {
    // Wait for data to load before doing anything else.
    if (!isInitialDataLoaded) {
      return;
    }

    // Update fieldMappings if needed
    fieldMappings.current = produce(fieldMappings.current, draft => {
      const mappedFields = draft[formPosition];
      const updateSchedule = (schedule, index) => {
        if (schedule.amsId && !schedule.name) {
          schedule.name = getAmsValue(schedule.amsId, 'name');
        }

        mappedFields[index].amsId = schedule.amsId || '';
        mappedFields[index].locationId = schedule.locationId || '';
        mappedFields[index].value = schedule.name || '';

        mappedFields[index + 1].amsId = schedule.amsId || '';
        mappedFields[index + 1].locationId = schedule.locationId || '';
        mappedFields[index + 1].value = isNumericString(schedule.value)
          ? `$${schedule.value.replace('/$/g', '')}`
          : schedule.value || '';

        mappedFields[index + 2].amsId = schedule.amsId || '';
        mappedFields[index + 2].locationId = schedule.locationId || '';
        mappedFields[index + 2].value = isNumericString(schedule.deductible)
          ? `$${schedule.deductible.replace('/$/g', '')}`
          : schedule.deductible || '';
      };

      const schedulesToUpdate = cloneDeep(allSchedulesOnForm);
      for (let i = 0; i < mappedFields.length; i += 3) {
        const schedule = schedulesToUpdate.shift();
        if (schedule) {
          updateSchedule(schedule, i);
        }
      }

      // add the overflow schedules
      draft[ADDITIONAL_SCHEDULES] = schedulesToUpdate
        .map(schedule =>
          [
            {
              amsId: schedule.amsId,
              section: 'schedule-A',
              name:
                schedule.overflowAmountName ||
                (schedule.name ? `${schedule.name} - Amount` : ''),
              value: schedule.value,
              locationId: schedule.locationId,
            },
            {
              amsId: schedule.amsId,
              section: 'schedule-D',
              name:
                schedule.overflowDeductName ||
                (schedule.name ? `${schedule.name} - Deductible` : ''),
              value: schedule.deductible,
              locationId: schedule.locationId,
            },
          ].filter(s => !!s.value)
        )
        .flat();
    });

    // remove any errors related to coverage
    setErrors(prev => {
      const { coverage, ...restErrors } = prev;
      return restErrors;
    });
  }, [
    allSchedulesOnForm,
    getAmsValue,
    setErrors,
    fieldMappings,
    isInitialDataLoaded,
    formPosition,
  ]);

  return (
    <div className={styles.schedulesContainer}>
      <Dropdown
        className={styles.schedulesDropDown}
        multiSelect
        name="schedulesDropDown"
        label="Schedules"
        placeholder="SELECT SCHEDULE"
        options={scheduleOptions}
        disabled={currentMode !== lobFormModes.MANAGER}
        selectedItems={allSchedulesOnForm
          .map(({ amsId = '', name }) => ({
            id: amsId,
            text: name || getAmsValue(amsId, 'name'),
          }))
          .filter(({ id }) => !!id)}
        setSelectedItems={setSelectedScheduleOptions}
      />

      <ScheduleTable
        allSchedulesOnForm={allSchedulesOnForm}
        getAmsValue={getAmsValue}
        amsSchedules={amsSchedules}
        setAllSchedulesOnForm={setAllSchedulesOnForm}
        currentMode={currentMode}
        numberOfSchedules={NUM_OF_SCHEDULE_FIELDS}
        shouldAddNewRow={shouldAddNewRow}
        setSelectedScheduleOptions={setSelectedScheduleOptions}
      />
    </div>
  );
};
export default Acord27Schedules;
