export class AddressEntity {
  constructor({
    id,
    mailingAddress1,
    mailingAddress2,
    mailingAddress3,
    city,
    state,
    postalCode,
    country = 'USA',
    email,
    phone,
    fax,
    createdAt = null,
    updatedAt = null,
  }) {
    this.id = id;
    this.mailingAddress1 = mailingAddress1 || '';
    this.mailingAddress2 = mailingAddress2 || '';
    this.mailingAddress3 = mailingAddress3 || '';
    this.city = city || '';
    this.state = state || '';
    this.postalCode = postalCode || '';
    this.country = country;

    this.fullAddress =
      (this.mailingAddress1?.trim() ? `${this.mailingAddress1.trim()} ` : '') +
      (this.mailingAddress2?.trim() ? `${this.mailingAddress2.trim()} ` : '') +
      (this.mailingAddress3?.trim() ? `${this.mailingAddress3.trim()} ` : '') +
      (this.city?.trim() ? `${this.city.trim()}, ` : '') +
      (this.state?.trim() ? `${this.state.trim()} ` : '') +
      (this.postalCode?.trim() ? `${this.postalCode.trim()} ` : '');

    this.email = email || '';
    this.phone = phone || '';
    this.fax = fax || '';
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
