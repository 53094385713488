import BModal from 'react-bootstrap/Modal';
import cx from 'clsx';

import styles from './styles.module.scss';

/**
 * A wrapper around Bootstrap's Modal with some base settings to enforce consistent modal behavior throughout the app
 * @param {import('react-bootstrap/Modal').ModalProps&{
 *   title: string,
 *   renderBody: () => JSX.Element,
 *   renderFooter: () => JSX.Element,
 *   containerClassName: string,
 *   closeButton?: boolean,
 *   overflowType?: 'inherit' | 'auto' | 'hidden' | 'scroll' | 'visible',
 * }} props
 */
export const Modal = props => {
  const {
    title,
    renderBody,
    renderFooter,
    renderHeaderRight,
    containerClassName,
    contentClassName,
    children,
    closeButton = true,
    overflowType = 'inherit',
    ...modalProps
  } = props;

  return (
    <BModal
      centered
      keyboard
      backdrop="static"
      fullscreen="md-down"
      className={cx(styles.modal, containerClassName)}
      contentClassName={cx(
        styles.modalContent,
        contentClassName,
        overflowType === 'visible' && styles.overFlowVisible
      )}
      {...modalProps}
    >
      {children || (
        <>
          <BModal.Header closeButton={closeButton} className={styles.header}>
            <BModal.Title className={styles.title}>{title}</BModal.Title>
            {renderHeaderRight ? renderHeaderRight() : null}
          </BModal.Header>
          <BModal.Body style={{ overflow: overflowType }} tabIndex={-1}>
            {renderBody ? renderBody() : null}
          </BModal.Body>
          {renderFooter ? (
            <BModal.Footer className={styles.footer}>
              {renderFooter()}
            </BModal.Footer>
          ) : null}
        </>
      )}
    </BModal>
  );
};

Modal.Body = BModal.Body;
Modal.Header = BModal.Header;
Modal.Title = BModal.Title;
Modal.Footer = BModal.Footer;
