export class IssueEntity {
  /** @param {{ id: string, type: string, field: string, old?: unknown, newValue?: unknown }} params */
  constructor({
    id = null,
    type = 'none',
    field = 'none',
    old = 'none',
    newValue = 'none',
  }) {
    this.id = id;
    this.type = type;
    this.field = field;
    this.old = old;
    this.new = newValue;
    this.description = `${this.field} will go from ${this.old} to ${this.new}`;
  }

  toString() {
    return `${this.type}: ${this.description}`;
  }
}
