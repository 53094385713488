import { forwardRef } from 'react';
import NextLink from 'next/link';
import cx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import globalStyles from '@/styles/globals.module.scss';
import styles from '../styles.module.scss';

const ButtonLink = forwardRef((props, ref) => {
  const {
    href,
    onClick,
    disabled,
    iconLeft,
    iconRight,
    children,
    className,
    style,
    id,
    'data-testid': dataTestId,
    ...linkProps
  } = props;

  const classNames = cx(
    styles.link,
    globalStyles.link,
    globalStyles.truncate,
    disabled && globalStyles.disabled,
    className
  );

  const left = iconLeft ? (
    <div className={cx(styles.iconLeftContainer, styles.link)}>
      <FontAwesomeIcon icon={iconLeft} />
    </div>
  ) : null;

  const right = iconRight ? (
    <div className={cx(styles.iconRightContainer, styles.link)}>
      <FontAwesomeIcon icon={iconRight} />
    </div>
  ) : null;

  return href ? (
    <NextLink
      ref={ref}
      href={href}
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestId}
      id={id}
      className={classNames}
      style={style}
      {...linkProps}
    >
      {left}
      {children}
      {right}
    </NextLink>
  ) : (
    <button
      ref={ref}
      data-testid={dataTestId}
      type="button"
      onClick={onClick}
      disabled={disabled}
      id={id}
      className={cx(
        globalStyles.buttonReset,
        classNames,
        globalStyles.clickable
      )}
      style={style}
      {...linkProps}
    >
      {left}
      {children}
      {right}
    </button>
  );
});

export default ButtonLink;
