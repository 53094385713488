import Dropdown from '@/components/dropdown';
import { MAPPING_ACTIONS } from '@/enums/renewal-mapping-actions';
import useRenewingLobs from '@/components/renewals/hooks/use-renewing-lobs';
import { ButtonLink } from '@/components/button';
import { useContext } from 'react';
import { LobTemplateMappingContext } from '@/components/lob-templates/context';
import { DROPDOWN_OPTION_TEXT_ELLIPSIS_CUTOFF_LIMIT } from '@/constants';
import { truncateString } from '@/utils/helpers';
import styles from './styles.module.scss';

const { CLONE, MAP, DROP } = MAPPING_ACTIONS;

/**
 * @param {object} params
 * @param {LobTemplateEntity} params.currentLob
 * @param {() => void} params.onRenewalEdit
 * @param {RowNode} params.node
 */
const LobMapperDropdown = ({ currentLob, onRenewalEdit, node }) => {
  const { data: renewingLobs, isValidating } = useRenewingLobs();

  const [lobTemplateMappings, applyLobTemplateMapping] = useContext(
    LobTemplateMappingContext
  );

  // The cloned lob contains the new policy
  const { cloneLob } = currentLob;

  const getLobTemplateMapping = () => {
    return lobTemplateMappings?.find(
      mapping => mapping.oldLobTemplateId === currentLob.id
    );
  };

  const onChange = async selection => {
    const { action, id } = selection;

    if (action === CLONE) {
      onRenewalEdit(node.data.cloneLob);
      return;
    }

    applyLobTemplateMapping(currentLob.id, id, action);
  };

  const getDropdownValue = () => {
    if (isValidating && !renewingLobs.length) {
      return 'Loading...';
    }

    const lobTemplateMapping = getLobTemplateMapping();

    if (lobTemplateMapping) {
      if (lobTemplateMapping.action === DROP) {
        return 'Drop';
      }

      if (lobTemplateMapping.action === CLONE) {
        return cloneLob.name;
      }

      const selectedLob = renewingLobs.find(
        lob => lob.id === lobTemplateMapping.newLobTemplateId
      );

      return selectedLob?.name;
    }

    return '';
  };

  const isEditButtonRendered = () => {
    const lobTemplateMapping = getLobTemplateMapping();

    if (lobTemplateMapping) {
      if (lobTemplateMapping.action === DROP) {
        return false;
      }
    }

    return true;
  };

  const onEditClick = () => {
    const lobTemplateMapping = getLobTemplateMapping();

    if (lobTemplateMapping.action === CLONE) {
      onRenewalEdit(node.data.cloneLob);
    } else {
      const selectedLob = renewingLobs.find(
        lob => lob.id === lobTemplateMapping.newLobTemplateId
      );
      onRenewalEdit(selectedLob);
      applyLobTemplateMapping(currentLob.id, selectedLob.id, MAP);
    }
  };

  const filteredLobsBySymbol = renewingLobs
    .filter(
      lob =>
        lob.lobSymbol === currentLob.lobSymbol &&
        lob.id !== cloneLob.id &&
        lob.policy.id === cloneLob.policy.id
    )
    .map(option => ({
      id: option.id,
      text: option.name,
      action: MAP,
    }));

  const options = [
    {
      id: currentLob.id,
      text: cloneLob.name,
      action: CLONE,
      lob: currentLob,
      element: (
        <div className={styles.dropdownElement}>
          <div className={styles.newTag}>New</div>
          {cloneLob.name.length > DROPDOWN_OPTION_TEXT_ELLIPSIS_CUTOFF_LIMIT
            ? truncateString(
                cloneLob.name,
                DROPDOWN_OPTION_TEXT_ELLIPSIS_CUTOFF_LIMIT
              )
            : cloneLob.name}
        </div>
      ),
    },
    ...filteredLobsBySymbol,
    {
      divider: true,
    },
    {
      id: DROP,
      text: 'Drop LOB',
      action: DROP,
      disabled: getLobTemplateMapping()?.action === DROP,
    },
  ];

  return (
    <>
      <Dropdown
        id={currentLob.id}
        data-cy="lobTemplateMapper"
        drop="down"
        align="middle"
        name="mappingDropdown"
        disabled={isValidating && !renewingLobs.length}
        placeholder="Select LOB"
        value={getDropdownValue()}
        pending={isValidating || !lobTemplateMappings}
        options={options}
        onChange={onChange}
        containerClassName={styles.mappingDropdown}
      />
      <div className={styles.editSpace}>
        {isEditButtonRendered() ? (
          <ButtonLink
            onClick={() => onEditClick()}
            disabled={isValidating || !lobTemplateMappings}
          >
            Edit
          </ButtonLink>
        ) : null}
      </div>
    </>
  );
};

export default LobMapperDropdown;
