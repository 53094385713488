import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import useSWR from 'swr';
import { route } from 'nextjs-routes';
import { useBulkJobId } from './use-bulk-job-id';

/**
 * endorsementCounts - count of endorsements per cert
 * lobTemplateCounts - count of templates per cert
 * countOfCertWithValidEmail - count of certs with valid email
 * countOfCertWithValidFax - count of certs with valid fax
 * countOfCertsWithIssues - count of certs with issues that need to be resolved before issuing
 * certPolicyCounts - The number of certs each policy/lob is on
 * certIdsInBulkJob - list of cert ids in the bulk job
 */

/**
 * Get stats for the certs in the bulk job
 * @param {object} params
 * @param {string} [params.step=null] - (optional) The renewal step to get stats for (only needed for renewals)
 * @param {string[]} [params.certIds=null] - (optional) The list of cert ids to get stats for (if not provided, will get stats for all certs in the bulk job)
 */
export const useBulkStats = ({ step = null, certIds = null }) => {
  const bulkJobId = useBulkJobId();

  /**
   */
  const { data, mutate, isValidating } = useSWR(
    bulkJobId
      ? [
          route({
            pathname: '/api/v2/bulk-info/[id]',
            query: { id: bulkJobId },
          }),
          step,
          certIds,
        ]
      : null,
    async ([url, _step, _certIds]) => {
      const query = {};

      if (_step) {
        query.step = _step;
      }

      if (_certIds) {
        query.certIds = _certIds;
      }

      const res = await FrontendHttpClient.put(url, query);
      return res.data;
    }
  );

  return {
    ...data,
    bulkJobId,
    mutate,
    isValidating,
  };
};
