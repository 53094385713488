import { AmsPolicyEntity } from './ams-policy-entity';
import { PolicyLobEntity } from './policy-lob-entity';
import { LobTypeEntity } from './lob-type-entity';
import { CertificatePolicyEntity } from './certificate-policy-entity';

/**
 * @param {object} params
 * @param {boolean} params.baseFormContinued
 * @param {string[]} params.baseFormCoverageNames
 * @param {CertificatePolicyEntity>} params.certPolicy
 * @param {string} params.formType
 * @param {AmsPolicyEntity} params.insuredPolicy
 * @param {PolicyLobEntity} params.insuredPolicyLOB
 * @param {string} params.insurerLetter
 * @param {PolicyLobEntity} params.lob
 * @param {LobTypeEntity} params.lobType
 * @param {boolean} params.manualContinuation
 */
export class XfdfLobEntity {
  constructor({
    baseFormContinued,
    baseFormCoverageNames = [],
    certPolicy,
    formType,
    insuredPolicy,
    insuredPolicyLOB,
    insurerLetter,
    lob,
    lobType,
    manualContinuation,
    exceedingPhysicalDesc,
  }) {
    this.baseFormContinued = baseFormContinued;
    this.baseFormCoverageNames = baseFormCoverageNames;
    this.certPolicy = new CertificatePolicyEntity(certPolicy);
    this.formType = formType;
    this.insuredPolicy = new AmsPolicyEntity(insuredPolicy);
    this.insuredPolicyLOB = new PolicyLobEntity(insuredPolicyLOB);
    this.insurerLetter = insurerLetter;
    this.lob = new PolicyLobEntity(lob);
    this.lobType = new LobTypeEntity(lobType);
    this.manualContinuation = manualContinuation;
    this.exceedingPhysicalDesc = exceedingPhysicalDesc;
  }
}
