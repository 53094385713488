import { useState, useEffect } from 'react';

/**
 * React hook that tracks the changes in the intersection of a target element with an
 * ancestor element or with a top-level document's viewport. Uses the
 * [Intersection Observer API](https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API)
 * and returns an IntersectionObserverEntry.
 *
 * @param {RefObject<HTMLElement>} ref Target element ref
 * @param {IntersectionObserverInit} options IntersectionObserver options
 */
export const useIntersection = (ref, options) => {
  /** @type {useStateReturn<IntersectionObserverEntry>} */
  const [entry, setEntry] = useState(null);

  useEffect(() => {
    if (ref.current && typeof IntersectionObserver === 'function') {
      const handleEntries = entries => {
        setEntry(entries[0]);
      };

      const observer = new IntersectionObserver(handleEntries, options);
      observer.observe(ref.current);

      return () => {
        setEntry(null);
        observer.disconnect();
      };
    }

    return () => {};
    // eslint-disable-next-line
  }, [ref.current]);

  return entry;
};
