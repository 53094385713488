import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { CONTRACT_STATUS } from '@/constants';
import { ContractEntity } from '@/entities/contract-entity';
import { useCallback } from 'react';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import { route } from 'nextjs-routes';
import { useCertId } from './use-cert-id';
import { usePageContext } from './use-page-context';
import { useTemplate } from './use-template';

export const useContract = () => {
  const router = useRouter();
  const [pageContext, setPageContext] = usePageContext();
  const certId = useCertId();
  const template = useTemplate();
  const {
    contract,
    contractUrl,
    contractLoading,
    contractOcrLoading,
    coverages,
  } = pageContext;

  /**
   * @param {Partial<import('@/entities/contract-entity').ContractEntity} data
   */
  const updateContract = useCallback(
    async data => {
      // Remove current contract
      if (contract) {
        await FrontendHttpClient.put(
          route({
            pathname: '/api/v2/contracts/[id]',
            query: { id: contract.id },
          }),
          {
            status: CONTRACT_STATUS.deleted,
            deleted: true,
          }
        );
        setPageContext({ contractUrl: null });
      }

      // Create new contract
      const { filename, fileLink } = data;
      const contractData = new ContractEntity({
        certId,
        contractKeyPath: filename,
      });

      const res = await FrontendHttpClient.post(
        '/api/v2/contracts/contract',
        contractData
      );
      const createdContract = res.data;

      setPageContext({
        contractLoading: true,
        contract: createdContract,
        contractUrl: fileLink,
        isEditorReady: false,
      });
    },
    [certId, contract, setPageContext]
  );

  const removeContract = useCallback(() => {
    FrontendHttpClient.put(
      route({
        pathname: '/api/v2/contracts/[id]',
        query: { id: contract.id },
      }),
      {
        status: CONTRACT_STATUS.deleted,
        deleted: true,
      }
    );

    setPageContext({
      contractLoading: false,
      contract: null,
      contractUrl: null,
    });
  }, [contract, setPageContext]);

  // Updated - in insurance-section
  const addCoverage = () => {};

  // Updated - in insurance-section
  const removeCoverage = () => {};

  // Updated - in insurance-section
  const updateCoverage = () => {};

  // Updated - in insurance-requirements
  const addLob = () => {};

  // Does not exist yet - in insurance-requirements
  const removeLob = () => {};

  const setContract = record => {
    if (!record) {
      return;
    }

    const { contract: newContract, contractUrl: url } = record;

    if (newContract && url) {
      setPageContext({
        contract: newContract,
        hasContract: !!newContract,
        contractUrl: url,
      });
    }
  };

  const { mutate } = useSWR(
    !template.mode
      ? [
          route({ pathname: '/api/v2/contracts/contract' }),
          certId,
          router.pathname === '/editor/editor-layout',
        ]
      : null,
    async ([url, id]) => {
      const res = await FrontendHttpClient.get(url, {
        certId: id,
      });

      return res.data?.record;
    },
    {
      onSuccess: setContract,
      revalidateOnFocus: false,
    }
  );

  return {
    mutate,
    contract,
    contractUrl,
    contractLoading,
    contractOcrLoading,
    coverages,
    updateContract,
    removeContract,
  };
};
