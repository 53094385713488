import Dropdown from '@/components/dropdown';
import { bulkEditActions } from '@/enums/bulk-edit-actions';
import { MAPPING_ACTIONS } from '@/enums/renewal-mapping-actions';
import { useStore } from '@/hooks/use-store';

import styles from './styles.module.scss';
import { isFormSpecial } from '@certificate_hero/utils';

/**
 * @param {object} props
 * @param {BulkEditLobEntity} props.lob
 * @param {BulkEditLobEntity[]} props.formValuesLobs
 * @param {() => void} props.onChange
 */
export default function BulkJobLobActionsDropdown({
  lob,
  formValuesLobs,
  onChange,
}) {
  const [{ user, bulkJobEditCertIds }] = useStore();
  const lobInForm = formValuesLobs.find(formLob => formLob.id === lob.id);

  const certWord = isFormSpecial(lob?.formId) ? `${lob.formId}'s` : 'Certs';

  const totalCertCount = bulkJobEditCertIds?.length || 0;

  const isAddDisabled =
    (lobInForm && lobInForm.action === bulkEditActions.APPEND) ||
    lob?.certCount === totalCertCount ||
    lob.isInvalid;

  let disableAddTooltip = '';

  if (lob?.certCount === totalCertCount) {
    disableAddTooltip =
      'LOB Profile is already attached to all the certs being edited';
  }

  if (lob.isInvalid) {
    disableAddTooltip = `LOB Profile has an invalid AMS Value in its coverages`;
  }

  let dropdownValue = '';
  if (lobInForm) {
    if (lobInForm.action === bulkEditActions.APPEND) {
      dropdownValue = `Add LOB to Certs`;
    }
    if (lobInForm.action === bulkEditActions.REMOVE) {
      dropdownValue = 'Remove LOB from Certs';
    }
  }
  const options = [
    lobInForm && {
      text: '-',
      value: MAPPING_ACTIONS.CLEAR,
      disabled: !lobInForm,
    },
    user.accessKeys.LOB_TEMPLATES.CREATE
      ? {
          text: 'Edit LOB',
          value: MAPPING_ACTIONS.EDIT,
        }
      : null,
    // No need to add lob if is already attached to all certs
    lobInForm?.action !== bulkEditActions.APPEND && {
      text: `Add LOB to ${certWord}`,
      value: MAPPING_ACTIONS.ADD,
      disabled: isAddDisabled,
      tooltip: disableAddTooltip,
    },
    // No need to remove lob if is not attached to any cert
    lobInForm?.action !== bulkEditActions.REMOVE && {
      text: 'Remove LOB from Certs',
      value: MAPPING_ACTIONS.REMOVE,
      disabled:
        (lobInForm && lobInForm.action === bulkEditActions.REMOVE) ||
        lob?.certCount === 0,
      tooltip:
        lob?.certCount === 0
          ? 'LOB is not attached to any of the certs being edited'
          : '',
    },
  ];

  return (
    <Dropdown
      id={lob.id}
      name="action"
      placeholder="-"
      options={options}
      value={dropdownValue}
      containerClassName={styles.lobActionsDropdown}
      onChange={onChange(lob)}
    />
  );
}
