import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import useSWR from 'swr';
import { route } from 'nextjs-routes';
import { useStore } from './use-store';

export const usePolicies = () => {
  const [{ insuredId }] = useStore();

  /**
   * @type {SWRResponse<PolicyEntity[]>}
   */
  const { data, mutate, isValidating } = useSWR(
    insuredId
      ? [route({ pathname: '/api/v2/policies/internal' }), insuredId]
      : null,
    async ([url, id]) => {
      const res = await FrontendHttpClient.get(url, { insuredId: id });
      return res.data;
    }
  );

  return {
    data,
    mutate,
    isValidating,
  };
};
