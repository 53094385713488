export default Object.freeze(
  /** @type {const} */ ({
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
    SET_TO_DEFAULT_STATE: 'SET_TO_DEFAULT_STATE',
    SET_CAN_CHANGE_INSURED: 'SET_CAN_CHANGE_INSURED',
    SET_USER: 'SET_USER',
    SET_BROKER: 'SET_BROKER',
    SET_UNSAVED_CHANGES_EXIST: 'SET_UNSAVED_CHANGES_EXIST',
    SET_UNSAVED_NOTES_EXIST: 'SET_UNSAVED_NOTES_EXIST',
    SET_BULK_JOB_EDIT_CERT_IDS: 'SET_BULK_JOB_EDIT_CERT_IDS',
  })
);
