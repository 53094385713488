import { NAMED_INSURED_STATUS } from '@/constants';

export class NamedInsuredEntity {
  constructor({
    id,
    insuredId = '',
    name = '',
    mailingAddress1 = '',
    mailingAddress2 = '',
    city = '',
    state = '',
    postalCode = '',
    status = NAMED_INSURED_STATUS.active,
    isDefault = false,
    deactivatedDate = null,
    createdAt = new Date(),
    updatedAt = new Date(),
    deleted = false,
  }) {
    this.id = id;
    this.insuredId = insuredId;
    this.name = typeof name === 'string' ? name : '';
    this.mailingAddress1 =
      typeof mailingAddress1 === 'string' ? mailingAddress1 : '';
    this.mailingAddress2 =
      typeof mailingAddress2 === 'string' ? mailingAddress2 : '';
    this.city = typeof city === 'string' ? city : '';
    this.state = typeof state === 'string' ? state : '';
    this.postalCode = typeof postalCode === 'string' ? postalCode : '';
    this.status = status;
    this.isDefault = isDefault;
    this.deactivatedDate = deactivatedDate;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.deleted = deleted;
  }
}
