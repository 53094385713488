import NotificationService from '@/components/notification-service';
import { ENDORSEMENT_STATUS } from '@/constants';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { useRouter } from 'next/router';
import { route } from 'nextjs-routes';

import useSWR from 'swr';

export const useEndorsement = () => {
  const router = useRouter();

  const { endorsementId } = router.query;

  /**
   */
  const {
    data: endorsement,
    mutate,
    isValidating,
    isLoading,
  } = useSWR(
    endorsementId
      ? route({
          pathname: '/api/v2/endorsements/[id]',
          query: { id: endorsementId },
        })
      : null,
    { revalidateOnFocus: false }
  );

  /**
   * @param {EndorsementEntity} currentEndorsement
   * @param {ENDORSEMENT_STATUS[keyof ENDORSEMENT_STATUS]} status
   * @param {GridApi} [gridApi]
   * @param {() => void} [refreshGrid]
   */
  const changeEndorsementStatus = async (
    currentEndorsement,
    status,
    gridApi,
    refreshGrid
  ) => {
    const verb =
      status === ENDORSEMENT_STATUS.active ? 'Activated' : 'Deactivated';

    if (currentEndorsement) {
      try {
        await FrontendHttpClient.put(
          route({
            pathname: '/api/v2/endorsements/[id]',
            query: { id: currentEndorsement.id },
          }),
          { status }
        );
      } catch (err) {
        NotificationService.error('Error updating endorsement status');
        return;
      }
      NotificationService.success(`Endorsement ${verb.toLowerCase()}`);

      if (gridApi) {
        refreshGrid();
      }
    }
  };

  return {
    endorsement,
    changeEndorsementStatus,
    mutate,
    isValidating,
    isLoading,
  };
};
