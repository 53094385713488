import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import useSWR from 'swr';
import { route } from 'nextjs-routes';
import { useStore } from './use-store';

export const useNamedInsureds = () => {
  const [{ insuredId }] = useStore();

  const { data, mutate } = useSWR(
    insuredId
      ? [route({ pathname: '/api/v2/named_insureds' }), insuredId]
      : null,
    async ([url]) => {
      const res = await FrontendHttpClient.get(url);
      return res.data;
    }
  );

  return {
    data,
    namedInsureds: data || [],
    mutate,
  };
};
