class PillRenderer {
  init(params) {
    const { colorMap } = params;
    let valColor = null;
    if (params.value) {
      valColor = colorMap[params.value];
    }
    const color = valColor && valColor.color ? valColor.color : '';
    const bgColor =
      valColor && valColor.backgroundColor
        ? valColor.backgroundColor
        : valColor;

    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `
       <div class='grid-pill grid-standard-cell' style='color: ${color}; background-color: ${bgColor};'>
         <div class="my-value grid-pill-content"></div>
       </div>
     `;

    this.eValue = this.eGui.querySelector('.my-value');
    this.cellValue = this.getValueToDisplay(params);
    this.eValue.innerHTML = this.cellValue;
  }

  getGui() {
    return this.eGui;
  }

  // gets called whenever the cell refreshes
  refresh(params) {
    const { colorMap } = params;
    let valColor = null;
    if (params.value) {
      valColor = colorMap[params.value];
    }
    const color = valColor && valColor.color ? valColor.color : '';
    const bgColor =
      valColor && valColor.backgroundColor
        ? valColor.backgroundColor
        : valColor;

    this.cellValue = this.getValueToDisplay(params);
    this.eValue.innerHTML = this.cellValue;
    if (color) {
      this.eGui.querySelector('div').style.color = color;
    }
    if (bgColor) {
      this.eGui.querySelector('div').style.backgroundColor = bgColor;
    }

    return true;
  }

  destroy() {}

  getValueToDisplay(params) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
}

export default PillRenderer;
