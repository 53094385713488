import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { useCallback } from 'react';
import useSWR from 'swr';
import { route } from 'nextjs-routes';
import { useStore } from './use-store';

/**
 * @param {'descOps' | 'email'} [languageLibraryType]
 */
export const useLanguageLibraries = languageLibraryType => {
  const [{ user }] = useStore();

  let BASE_URL;

  if (user.accessKeys.LANGUAGE_LIBRARY.ACCESS) {
    BASE_URL = route({ pathname: '/api/v2/language_library' });
  }

  if (languageLibraryType === 'email') {
    BASE_URL = route({ pathname: '/api/v2/email_template_library' });
  }

  const { mutate, ...swr } = useSWR(BASE_URL);

  /**
   * Create a new language library
   */
  const create = useCallback(
    async data => {
      try {
        const response = await FrontendHttpClient.post(BASE_URL, data);
        mutate();
        return { ...response.data };
      } catch (err) {
        let errorMsg = 'Error creating language';

        let status = { success: false, message: errorMsg };
        if (err.response?.status === 409) {
          errorMsg = err.response.data.message;
          status = { ...err.response.data };
        }
        return status;
      }
    },
    [BASE_URL, mutate]
  );

  /**
   * Update an existing language library
   */
  const update = useCallback(
    async (id, newData) => {
      try {
        const response = await FrontendHttpClient.put(
          `${BASE_URL}/${id}`,
          newData
        );
        mutate();
        return { ...response.data };
      } catch (err) {
        let status = { success: false, message: 'Updated library failed' };
        if (err.response?.status === 409) {
          status = { ...err.response.data };
        }
        return status;
      }
    },
    [BASE_URL, mutate]
  );

  return {
    ...swr,
    mutate,
    create,
    update,
  };
};
