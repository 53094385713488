import Dropdown from '@/components/dropdown';

import globalStyles from '@/styles/globals.module.scss';
import { useContext, useEffect, useState } from 'react';
import { GridFilterDropdownContext } from './context';
import styles from './styles.module.scss';

/**
 * @typedef {{ [key: string]: { id: string, text: string } }} DropdownSelections
 */

/**
 * @param {object} params
 * @param {ColDef} params.colDef
 * @param {(field: string) => (value: DropdownOption[]) => void} params.onFilter
 * @param {string} params.defaultValue
 */
const RenderMultiDropdown = ({ colDef, onFilter, defaultValue }) => {
  const { field, headerName, customFilterParams } = colDef;
  const [dropdownSelections, setDropdownSelections] = useState(
    defaultValue?.map(val => {
      return { id: val, text: val };
    }) || []
  );
  const [, setResetHandlers] = useContext(GridFilterDropdownContext);

  useEffect(() => {
    setResetHandlers(prev => [...prev, () => setDropdownSelections([])]);
  }, [setResetHandlers]);

  useEffect(() => {
    onFilter(field)(dropdownSelections.map(selection => selection?.id));
  }, [dropdownSelections, field, onFilter]);

  if (!customFilterParams) {
    return null;
  }

  return (
    <div className={globalStyles.col}>
      <Dropdown
        multiSelect
        name={`filter-select-${field}`}
        options={customFilterParams?.options || []}
        label={`Select ${headerName}`}
        placeholder={`Filter by "${headerName}"`}
        selectedItems={dropdownSelections}
        setSelectedItems={setDropdownSelections}
        containerClassName={styles.statusDropdown}
      />
    </div>
  );
};

export default RenderMultiDropdown;
