import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import useSWR from 'swr';
import { route } from 'nextjs-routes';
import { useBulkJobId } from './use-bulk-job-id';

/**
 */

export const useBulkJob = () => {
  const bulkJobId = useBulkJobId();

  /**
   */
  const { data, mutate, isValidating } = useSWR(
    bulkJobId
      ? [
          route({
            pathname: '/api/v2/bulk_jobs/[id]',
            query: { id: bulkJobId },
          }),
        ]
      : null,
    async ([url]) => {
      const res = await FrontendHttpClient.get(url);
      return res.data;
    }
  );

  return {
    data,
    bulkJobId,
    mutate,
    isValidating,
  };
};
