import useSWR from 'swr';
import { route } from 'nextjs-routes';

export const useAssignedUsers = () => {
  const { mutate, data } = useSWR(
    route({
      pathname: '/api/v2/outbound_certificates/utils/assigned_user_options',
    })
  );
  return { assignedUsers: data?.result, mutate };
};
