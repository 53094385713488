export const changeHistoryEntities = Object.freeze(
  /** @type {const} */ ({
    OUTBOUND_CERTIFICATES: 'outbound_certificates',
    ENDORSEMENTS: 'endorsements',
    COLLECTIONS: 'collections',
    ENDORSEMENT_FORMS: 'endorsement_forms',
    INSUREDS: 'insureds',
    CERTIFICATE_TEMPLATES: 'certificatetemplates',
  })
);

export const allowedChangeHistoryEntities = Object.values(
  changeHistoryEntities
);

export const changeHistorySystemUserUuid =
  '00000000-0000-1000-8000-000000000000';
