import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

import GridFilter from '@/components/grid-filter';
import format from 'date-fns/format';
import { toDate } from 'date-fns-tz';

const isDate = value => !Number.isNaN(Date.parse(value));

/**
 * @param {object} param
 * @param {GridApi} param.gridApi
 * @param {ColumnApi} param.columnApi
 * @param {string} [param.searchName]
 * @param {number} [param.recordCount]
 * @param {string[]} param.defaultStatusFilter Which statuses to filter by default
 * @param {string} param.remoteDataEndpoint
 */
export const useGridFilter = ({
  gridApi,
  columnApi,
  searchName,
  recordCount,
  defaultStatusFilter,
  remoteDataEndpoint,
}) => {
  const defaultFilterModel = useMemo(() => {
    if (!defaultStatusFilter) {
      return {};
    }

    return {
      status: {
        filter: defaultStatusFilter,
        type: 'withinArray',
        filterType: 'text',
      },
    };
  }, [defaultStatusFilter]);

  const defaultInputs = useMemo(() => {
    if (!defaultStatusFilter) {
      return {};
    }

    return {
      status: defaultStatusFilter,
    };
  }, [defaultStatusFilter]);

  const [inputs, setInputs] = useState(defaultInputs);

  const [searchInput, setSearchInput] = useState('');

  const [filterModel, setFilterModel] = useState(defaultFilterModel);

  const [columns, setColumns] = useState([]);
  const [currentColumnApi, setCurrentColumnApi] = useState(columnApi);

  // Used so that we can show the right count of inputs that have changed
  const usingDefaultInputs = useMemo(() => {
    return isEqual(inputs.status, defaultInputs.status);
  }, [defaultInputs, inputs]);

  useEffect(() => {
    setCurrentColumnApi(columnApi);
  }, [columnApi]);

  useEffect(() => {
    const cleanup = () => {
      setCurrentColumnApi(null);
    };

    if (!currentColumnApi) {
      return cleanup;
    }

    const allColumns = currentColumnApi.getAllGridColumns() || [];

    const newColumns = allColumns.filter(
      c =>
        !['action', 'actionMenu'].includes(c.colId) &&
        !c?.userProvidedColDef?.suppressFilter
    );

    setColumns(newColumns);

    return cleanup;
  }, [currentColumnApi]);

  const handleFilterChange = useCallback(
    field => value => {
      if (Array.isArray(value) && !value.length) {
        setInputs(prev => ({ ...prev, [field]: null }));
        return;
      }

      setInputs(prev => ({ ...prev, [field]: value }));
    },
    []
  );

  const handleReset = () => {
    setInputs(defaultInputs);
    setFilterModel(JSON.parse(JSON.stringify(defaultFilterModel)));
    if (gridApi) {
      gridApi.deselectAll();
    }
  };

  const refreshFilter = useRef(
    debounce((_inputs, _gridApi, _columns, _filterModel) => {
      const updatedModel = {};
      const keys = Object.keys(_inputs).filter(key => !!_inputs[key]);

      for (let i = 0; i < keys.length; i++) {
        const field = keys[i];
        const value = _inputs[field];

        const col = _columns.find(column => {
          return column.colId === field;
        });

        const colFilter = col?.colDef?.filter;

        let type = 'contains';

        let filterType = 'text';
        if (colFilter === 'agNumberColumnFilter') {
          filterType = 'number';
          type = 'equals';
        }
        if (colFilter === 'agDateColumnFilter') {
          filterType = 'date';
          type = 'inRange';
        }

        // Custom status filter
        if (field === 'status') {
          type = 'equals';
        }

        const filterBody = {
          filter: value,
          type,
          filterType,
        };

        if (colFilter === 'agBooleanColumnFilter') {
          filterBody.filterType = 'boolean';
          filterBody.type = 'booleanEquals';

          if (value === 'Yes') {
            filterBody.filter = 'true';
          } else {
            filterBody.filter = 'false';
          }
        }

        if (Array.isArray(value)) {
          filterBody.type = 'withinArray';
        }

        if (
          colFilter === 'agDateColumnFilter' &&
          col?.colDef?.customFilter !== 'expirationDateColumnFilter'
        ) {
          const [dateFrom, dateTo] = value.split('_');

          if (isDate(dateFrom) && isDate(dateTo)) {
            filterBody.type = 'inRange';
            if (remoteDataEndpoint) {
              filterBody.dateFrom = dateFrom;
              filterBody.dateTo = dateTo;
            } else {
              // local date filtering doesn't include the date itself for ranges.
              // So to look at Feb 5th, we need to look at Feb 4th to Feb 6th
              const dayEarlier = toDate(dateFrom);
              dayEarlier.setDate(dayEarlier.getDate() - 1);
              filterBody.dateFrom = format(dayEarlier, 'yyyy-MM-dd');

              const dayLater = toDate(dateTo);

              dayLater.setDate(dayLater.getDate() + 1);
              filterBody.dateTo = format(dayLater, 'yyyy-MM-dd');
            }
          } else if (isDate(dateFrom) && !isDate(dateTo)) {
            filterBody.type = 'greaterThan';
            filterBody.dateFrom = dateFrom;
          } else if (!isDate(dateFrom) && isDate(dateTo)) {
            filterBody.type = 'lessThan';
            filterBody.dateFrom = dateTo;
          }
        }

        if (col?.colDef?.customFilter === 'expirationDateColumnFilter') {
          if (value) {
            filterBody.type = 'greaterThan';
            filterBody.filterType = 'date';
            filterBody.dateFrom = format(new Date(), 'yyyy-MM-dd');
          }
        }

        updatedModel[col?.colDef?.filterField || field] = filterBody;
      }

      if (!isEqual(updatedModel, _filterModel)) {
        setFilterModel(updatedModel);

        if (_gridApi) {
          _gridApi.deselectAll();
        }
      }
    }, 330)
  );

  useEffect(() => {
    if (refreshFilter.current) {
      refreshFilter.current(inputs, gridApi, columns, filterModel);
    }

    // We need to pass in the filter model here to see if the value has changed
    // but we don't want to trigger a re-render when it changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs, gridApi, columns]);

  useEffect(() => {
    // Refresh the grid if a filter changes
    if (gridApi) {
      gridApi.setFilterModel(filterModel);
      gridApi.onFilterChanged();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterModel]);

  const headerLeft = (
    <GridFilter
      gridApi={gridApi}
      columnApi={columnApi}
      columns={columns}
      inputs={inputs}
      searchInput={searchInput}
      searchName={searchName}
      recordCount={recordCount}
      setSearchInput={setSearchInput}
      onFilter={handleFilterChange}
      onReset={handleReset}
      usingDefaultInputs={usingDefaultInputs}
    />
  );

  const filterProps = {
    headerLeft,
  };

  return {
    columns,
    inputs,
    searchInput,
    filterModel,
    filterProps,
    resetFilter: () => {
      handleReset();
      setSearchInput('');
    },
  };
};
