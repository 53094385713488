import { allowedChangeHistoryActions } from '@/enums/change-history-actions';
import { allowedChangeHistoryEntities } from '@/enums/change-history-enitities';

export class ChangeHistoryEntity {
  constructor({
    id,
    createdAt,
    updatedAt,
    entityType,
    entityId,
    entity,
    action,
    userId,
  }) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.entityId = entityId;
    this.entity = entity;
    this.userId = userId;

    if (!allowedChangeHistoryEntities.includes(entityType)) {
      throw Error('Invalid Entity Type');
    }

    this.entityType = entityType;

    if (!allowedChangeHistoryActions.includes(action)) {
      throw Error('Invalid Entity Action');
    }

    this.action = action;
  }
}
