import accessKeyTemplate from '../enums/access-key-template';

/**
 * Enables all user access keys for testing purposes
 *
 * @param {typeof accessKeyTemplate} obj
 */
export const enableUserKeys = obj => {
  const newObj = {};
  Object.entries(obj).forEach(([key, value]) => {
    const newValue = {};
    Object.entries(value).forEach(([k, v]) => {
      newValue[k] = !v;
    });
    newObj[key] = newValue;
  });

  return newObj;
};

/**
 * @typedef {State} DefaultState
 */
export default {
  insuredId: '',
  insuredName: '',
  broker: {
    branding: {},
  },
  user: {
    certificateFlow: 'OUTBOUND',
    accessKeys: process.env.JEST_WORKER_ID
      ? enableUserKeys(accessKeyTemplate)
      : accessKeyTemplate,
  },
  unsavedNotesExist: false,
  unsavedChangesExist: {},
  canChangeInsured: true,
  bulkJobEditCertIds: [],
};
