import { SignatureEntity } from '@/entities/signature-entity';
import useSWR from 'swr';
import { route } from 'nextjs-routes';
import { useMemo } from 'react';

/**
 * @returns {{signatures: SignatureEntity[], mutate: function():void}}
 */
export const useSignatures = () => {
  const { data, mutate } = useSWR(route({ pathname: '/api/v2/signatures' }));

  const signatures = useMemo(() => {
    return (
      (Array.isArray(data) &&
        data
          .sort(o => (o.isDefault ? -1 : 1))
          .map(o => new SignatureEntity(o))) ||
      []
    );
  }, [data]);

  return {
    signatures,
    mutate,
  };
};
