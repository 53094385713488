import useSWR from 'swr';
import { useRouter } from 'next/router';
import { route } from 'nextjs-routes';

/**
 * @param {object} param
 * @param {'inbound' | 'outbound'} param.type
 * @returns {object} Certificate History List
 */
export const useCertificateHistory = ({ type }) => {
  const router = useRouter();

  const { certId, bulkJobId } = router.query;

  const endpoint =
    type === 'outbound'
      ? route({
          pathname: '/api/v2/outbound_certificates/[id]/history',
          query: { id: certId, bulkJobId: bulkJobId || '' },
        })
      : route({
          pathname: '/api/v2/inbound_certificates/[id]/history',
          query: { id: certId },
        });

  const { data, error } = useSWR(certId ? endpoint : null);

  return error ? null : data;
};
