import NotificationService from '@/components/notification-service';
import { STATUS } from '@/constants';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import useSWR from 'swr';
import { CHError } from '@/utils/errors';
import { min } from 'date-fns';
import { route } from 'nextjs-routes';
import { useStore } from './use-store';
import { useNotes } from './use-notes';
import { usePageContext } from './use-page-context';
import { useCertificate } from './use-certificate';
import { useLobTemplates } from './use-lob-templates';

export const useTemplate = () => {
  const [pageContext, setPageContext] = usePageContext();
  const [{ user, unsavedNotesExist }] = useStore();
  const notes = useNotes();
  const router = useRouter();
  const certificate = useCertificate();
  const lobTemplates = useLobTemplates({ enableAllForms: true });

  const { saveTemplateLoading, template, certContext } = pageContext;
  const { templateMode, userTemplateId } = router.query;

  const { data, mutate } = useSWR(
    userTemplateId
      ? [route({ pathname: '/api/v2/certificate_templates' }), userTemplateId]
      : null,
    async ([url, templateId]) => {
      const res = await FrontendHttpClient.get(url, { id: templateId });
      return res.data;
    }
  );

  const addEarliestExpDate = useCallback(
    templateData => {
      const lobTemplateIds = templateData.lobTemplates.map(
        lobTemplate => lobTemplate.id
      );

      const policyExpirationDates = lobTemplates.lobs
        ?.map(lob => {
          if (!lobTemplateIds.includes(lob.id)) {
            return null;
          }
          return new Date(lob.policy.expirationDate);
        })
        .filter(Boolean);

      return {
        ...templateData,
        earliestExpDate: min(policyExpirationDates),
      };
    },
    [lobTemplates]
  );

  const save = useCallback(async () => {
    const { id, status, templateName } = template;

    setPageContext({
      isEditorReady: false,
      saveTemplateLoading: true,
    });

    try {
      const templateData = {
        ...certContext.save(),
        status: template.status === STATUS.draft ? STATUS.active : status,
        updatePDF: true,
        action: 'edit',
      };

      if (!templateData.lobTemplates.length) {
        throw new CHError('A template must include at least one policy');
      }

      const newTemplateData = addEarliestExpDate(templateData);

      if (unsavedNotesExist) {
        await notes.save();
      }

      await FrontendHttpClient.put(
        route({
          pathname: '/api/v2/certificate_templates/[id]',
          query: { id },
        }),
        newTemplateData
      );

      certificate.mutateCertContext();

      NotificationService.success(`Template ${templateName} saved`);
    } catch (err) {
      setPageContext({
        isEditorReady: true,
        saveTemplateLoading: false,
      });

      let errorMessage;
      switch (true) {
        case err.response?.status === 409:
          errorMessage = `Template by the name "${templateName}" already exist`;
          break;
        case err instanceof CHError:
          errorMessage = err.message;
          break;
        default:
          errorMessage = 'Error saving template';
      }

      NotificationService.error(errorMessage);

      throw err;
    }
  }, [
    addEarliestExpDate,
    certContext,
    certificate,
    notes,
    setPageContext,
    template,
    unsavedNotesExist,
  ]);

  const create = useCallback(
    async name => {
      setPageContext({
        isEditorReady: false,
        saveTemplateLoading: true,
      });

      try {
        const templateData = {
          templateName: name,
          status: STATUS.active,
          ...certContext.save(),
          createdByUserId: user.id,
          action: 'create',
        };

        if (!templateData.lobTemplates.length) {
          throw new CHError('A template must include at least one policy');
        }

        const newTemplateData = addEarliestExpDate(templateData);

        if (unsavedNotesExist) {
          await notes.save();
        }

        await FrontendHttpClient.post('/api/v2/certificate_templates', {
          template: newTemplateData,
        });

        NotificationService.success(`Template ${name} saved`);
      } catch (err) {
        if (err.response?.status === 409) {
          NotificationService.error(
            `Template by the name "${name}" already exist`
          );
        } else {
          NotificationService.error(
            err instanceof CHError ? err.message : 'Error creating template'
          );
        }
        setPageContext({
          isEditorReady: true,
          saveTemplateLoading: false,
        });
        throw err;
      }
      setPageContext({
        isEditorReady: true,
        saveTemplateLoading: false,
      });
    },
    [
      addEarliestExpDate,
      certContext,
      notes,
      setPageContext,
      unsavedNotesExist,
      user.id,
    ]
  );

  const remove = useCallback(async () => {
    const res = await FrontendHttpClient.post(
      route({
        pathname: '/api/v2/certificate_templates/[id]/[...params]',
        query: { id: template.id, params: ['delete'] },
      })
    );

    return res.data;
  }, [template]);

  const rename = useCallback(
    async newName => {
      try {
        const res = await FrontendHttpClient.post(
          route({
            pathname: '/api/v2/certificate_templates/[id]/[...params]',
            query: { id: template.id, params: ['rename'] },
          }),
          { templateName: newName, insuredId: template.insuredId }
        );
        mutate();
        return res.data;
      } catch (err) {
        if (err.response?.status === 409) {
          NotificationService.error(
            `Template by the name "${newName}" already exist`
          );
        }
        return { success: false };
      }
    },
    [mutate, template]
  );

  const activate = useCallback(async () => {
    const res = await FrontendHttpClient.post(
      route({
        pathname: '/api/v2/certificate_templates/[id]/[...params]',
        query: { id: template.id, params: ['activate'] },
      })
    );

    mutate();
    return res.data;
  }, [mutate, template]);

  const deactivate = useCallback(async () => {
    const res = await FrontendHttpClient.post(
      route({
        pathname: '/api/v2/certificate_templates/[id]/[...params]',
        query: { id: template.id, params: ['deactivate'] },
      })
    );

    mutate();
    return res.data;
  }, [mutate, template]);

  useEffect(() => {
    if (data) {
      setPageContext({ template: data.record[0] });
    }
  }, [data, setPageContext]);

  return {
    mode: templateMode,
    isLoading: saveTemplateLoading,
    data: template,
    get: mutate,
    save,
    create,
    remove,
    rename,
    activate,
    deactivate,
  };
};
