import WarningModal from '@/components/warning-modal';
import { useModal } from './use-modal';
import { useStore } from './use-store';

/**
 * A hook that handles logic for unsaved changes warning
 * @param {object} props
 * @param {string} [props.formName] Name of the formik form to clear unsaved changes for; if not provided, all forms will be cleared
 * @param {() => void} props.handleContinue
 */
export function useUnsavedChangesWarning({ formName, handleContinue }) {
  const [{ unsavedChangesExist, unsavedNotesExist }, setStore] = useStore();
  const modal = useModal();

  const hasUnsavedChanges = key => {
    if (key) {
      return key in unsavedChangesExist;
    }
    return Object.keys(unsavedChangesExist).length > 0;
  };

  const handleClick = () => {
    if (hasUnsavedChanges(formName) || unsavedNotesExist) {
      modal.show(WarningModal, {
        handleContinue: () => {
          setStore({
            unsavedChangesExist: formName ? { [formName]: false } : {},
            unsavedNotesExist: false,
          });
          handleContinue();
        },
      });
    } else {
      handleContinue();
    }
  };

  const renderWarningModal = () => <></>;

  return {
    unsavedChangesExist,
    unsavedNotesExist,
    handleClick,
    renderWarningModal,
  };
}
