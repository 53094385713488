import { useState } from 'react';
import useSWR from 'swr';
import { AgencyContactEntity } from '@/entities/agency-contact-entity';
import { route } from 'nextjs-routes';

export const useAgencyContact = () => {
  const [contacts, setContacts] = useState([]);

  const { mutate, isValidating } = useSWR(
    route({ pathname: '/api/v2/agency' }),
    {
      onSuccess: data => {
        const contactData = data
          .sort(o => (o.isDefault ? -1 : 1))
          .map(o => new AgencyContactEntity(o));
        setContacts(contactData);
      },
    }
  );
  return { contacts, mutate, isValidating };
};
