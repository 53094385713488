import useSWR from 'swr';
import { useBulkJobId } from '@/hooks/use-bulk-job-id';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { route } from 'nextjs-routes';

/**
  List of policies that we can map to during the renewal process
 */
const useRenewingPolicies = () => {
  const bulkJobId = useBulkJobId();

  /**
   */
  const { data, mutate, isValidating } = useSWR(
    bulkJobId
      ? [route({ pathname: '/api/v2/policies/renewing' }), bulkJobId]
      : null,
    async ([url, id]) => {
      const res = await FrontendHttpClient.get(url, {
        bulkJobId: id,
      });
      return res.data;
    }
  );

  return {
    renewalTargetPolicies: data,
    mutate,
    isValidating,
  };
};

export default useRenewingPolicies;
