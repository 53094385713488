import NotificationService from '@/components/notification-service';
import { STATUS } from '@/constants';
import { certificateFlows } from '@/enums/certificate-flows';
import { validObjectNames } from '@/enums/model-map';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { splitText } from '@/utils/helpers/strings';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import cloneDeep from 'lodash/cloneDeep';
import { route } from 'nextjs-routes';
import { usePageContext } from './use-page-context';
import { useNotes } from './use-notes';
import { useStore } from './use-store';
import { useEndorsements } from './use-endorsements';

export const useCertificate = () => {
  const [{ certContext, sampleCert }, setPageContext] = usePageContext();
  const [{ user, unsavedNotesExist }] = useStore();
  const router = useRouter();
  const notes = useNotes();

  const { push, pathname } = router;
  const { certId, bulkJobId, status, userTemplateId, templateMode } =
    router.query;

  const [certificate, setCertificate] = useState(null);

  const [contextLoaded, setContextLoaded] = useState(false);

  const [fileLink, setFileLink] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [bulkJobCount, setBulkJobCount] = useState(null);
  const [assignedUser, setAssignedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [objName, setObjName] = useState(null);
  const [objId, setObjId] = useState(null);

  // this should be in useCertificateEditor
  const { data: certEditorData, mutate: mutateCertContext } = useSWR(
    (pathname === '/editor/editor-layout' ||
      pathname === '/editorv2/editor-layoutv2' ||
      pathname === '/renew/edit-cert-template' ||
      pathname === '/certificate-templates/edit') &&
      objName &&
      objId
      ? [route({ pathname: '/api/v2/cert_context' }), objName, objId]
      : null,
    async ([url, name, id]) => {
      const res = await FrontendHttpClient.get(url, {
        objName: name,
        objId: id,
      });
      return res.data;
    },
    {
      onSuccess: () => {
        setContextLoaded(true);
      },
    }
  );

  const { data: certData, mutate: mutateCertData } = useSWR(
    certId
      ? [route({ pathname: '/api/v2/certificates' }), certId, bulkJobId]
      : null,
    async ([url, id, bulkId]) => {
      const res = await FrontendHttpClient.get(url, { id, bulkJobId: bulkId });
      return res.data;
    }
  );

  const { endorsements } = useEndorsements();

  // todo: make a useUsers hook?
  const { data: userData } = useSWR(
    certificate?.assignedUserId
      ? [route({ pathname: '/api/v2/users' }), certificate?.assignedUserId]
      : null,
    async ([url, id]) => {
      const res = await FrontendHttpClient.get(url, { id });
      return res.data?.record;
    }
  );

  const addEndorsementsToContext = useCallback(
    context => {
      if (!endorsements) {
        return { ...context, insuredEndorsements: [] };
      }

      let insuredEndorsements = cloneDeep(endorsements);

      if (!user.accessKeys.ENDORSEMENTS.TAGS_ALLOWED) {
        insuredEndorsements = endorsements.map(endorsement => {
          const clone = cloneDeep(endorsement);
          delete clone.tags;

          if (clone.endorsementForm) {
            delete clone.endorsementForm.tags;
          }

          return clone;
        });
      }

      return { ...context, insuredEndorsements };
    },
    [endorsements, user.accessKeys]
  );

  // todo: this should moved outside of hook
  const goToFileCabinet = useCallback(() => {
    if (bulkJobId) {
      if (certificate.bulkJobCount === 0) {
        // TODO: this route doesn't exist
        push(`/bulk-job/bulk-job-layout`);
      } else {
        // TODO: this route doesn't exist
        push(`/bulk-job/bulk-job-editor/${bulkJobId}`);
      }
    } else {
      push({
        pathname: '/certificates/[tab]',
        query: { tab: 'manager' },
      });
    }
  }, [bulkJobId, certificate, push]);

  const getCertContext = useCallback(async () => {
    if (templateMode || userTemplateId) {
      setObjName(validObjectNames.CERTIFICATE_TEMPLATES);
      setObjId(userTemplateId);
    } else if (certId) {
      const selectedObjName = bulkJobId
        ? validObjectNames.BULK_JOB_CERTIFICATES
        : validObjectNames.OUTBOUND_CERTIFICATES;

      setObjName(selectedObjName);
      setObjId(certId);
    }

    if (!certEditorData || !contextLoaded) {
      return;
    }
    const context = addEndorsementsToContext(certEditorData.record);

    // this will trigger useEffect in editor-layout
    setPageContext({ certContext: context });
  }, [
    addEndorsementsToContext,
    bulkJobId,
    certEditorData,
    certId,
    contextLoaded,
    setPageContext,
    templateMode,
    userTemplateId,
  ]);

  const getCertData = useCallback(async () => {
    if (!certData) {
      return;
    }

    const { record } = certData;

    const cert = record?.certificate;
    const link = record?.fileLink;
    const name = record?.fileName;
    const sample = record?.sampleCertificate;

    if (!cert) {
      return;
    }

    setCertificate(cert);
    setFileLink(link);
    setFileName(name);

    // We don't want to keep setting the same cert every time
    // useCertificate is used in the new multi-column editor
    if (!sampleCert && sample) {
      setPageContext({
        sampleCert: sample,
      });
    }

    if (bulkJobId) {
      const count = record?.bulkJobCount;
      setBulkJobCount(count);
    }

    const certIsActive =
      cert?.status !== STATUS.inProgress && cert?.status !== STATUS.draft;

    if (certIsActive && pathname !== '/review/review-layout' && bulkJobId) {
      push({
        pathname: '/review/review-layout',
        query: { certId, bulkJobId },
      });
    }

    if (!templateMode && userData) {
      setAssignedUser({
        id: userData.id,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
      });
    }
  }, [
    certData,
    sampleCert,
    bulkJobId,
    pathname,
    templateMode,
    userData,
    setPageContext,
    push,
    certId,
  ]);

  // Save an in-progress cert
  const save = useCallback(
    async ({ assignedUserId = '', logEvent = null } = {}) => {
      if (!certContext) {
        return false;
      }

      const finalContext = {
        ...certContext.save(),
        status: STATUS.inProgress,
      };

      if (!finalContext.lobTemplates.length && !assignedUserId) {
        NotificationService.error(
          'A certificate must include at least one policy'
        );
        return false;
      }

      if (unsavedNotesExist) {
        await notes.save();
      }
      if (assignedUserId) {
        finalContext.assignedUserId = assignedUserId;
      }
      try {
        await FrontendHttpClient.put(
          `/api/v2/${
            bulkJobId
              ? validObjectNames.BULK_JOB_CERTIFICATES
              : validObjectNames.OUTBOUND_CERTIFICATES
          }/${certId}${logEvent ? `?logEvent=${logEvent}` : ''}`,
          finalContext
        );

        mutateCertContext();

        NotificationService.success(`Certificate saved`);
        return true;
      } catch (e) {
        NotificationService.error('Unable to save certificate');
        return false;
      }
    },
    [
      bulkJobId,
      certContext,
      certId,
      mutateCertContext,
      notes,
      unsavedNotesExist,
    ]
  );

  const issue = useCallback(
    async (values, markAsInactive) => {
      setIsLoading(true);
      try {
        const certContacts = {
          contacts: {
            faxNumber: values.faxNumber,
            faxName: values.faxName,
            faxSubject: values.faxSubject,
            faxBody: values.faxBody,
            emailTo: values.emailTo,
            emailReplyTo: values.emailReplyTo,
            emailCc: values.emailCc,
            emailBcc: values.emailBcc,
            emailSubject: values.emailSubject,
            emailBody: values.emailBody,
          },
        };

        // Need to split text area to array though sometimes an array is passed in.
        certContacts.contacts.emailTo = Array.isArray(values.emailTo)
          ? values.emailTo
          : splitText(values.emailTo);
        certContacts.contacts.emailCc = Array.isArray(values.emailCc)
          ? values.emailCc
          : splitText(values.emailCc);
        certContacts.contacts.emailBcc = Array.isArray(values.emailBcc)
          ? values.emailBcc
          : splitText(values.emailBcc);
        certContacts.contacts.faxNumber = Array.isArray(values.faxNumber)
          ? values.faxNumber
          : splitText(values.faxNumber);

        await FrontendHttpClient.put(
          route({
            pathname: '/api/v2/outbound_certificates/[id]',
            query: { id: certId },
          }),
          certContacts
        );
      } catch (err) {
        NotificationService.error('Error saving contacts for certificate');
        setIsLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append('send', values.send || '');
      formData.append('sendType', values.sendType || '');
      formData.append('status', status.toString());
      formData.append('markAsInactive', markAsInactive);
      formData.append('flow', certificateFlows.OUTBOUND);
      formData.append('userFile', values.userFile);

      try {
        const response = await FrontendHttpClient.post(
          route({
            pathname: '/api/v2/outbound_certificates/[id]/issue',
            query: { id: certId },
          }),
          formData
        );
        if (response.data.message) {
          NotificationService.success(response.data.message);
        }

        if (unsavedNotesExist) {
          await notes.save();
        }
        goToFileCabinet();
      } catch (err) {
        if (err.response.data.message) {
          NotificationService.error(err.response.data.message);
        }
        setIsLoading(false);
      }
    },
    [certId, goToFileCabinet, notes, status, unsavedNotesExist]
  );

  useEffect(() => {
    getCertData();
    getCertContext();
  }, [getCertContext, getCertData]);

  return {
    data: certificate,
    sample: sampleCert,
    fileLink,
    fileName,
    assignedUser,
    editor: certContext,
    bulkJobCount,
    issue,
    save,
    isLoading,
    goToFileCabinet,
    mutateCertContext,
    mutateCertData,
  };
};
