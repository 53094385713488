import { forwardRef } from 'react';
import cx from 'clsx';

import Button from '@/components/button/button';

import styles from '../styles.module.scss';

const ButtonPrimary = forwardRef((props, ref) => {
  const { className, labelClassName, ...buttonProps } = props;

  return (
    <Button
      ref={ref}
      className={cx(styles.primaryButton, className)}
      labelClassName={cx(styles.primaryButtonLabel, labelClassName)}
      {...buttonProps}
      data-testid="primary-button"
    />
  );
});

export default ButtonPrimary;
