export const formPositions = Object.freeze(
  /** @type {const} */ ({
    TOP: 'top',
    CENTER: 'center',
    RIGHT: 'right',
    LEFT: 'left',
    ADDITIONAL: 'additional',
    ADDITIONAL_SCHEDULES: 'schedule',
    BOTTOM: 'bottom',
  })
);
