import { useRouter } from 'next/router';

/**
 *
 * @returns {string} The id of the current bulk job
 */
export const useBulkJobId = () => {
  const router = useRouter();
  return router.query.bulkJobId;
};
