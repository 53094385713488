import { Fragment } from 'react';
import Head from 'next/head';
import { ToastContainer } from 'react-toastify';
import { Amplify } from 'aws-amplify';
import { Provider as JotaiProvider } from 'jotai';
import 'focus-visible';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import awsconfig from '@/config/amplify.json';
import '@/styles/base.scss';
import '@/styles/editor.scss';
import '@/styles/grid.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import dynamic from 'next/dynamic';
import { SocketProvider } from '@/components/socket/provider';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallBackComponent from '@/components/errors/error-fallback';
import { errorHandler } from '@/utils/errors';

const Store = dynamic(() =>
  import('@/utils/app-context').then(mod => mod.Store)
);
const ModalProvider = dynamic(() =>
  import('@/components/modal-provider').then(mod => mod.ModalProvider)
);
const SWRProvider = dynamic(() =>
  import('@/components/swr-provider').then(mod => mod.SWRProvider)
);

Amplify.configure({ ...awsconfig, ssr: true });

function MyApp({ Component, pageProps }) {
  const MasterLayout = Component.MasterLayout
    ? Component.MasterLayout
    : Fragment;

  return (
    <SWRProvider>
      <Store>
        <JotaiProvider>
          <SocketProvider>
            <ModalProvider>
              <Head>
                <title>Certificate Hero</title>
              </Head>
              <MasterLayout>
                <ErrorBoundary
                  FallbackComponent={({ error }) => {
                    return (
                      <ErrorFallBackComponent
                        {...{ errorMessage: error.message }}
                      />
                    );
                  }}
                  onError={errorHandler}
                >
                  <Component {...pageProps} />
                </ErrorBoundary>
              </MasterLayout>
              <ToastContainer />
            </ModalProvider>
          </SocketProvider>
        </JotaiProvider>
      </Store>
    </SWRProvider>
  );
}

export default MyApp;
